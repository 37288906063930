import {
  DocumentFormat,
  QualioDocument,
  QualioDocumentSection,
} from '../api/model/document';
import { ChangeControl, ChangeControlSection } from '../types/DocumentCreate';

export const convertApiDocumentToViewableInstance = (
  fetchedDocument: QualioDocument,
): QualioDocument => {
  const convertedDocument = { ...fetchedDocument };
  convertedDocument.sections.forEach((section: QualioDocumentSection) => {
    if (
      section.type === 'attachment' &&
      fetchedDocument.document_format === DocumentFormat.DocumentUpload
    ) {
      section.type = 'text';
    }
    if (
      section.type === 'attachment' &&
      fetchedDocument.document_format !== DocumentFormat.DocumentUpload
    ) {
      convertFroalaAttachmentSection(section);
    }
  });
  return convertedDocument;
};

export const convertApiChangeControlToViewableInstance = (
  changeControlDoc: ChangeControl,
) => {
  const convertedDocument = { ...changeControlDoc };
  convertedDocument.sections.forEach((section: ChangeControlSection) => {
    if (section.type === 'attachment') {
      section.type = 'text';
      convertChangeControlSection(section);
    }
  });
  return convertedDocument;
};

const convertFroalaAttachmentSection = (section: QualioDocumentSection) => {
  if (section.section_attachments && section.section_attachments.length > 0) {
    let content = '<ol> ';
    section.section_attachments?.forEach((attachment) => {
      // need to normalise the attachment url
      content += buildAttachmentSectionListItem(
        attachment.url,
        attachment.filename,
      );
    });
    content += '</ol>';
    section.content = content;
  } else if (section.content === '') {
    // we only want to replace the content with empty p tags if there is no other text content
    // if there was text content in a section of type "attachment" we keep it
    section.content = '<p></p>';
  }
  section.section_attachments = null;
  section.type = 'text';
};

const convertChangeControlSection = (section: ChangeControlSection) => {
  if (section.attachments && section.attachments.length > 0) {
    let content = '<ol> ';
    section.attachments?.forEach((attachment) => {
      // need to normalise the attachment url
      content += buildAttachmentSectionListItem(
        attachment.url,
        attachment.filename,
      );
    });
    content += '</ol>';
    section.content = content;
  } else if (section.content === '') {
    // we only want to replace the content with empty p tags if there is no other text content
    // if there was text content in a section of type "attachment" we keep it
    section.content = '<p></p>';
  }
  section.attachments = null;
  section.type = 'text';
};

const buildAttachmentSectionListItem = (url: string, filename: string) => {
  let listItem = '';
  // need to normalise the attachment url
  const normalisedUrl = url.replace(/^\/\//, '');
  const protocol = `http${process.env.NODE_ENV !== 'development' ? 's' : ''}`;
  listItem += `<li> <a href="${protocol}://${normalisedUrl}"> ${filename}</a></li> <span class="filePreviewLink"></span><br>`;
  return listItem;
};

import { QMenuButton } from '@qualio/ui-components';
import { useMemo } from 'react';
import { DocumentVersion } from '../../../../../api/model/document';
import { getVersionsToDisplayOrCompare, sortVersions } from '../../RenderLogic';

type DisplayVersionSelectProps = {
  handleOptionChange: (
    isLatest: boolean,
    docId: number,
    option: string,
  ) => void;
  version: string;
  versions: DocumentVersion[];
  latestVersion: string | null;
};

export const getVersionsToDisplay = (
  version: string | null | undefined,
  versions: DocumentVersion[],
) => {
  if (versions.length === 0) {
    return [];
  }

  const sortedVersions = sortVersions(versions);
  const latestMajorVersion = sortedVersions[sortedVersions.length - 1].major;

  return getVersionsToDisplayOrCompare(version, latestMajorVersion, versions);
};

const getVersionsToDocIdMap = (versions: DocumentVersion[]) => {
  return versions.reduce((acc: Record<string, number>, current) => {
    acc[current.version] = current.document_id;
    return acc;
  }, {});
};

export const DisplayVersionSelect = ({
  latestVersion,
  handleOptionChange,
  version,
  versions = [],
}: DisplayVersionSelectProps) => {
  const sortedVersions = useMemo(() => sortVersions(versions), [versions]);

  const label = useMemo(() => {
    if (sortedVersions.length > 1) {
      return version === latestVersion
        ? `Latest version (${version})`
        : `Version (${version})`;
    }
    return `Latest version (${version})`;
  }, [sortedVersions.length, version, latestVersion]);

  const versionToDocIdMap = useMemo(
    () => getVersionsToDocIdMap(versions),
    [versions],
  );

  const versionOptions = useMemo(() => {
    const versionsToDisplay = getVersionsToDisplay(version, sortedVersions);

    return sortVersions(versionsToDisplay)
      .reverse()
      .map((version) => {
        return {
          label:
            version.version === latestVersion
              ? `Latest version (${version.version})`
              : `Version (${version.version})`,
          id: version.version,
        };
      });
  }, [sortedVersions, latestVersion, version]);

  return (
    <QMenuButton
      isDisabled={versionOptions.length === 0}
      variant="ghost"
      items={versionOptions}
      onItemClick={(option) => {
        if (option && versions.length > 1) {
          const isLatest = option.id === latestVersion;
          const docId = versionToDocIdMap[option.id];
          handleOptionChange(isLatest, docId, option.id);
        }
      }}
      buttonLabel={label}
    />
  );
};

import { useToastProvider } from '@qualio/ui-components';
import { useState } from 'react';
import { DocumentStatus } from '../../../../../../../api/model/document';
import { DeleteEntityModal } from '../../../../../DeleteEntityModal/index';
import { WorkspaceTabs } from '../../../../types';
import {
  hasAllowedStatusesForAction,
  hasRequiredPermissionsForAction,
  isValidTabForAction,
} from '../../../BulkActions';
import { BulkActionButton } from '../../BulkActionButton/index';
import { handleStatusChange } from '../ChangeStatusService';
import {
  ChangeStatusButtonProps,
  IsChangeStatusActionAllowedCallback,
} from '../types';

export const DeleteButton = (props: ChangeStatusButtonProps) => {
  const { showToast } = useToastProvider();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onClose = () => setIsModalOpen(false);
  const [isDeletingDocs, setIsDeletingDocs] = useState(false);

  const toastActionStringActive = 'delete';
  const toastActionStringPassive = 'deleted';

  const handleDelete = async () => {
    setIsDeletingDocs(true);
    await handleStatusChange(
      props.selectedDocuments,
      { status: DocumentStatus.Deleted },
      props.setSelectedDocumentsMap,
      showToast,
      toastActionStringActive,
      toastActionStringPassive,
    );
    setIsDeletingDocs(false);
    setIsModalOpen(false);
    props.refetch();
  };

  const canDelete: IsChangeStatusActionAllowedCallback = (
    user,
    statuses,
    activeTab,
    requiredPermissions,
  ) => {
    if (
      !hasRequiredPermissionsForAction(requiredPermissions, user.permissions) ||
      !isValidTabForAction(
        [WorkspaceTabs.YOUR_DOCUMENTS, WorkspaceTabs.ALL_DOCUMENTS],
        activeTab,
      )
    ) {
      return false;
    }
    return hasAllowedStatusesForAction([DocumentStatus.Draft], statuses);
  };

  const modalText = (
    <p>
      Are you sure you want to delete {props.selectedDocuments.length}{' '}
      {props.selectedDocuments.length > 1 ? 'documents' : 'document'}? The
      action can be undone at the document level.
    </p>
  );

  const entityName =
    props.selectedDocuments.length > 1 ? 'documents' : 'document';

  return (
    <>
      <BulkActionButton
        {...props}
        isActionAllowed={canDelete}
        onClick={() => setIsModalOpen(true)}
      />
      <DeleteEntityModal
        isDeletingEntity={isDeletingDocs}
        isOpen={isModalOpen}
        onCloseModal={onClose}
        deleteHandler={handleDelete}
        isDestructive={false}
        modalText={modalText}
        entityName={entityName}
      />
    </>
  );
};

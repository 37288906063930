import { z } from 'zod';
import {
  MedtechCompanyUserGroup,
  MedtechUserV2,
  MTBEUserCompany,
} from '../user';

export enum DocumentFormat {
  QualioNativeDocument = 'qualio_document',
  DocumentUpload = 'file_document',
}

export function isDocumentFormat(value: string): value is DocumentFormat {
  return Object.values<string>(DocumentFormat).includes(value);
}

export enum PageOrientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
}

export enum DocumentStatus {
  Effective = 'effective',
  Draft = 'draft',
  For_Review = 'for_review',
  For_Approval = 'for_approval',
  Approved = 'approved',
  Approval_Declined = 'approval_declined',
  Superseded = 'superseded',
  Retired = 'retired',
  Deleted = 'deleted',
  For_Periodic_Review = 'for_periodic_review',
  For_Retirement = 'for_retirement',
}

export enum DocumentCategory {
  REGULAR = 'regular',
  ISSUE_RELATED = 'issue_related',
}

export type GroupUser = {
  action_document_version: string | null;
  action_done: boolean;
  action_pending: boolean;
  action_time: number | null;
  active: boolean;
  company: MTBEUserCompany;
  company_id: number;
  currently_selected: boolean;
  email: string;
  full_name: string;
  id: number;
  user_id: number;
  is_qm: boolean;
  permissions: string[];
  previous_action_is_valid: boolean;
  usercompany_id: number;
  usergroups: [MedtechCompanyUserGroup];
};

export type DocumentUser = { full_name: string; id: number; email: string };

export enum DocumentTraineeStatus {
  COMPLETE = 'Complete',
  UNASSIGNED = 'Unassigned',
  OVERDUE = 'Overdue',
  DUE = 'Due',
}

export type DocumentTrainee = {
  id: number;
  periodic: boolean;
  action_time: number | null;
  done_by_api: boolean;
  action_done: boolean;
  training_active: boolean;
  training_id: number;
  action_assigned_time: number;
  document_id: QualioDocument['id'];
  usercompany_id: number;
  retraining: boolean;
  action_document_version: string | null;
  reason: string | null;
  training_plan: boolean;
  training_removed_version: string | null;
  training_removed?: boolean;
  training_removed_date: number | null;
  training_completion_period: number; // Comes from MTBE company entity
  due_date: number | null;
  status: 'Complete' | 'Unassigned' | 'Overdue' | 'Due';
  number_of_completions: number;
  // Usercompany
  email: MedtechUserV2['email'];
  user_id: MedtechUserV2['id'];
  full_name: MedtechUserV2['full_name'];
  company_id: number;
  active: boolean | null;
  ghost: boolean;
  previous_user_company: number | null;
  invite_status: 'pending' | 'accepted' | 'declined' | 'canceled';
  invite_time: number | null;
  invite_acted_on: number | null;
  currently_selected: boolean;
};

export type DocumentAudit = {
  action: string;
  change_id: string | null;
  comment: string | null;
  company_id: number | null;
  description: string;
  document_id: QualioDocument['id'];
  id: number;
  invalid: boolean | null;
  ip_address: string;
  issue_id: number | null;
  task_id: number | null;
  timestamp: number;
  user_id: number;
  user: Pick<MedtechUserV2, 'email' | 'full_name' | 'id'>;
};

export type QualioDocumentAuditGroups = {
  review_group?: GroupUser[];
  approver_group?: GroupUser[];
  training_group?: GroupUser[];
  qm_group?: GroupUser[];
  editor_group?: GroupUser[];
};

export type QualioDocument = {
  approval_date: number | null;
  approvers_and_timestamps?: string[][];
  audit_groups?: QualioDocumentAuditGroups;
  audits: DocumentAudit[];
  author: DocumentUser;
  category: DocumentCategory;
  cloud_document_version: number | null;
  code: string;
  company_id: number;
  company_logo: string;
  content_last_modified_at: number;
  modified_time: number;
  document_matrix_id: string;
  editor_version: string | null;
  effective_date: number | null;
  effective_on_approval: boolean;
  id: number;
  issue_id: number | null;
  issue: { code: string } | null;
  is_most_recent_version: boolean;
  major_version: number;
  minor_version: number;
  orientation: PageOrientation;
  owner: DocumentUser;
  review_period: number | null;
  review_date: number | null;
  reviewers_and_timestamps?: string[][];
  sections: QualioDocumentSection[];
  status_id: string;
  status: string;
  superseded_date?: string;
  tag_ids: number[];
  template_id: number;
  title: string;
  text_sections_titles_visible: boolean;
  training_available: boolean;
  training_required: boolean;
  trainees: DocumentTrainee[];
  type: string;
  document_format: DocumentFormat;
  file_download_user_groups?: MedtechCompanyUserGroup[];
};

export type SmartLinkedDocumentSuggestion = {
  code: string;
  document_matrix_id: string;
  id: number;
  major_version: number;
  minor_version: number;
  modified_time: number;
  rank: number;
  smartlink_search_key: string;
  status_id: string;
  title: string;
  training_available: boolean;
  uuid: string;
};

export type QualioDocumentSection = {
  // instead of `attachments` we rely on `section_attachments` that always includes populated objects if not null
  section_attachments: QualioDocumentAttachment[] | null;
  content: string;
  cloud_section_version: number | undefined;
  document_id: number;
  id: number;
  modified_at: number;
  modified_by_id: number;
  position: number;
  template_section_id: number;
  title: string;
  type: string;
};

export type ExternalFileSourceType = 'one_drive';

export type QualioDocumentAttachment = {
  date_added: number;
  filename: string;
  id: number | string;
  preview_state: string;
  preview_type: string;
  preview_url: string;
  url: string;
  external_file_last_fetched_at: number | null;
  external_file_download_url: string | null;
  external_file_source_type: ExternalFileSourceType | null;
  external_file_url: string | null;
};

export type QualioDocumentAuditGroupsPatch =
  | {
      review_group: number[];
      approver_group: number[];
    }
  | {
      review_group: number[];
      approver_group: number[];
      editor_group?: number[];
    };

export type QualioDocumentPatch = {
  content_last_modified_at?: number;
  editor_version?: string;
  id?: number;
  orientation?: PageOrientation;
  sections?: QualioDocumentSectionPatch[];
  title?: string;
  document_format?: DocumentFormat;
  owner_id?: number;
  audit_groups?: QualioDocumentAuditGroupsPatch;
  tag_ids?: number[];
};

export type QualioDocumentSectionPatch = {
  cloud_section_version?: number;
  content: string;
  id: number;
  type?: string;
};

export const createQualioDocumentPatchForSectionUpdate: (
  document: QualioDocument,
  updatedSection: QualioDocumentSection | undefined,
  sectionText: string,
  cloudSectionVersion: number | undefined,
  editorVersion: string,
  updateLastModifiedTimestamp: boolean,
) => QualioDocumentPatch = (
  document,
  updatedSection: QualioDocumentSection | undefined,
  sectionText,
  cloudSectionVersion,
  editorVersion,
  updateLastModifiedTimestamp,
) => {
  if (updatedSection) {
    updatedSection.cloud_section_version = cloudSectionVersion ?? undefined;
    updatedSection.content = sectionText;
  }

  const patchDocument: QualioDocumentPatch = {
    // we omit all sections there were not updated,
    // MTBE will only update the content and cloud_section_version of the updated section and keep the others untouched
    editor_version: editorVersion,
    sections: updatedSection ? [updatedSection] : [],
    content_last_modified_at: updateLastModifiedTimestamp
      ? Date.now() / 1000
      : document.content_last_modified_at,
  };

  return patchDocument;
};

export type QualioDocumentStatus = {
  status: string;
};

export type DocumentVersion = {
  document_id: QualioDocument['id'];
  version: string;
  major: string;
  minor: string;
  status_id: QualioDocument['status_id'];
};

export interface DocumentSectionRevision {
  modified_at: number;
  id: number;
  title: string;
  content: string;
  attachments: QualioDocumentAttachment[] | null;
  position: number;
  document_id: number;
  cloud_section_version: string | null;
  type: string;
  template_section_id: number;
  modified_by_id: number;
}

export type DocumentTemplateUpgradeInfo = {
  can_upgrade: boolean;
  current_template: {
    id: number;
    version: number;
  };
  newest_template: {
    id: number;
    version: number;
  };
  non_upgradeable_reason: string;
};

export const ChangeControlAIResponse = z.object({
  rationale: z.string().array(),
  changeControl: z
    .object({
      title: z.string(),
      content: z.string(),
    })
    .array(),
});

export type DocumentPreviewResponse = {
  preview_document_sections: PreviewItem[];
};

export type PreviewItems = {
  preview_result_per_version_change: PreviewItem[];
};

export type PreviewItem = {
  attachments: number[];
  document: {
    text_section_titles_visible: boolean;
  };
  document_id: number;
  sections: QualioDocumentSection[];
};

export type DocumentTemplateUpgradeIdList = {
  updated_documents_ids: number[];
};

export type NextVersionDetails = {
  id: number;
  status_id: DocumentStatus;
  version: string;
};

import {
  Filtering,
  Pagination,
  QBox,
  QSelect,
  QSelectItem,
  useCurrentUser,
} from '@qualio/ui-components';

import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { QualioDocument } from '../../../api/model/document';
import { StatusFilterMapType, WorkspaceTabs } from './types';
import { getDefaultStatus } from './utils';

export type StatusFilterProps = {
  statusFiltersMap: StatusFilterMapType;
  currentTab: WorkspaceTabs | null;
  setSelectedDocumentsMap: (selected: Map<number, QualioDocument>) => void;
};

const getFilterOptions = (statusFiltersMap: StatusFilterMapType) => {
  return Object.keys(statusFiltersMap).map((key) => {
    return {
      label: statusFiltersMap[key].label,
      value: key,
    };
  });
};

export const StatusFilter = ({
  statusFiltersMap,
  currentTab,
  setSelectedDocumentsMap,
}: StatusFilterProps) => {
  const currentUser = useCurrentUser();
  const filterOptions = getFilterOptions(statusFiltersMap);
  const [searchParams] = useSearchParams();
  const filtering = Filtering.useFiltering();
  const pagination = Pagination.usePaginationMaybe();
  const statusParam = searchParams.get('subfilter');
  const selectedStatus =
    statusParam ?? getDefaultStatus(currentTab, currentUser);

  useEffect(() => {
    pagination?.setPageIndex(0);
    // Can't have `paginaton` as a dependency or else pagination will not work
    // eslint-disable-next-line
  }, [statusParam]);

  const onStatusChange = (status: QSelectItem | null) => {
    if (status) {
      filtering.setFilters({
        subfilter: status.value,
      });
    }
    setSelectedDocumentsMap(new Map());
  };

  return (
    <QBox w="300px">
      <QSelect
        size="sm"
        options={filterOptions}
        onChange={onStatusChange}
        value={selectedStatus}
        aria-label="Document status"
        data-cy={'workspace-status-filter'}
      ></QSelect>
    </QBox>
  );
};

import { CurrentUserContextType } from '@qualio/ui-components';
import { ChangeControlComment } from '../../../../../api/changeControl';
import {
  HistoricalOpenCommentThread,
  HistoricalResolvedCommentThread,
} from '../HistoricalComments/HistoricalCommentThread';

interface HistoricalCommentThreadListProps {
  threads: HistoricalCommentThread[];
  currentUser: CurrentUserContextType;
}

export type HistoricalCommentThread = ChangeControlComment & {
  replies: ChangeControlComment[];
};

export const HistoricalCommentThreadList = ({
  threads,
  currentUser,
}: HistoricalCommentThreadListProps) => {
  return (
    <>
      {threads?.map((commentThread: HistoricalCommentThread) => {
        switch (commentThread.resolved) {
          case false:
            return (
              <HistoricalOpenCommentThread
                thread={commentThread}
                currentUser={currentUser}
                key={commentThread.id}
              />
            );
          case true:
            return (
              <HistoricalResolvedCommentThread
                thread={commentThread}
                currentUser={currentUser}
                key={commentThread.id}
              />
            );
          default:
            return <></>;
        }
      })}
    </>
  );
};

import axios, { AxiosRequestConfig } from 'axios';
import { RemoteFileMetadata } from '../views/components/QFilePicker/index';
import { migrationManagerApiClient } from './index';
import {
  FileUpdate,
  MigrationDTO,
  MigrationFile,
  MigrationStatus,
} from './model/migration';

export type ExternalFile = RemoteFileMetadata & {
  presignedUrl: string;
};

const Routes = {
  Migrations: (companyId: number) => `company/${companyId}/migrations`,
  Files: (companyId: number) => `company/${companyId}/files`,
} as const;

export const migrationManagerApi = {
  fetchMigration: async (companyId: number, migrationId: string) => {
    const { data } = await migrationManagerApiClient.get<MigrationDTO>(
      `${Routes.Migrations(companyId)}/${migrationId}`,
    );
    return data;
  },

  createMigration: async (
    migrationName: string,
    changeControlStatement: string,
    companyID: number,
  ): Promise<MigrationDTO> => {
    const { data } = await migrationManagerApiClient.post<MigrationDTO>(
      `/${Routes.Migrations(companyID)}/v2`,
      {
        migrationName,
        changeControlStatement,
      },
    );
    return data;
  },

  startMigration: async (companyId: number, id: string) =>
    await migrationManagerApiClient.post(
      `${Routes.Migrations(companyId)}/${id}/v2/start`,
    ),

  updateStatus: async (
    companyId: number,
    migrationId: string,
    newStatus: MigrationStatus,
  ) =>
    await migrationManagerApiClient.patch<void>(
      `${Routes.Migrations(companyId)}/${migrationId}`,
      {
        status: newStatus,
      },
    ),
};

export const migrationManagerFileApi = {
  deleteFile: async (companyId: number, fileId: string): Promise<void> =>
    migrationManagerApiClient.delete(`${Routes.Files(companyId)}/${fileId}`),

  updateFiles: async (
    companyId: number,
    migrationId: string,
    payload: FileUpdate[],
  ) => {
    const { data } = await migrationManagerApiClient.patch<MigrationFile[]>(
      `${Routes.Migrations(companyId)}/${migrationId}/files`,
      {
        fileUpdates: payload,
      },
    );
    return data;
  },
  uploadFile: async (
    companyId: number,
    migrationId: string,
    file: File,
  ): Promise<MigrationFile> => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const encodedFileName = encodeURIComponent(file.name);

    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': file.type,
        'Content-Disposition': `inline; filename="${encodedFileName}"; filename*=UTF-8''${encodedFileName}`,
      },
      cancelToken: source.token,
    };

    const formData = new FormData();
    formData.append('file_1', file);

    const { data } = await migrationManagerApiClient.post<MigrationFile>(
      `${Routes.Migrations(
        companyId,
      )}/${migrationId}/v2/files/upload?isBulkUpload=true`,
      formData,
      config,
    );
    if (!data.hasOwnProperty('filename') && data.hasOwnProperty('fileName')) {
      data.filename = (data as any).fileName;
      delete (data as any).fileName;
    }
    return data;
  },
  uploadExternalFile: async (
    migrationId: string,
    companyId: number,
    file: ExternalFile,
  ): Promise<MigrationFile> => {
    const { presignedUrl, filename, source, webUrl, downloadUrl } = file;
    const { data } = await migrationManagerApiClient.post<MigrationFile>(
      `/${Routes.Migrations(companyId)}/${migrationId}/files/upload/external`,
      {
        presignedUrl,
        filename,
        source,
        webUrl,
        downloadUrl,
      },
    );
    if (!data.hasOwnProperty('filename') && data.hasOwnProperty('fileName')) {
      data.filename = (data as any).fileName;
      delete (data as any).fileName;
    }
    return data;
  },
  uploadFiles: async (
    companyId: number,
    migrationId: string,
    files: File[] | ExternalFile[],
    isExternal?: boolean,
  ): Promise<{ successfulFiles: MigrationFile[]; errors: any[] }> => {
    const promises = files.map((file) =>
      isExternal
        ? migrationManagerFileApi.uploadExternalFile(
            migrationId,
            companyId,
            file as ExternalFile,
          )
        : migrationManagerFileApi.uploadFile(
            companyId,
            migrationId,
            file as File,
          ),
    );

    const migrationFilesResults = await Promise.allSettled(promises);

    const successfulFiles: MigrationFile[] = [];
    const errors: any[] = [];

    migrationFilesResults.forEach((r) => {
      if (r.status === 'fulfilled') {
        successfulFiles.push(r.value);
      } else if (r.status === 'rejected') {
        errors.push(r.reason);
      }
    });

    return { successfulFiles, errors };
  },
};

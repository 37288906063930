import { QStack, QTag } from '@qualio/ui-components';

export type tagAction = 'removed' | 'added';

export const QTagList = ({
  tagNames,
  action,
}: {
  tagNames: string[];
  action: tagAction;
}) => {
  return (
    <>
      {tagNames.length
        ? tagNames.map((tagName: string) => {
            return (
              <QStack pr={4} key={tagName}>
                {action === 'removed' && (
                  <QTag variantColor={'red'}>- {tagName}</QTag>
                )}
                {action === 'added' && (
                  <QTag variantColor={'green'}>+ {tagName}</QTag>
                )}
              </QStack>
            );
          })
        : null}
    </>
  );
};

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const sentenceCase = (str: string) => {
  return str
    .split(' ')
    .map((word, index) => (index === 0 ? capitalize(word) : word))
    .join(' ');
};

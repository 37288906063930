import { useEffect } from 'react';

import {
  QualioQThemeProvider,
  ToastProvider,
  useToastProvider,
} from '@qualio/ui-components';
import { genericApplicationErrorMessage } from '../../../messages/UserErrorMessages';
import { buildToastDescription } from '../../../util/ErrorManagementUtils';

interface ErrorToastProps {
  hasError: boolean;
  msg?: string | null;
}

const ToastProviderChildren = ({ msg, hasError }: ErrorToastProps) => {
  const { showToast } = useToastProvider();
  useEffect(() => {
    if (hasError) {
      showToast({
        id: 'error-toast-manager',
        replace: true,
        title: 'Error',
        description:
          buildToastDescription(msg) || genericApplicationErrorMessage,
        duration: null,
        status: 'error',
      });
    }
    // if we add showToast as a dep the toast shows twice
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError, msg]);
  return <></>;
};

export const ErrorToastManager = (props: ErrorToastProps) => {
  return (
    <QualioQThemeProvider>
      <ToastProvider enableDelegation={true}>
        <ToastProviderChildren {...props} />
      </ToastProvider>
    </QualioQThemeProvider>
  );
};

import { useMemo, useState } from 'react';

type SortDirection = 'ascending' | 'descending';

export const useSortedList = <T>(list: T[] | undefined) => {
  const [sortDirection, setSortDirection] =
    useState<SortDirection>('descending');

  const sortedList = useMemo(() => {
    if (!list) return undefined;
    return sortDirection === 'descending' ? [...list] : [...list].reverse();
  }, [list, sortDirection]);

  const toggleSortDirection = () => {
    setSortDirection((prev) =>
      prev === 'ascending' ? 'descending' : 'ascending',
    );
  };

  return { sortedList, sortDirection, toggleSortDirection };
};

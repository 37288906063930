import { QStack, QText } from '@qualio/ui-components';
import React from 'react';

type OwnerProps = {
  id: number;
  fullName: string;
};

export const Owner: React.FC<OwnerProps> = ({ id, fullName }) => {
  return (
    <QStack direction="row" alignItems="center" data-cy={`cc-owner-${id}`}>
      <QText fontSize="xs" color="gray.800" weight="semibold">
        Owner
      </QText>
      <QText fontSize="xs" color="gray.500">
        {fullName}
      </QText>
    </QStack>
  );
};

import { useToastProvider } from '@qualio/ui-components';
import { useState } from 'react';
import { DocumentStatus } from '../../../../../../../api/model/document';
import { CredentialConfirmationModal } from '../../../../../CredentialConfirmationModal/index';
import { WorkspaceTabs } from '../../../../types';
import {
  hasAllowedStatusesForAction,
  hasRequiredPermissionsForAction,
  isValidTabForAction,
} from '../../../BulkActions';
import { BulkActionButton } from '../../BulkActionButton/index';
import { handleStatusChange } from '../ChangeStatusService';
import {
  ChangeStatusButtonProps,
  IsChangeStatusActionAllowedCallback,
} from '../types';

export const MakeEffectiveButton = (props: ChangeStatusButtonProps) => {
  const { showToast } = useToastProvider();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onClose = () => setIsModalOpen(false);

  const buttonActionLabel = 'Make effective';
  const modalHeading = `Make ${
    props.selectedDocuments.length === 1 ? 'document' : 'documents'
  } effective`;
  const modalText = `Enter your digital signature to make ${
    props.selectedDocuments.length
  } ${
    props.selectedDocuments.length === 1 ? 'document' : 'documents'
  } effective`;

  const toastActionStringActive = 'make effective';
  const toastActionStringPassive = 'made effective';

  const handleMakeEffective = async (
    email: string,
    password: string,
    comment?: string,
  ) => {
    await handleStatusChange(
      props.selectedDocuments,
      { status: DocumentStatus.Effective, email, password, comment },
      props.setSelectedDocumentsMap,
      showToast,
      toastActionStringActive,
      toastActionStringPassive,
    );
    setIsModalOpen(false);
    props.refetch();
  };

  const canMakeEffective: IsChangeStatusActionAllowedCallback = (
    user,
    statuses,
    activeTab,
    requiredPermissions,
    selectedDocuments,
  ) => {
    if (selectedDocuments.length > 1) {
      return false;
    }
    if (
      !hasRequiredPermissionsForAction(requiredPermissions, user.permissions) ||
      !isValidTabForAction(
        [WorkspaceTabs.YOUR_ACTIONS, WorkspaceTabs.ALL_DOCUMENTS],
        activeTab,
      )
    ) {
      return false;
    }
    return hasAllowedStatusesForAction([DocumentStatus.Approved], statuses);
  };

  return (
    <>
      <BulkActionButton
        {...props}
        isActionAllowed={canMakeEffective}
        onClick={() => setIsModalOpen(true)}
      />
      <CredentialConfirmationModal
        isOpen={isModalOpen}
        onCloseModal={onClose}
        handleStatusChange={handleMakeEffective}
        modalText={modalText}
        modalHeading={modalHeading}
        buttonActionLabel={buttonActionLabel}
      />
    </>
  );
};

import { useCurrentUser } from '@qualio/ui-components';
import { useQueries } from 'react-query';
import { IntegrationsAPI } from '../api/integrationsApi';
import { QFilePickerError, QFilePickerErrorType, Source } from '../types';

export type useAccessTokenQueryOptions = {
  source: Source;
  onError?: (errors: QFilePickerError[]) => void;
};
const Duration = {
  seconds: (seconds: number) => seconds * 1000,
  minutes: (minutes: number) => minutes * Duration.seconds(60),
} as const;
export const useAccessToken = ({
  source,
  onError,
}: useAccessTokenQueryOptions) => {
  const { companyId } = useCurrentUser();

  const queries = useQueries([
    {
      queryKey: ['access-token', source, companyId],
      queryFn: () => IntegrationsAPI.getAccessToken(companyId, source),
      onError: () => {
        onError?.([
          {
            type: QFilePickerErrorType.CANNOT_ACCESS,
            message:
              'Unable to access OneDrive, please contact your Administrator.',
          },
        ]);
      },
      staleTime: Duration.minutes(10),
    },
    {
      queryKey: ['access-token', `onedrive-business`, companyId],
      queryFn: () =>
        IntegrationsAPI.getAccessToken(companyId, `onedrive-business`),
      onError: () => {
        onError?.([
          {
            type: QFilePickerErrorType.CANNOT_ACCESS,
            message:
              'Unable to access OneDrive, please contact your Administrator.',
          },
        ]);
      },
      staleTime: Duration.minutes(10),
    },
  ]);

  const isFetched = queries.every((query) => query.isFetched);

  return {
    isFetched,
    accessToken: queries[0].data?.access_token,
    accessTokenBusiness: queries[1].data?.access_token,
    tenant: queries[0].data?.tenant,
  };
};

import { QPageLoader } from '@qualio/ui-components';
import React, { useContext } from 'react';
import { EditorMode } from '../../../util/EditModeUtils';
import { DocumentOverviewContext } from '../DocumentOverview/Context';
import { EditTrainingAssessment } from './EditTrainingAssessment';
import { useTrainingAssessment } from './hooks/useTrainingAssessment';
import { TrainingAssessment } from './TrainingAssessment';
import { TrainingAssessmentErrorState } from './TrainingAssessmentErrorState';

export const TrainingAssessmentContainer: React.VFC = () => {
  const { qualioDocument, currentEditorMode } = useContext(
    DocumentOverviewContext,
  );
  const {
    data: trainingAssessment,
    isLoading,
    isError,
  } = useTrainingAssessment(qualioDocument.id);
  const isEditMode = currentEditorMode === EditorMode.EDIT;

  if (isError) {
    return <TrainingAssessmentErrorState />;
  }
  if (isLoading) {
    return <QPageLoader />;
  }

  if (isEditMode) {
    return (
      <EditTrainingAssessment
        trainingAssessment={trainingAssessment}
        documentId={qualioDocument.id}
      />
    );
  }

  return <TrainingAssessment questions={trainingAssessment?.questions} />;
};

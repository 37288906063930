import {
  QBox,
  QRadio,
  QRadioGroup,
  QStack,
  QText,
} from '@qualio/ui-components';
import { Question } from './types/trainingAssessment';

type TrainingAssessmentQuestionCardProps = Omit<Question, 'id'> & {
  questionLabel?: string;
};

export const TrainingAssessmentQuestionCard = ({
  question,
  answers,
  questionLabel,
}: TrainingAssessmentQuestionCardProps) => {
  return (
    <QBox
      width="648px"
      maxWidth="100%"
      padding="24px"
      border="1px solid"
      borderColor="gray.100"
      borderRadius="4px"
      data-cy="training-assessment-question-card"
      marginTop="16px"
    >
      <QStack justify="space-between">
        {questionLabel && (
          <QText
            weight="semibold"
            fontSize="sm"
            color="gray.500"
            paddingBottom="16px"
          >
            {questionLabel}
          </QText>
        )}
        <QText
          fontWeight="semibold"
          paddingBottom="8px"
          color="gray.700"
          fontSize="sm"
        >
          {question}
        </QText>
        <QRadioGroup>
          <QStack direction="column" gap="2">
            {answers.map(({ answer, is_correct }) => (
              <QRadio key={answer} isDisabled={true} isChecked={is_correct}>
                <QStack direction="row" align="baseline">
                  {is_correct ? (
                    <>
                      <QText fontWeight="semibold">{answer}</QText>
                      <QText color="gray.500" width="140px" fontSize="sm">
                        Correct answer
                      </QText>
                    </>
                  ) : (
                    <QText>{answer}</QText>
                  )}
                </QStack>
              </QRadio>
            ))}
          </QStack>
        </QRadioGroup>
      </QStack>
    </QBox>
  );
};

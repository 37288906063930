import { tokenApiClient } from '../api';

export const tokenApi = {
  async fetch(params?: { [key: string]: any }): Promise<string> {
    const searchParams = new URLSearchParams(params);
    return tokenApiClient
      .get('', { params: searchParams })
      .then((res) => res.data);
  },
};

import { QIcon, QStack, QText } from '@qualio/ui-components';
import { Tag } from '../../../api/model/tag';

type TagLabelProps = {
  tag: Tag;
};
export const TagLabel = ({ tag }: TagLabelProps) => {
  return (
    <QStack direction="row" align="center">
      <QText isTruncated margin="auto">
        {tag.name}
      </QText>
      {!!tag.group_ids.length && <QIcon iconName="Lock" size="xs" />}
    </QStack>
  );
};

import { useEffect } from 'react';

export const useBodyBackground = (bodyClass: string, force?: boolean) => {
  useEffect(() => {
    const body = document.querySelector('body');
    const firstCondition =
      force ||
      (force === undefined && body?.classList?.contains('chakra-ui-light'));
    if (firstCondition && body && !body?.classList?.contains(bodyClass)) {
      body.classList.add(bodyClass);
    }

    return () => {
      const body = document.querySelector('body');
      body?.classList?.remove(bodyClass);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

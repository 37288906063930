import { CommentsOnly } from '@ckeditor/ckeditor5-comments';
import { TrackChangesEditing } from '@ckeditor/ckeditor5-track-changes';
import { QualioAttachmentCKUploadAdapter } from '../plugins/Attachment/QualioAttachmentCKUploadAdapter';
import { CKEditorInstance } from '../types/CKEditorInstance';
import { CKEditorUtils } from './CKEditorUtils';

export enum EditorMode {
  EDIT,
  VIEW,
  TRACKCHANGES,
}

export const mapIntentStringToEditorMode = (mode: string) => {
  if (mode === 'edit') {
    return EditorMode.EDIT;
  } else if (mode === 'suggest') {
    return EditorMode.TRACKCHANGES;
  }
  return EditorMode.VIEW;
};

export const mapEditorModeToIntentString = (mode: EditorMode) => {
  let intent = 'view';
  if (mode === EditorMode.EDIT) {
    intent = 'edit';
  } else if (mode === EditorMode.TRACKCHANGES) {
    intent = 'suggest';
  }
  return intent;
};

export const configureBalloonPanels = (editor: CKEditorInstance) => {
  const balloonPlugin = editor.plugins.get('ContextualBalloon');
  balloonPlugin.view.on(
    'change:isVisible',
    (eventInfo, name, value, oldValue) => {
      const isCommentOrReadOnlyEditor =
        isCommentsOnlyMode(editor) || editor.isReadOnly;
      if (isCommentOrReadOnlyEditor && value) {
        balloonPlugin.view.hide();
      }
    },
  );
  balloonPlugin.view.on('change:left', (eventInfo, name, value, oldValue) => {
    if (value < 0 || oldValue < 0) {
      balloonPlugin.view.set('left', 0);
    }
  });
};

export const toggleCheckboxMode = (editable: boolean) => {
  const checkboxes = document.querySelectorAll(
    '.ck-content input[type=checkbox]',
  );
  if (editable) {
    checkboxes.forEach((checkbox) => {
      checkbox.classList.remove('non-editable-mode-wrapper');
    });
  } else {
    checkboxes.forEach((checkbox) => {
      checkbox.classList.add('non-editable-mode-wrapper');
    });
  }
};

export const isCommentsOnlyMode = (editor: CKEditorInstance): boolean => {
  const commentsOnlyModePlugin = CKEditorUtils.getPluginIfAvailable(
    editor,
    'CommentsOnly',
  ) as CommentsOnly;
  if (commentsOnlyModePlugin) {
    return commentsOnlyModePlugin.isEnabled;
  } else {
    return false;
  }
};

export const setCommentsOnlyModeTo = (
  editors: CKEditorInstance[],
  value: boolean,
) => {
  editors.forEach((editor) => {
    const commentsOnlyModePlugin = CKEditorUtils.getPluginIfAvailable(
      editor,
      'CommentsOnly',
    ) as CommentsOnly;
    if (commentsOnlyModePlugin) {
      commentsOnlyModePlugin.isEnabled = value;
    }
    if (value === false) {
      const drop = editor.plugins.get('DragDrop');
      // comments only mode disables drag and drop, but
      // disabling the mode doesn't enable drag and drop
      drop.clearForceDisabled('commentsOnlyMode');
    }
  });
};

export const enableUploadAdapter = (editor: CKEditorInstance) => {
  const fileRepositoryPlugin = editor.plugins.get('FileRepository');
  fileRepositoryPlugin.createUploadAdapter = (loader: any) => {
    return new QualioAttachmentCKUploadAdapter(
      loader,
      editor.config.get('sectionId') as any,
      editor.config.get('entityType') as any,
    );
  };
};

export const configureQualioPluginsForTrackChanges = (
  editor: CKEditorInstance,
) => {
  const trackChangesEditing =
    CKEditorUtils.getPluginIfAvailable<TrackChangesEditing>(
      editor,
      'TrackChangesEditing',
    );

  if (trackChangesEditing) {
    trackChangesEditing.enableCommand('QualioCodeBlockCommand');

    trackChangesEditing.enableCommand(
      'QualioSectionListCommand',
      (executeCommand: any, options: any) => {
        executeCommand({ ...options, isSuggestionMode: true });
      },
    );
  }
};

export const toggleTrackChanges = (
  editors: CKEditorInstance[],
  mode: EditorMode,
) => {
  editors.forEach((editor) => {
    const trackChangesCommand = editor.commands.get('trackChanges');
    const trackMode =
      mode === EditorMode.TRACKCHANGES && !trackChangesCommand?.value;
    const editMode = mode === EditorMode.EDIT && trackChangesCommand?.value;
    if (trackMode || editMode) {
      trackChangesCommand?.execute();
    }
  });
};

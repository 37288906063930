import { QIcon, QStack, QText, QTooltip } from '@qualio/ui-components';
import React from 'react';
import { MedtechUserV2 } from '../../../../../../../api/user';

type NameCellProps = {
  user: MedtechUserV2;
  usersWithoutAccess: readonly MedtechUserV2[];
};

export const NameCell: React.FC<NameCellProps> = ({
  user,
  usersWithoutAccess,
}) => {
  const userCanViewDoc =
    usersWithoutAccess.findIndex((item) => item.id === user.id) === -1;
  if (!userCanViewDoc) {
    return (
      <QTooltip label={'Trainee does not have access to document'}>
        <QStack data-cy="trainee-without-access" direction="row" align="center">
          <QText>{user.full_name}</QText>
          <QIcon iconName="EyeOff" size="xs" />
        </QStack>
      </QTooltip>
    );
  }
  return <QText>{user.full_name}</QText>;
};

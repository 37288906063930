//------------------------- Error Boundary Messages -----------------------------//

export const genericApplicationErrorMessage =
  'An error has occurred with this document. Please try refreshing or contact support';

//------------------------- Notification Toast Messages -----------------------------//

export const savingFailedErrorMessage =
  'An error has occurred saving this document';

export const savingFailedCloudSectionVersion = (sectionNumber: any) =>
  `We couldn't save this change to section ${sectionNumber} as the editor content is out of date. Try making another change or reloading the page to fetch the latest version.`;

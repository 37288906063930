import { QBox, QLink, QText } from '@qualio/ui-components';
import React, { useMemo, useState } from 'react';

type CommentedOnTextProps = {
  commentThread: CommentsRepository.CommentThreadToDisplay;
  letterAmountToDisplay: number;
};

export const CommentedOnSuggestionText: React.FC<CommentedOnTextProps> = ({
  commentThread,
  letterAmountToDisplay,
}) => {
  const [ifShowFullCommentedOn, setIfShowFullCommentedOn] = useState(false);

  const isInsertTableSuggest = useMemo(() => {
    return commentThread.suggestionContent?.indexOf('*Insert:* table') === 0;
  }, [commentThread.suggestionContent]);

  const isReplaceSuggest = useMemo(() => {
    return commentThread.suggestionContent?.indexOf('*Replace:*') === 0;
  }, [commentThread.suggestionContent]);

  const suggestionContentAndAction = useMemo(() => {
    if (!commentThread.suggestionContent) {
      return {};
    }

    let suggestionContent;
    if (isInsertTableSuggest) {
      suggestionContent = commentThread.suggestionContent.replace(
        '*with text*',
        'with text',
      );
    } else if (isReplaceSuggest) {
      suggestionContent = commentThread.suggestionContent.replace(
        '*with*',
        'with',
      );
    } else {
      suggestionContent = commentThread.suggestionContent;
    }

    const splitToken = '*';
    const splitSectionAmount = 3;
    const splitResultArray = suggestionContent.split(
      splitToken,
      splitSectionAmount,
    );

    return { content: splitResultArray[2], action: splitResultArray[1] };
  }, [commentThread.suggestionContent, isReplaceSuggest, isInsertTableSuggest]);

  const suggestionContentPartialText = useMemo(() => {
    if (
      suggestionContentAndAction.content &&
      suggestionContentAndAction.content.length > letterAmountToDisplay
    ) {
      return (
        suggestionContentAndAction.content.slice(0, letterAmountToDisplay) +
        '...'
      );
    }

    return suggestionContentAndAction.content;
  }, [suggestionContentAndAction.content, letterAmountToDisplay]);

  return (
    <QBox display="flex" p={2}>
      {ifShowFullCommentedOn && (
        <QBox display="flex" data-cy="selectedText">
          <QText fontWeight="bold" whiteSpace="nowrap">
            {suggestionContentAndAction.action}&nbsp;
          </QText>
          <QText wordBreak="break-word">
            {suggestionContentAndAction.content}&nbsp;
          </QText>
          <span onClick={() => setIfShowFullCommentedOn(false)}>
            <QLink data-cy="showLess">
              <QText whiteSpace="nowrap">show less</QText>
            </QLink>
          </span>
        </QBox>
      )}
      {!ifShowFullCommentedOn && (
        <QBox display="flex" data-cy="selectedTextLarge">
          <QText fontWeight="bold" whiteSpace="nowrap">
            {suggestionContentAndAction.action}&nbsp;
          </QText>
          <QText wordBreak="break-word">{suggestionContentPartialText}</QText>
        </QBox>
      )}
      {!ifShowFullCommentedOn &&
        suggestionContentAndAction.content &&
        suggestionContentAndAction.content.length > letterAmountToDisplay && (
          <QBox pl={1} onClick={() => setIfShowFullCommentedOn(true)}>
            <QLink data-cy="showMore">
              <QText whiteSpace="nowrap">show more</QText>
            </QLink>
          </QBox>
        )}
    </QBox>
  );
};

import { QButtonGroup, useCurrentUser } from '@qualio/ui-components';
import { useContext } from 'react';
import { DocumentVersion } from '../../../../../api/model/document';
import { PeriodicReview } from '../../../../../api/model/periodicReview';
import { DocumentOverviewContext } from '../../Context';
import { shouldShowApprovalControls } from '../../RenderLogic';
import { ApproveButton } from './ApproveButton';
import { DeclineButton } from './DeclineButton';

type ApprovalControlsProps = {
  periodicReview?: PeriodicReview;
  versions?: DocumentVersion[];
  refetchPeriodicReview: () => void;
};
export const ApprovalControls = (props: ApprovalControlsProps) => {
  const currentUser = useCurrentUser();
  const { periodicReview, versions } = props;
  const { qualioDocument } = useContext(DocumentOverviewContext);
  const showControls = shouldShowApprovalControls(
    currentUser,
    qualioDocument,
    periodicReview,
    versions,
  );

  return (
    <>
      {showControls && (
        <QButtonGroup>
          <DeclineButton {...props} />
          <ApproveButton {...props} />
        </QButtonGroup>
      )}
    </>
  );
};

import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import { documentApi } from '../../../../../api/document';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteEntityModal } from '../../../DeleteEntityModal/index';
import { shouldRenderDeleteButton } from '../../RenderLogic';

import { useContext } from 'react';

import { ButtonGroupContext } from '../../../Contexts/ButtonGroupContext';
import { DocumentOverviewContext } from '../../Context';

type DeleteDocumentProps = {
  renderClickableItem: (onClick: () => void) => JSX.Element;
};

export const DeleteDocumentAction = ({
  renderClickableItem,
}: DeleteDocumentProps) => {
  const currentUser = useCurrentUser();
  const { qualioDocument: document } = useContext(DocumentOverviewContext);
  const { setDisabled } = useContext(ButtonGroupContext);

  const navigate = useNavigate();
  const { showToast } = useToastProvider();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const onClose = () => setDisabled(false, setIsDeleteModalOpen);
  const [isDeletingDoc, setIsDeletingDoc] = useState(false);

  const modalText = <p>Are you sure you want to delete this document?</p>;

  const handleDelete = async () => {
    const toastCommonProps = {
      id: 'document-overview-delete-toast',
      replace: true,
    };

    setDisabled(true, setIsDeletingDoc);

    try {
      await documentApi.deleteDocument(document.id);

      setIsDeletingDoc(false);
      setIsDeleteModalOpen(false);

      showToast({
        status: 'success',
        title: 'Success!',
        description: 'Successfully deleted document.',
        ...toastCommonProps,
      });

      navigate('/workspace');
    } catch (error) {
      setDisabled(false, setIsDeletingDoc);
      setIsDeleteModalOpen(false);

      showToast({
        status: 'error',
        title: 'Error',
        description:
          'Oops! Something went wrong when attempting to delete document. Please try again',
        ...toastCommonProps,
      });
    }
  };

  if (!shouldRenderDeleteButton(document, currentUser)) {
    return null;
  }

  return (
    <>
      {renderClickableItem(() => {
        setDisabled(true);
        setIsDeleteModalOpen(true);
      })}

      <DeleteEntityModal
        isDeletingEntity={isDeletingDoc}
        isOpen={isDeleteModalOpen}
        onCloseModal={onClose}
        deleteHandler={handleDelete}
        isDestructive={false}
        modalText={modalText}
        entityName={'document'}
      />
    </>
  );
};

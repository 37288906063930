import { CurrentUser } from '@qualio/ui-components';
import {
  backendTaskApi,
  ExportTaskStatusResponse,
} from '../../../api/backendTask';
import { ExportMethodType } from '../DocumentWorkspace/types';
import { ExportFormat } from './ExportDocumentListButton/types';

export const exportViaMedtechTask = async (
  currentUser: CurrentUser,
  exportFormat: ExportFormat,
  tagsFilter: string[],
  searchQuery: string | null,
  onWaitingForDownload: (taskId: string) => void,
  onSuccess: (taskId: string, downloadLink: string) => void,
  onFailure: (taskId: string) => void,
  exportTaskMethod: ExportMethodType,
  sortingValue: string | null | undefined,
  statusFilters?: string[] | null | undefined,
) => {
  const { task_id: taskId } = await exportTaskMethod(
    currentUser,
    exportFormat,
    tagsFilter,
    searchQuery,
    sortingValue,
    statusFilters,
  );

  onWaitingForDownload(taskId);

  const result: ExportTaskStatusResponse =
    await backendTaskApi.pollTaskUntilCompletion(currentUser.companyId, taskId);
  if (result.successful) {
    if (result.payload) {
      onSuccess(taskId, result.payload);
    }
  } else {
    onFailure(taskId);
  }
};

import { QualioQThemeProvider, ToastProvider } from '@qualio/ui-components';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { BulkFileUpload } from './BulkFileUpload';
import styles from './BulkFileUpload.module.css';

export const BulkFileUploadRoot: React.FC<{ currentUser: CurrentUser }> = ({
  currentUser,
}) => {
  return (
    <div className={styles['bulk-upload-container']}>
      <QualioQThemeProvider>
        <ToastProvider enableDelegation={true}>
          <BulkFileUpload currentUser={currentUser} />
        </ToastProvider>
      </QualioQThemeProvider>
    </div>
  );
};

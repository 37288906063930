import { QFlex } from '@qualio/ui-components';
import React from 'react';
import { ChangeRequest } from '../../../../api/model/changeRequestItem';
import {
  ChangeRequestStatus,
  ChangeRequestStatusEnum,
} from './ChangeRequestStatus/ChangeRequestStatus';
import { DisplayTimestamp } from './DisplayTimestamp/DisplayTimestamp';
import { DotSeparator } from './DotSeparator/DotSeparator';
import { Owner } from './Owner/Owner';

type ChangeRequestSideDrawerHeaderInfoRowProps = {
  changeRequest: ChangeRequest;
};

export const ChangeRequestSideDrawerHeaderInfoRow: React.FC<
  ChangeRequestSideDrawerHeaderInfoRowProps
> = ({ changeRequest }) => {
  return (
    <QFlex
      flexDirection="row"
      alignItems="center"
      data-cy={'change-request-side-drawer-details-metadata'}
    >
      <ChangeRequestStatus status={changeRequest.status} />
      <DotSeparator />
      <Owner
        id={changeRequest.owner.id}
        fullName={changeRequest.owner.full_name}
      />
      <DotSeparator />
      {changeRequest.status === ChangeRequestStatusEnum.CLOSED &&
      changeRequest.closed_at ? (
        <DisplayTimestamp type={'Date closed'} date={changeRequest.closed_at} />
      ) : (
        <>
          <DisplayTimestamp
            type={'Date created'}
            date={changeRequest.created_at}
          />
          {changeRequest.last_modified_at ? (
            <>
              <DotSeparator />
              <DisplayTimestamp
                type={'Modified'}
                date={changeRequest.last_modified_at}
              />
            </>
          ) : null}
        </>
      )}
    </QFlex>
  );
};

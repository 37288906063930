import { QBox, QIconButton, QMenuButton } from '@qualio/ui-components';
import { useMemo } from 'react';
import { MigrationFile } from '../../../api/model/migration';

export interface UploadedFileRowActionsProps {
  uploadedFile: MigrationFile;
  isFirst: boolean;
  isLast: boolean;
  handleOrderChange: (file: MigrationFile, direction: string) => void;
  removeHandler: (fileToRemove: MigrationFile) => void;
  editHandler: (file: MigrationFile) => void;
  disableActions: boolean;
}

export const FileActions = {
  EDIT: {
    id: 'EDIT',
    label: 'Edit',
  },
  DELETE: {
    id: 'DELETE',
    label: 'Delete',
  },
} as const;

export const UploadedFileRowActions = ({
  uploadedFile,
  isFirst,
  isLast,
  handleOrderChange,
  removeHandler,
  editHandler,
  disableActions,
}: UploadedFileRowActionsProps) => {
  const handleMoveUp = () => {
    handleOrderChange(uploadedFile, 'up');
  };

  const handleMoveDown = () => {
    handleOrderChange(uploadedFile, 'down');
  };

  const actionHandlerMap: {
    [actionId: string]: (file: MigrationFile) => void;
  } = useMemo(
    () => ({
      [FileActions.DELETE.id]: removeHandler,
      [FileActions.EDIT.id]: editHandler,
    }),
    [editHandler, removeHandler],
  );

  return (
    <QBox>
      <QIconButton
        iconName="ArrowUp"
        aria-label="moveUp"
        onClick={handleMoveUp}
        isDisabled={isFirst || disableActions}
        data-cy="moveUp"
      />
      <QIconButton
        iconName="ArrowDown"
        aria-label="moveDown"
        onClick={handleMoveDown}
        isDisabled={isLast || disableActions}
        data-cy="moveDown"
      />
      <QMenuButton
        variant="icon"
        buttonLabel="options"
        iconName="MoreVertical"
        items={Object.values(FileActions)}
        onItemClick={({ id: actionId }) => {
          const actionHandler = actionHandlerMap[actionId];
          actionHandler(uploadedFile);
        }}
        isDisabled={disableActions}
        data-cy="moreOptions"
      />
    </QBox>
  );
};

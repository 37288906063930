import { ChangeControlComment } from '../../../api/changeControl';
import { HistoricalCommentThread } from '../DocumentHistoryPanel/Comments/HistoricalComments/HistoricalCommentThreadList';

export function extractTextFromTargets(
  targets: CommentsRepository.Target[] | undefined,
) {
  if (!targets) {
    return 'Not available';
  }

  let result = '';

  // we use the data of first text node as text indicator for the comment
  for (const target of targets) {
    if (target.name === 'text') {
      result = target.data;
      break;
    }
  }

  if (!result) {
    for (const target of targets) {
      if (target.name === 'imageBlock') {
        return 'Image element';
      } else if (target.name === 'table') {
        return 'Table element';
      }
    }
  }

  return result;
}

function getSuggestionActionTokenLength(suggestionContent: string) {
  return suggestionContent.split('*', 2).join().length + 2;
}

function needToInsertSuggestedOnText(suggestionContent: string | undefined) {
  return (
    suggestionContent &&
    (suggestionContent.indexOf('*Format:*') === 0 ||
      suggestionContent.indexOf('*Remove format:*') === 0 ||
      suggestionContent.indexOf('*Set font color:*') === 0 ||
      suggestionContent.indexOf('*Set font size:*') === 0 ||
      suggestionContent.indexOf('*Change to:*') === 0 ||
      suggestionContent.indexOf('*Set font background color:*') === 0)
  );
}

export function getRealSuggestionContent(
  suggestionContent: string | undefined,
  targets: Array<CommentsRepository.Target> | undefined,
) {
  if (!suggestionContent) {
    return suggestionContent;
  }

  const suggestedOn = extractTextFromTargets(targets);

  if (needToInsertSuggestedOnText(suggestionContent)) {
    return [
      suggestionContent.slice(
        0,
        getSuggestionActionTokenLength(suggestionContent),
      ),
      '"' + suggestedOn + '" ',
      suggestionContent.slice(
        getSuggestionActionTokenLength(suggestionContent),
      ),
    ].join('');
  } else if (suggestionContent.indexOf('*Set link:*') === 0) {
    return [suggestionContent, ' on text ', '"' + suggestedOn + '" '].join('');
  }

  return suggestionContent;
}

export const isSuggestion = (
  commentThread: CommentsRepository.CommentThreadToDisplay,
) => Boolean(commentThread.suggestionContent);

export const isAddSuggestion = (
  commentThread: CommentsRepository.CommentThreadToDisplay,
) => {
  const { suggestionContent } = commentThread;
  return Boolean(
    suggestionContent?.startsWith('*Insert:*') ||
      suggestionContent?.startsWith('*Replace:*'),
  );
};

const isFormatSuggestion = (
  commentThread: CommentsRepository.CommentThreadToDisplay,
) => {
  const { suggestionContent } = commentThread;
  return Boolean(
    suggestionContent?.startsWith('*Format:*') ||
      suggestionContent?.startsWith('*Remove format:*'),
  );
};

const isDeleteSuggestion = (
  commentThread: CommentsRepository.CommentThreadToDisplay,
) => {
  const { suggestionContent } = commentThread;
  return Boolean(suggestionContent?.startsWith('*Remove:*'));
};

export const getCardColor = (
  commentThread: CommentsRepository.CommentThreadToDisplay,
) => {
  const isComment = !isSuggestion(commentThread);

  if (isComment) {
    return 'comment';
  }

  let colour = 'suggestion__';

  if (isAddSuggestion(commentThread)) {
    colour = `${colour}add`;
  } else if (isDeleteSuggestion(commentThread)) {
    colour = `${colour}delete`;
  } else if (isFormatSuggestion(commentThread)) {
    colour = `${colour}format`;
  }

  return colour;
};

export const parseFetchedCommentThreadsForDisplay = (
  threads: CommentsRepository.CommentThreadFetched[],
): CommentsRepository.CommentThreadToDisplay[] =>
  threads.map((thread: CommentsRepository.CommentThreadFetched) => ({
    id: thread.id,
    status: thread.status,
    suggestionContent: getRealSuggestionContent(
      thread.suggestion_content,
      thread.suggested_on,
    ),
    suggestionDeletedAt: thread.suggestion_deleted_at,
    authorFullName: thread.author_full_name,
    suggestionCreatedAt: thread.suggestion_created_at,
    lastUpdatedBy: thread.last_updated_by,
    contentCommentedOn: thread.content_commented_on,
    deletedByUser: thread.deleted_by_user,
    deletedAt: thread.deleted_at,
    comments: thread.comments.map(
      (comment: CommentsRepository.CommentDataFetched) => ({
        comment: comment.content,
        commentTime: comment.updated_at || comment.created_at,
        author: comment.attributes.authorFullName ?? comment.user.id,
        ...comment,
      }),
    ),
  }));

export const parseHistoricalCommentThreadsForDisplay = (
  comments: ChangeControlComment[],
): HistoricalCommentThread[] => {
  let commentThreads: HistoricalCommentThread[] = [];
  commentThreads = comments
    .filter((comment) => {
      return !comment.reply_to;
    })
    .sort((a, b) => {
      return b.timestamp - a.timestamp;
    })
    .reduce((previousVal, currentVal) => {
      const commentThread = {
        ...currentVal,
        replies: comments.filter((comment: ChangeControlComment) => {
          return currentVal.id === comment.reply_to;
        }),
      };
      previousVal.push(commentThread);
      return previousVal;
    }, commentThreads);
  return commentThreads;
};

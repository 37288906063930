import { useState } from 'react';

import {
  QAlert,
  QBox,
  QButton,
  QCheckbox,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QStack,
  QText,
} from '@qualio/ui-components';

import { documentApi } from '../../../../../../api/document';
import { QualioDocument } from '../../../../../../api/model/document';
import { Group } from '../../../../../../api/model/group';
import { Tag } from '../../../../../../api/model/tag';

import { TagLabel } from '../../../../TagLabel/TagLabel';

type ManageTagsModalProps = {
  qualioDocument: QualioDocument;
  tags: Tag[];
  onClose: () => void;
  isOpen: boolean;
  onSuccess: () => void;
  onError: (e: unknown) => void;
  groups: Group[];
};

export const ManageTagsModal = ({
  qualioDocument,
  tags,
  onClose,
  isOpen,
  onSuccess,
  onError,
  groups,
}: ManageTagsModalProps) => {
  const [selectedTagsIds, setSelectedTagsIds] = useState<number[]>(
    qualioDocument.tag_ids,
  );
  const [selectedPrivateTags, setSelectedPrivateTags] = useState<Tag[]>([]);
  const [isApplyingChanges, setIsApplyingChanges] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const onWarningClose = () => setIsWarningModalOpen(false);

  const handleApplyChanges = async () => {
    setIsApplyingChanges(true);
    try {
      await documentApi.update({ tag_ids: selectedTagsIds }, qualioDocument.id);
      onSuccess();
      setIsApplyingChanges(false);
      onClose();
      setIsWarningModalOpen(false);
    } catch (e: unknown) {
      onError(e);
      setIsApplyingChanges(false);
    }
  };

  const getSelectedPrivateTags = () => {
    const selectedTags = tags.filter((tag) => selectedTagsIds.includes(tag.id));
    return selectedTags.filter((tag) => tag.group_ids.length > 0);
  };

  const handleCheckboxChange = (tagId: Tag['id'], isChecked: boolean) => {
    const currentTagSet = new Set(selectedTagsIds);
    if (isChecked) {
      currentTagSet.add(tagId);
    } else {
      currentTagSet.delete(tagId);
    }
    setSelectedTagsIds(Array.from(currentTagSet));
  };

  const handleManageTags = () => {
    const privateTags = getSelectedPrivateTags();

    if (privateTags.length > 0) {
      setSelectedPrivateTags(privateTags);
      setIsWarningModalOpen(true);
    } else {
      void handleApplyChanges();
    }
  };

  const getAffectedGroups = (): Group[] => {
    const affectedGroups = new Set<Group>();
    selectedPrivateTags.forEach((tag) => {
      tag.group_ids.forEach((groupId) => {
        const group = groups.find((g) => g.id === groupId);
        if (group) {
          affectedGroups.add(group);
        }
      });
    });

    return Array.from(affectedGroups);
  };

  const getGroupVisibilityText = () => {
    const affectedGroups = getAffectedGroups().map((group) => group.name);
    return (
      <>
        Visible to members of{' '}
        {affectedGroups.map((groupName, idx) => {
          if (idx === 0) {
            return <b key={groupName}>{groupName}</b>;
          }
          if (idx > 0 && idx < affectedGroups.length - 1) {
            return (
              <>
                , <b key={groupName}>{groupName}</b>
              </>
            );
          } else {
            return (
              <>
                {' '}
                and <b key={groupName}>{groupName}</b>
              </>
            );
          }
        })}{' '}
        group{affectedGroups.length > 1 ? 's' : ''} only.
        <br />
        <br />
        Trainees who do not belong to{' '}
        {affectedGroups.length > 1
          ? 'at least one of those groups'
          : 'this group'}{' '}
        will not have access for training on this document.
      </>
    );
  };

  const checkTagsHaveChanged = () => {
    return (
      qualioDocument.tag_ids.length !== selectedTagsIds.length ||
      selectedTagsIds.some((id) => !qualioDocument.tag_ids.includes(id))
    );
  };

  return (
    <>
      {isOpen && !isWarningModalOpen && (
        <QModal isOpen onClose={onClose}>
          <QModalHeader>
            <QText>Manage tags</QText>
          </QModalHeader>
          <QModalBody maxHeight="450px" style={{ overflowY: 'auto' }}>
            <QText fontSize="md" pb={'24px'}>
              Select tags to apply to your document.
            </QText>
            <QStack>
              {tags.map((tag) => (
                <QBox>
                  <QCheckbox
                    data-cy={`tag-${tag.name}`}
                    key={tag.id}
                    isChecked={selectedTagsIds.includes(tag.id)}
                    onChange={(e) =>
                      handleCheckboxChange(tag.id, e.target.checked)
                    }
                  >
                    <TagLabel tag={tag} />
                  </QCheckbox>
                </QBox>
              ))}
            </QStack>
          </QModalBody>
          <QModalActions>
            <QButton
              data-cy="document-manage-tags-cancel-button"
              variant="outline"
              onClick={onClose}
            >
              Cancel
            </QButton>
            <QButton
              data-cy="document-manage-tags-apply-changes-button"
              isLoading={isApplyingChanges}
              onClick={handleManageTags}
              isDisabled={!checkTagsHaveChanged()}
            >
              Apply changes
            </QButton>
          </QModalActions>
        </QModal>
      )}
      {isOpen && isWarningModalOpen && (
        <QModal isOpen onClose={onWarningClose}>
          <QModalHeader>
            <QText>Manage tags</QText>
          </QModalHeader>
          <QModalBody>
            <QAlert
              status="warning"
              title="This action affects content visibility"
              description="Some users' ability to view this content will be affected."
            />
            <QText fontSize="16px" pt="4">
              {getGroupVisibilityText()}
            </QText>
          </QModalBody>
          <QModalActions>
            <QButton variant="outline" onClick={onWarningClose}>
              Cancel
            </QButton>
            <QButton isLoading={isApplyingChanges} onClick={handleApplyChanges}>
              Continue
            </QButton>
          </QModalActions>
        </QModal>
      )}
    </>
  );
};

import { QualioQThemeProvider, ToastProvider } from '@qualio/ui-components';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styles from './App.module.css';
import { ProtectedRoute } from './ProtectedRoute';
import { BulkFileUploadRoot } from './views/components/BulkFileUpload/BulkFileUploadRoot';
import { DocumentCompareVersions } from './views/components/DocumentCompareVersions';
import { DocumentLibrary } from './views/components/DocumentLibrary';
import { DocumentOverview } from './views/components/DocumentOverview/DocumentOverview';
import { TrainingAssessmentView } from './views/components/DocumentOverview/DocumentTraining';
import { DocumentWorkspace } from './views/components/DocumentWorkspace/DocumentWorkspace';
import { ErrorHandlerProvider } from './views/components/ErrorHandler';
import { NotFound } from './views/components/NotFound';

export const App: React.FC<{
  currentUser: CurrentUser;
}> = ({ currentUser }) => {
  const routePaths = [
    '/document-editor/edit/:id',
    '/workspace/e/:id',
    '/workspace/e/:id/approve-pr',
    '/workspace/e/:id/training',
    '/workspace/documents/:id/approve-pr',
    '/workspace/documents/:id',
    '/workspace/documents/:id/training',
    '/library/documents/:id',
    '/library/documents/:id/training',
    '/library/e/:id',
    '/library/e/:id/training',
  ];

  const compareVersionsRoutePaths = [
    '/workspace/e/:id/compare/:baseCompare',
    '/workspace/documents/:id/compare/:baseCompare',
    '/library/documents/:id/compare/:baseCompare',
    '/library/e/:id/compare/:baseCompare',
    '/workspace/e/:id/training/compare/:baseCompare',
    '/workspace/documents/:id/training/compare/:baseCompare',
    '/library/documents/:id/training/compare/:baseCompare',
    '/library/e/:id/training/compare/:baseCompare',
  ];

  const trainingAssessmentRoutePaths = [
    '/workspace/e/:id/training/assessment',
    '/workspace/documents/:id/training/assessment',
    '/library/documents/:id/training/assessment',
    '/library/e/:id/training/assessment',
  ];

  const fileUploadRoute = '/upload';
  const libraryRoute = '/library';
  const workspaceRoute = '/workspace';
  const appClasses = `${styles['document-editor__app']}`;
  return (
    <div id="documentEditorApp" className={appClasses}>
      <ErrorHandlerProvider>
        <Router>
          <Routes>
            {compareVersionsRoutePaths.map((path, index) => {
              return (
                <Route
                  key={'DocumentCompareVersions' + index}
                  path={path}
                  element={<DocumentCompareVersions />}
                />
              );
            })}
            {trainingAssessmentRoutePaths.map((path, index) => {
              return (
                <Route
                  key={'TrainingAssessmentView' + index}
                  path={path}
                  element={
                    <QualioQThemeProvider>
                      <ToastProvider enableDelegation={true}>
                        <TrainingAssessmentView />
                      </ToastProvider>
                    </QualioQThemeProvider>
                  }
                />
              );
            })}
            {routePaths.map((path, index) => {
              return (
                <Route
                  key={index}
                  path={path}
                  element={
                    <QualioQThemeProvider>
                      <ToastProvider enableDelegation={true}>
                        <DocumentOverview />
                      </ToastProvider>
                    </QualioQThemeProvider>
                  }
                />
              );
            })}
            <Route
              path={fileUploadRoute}
              element={<BulkFileUploadRoot currentUser={currentUser} />}
            ></Route>
            <Route
              path={libraryRoute}
              element={
                <ProtectedRoute
                  predicate={() => currentUser.permissions.can_view_library}
                >
                  <QualioQThemeProvider>
                    <ToastProvider enableDelegation={true}>
                      <DocumentLibrary />
                    </ToastProvider>
                  </QualioQThemeProvider>
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path={workspaceRoute}
              element={
                <ProtectedRoute
                  predicate={() => currentUser.permissions.can_view_workspace}
                >
                  <QualioQThemeProvider>
                    <ToastProvider enableDelegation={true}>
                      <DocumentWorkspace />
                    </ToastProvider>
                  </QualioQThemeProvider>
                </ProtectedRoute>
              }
            ></Route>
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </Router>
      </ErrorHandlerProvider>
    </div>
  );
};

import { medtechApiClient } from './index';
import { ChangeRequest, ChangeRequestItem } from './model/changeRequestItem';
import { QualioDocument } from './model/document';

export const changeManagementApi = {
  async getChangeRequestForDoc(
    companyId: number,
    qualioDocument: QualioDocument,
  ): Promise<ChangeRequestItem> {
    const { document_matrix_id, id: documentId } = qualioDocument;
    const changeRequestUrl = `/v2/${companyId}/change-management/change-request-item/type/document/id/${document_matrix_id}/reference/${documentId}/change-request`;

    const { data } = await medtechApiClient.get(changeRequestUrl);

    return data;
  },

  async getChangeRequestById(
    companyId: number,
    changeRequestId: number,
  ): Promise<ChangeRequest> {
    const changeRequestUrl = `/v2/${companyId}/change-management/change-requests/${changeRequestId}`;

    const { data } = await medtechApiClient.get(changeRequestUrl);

    return data;
  },
};

import {
  QBox,
  QButton,
  QEmptyState,
  QSelect,
  QSelectItem,
  QSpinner,
  QStack,
  QText,
  useToastProvider,
} from '@qualio/ui-components';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { documentApi, GeneralActionTypeFilter } from '../../../../api/document';
import { ActivityLog } from '../../../../api/model/activityLog';
import { useSortedList } from '../../../../hooks/SortedList';
import {
  ACTIVITY_LOG_FETCH_DATA_ERROR,
  logCustomError,
} from '../../../../messages/LogErrorMessages';
import { ActivityLogCardWrapper } from './ActivityLogCardWrapper';

type ActivityTypeSelectOptions = QSelectItem<
  ActivityTypeOptions,
  GeneralActionTypeFilter
>;

export enum ActivityTypeOptions {
  ALL_ACTIVITIES = 'All activity',
  STATUS = 'Status & Versions',
  MODIFICATION = 'Modifications',
}

interface ActivityHistoryTabProps {
  companyId: number;
  documentId: string;
  isOpen: boolean;
}

export const ActivityHistoryTab = ({
  companyId,
  documentId,
  isOpen,
}: ActivityHistoryTabProps) => {
  const { showToast } = useToastProvider();
  const [selectedActivityType, setSelectedActivityType] =
    useState<GeneralActionTypeFilter>('all-activities');

  const activityTypeOptions: QSelectItem<
    ActivityTypeOptions,
    GeneralActionTypeFilter
  >[] = [
    {
      label: ActivityTypeOptions.ALL_ACTIVITIES,
      value: 'all-activities',
    },
    {
      label: ActivityTypeOptions.STATUS,
      value: 'lifecycle',
    },
    {
      label: ActivityTypeOptions.MODIFICATION,
      value: 'modifications',
    },
  ];

  const onActivityTypeChange = (
    selectedActivityType: ActivityTypeSelectOptions | null,
  ) => {
    if (selectedActivityType) {
      setSelectedActivityType(selectedActivityType.value);
    }
  };

  const loadActivityLogs = async (
    companyId: number,
    documentId: number,
    selectedActivityType: GeneralActionTypeFilter,
  ): Promise<ActivityLog[]> => {
    const logs = await documentApi.fetchActivityLogs(
      companyId,
      documentId,
      selectedActivityType,
    );
    return logs.map((activityLog: ActivityLog, idx: number) => {
      activityLog.key = idx;
      return activityLog;
    });
  };

  const { isLoading: isFetchingActivity, data: activityLogs } = useQuery({
    queryKey: ['activityLogs', companyId, documentId, selectedActivityType],
    queryFn: () =>
      loadActivityLogs(companyId, parseInt(documentId), selectedActivityType),
    refetchOnWindowFocus: false,
    enabled: isOpen,
    onError: (error) => {
      showToast({
        id: 'error-activity-log',
        title: 'Error',
        description: 'Could not retrieve activity logs',
        status: 'error',
      });
      logCustomError(ACTIVITY_LOG_FETCH_DATA_ERROR, { error });
    },
  });

  const {
    sortedList: sortedActivityLogs,
    sortDirection,
    toggleSortDirection,
  } = useSortedList(activityLogs);

  if (isFetchingActivity) {
    return (
      <QStack height="100%" minHeight="60vh" justify="center" align="center">
        <QSpinner />
        <QText data-cy="loading-document-history">Loading activity...</QText>
      </QStack>
    );
  }

  if (activityLogs?.length === 0) {
    if (selectedActivityType === 'all-activities') {
      return (
        <QEmptyState
          data-cy="document-history-empty-placeholder"
          title="No activity has been recorded."
          subtitle={
            "This document's activity will be tracked here as changes occur."
          }
        />
      );
    }

    return (
      <QStack fontSize="sm" pt="4" spacing="4">
        <QStack direction="row" justifyContent="space-between">
          <QBox width={160}>
            <QSelect
              size="sm"
              options={activityTypeOptions}
              onChange={onActivityTypeChange}
              value={selectedActivityType}
              aria-label="Activity type"
              data-cy="activity-type-select"
            ></QSelect>
          </QBox>
        </QStack>
        <QStack direction="column">
          <QEmptyState
            data-cy="document-history-empty-filter-placeholder"
            title="No activity of this type recorded."
            subtitle="Try adjusting your filter or check back later as new activities occur."
          />
        </QStack>
      </QStack>
    );
  }

  return (
    <QStack fontSize="sm" pt="4" spacing="4">
      <QStack direction="row" justifyContent="space-between">
        <QBox width={160}>
          <QSelect
            size="sm"
            options={activityTypeOptions}
            onChange={onActivityTypeChange}
            value={selectedActivityType}
            aria-label="Activity type"
            data-cy="activity-type-select"
          ></QSelect>
        </QBox>

        {sortDirection === 'ascending' && (
          <QButton
            rightIcon="ArrowUp"
            onClick={toggleSortDirection}
            variant="ghost"
          >
            Oldest first
          </QButton>
        )}
        {sortDirection === 'descending' && (
          <QButton
            rightIcon="ArrowDown"
            onClick={toggleSortDirection}
            variant="ghost"
          >
            Newest first
          </QButton>
        )}
      </QStack>
      <QStack direction="column" spacing="4">
        {sortedActivityLogs?.map((activityLog) => (
          <ActivityLogCardWrapper
            key={activityLog.key}
            actionType={activityLog.action}
            entityType={activityLog.entity_type}
            actor={activityLog.actor}
            timestamp={activityLog.timestamp}
            data={activityLog.data}
          />
        ))}
      </QStack>
    </QStack>
  );
};

import {
  QAlert,
  QBox,
  QButton,
  QCloseButton,
  QCollapse,
  QDrawer,
  QDrawerHeader,
  QHeading,
  QIconButton,
  QSpinner,
  QStack,
  QText,
  useToastProvider,
} from '@qualio/ui-components';
import React, { useContext, useState } from 'react';
import { QualioDocument } from '../../../../api/model/document';
import { CKEditorInstance } from '../../../../types/CKEditorInstance';
import {
  ChangeControlDTO,
  SuggestedChangeControlSection,
} from '../../../../types/DocumentCreate';
import { DocumentOverviewContext } from '../../DocumentOverview/Context';
import { aiService } from '../ai-service';
import styles from './GenerateCC.module.css';

import { Parser } from 'html-to-react';

interface SuggestedSectionsProps {
  suggestedSections: SuggestedChangeControlSection[];
}

const htmlToReactParser = new Parser();

const SuggestedSections: React.VFC<SuggestedSectionsProps> = ({
  suggestedSections,
}) => {
  return (
    <div className="suggestion-sections">
      {suggestedSections.map(
        (section: SuggestedChangeControlSection, index) => (
          <QBox style={{ marginBottom: '20px' }} key={section.title}>
            <QBox style={{ marginBottom: '10px' }}>
              <QHeading data-cy={`ai-generated-cc-section-heading-${index}`}>
                {section.title}
              </QHeading>
            </QBox>
            <QBox fontSize={'sm'}>
              {htmlToReactParser.parse(section.content)}
            </QBox>
          </QBox>
        ),
      )}
    </div>
  );
};

interface RationaleSectionProps {
  rationaleDetails: string[];
}

const RationaleSection = ({ rationaleDetails }: RationaleSectionProps) => {
  const [panelOpen, setPanelOpen] = useState(false);
  return (
    <QBox mb="4">
      <QAlert
        data-cy="ai-generated-cc-content-rationale"
        title="Rationale behind suggestions"
        description={
          <QStack
            alignItems="flex-start"
            width="full"
            flexDirection="row"
            justifyContent="space-between"
          >
            <QCollapse isOpen={panelOpen} startingHeight={42}>
              <QStack
                alignItems="flex-start"
                width="full"
                flexDirection="row"
                justifyContent="space-between"
              >
                <QText casing="none" noOfLines={panelOpen ? undefined : 2}>
                  <QBox pl={5}>
                    <ul>
                      {rationaleDetails.map((rationaleDetail, index) => {
                        return <li key={index}>{rationaleDetail}</li>;
                      })}
                    </ul>
                  </QBox>
                </QText>

                <QIconButton
                  iconName={panelOpen ? 'ChevronUp' : 'ChevronDown'}
                  size="xs"
                  aria-label="Open"
                  onClick={() => setPanelOpen((state) => !state)}
                  data-cy="toggle-rationale-alert-size"
                />
              </QStack>
            </QCollapse>
          </QStack>
        }
        status="info"
      />
    </QBox>
  );
};

export const GenerateCCComponent = ({
  changeControl,
  editors,
}: {
  changeControl: ChangeControlDTO;
  editors: CKEditorInstance[];
}) => {
  const { qualioDocument } = useContext(DocumentOverviewContext);

  if (!qualioDocument || !changeControl || qualioDocument.major_version < 1) {
    return <></>;
  }

  return (
    <GenerateCCBox
      document={qualioDocument}
      currentCC={changeControl}
      editors={editors}
    />
  );
};

const GenerateCCBox: React.FC<{
  document: QualioDocument;
  currentCC: ChangeControlDTO;
  editors: CKEditorInstance[];
}> = ({ document, currentCC, editors }) => {
  const { showToast } = useToastProvider();
  const [isFetching, setIsFetching] = useState(false);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [rationaleDetails, setRationaleDetails] = useState<string[]>([]);
  const [suggestedSections, setSuggestedSections] = useState<
    SuggestedChangeControlSection[] | null
  >(null);
  const [acceptedCurrentSuggestion, setAcceptedCurrentSuggestion] =
    useState(false);
  const handleAccept = (
    suggestedSections: SuggestedChangeControlSection[] | null,
  ) => {
    if (!suggestedSections) {
      return;
    }
    for (let i = 0; i < suggestedSections.length; i++) {
      const suggestedSection = suggestedSections[i];
      const sectionContent = suggestedSection.content;
      editors[i].data.set(sectionContent, {
        suppressErrorInCollaboration: true,
      });
      setAcceptedCurrentSuggestion(true);
    }

    showToast({
      id: 'accept-ai-change-control-success',
      status: 'success',
      title: 'Successfully applied change control to your document',
      description: 'Feel free to edit them as you wish, or generate a new one',
      replace: true,
    });

    setIsPanelOpen(false);
  };

  const handleGenerate = async () => {
    setIsPanelOpen(true);
    let numAttempts = 0;
    while (numAttempts < 3) {
      try {
        setIsFetching(true);
        const generatedCCText = await aiService.generateChangeControl(
          document,
          currentCC,
        );

        const rationaleItems = generatedCCText.rationale;
        if (rationaleItems.length === 0) {
          throw new Error('Failed to parse generated change control rationale');
        }
        setRationaleDetails(generatedCCText.rationale);

        const sections = generatedCCText.changeControl;
        if (Object.keys(sections).length === 0) {
          throw new Error(
            'Failed to parse generated change control suggested sections',
          );
        }
        setSuggestedSections(sections);
        setAcceptedCurrentSuggestion(false);
        setIsFetching(false);

        break;
      } catch (e: any) {
        console.warn(e);
        numAttempts++;
      }

      if (numAttempts >= 3) {
        setIsFetching(false);
        showToast({
          id: 'generate-ai-change-control-error',
          status: 'error',
          title: 'Cannot generate change control',
          description:
            'Failed to create change control suggestions for this document. Please try again',
          replace: true,
        });
      }
    }
  };

  return (
    <>
      {isPanelOpen && (
        <QDrawer
          size="lg"
          isOpen={true}
          onClose={() => {
            setIsPanelOpen(false);
          }}
        >
          <QDrawerHeader>
            <QText>Qualio AI</QText>

            <QStack direction="row">
              <QButton
                variant="outline"
                onClick={handleGenerate}
                isDisabled={isFetching}
                data-cy="regenerate-ai-change-control-drawer"
                leftIcon="QualioAI"
              >
                Regenerate
              </QButton>
              <QButton
                data-cy="accept-ai-change-control"
                onClick={() => handleAccept(suggestedSections)}
                isDisabled={isFetching || acceptedCurrentSuggestion}
              >
                Accept
              </QButton>
            </QStack>
            <QCloseButton onClick={() => setIsPanelOpen(false)} />
          </QDrawerHeader>

          {isFetching || !suggestedSections ? (
            <QStack
              height="100%"
              minHeight="60vh"
              justify="center"
              align="center"
            >
              <QSpinner />
              <QText data-cy="generate-suggestions" fontSize={'sm'}>
                Typing suggestions...
              </QText>
            </QStack>
          ) : (
            <>
              <QBox
                style={{ marginTop: '-10px', marginBottom: '10px' }}
                data-cy="ai-cc-disclaimer"
              >
                <QText fontSize="sm">
                  Use these suggestions as guidance. Review all generated
                  content carefully.
                </QText>
              </QBox>
              <RationaleSection rationaleDetails={rationaleDetails} />
              <SuggestedSections suggestedSections={suggestedSections} />
            </>
          )}
        </QDrawer>
      )}
      <QBox
        width="full"
        borderRadius="md"
        padding="16px 24px"
        mb={6}
        background="linear-gradient(90.14deg, #3D4CF2 0%, #7B4FFF 100%)"
        data-cy="generate-banner"
        className={styles['generate-cc-component']}
      >
        <QStack
          justify="space-between"
          alignContent="center"
          alignItems="center"
          direction="row"
        >
          <QStack direction="column">
            <QText weight="semibold" color="white" fontSize="md">
              Need suggestions?
            </QText>
            <QText fontSize="xs" color="white">
              Use Qualio's AI to compare document versions and intelligently
              suggest your change control content.
            </QText>
          </QStack>
          {suggestedSections !== null ? (
            <QStack direction="row">
              <QButton
                data-cy="regenerate-ai-change-control-banner"
                onClick={handleGenerate}
                isDisabled={isFetching}
                leftIcon="QualioAI"
                variant="outline"
              >
                Regenerate
              </QButton>
              <QButton
                data-cy="view-suggestions-ai-change-control-banner"
                variant="outline"
                onClick={() => setIsPanelOpen(true)}
                isDisabled={isFetching}
              >
                View suggestions
              </QButton>
            </QStack>
          ) : (
            <QButton
              variant="outline"
              leftIcon={'QualioAI' as any}
              onClick={handleGenerate}
              isDisabled={isFetching}
              data-cy="generate-ai-cc"
            >
              Generate
            </QButton>
          )}
        </QStack>
      </QBox>
    </>
  );
};

import { CurrentUserContextType } from '@qualio/ui-components';
import { OpenCommentThread, ResolvedCommentThread } from './CommentThread';
import {
  AcceptedSuggestionThread,
  DeclinedSuggestionThread,
} from './SuggestionThread';

interface CommentThreadListProps {
  threads: CommentsRepository.CommentThreadFetched[];
  currentUser: CurrentUserContextType;
}

export const CommentThreadList = ({
  threads,
  currentUser,
}: CommentThreadListProps) => {
  return (
    <>
      {threads?.map(
        (commentThread: CommentsRepository.CommentThreadFetched) => {
          switch (commentThread.status) {
            case 'Open':
              return (
                <OpenCommentThread
                  thread={commentThread}
                  currentUser={currentUser}
                  key={commentThread.id}
                />
              );
            case 'Resolved':
              return (
                <ResolvedCommentThread
                  thread={commentThread}
                  currentUser={currentUser}
                  key={commentThread.id}
                />
              );
            case 'Accepted':
              return (
                <AcceptedSuggestionThread
                  thread={commentThread}
                  currentUser={currentUser}
                  key={commentThread.id}
                />
              );
            case 'Rejected':
              return (
                <DeclinedSuggestionThread
                  thread={commentThread}
                  currentUser={currentUser}
                  key={commentThread.id}
                />
              );
            default:
              return <></>;
          }
        },
      )}
    </>
  );
};

import {
  QButton,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';
import { useContext, useState } from 'react';
import { documentApi } from '../../../../../api/document';
import { DocumentStatus } from '../../../../../api/model/document';
import { ButtonGroupContext } from '../../../Contexts/ButtonGroupContext';
import { DocumentOverviewContext } from '../../Context';
import { shouldRenderRevertToDraftButton } from '../../RenderLogic';

export const RevertToDraftButton = () => {
  const currentUser = useCurrentUser();
  const { qualioDocument, refetchDocument, currentEditorMode } = useContext(
    DocumentOverviewContext,
  );
  const { isDisabled, setDisabled } = useContext(ButtonGroupContext);
  const [isLoading, setIsLoading] = useState(false);
  const { showToast } = useToastProvider();

  const handleRevertToDraft = () => {
    setDisabled(true, setIsLoading);
    documentApi
      .changeStatus(qualioDocument.id, { status: DocumentStatus.Draft })
      .then(() => {
        setDisabled(false, setIsLoading);
        refetchDocument();
        showToast({
          description: 'Successfully reverted document to draft.',
          status: 'success',
          title: 'Success!',
        });
      })
      .catch((e) => {
        setDisabled(false, setIsLoading);
        const failureMessage =
          e.response?.data?.message ||
          'Failed to revert to draft. Please try again.';
        showToast({
          id: 'revert-to-draft-error-toast',
          status: 'error',
          title: 'Error',
          description: failureMessage,
        });
      });
  };

  if (
    !shouldRenderRevertToDraftButton(
      qualioDocument,
      currentUser,
      currentEditorMode,
    )
  ) {
    return null;
  }

  return (
    <QButton
      isLoading={isLoading}
      isDisabled={isDisabled}
      variant="outline"
      onClick={handleRevertToDraft}
      data-cy="doc-overview-revert-to-draft-button"
    >
      Revert to draft
    </QButton>
  );
};

import { QMenuButton } from '@qualio/ui-components';
import { useCallback, useMemo } from 'react';
import { DocumentVersion } from '../../../api/model/document';
import { getVersionsToDisplay } from '../DocumentOverview/Buttons/index';
import {
  sortVersions,
  versionIsGreaterThan,
} from '../DocumentOverview/RenderLogic';
import { parseVersion } from './util';

export type VersionSelectOperatorVariant = 'LHS' | 'RHS';

interface VersionSelectProps {
  latestVersion: string;
  version: string | null | undefined;
  handleOptionChange: (option: string) => void;
  allVersions: DocumentVersion[];
  variant: VersionSelectOperatorVariant;
  LHSVersion?: string | null;
}

export const VersionSelect = ({
  latestVersion,
  version,
  handleOptionChange,
  allVersions,
  variant,
  LHSVersion,
}: VersionSelectProps) => {
  const getVersionsToCompareAgainst = useCallback(
    (versions: DocumentVersion[]) => {
      if (!LHSVersion) {
        return versions;
      }

      return versions.filter((ver) => {
        const isVersionOlderThanDocVersion = !versionIsGreaterThan(
          ver,
          parseVersion(LHSVersion) as unknown as DocumentVersion,
        );

        return isVersionOlderThanDocVersion && ver.version !== LHSVersion;
      });
    },
    [LHSVersion],
  );

  const sortedVersions = useMemo(
    () => (allVersions ? sortVersions(allVersions) : []),
    [allVersions],
  );

  const versionsToDisplay = useMemo(() => {
    const versions = getVersionsToDisplay(version, allVersions);
    return variant === 'RHS' ? getVersionsToCompareAgainst(versions) : versions;
  }, [allVersions, variant, version, getVersionsToCompareAgainst]);

  const isCompareVersionsDisabled = useMemo(() => {
    return versionsToDisplay ? versionsToDisplay.length === 0 : false;
  }, [versionsToDisplay]);

  const versionOptions = useMemo(() => {
    const options = sortVersions(versionsToDisplay)
      .reverse()
      .map((ver) => ({
        label:
          ver.version !== latestVersion
            ? `Version (${ver.version})`
            : `Latest version (${ver.version})`,
        id: ver.version,
      }));

    return options;
  }, [latestVersion, versionsToDisplay]);

  const label = useMemo(() => {
    if (isCompareVersionsDisabled && !allVersions?.length) {
      return 'Compare against';
    }

    let versionLabel;
    if (sortedVersions.length === 1 && version === latestVersion) {
      versionLabel = `Latest version (${version})`;
    } else {
      if (variant === 'LHS') {
        if (sortedVersions.length === 0 || version === '') {
          return 'Select version';
        }

        if (sortedVersions.length > 1) {
          versionLabel =
            version === latestVersion
              ? `Latest version (${version})`
              : `Version (${version})`;
        } else {
          versionLabel =
            version === latestVersion
              ? `Latest version (${version})`
              : `(${version})`;
        }
      } else {
        if (sortedVersions.length === 0 || version === '') {
          return 'Compare against';
        }

        versionLabel =
          version === latestVersion
            ? `Latest version (${version})`
            : `(${version})`;
      }
    }
    return variant === 'LHS'
      ? versionLabel
      : `Compare against ${versionLabel?.toLowerCase()}`;
  }, [
    allVersions?.length,
    version,
    sortedVersions,
    variant,
    isCompareVersionsDisabled,
    latestVersion,
  ]);

  if (variant !== 'LHS' && variant !== 'RHS') {
    throw new Error(
      `Unsupported variant type: ${variant} must be one of LHS or RHS`,
    );
  }

  return (
    <QMenuButton
      isDisabled={isCompareVersionsDisabled}
      variant="ghost"
      items={versionOptions}
      onItemClick={(option) => option && handleOptionChange(option.id)}
      buttonLabel={label as string}
      data-cy={`compare-against-button`}
    />
  );
};

import {
  getFileExtension,
  translateExtensionToMimeType,
} from '../util/FileUploadUtils';
import { sleep } from '../util/TimerUtils';
import { documentApi, QualioAttachmentInfoResponse } from './document';

export const qualioAttachmentService = {
  async pollAttachmentInfoUntilCompletion(
    attachmentId: string,
    pollIntervalMillis?: number,
  ): Promise<QualioAttachmentInfoResponse | null> {
    let info: QualioAttachmentInfoResponse | null = null;
    let attempts = 0;
    let isFinished = false;
    while (attempts < 10 && !isFinished) {
      info = await documentApi.fetchAttachmentInfo(attachmentId);
      isFinished = this.attachmentPreviewFinished(info);
      if (!isFinished) {
        await sleep(pollIntervalMillis ?? 2000);
      }
      attempts++;
    }
    return info;
  },

  attachmentPreviewFinished(
    attachmentInfo: QualioAttachmentInfoResponse | null,
  ): boolean {
    return ['done', 'failed', 'n/a'].includes(
      attachmentInfo?.preview_state ?? '',
    );
  },

  attachmentPreviewSuccessful(
    attachmentInfo: QualioAttachmentInfoResponse,
  ): boolean {
    return (
      attachmentInfo.preview_type !== 'unsupported' &&
      (attachmentInfo.preview_state === 'done' ||
        (attachmentInfo.preview_state === 'n/a' &&
          getFileExtension(attachmentInfo.filename)?.toLocaleLowerCase() ===
            'pdf') ||
        (attachmentInfo.preview_state === 'n/a' &&
          attachmentInfo.preview_type === 'image'))
    );
  },

  determinePreviewContentMimeType(filename: string): string {
    let mimeType = '';
    const extension = getFileExtension(filename);
    if (extension) {
      mimeType = translateExtensionToMimeType(extension.toLowerCase());
    }
    return mimeType;
  },
};

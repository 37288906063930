import { QButton } from '@qualio/ui-components';
import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ButtonGroupContext } from '../../../Contexts/ButtonGroupContext';
import { ExportWrapper } from '../../../Export/ExportWrapper';
import { DocumentOverviewContext } from '../../Context';
import { shouldRenderExportButton } from '../../RenderLogic';

export const EXPORT_BUTTON_LABEL = 'Export';

export const ExportDocumentButton = () => {
  const { qualioDocument } = useContext(DocumentOverviewContext);
  const { isDisabled, setDisabled } = useContext(ButtonGroupContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  if (!shouldRenderExportButton(location)) {
    return null;
  }

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setDisabled(true);
  };

  return (
    <>
      <QButton
        isDisabled={isDisabled}
        variant="ghost"
        onClick={handleOpenModal}
        data-cy="document-export-button"
      >
        {EXPORT_BUTTON_LABEL}
      </QButton>
      {isModalOpen && (
        <ExportWrapper
          qualioDocument={qualioDocument}
          isDocumentExportModalOpen={isModalOpen}
          onClose={() => setDisabled(false, setIsModalOpen)}
        />
      )}
    </>
  );
};

import {
  QButton,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';
import { useContext, useState } from 'react';
import { documentApi } from '../../../../../api/document';
import { DocumentStatus } from '../../../../../api/model/document';
import { ButtonGroupContext } from '../../../Contexts/ButtonGroupContext';
import { DocumentOverviewContext } from '../../Context';
import { shouldRenderSendForReviewButton } from '../../RenderLogic';

export const SendForReviewButton = () => {
  const currentUser = useCurrentUser();
  const { qualioDocument, refetchDocument, currentEditorMode } = useContext(
    DocumentOverviewContext,
  );
  const { isDisabled, setDisabled } = useContext(ButtonGroupContext);
  const [isSendingForReview, setIsSendingForReview] = useState(false);
  const { showToast } = useToastProvider();

  const commonToastProps = {
    id: 'send-for-review-toast',
    replace: true,
  };

  const handleSendForReview = () => {
    setDisabled(true, setIsSendingForReview);
    documentApi
      .changeStatus(qualioDocument.id, { status: DocumentStatus.For_Review })
      .then(() => {
        setDisabled(false, setIsSendingForReview);
        refetchDocument();
        showToast({
          ...commonToastProps,
          description: 'Successfully sent document for review.',
          status: 'success',
          title: 'Sent for review!',
        });
      })
      .catch((e) => {
        setDisabled(false, setIsSendingForReview);
        const failureMessage =
          e.response?.data?.message ||
          'Failed to send for review. Please try again.';
        showToast({
          ...commonToastProps,
          status: 'error',
          title: 'Error',
          description: failureMessage,
        });
      });
  };

  if (
    !shouldRenderSendForReviewButton(
      qualioDocument,
      currentUser,
      currentEditorMode,
    )
  ) {
    return null;
  }

  return (
    <QButton
      isDisabled={isDisabled}
      isLoading={isSendingForReview}
      variant="outline"
      onClick={handleSendForReview}
      data-cy="doc-overview-send-for-review-button"
    >
      Send for review
    </QButton>
  );
};

import * as z from 'zod';

export const QriDetailsSchema = z.object({
  identifiers: z.object({
    id: z.string(),
    code: z.string().nullish(),
    qri: z.string(),
  }),
  resource: z.string(),
  name: z.string(),
});

export const QriBulkDetailsSchema = z.record(z.string(), QriDetailsSchema);

export type QriDetails = z.infer<typeof QriDetailsSchema>;

export type QriBulkDetails = z.infer<typeof QriBulkDetailsSchema>;

import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import { useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { documentApi } from '../../../../../../api/document';
import { ButtonGroupContext } from '../../../../Contexts/ButtonGroupContext';
import { DocumentUpgradePreviewModal } from '../../../../DocumentUpgradePreviewModal/DocumentUpgradePreviewModal';
import { DocumentOverviewContext } from '../../../Context';
import { shouldRenderApplyTemplateChangesButton } from '../../../RenderLogic';

type ApplyTemplateChangesButtonProps = {
  renderClickableItem: (onClick: () => void) => JSX.Element;
};

export const ApplyTemplateChangesButton = ({
  renderClickableItem,
}: ApplyTemplateChangesButtonProps) => {
  const currentUser = useCurrentUser();
  const { showToast } = useToastProvider();
  const { qualioDocument, refetchDocument } = useContext(
    DocumentOverviewContext,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setDisabled } = useContext(ButtonGroupContext);

  const canApplyTemplateChanges = useMemo(
    () => shouldRenderApplyTemplateChangesButton(qualioDocument, currentUser),
    [currentUser, qualioDocument],
  );

  const getDocumentUpgradeInformation = async () => {
    return await documentApi.getDocumentUpgradeInformation(
      currentUser.companyId,
      qualioDocument.id,
    );
  };

  const { data: documentUpgradeInformation } = useQuery({
    queryFn: getDocumentUpgradeInformation,
    queryKey: ['upgradeInfo', qualioDocument.id, currentUser.companyId],
    refetchOnWindowFocus: false,
    enabled: canApplyTemplateChanges,
    onError: () => {
      showToast({
        status: 'error',
        title: 'Error',
        description:
          "Failed to load information on the document's template version. Please refresh.",
      });
      setDisabled(false);
    },
  });

  if (!canApplyTemplateChanges || !documentUpgradeInformation?.can_upgrade) {
    return null;
  }

  const onSuccess = () => {
    refetchDocument();
    setDisabled(false);
  };

  const onClose = () => {
    setIsModalOpen(false);
    setDisabled(false);
  };

  return (
    <>
      {renderClickableItem(() => {
        setDisabled(true);
        setIsModalOpen(true);
      })}
      {isModalOpen && (
        <DocumentUpgradePreviewModal
          documentId={qualioDocument.id}
          companyId={currentUser.companyId}
          targetTemplate={documentUpgradeInformation.newest_template}
          currentTemplate={documentUpgradeInformation.current_template}
          onClose={onClose}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};

import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { ChangeControlTemplateDTO } from '../types/DocumentCreate';
import { changeControlApi } from './changeControl';
import { migrationManagerApi } from './migrationManagerApi';

const DEFAULT_EMPTY_CC_STATEMENT = '<p></p>';

export const bulkFileUploadService = {
  createBulkUploadMigration: async (currentUser: CurrentUser) => {
    return changeControlApi
      .fetchChangeControlTemplates()
      .then((templates: ChangeControlTemplateDTO[]) => {
        const changeControlStatement =
          templates[0].sections[0].content || DEFAULT_EMPTY_CC_STATEMENT;
        return migrationManagerApi.createMigration(
          `Bulk upload ${currentUser.email} ${new Date().toISOString()}`,
          changeControlStatement,
          currentUser.companyId,
        );
      });
  },
};

import { ExportPdfConfig } from '@ckeditor/ckeditor5-export-pdf/src/exportpdf';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import {
  AttachmentsTaskStatusResponse,
  backendTaskApi,
  ExportTaskStatusResponse,
} from '../../api/backendTask';
import { documentApi } from '../../api/document';
import { DocumentStatus, QualioDocument } from '../../api/model/document';
import { pdfApi } from '../../api/pdf-service';
import {
  DocumentExport,
  downloadFile,
} from '../../util/DocumentExport/DocumentExport';

export type QualioPdfExportCommandOptions = {
  qualioDocument: QualioDocument;
  currentUser: CurrentUser;
  coverPageEnabled: boolean;
  controlledCopy: boolean;
  orientation: 'portrait' | 'landscape' | undefined;
  onDocumentExportCompletion: () => void;
  onDocumentExportFailure: (msg?: string) => void;
  onAttachmentDownloadFailure: (msg?: string) => void;
  documentHtml: string;
  processImagesOnExport?: boolean;
  shouldAuditControlledExports?: boolean;
  useLegacyExport?: boolean;
};

export const exportPdfConfig: ExportPdfConfig = {
  converterOptions: {
    format: 'A4',
  },
};

const exportPdf = async (
  exportCmd: (documentExport: DocumentExport) => Promise<void>,
  options: QualioPdfExportCommandOptions,
) => {
  const {
    controlledCopy,
    currentUser,
    coverPageEnabled,
    orientation,
    qualioDocument,
    onDocumentExportCompletion,
    onAttachmentDownloadFailure,
    documentHtml,
    processImagesOnExport,
    shouldAuditControlledExports,
    useLegacyExport,
  } = options;

  // call pdf export conversion handler
  const documentExport = new DocumentExport(
    qualioDocument,
    currentUser,
    documentHtml,
    coverPageEnabled,
    orientation ?? 'portrait',
    controlledCopy,
    processImagesOnExport ?? false,
    useLegacyExport ?? false,
    shouldAuditControlledExports ?? false,
  );

  const pdfExportRequest: Promise<void> = exportCmd(documentExport);

  // Create & download attachment zip file
  const attachmentDownloadRequest: Promise<void> =
    downloadDocumentAttachmentsViaMedtechTask(
      qualioDocument,
      onAttachmentDownloadFailure,
    );

  await Promise.all([pdfExportRequest, attachmentDownloadRequest]);

  if (onDocumentExportCompletion) {
    onDocumentExportCompletion();
  }
};

export const exportViaPdfApi = async (
  options: QualioPdfExportCommandOptions,
) => {
  try {
    await exportPdf(async (documentExport: DocumentExport) => {
      return pdfApi.exportPDF(
        {
          html: await documentExport.render(),
          options: {
            ...exportPdfConfig.converterOptions,
            page_orientation: documentExport.orientation,
            header_html: documentExport.getPdfHeaderHtml(),
            footer_html: documentExport.getPdfFooterHtml(),
          },
        },
        determinePdfFilename(options.qualioDocument),
        options.onDocumentExportFailure,
        documentExport.qualioDocument.company_id,
        documentExport.qualioDocument.id,
        documentExport.shouldAuditExport,
      );
    }, options);
  } catch (e: any) {
    options.onDocumentExportFailure(
      'Cannot complete export to pdf call. Please try again or contact support. Error was:' +
        e.toString(),
    );
  }
};

export const exportDocumentViaMedtechTask = async (
  qualioDocument: QualioDocument,
  onDocumentExportCompletion: () => void,
  onDocumentExportFailure: () => void,
  coverPagedEnabled = true,
  controlledCopy = false,
  shouldAuditControlledExports = false,
) => {
  // kick off MTBE task to create attachments zip file
  const { task_id } = await backendTaskApi.startPDFExportTask(
    qualioDocument.id.toString(),
    coverPagedEnabled,
    controlledCopy,
  );

  // Poll task status until it is complete
  const result: ExportTaskStatusResponse =
    await backendTaskApi.pollTaskUntilCompletion(
      qualioDocument.company_id,
      task_id,
    );

  const shouldAuditExport =
    shouldAuditControlledExports &&
    qualioDocument.status_id === DocumentStatus.Effective &&
    controlledCopy;

  if (result.successful) {
    if (result.payload) {
      if (shouldAuditExport) {
        documentApi
          .completeControlledExport(
            qualioDocument.company_id,
            qualioDocument.id,
          )
          .then(() => {
            downloadFile(result.payload);
          })
          .catch(() => {
            onDocumentExportFailure();
          });
      } else {
        downloadFile(result.payload);
      }
    }
    onDocumentExportCompletion();
  } else {
    onDocumentExportFailure();
  }
};

const downloadDocumentAttachmentsViaMedtechTask = async (
  qualioDocument: QualioDocument,
  onAttachmentDownloadFailure?: () => void,
) => {
  // kick off MTBE task to create attachments zip file
  const { task_id } = await backendTaskApi.startAttachmentDownloadTask(
    qualioDocument.id.toString(),
  );

  // Poll task status until it is complete
  const result: AttachmentsTaskStatusResponse =
    await backendTaskApi.pollTaskUntilCompletion(
      qualioDocument.company_id,
      task_id,
    );

  if (result.successful) {
    // attachment_count may be 0 if the document doesn't have any
    if (result.payload && result.payload.attachment_count > 0) {
      // download the zip file:
      downloadFile(result.payload.download_url);
    }
  } else if (onAttachmentDownloadFailure) {
    onAttachmentDownloadFailure();
  }
};

export const determinePdfFilename = (document: QualioDocument) =>
  `${document.code}_${document.title}_v${document.major_version}.${document.minor_version}.pdf`;

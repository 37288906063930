import axios, { AxiosInstance } from 'axios';
import { QriBulkDetails, QriBulkDetailsSchema } from './model/qri';

export let qriApi: AxiosInstance;

export const getQriApi = (qriServiceUrl: string) => {
  if (!qriApi) {
    qriApi = axios.create({
      baseURL: qriServiceUrl,
      withCredentials: true,
    });
  }
  return qriApi;
};

const qriServiceApi = {
  async getBulkDetails(
    qriServiceUrl: string,
    qris: string[],
  ): Promise<QriBulkDetails | undefined> {
    if (!qris.length) {
      return undefined;
    }
    const response = await getQriApi(qriServiceUrl).post('/details', {
      qris,
      short: false,
    });
    const result = QriBulkDetailsSchema.safeParse(response.data);

    if (result.success) {
      return result.data;
    } else {
      console.log('QRI bulk details response parsing error', result.error);
    }
  },
};

export default qriServiceApi;

import {
  QAlert,
  QBox,
  QButton,
  QCloseButton,
  QCollapse,
  QDrawer,
  QDrawerHeader,
  QIconButton,
  QSpinner,
  QStack,
  QText,
} from '@qualio/ui-components';

import { useState } from 'react';
import { TrainingAssessmentQuestionCard } from './TrainingAssessmentQuestionCard';
import { TrainingAssessmentAIResponse } from './types/trainingAssessment';

type SuggestionDrawerProps = {
  onClose: () => void;
  accepted: boolean;
  isOpen: boolean;
  suggestion: TrainingAssessmentAIResponse | null;
  onGenerate: () => void;
  onAccept: () => void;
  isLoading: boolean;
};

export const SuggestionDrawer: React.VFC<SuggestionDrawerProps> = ({
  isOpen,
  onClose,
  accepted,
  suggestion,
  onGenerate,
  isLoading,
  onAccept,
}) => {
  const [panelOpen, setPanelOpen] = useState(false);

  return (
    <QDrawer isOpen={isOpen} onClose={onClose} size="lg">
      <QDrawerHeader>
        <QText>Qualio AI</QText>

        <QStack direction="row">
          <QButton
            variant="outline"
            onClick={onGenerate}
            isDisabled={isLoading}
            data-cy="regenerate"
            leftIcon="QualioAI"
          >
            Regenerate
          </QButton>
          <QButton
            isDisabled={accepted || isLoading}
            data-cy="accept-generated-ta"
            onClick={onAccept}
          >
            Accept
          </QButton>
        </QStack>
        <QCloseButton onClick={onClose} />
      </QDrawerHeader>
      {isLoading ? (
        <QStack height="100%" minHeight="60vh" justify="center" align="center">
          <QSpinner />
          <QText data-cy="generate-suggestions" fontSize={'sm'}>
            Typing suggestions...
          </QText>
        </QStack>
      ) : (
        <>
          <QBox
            style={{ marginTop: '-10px', marginBottom: '10px' }}
            data-cy="ai-ta-disclaimer"
          >
            <QText fontSize="sm">
              Use these suggestions as guidance. Review all generated content
              carefully.
            </QText>
          </QBox>

          <QAlert
            data-cy="ai-generated-ta-content-rationale"
            title="Rationale behind suggestions"
            description={
              <QCollapse isOpen={panelOpen} startingHeight={42}>
                <QStack
                  alignItems="flex-start"
                  width="full"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <QText casing="none" noOfLines={panelOpen ? undefined : 2}>
                    <QBox pl={5}>
                      <ul>
                        {suggestion?.rationale.map((rationaleDetails, idx) => (
                          <li key={idx}>{rationaleDetails}</li>
                        ))}
                      </ul>
                    </QBox>
                  </QText>
                  <QIconButton
                    iconName={panelOpen ? 'ChevronUp' : 'ChevronDown'}
                    size="xs"
                    aria-label="Open"
                    onClick={() => setPanelOpen((state) => !state)}
                    data-cy="toggle-rationale-alert-size"
                  />
                </QStack>
              </QCollapse>
            }
            status="info"
          />
          {suggestion?.questions.map((question, rank) => (
            <TrainingAssessmentQuestionCard
              question={question.question}
              answers={question.answers}
              key={question.question}
            />
          ))}
        </>
      )}
    </QDrawer>
  );
};

import { useToastProvider } from '@qualio/ui-components';
import { useState } from 'react';
import { DocumentStatus } from '../../../../../../../api/model/document';
import { CredentialConfirmationModal } from '../../../../../CredentialConfirmationModal/index';
import { WorkspaceTabs } from '../../../../types';
import { handleStatusChange } from '../ChangeStatusService';
import {
  ChangeStatusButtonProps,
  IsChangeStatusActionAllowedCallback,
} from '../types';

import {
  hasAllowedStatusesForAction,
  hasRequiredPermissionsForAction,
  isValidTabForAction,
} from '../../../BulkActions';
import { BulkActionButton } from '../../BulkActionButton/index';

export const RetireButton = (props: ChangeStatusButtonProps) => {
  const { showToast } = useToastProvider();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onClose = () => setIsModalOpen(false);

  const buttonActionLabel = 'Retire';
  const modalHeading = `Retire ${
    props.selectedDocuments.length === 1 ? 'document' : 'documents'
  }`;

  const toastActionStringActive = 'retire';
  const toastActionStringPassive = 'retired';

  const handleRetire = async (
    email: string,
    password: string,
    comment?: string,
  ) => {
    await handleStatusChange(
      props.selectedDocuments,
      { status: DocumentStatus.Retired, email, password, comment },
      props.setSelectedDocumentsMap,
      showToast,
      toastActionStringActive,
      toastActionStringPassive,
    );
    setIsModalOpen(false);
    props.refetch();
  };

  const canRetire: IsChangeStatusActionAllowedCallback = (
    user,
    statuses,
    activeTab,
    requiredPermissions,
    selectedDocuments,
  ) => {
    if (selectedDocuments.length > 1) {
      return false;
    }
    if (
      !hasRequiredPermissionsForAction(requiredPermissions, user.permissions) ||
      !isValidTabForAction([WorkspaceTabs.ALL_DOCUMENTS], activeTab)
    ) {
      return false;
    }
    return hasAllowedStatusesForAction([DocumentStatus.Effective], statuses);
  };

  return (
    <>
      <BulkActionButton
        {...props}
        isActionAllowed={canRetire}
        onClick={() => setIsModalOpen(true)}
      />
      <CredentialConfirmationModal
        isOpen={isModalOpen}
        onCloseModal={onClose}
        handleStatusChange={handleRetire}
        modalText={
          <>
            Retiring a document that is part of a Qualio Training Plan can
            impact your training assignments. Please check the Training Matrix
            to identify impacted plans.
            <br />
            <br />
            Enter your digital signature to retire this document.
          </>
        }
        modalHeading={modalHeading}
        buttonActionLabel={buttonActionLabel}
        buttonProps={{ isDestructive: true }}
      />
    </>
  );
};

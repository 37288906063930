import {
  QButton,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { changeControlApi } from '../../../../../api/changeControl';
import { documentApi } from '../../../../../api/document';
import { QualioDocument } from '../../../../../api/model/document';
import { PeriodicReview } from '../../../../../api/model/periodicReview';
import { ChangeControlDTO } from '../../../../../types/DocumentCreate';
import { ButtonGroupContext } from '../../../Contexts/ButtonGroupContext';
import { DocumentOverviewContext } from '../../Context';
import { shouldRenderCreateNewDraftButton } from '../../RenderLogic';

export const CreateNewDraftButton = ({
  periodicReview,
}: {
  periodicReview?: PeriodicReview;
}) => {
  const currentUser = useCurrentUser();
  const { qualioDocument } = useContext(DocumentOverviewContext);
  const { isDisabled, setDisabled } = useContext(ButtonGroupContext);
  const [isCreatingNewDraft, setIsCreatingNewDraft] = useState(false);
  const { showToast } = useToastProvider();
  const navigate = useNavigate();

  const handleCreateNewDraft = async () => {
    setDisabled(true, setIsCreatingNewDraft);
    let newDoc: QualioDocument;
    let ccTab = '';
    try {
      newDoc = await documentApi.createDraftFromEffectiveDoc(
        qualioDocument.company_id,
        qualioDocument.document_matrix_id,
      );
    } catch (e: any) {
      const failureMessage =
        e.response?.data?.message ||
        'Failed to create new draft. Please try again.';
      showToast({
        id: 'create-new-draft-error-toast',
        status: 'error',
        title: 'Error',
        description: failureMessage,
      });
      setDisabled(false, setIsCreatingNewDraft);
      return;
    }

    try {
      const changeControlTemplates =
        await changeControlApi.fetchChangeControlTemplates();
      if (changeControlTemplates && changeControlTemplates.length > 0) {
        const changeControlTemplate = changeControlTemplates[0];
        await changeControlApi.createChangeControl(
          newDoc,
          changeControlTemplate.id as number,
          { sections: changeControlTemplate.sections } as ChangeControlDTO,
        );
      }
    } catch (e: unknown) {
      ccTab = '&tab=change-control';
    }

    navigate(`/workspace/documents/${newDoc.id}?intent=edit${ccTab}`);
  };

  if (
    !shouldRenderCreateNewDraftButton(qualioDocument, currentUser) ||
    periodicReview
  ) {
    return null;
  }

  return (
    <QButton
      isDisabled={isDisabled}
      isLoading={isCreatingNewDraft}
      variant="outline"
      onClick={handleCreateNewDraft}
      data-cy="doc-overview-create-new-draft-button"
    >
      Create new draft
    </QButton>
  );
};

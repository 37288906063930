import {
  QBox,
  QButton,
  QStack,
  QText,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useCallback, useContext, useState } from 'react';
import { DocumentFormat } from '../../../api/model/document';
import { DocumentOverviewContext } from '../DocumentOverview/Context';
import { useGenerateTrainingAssessment } from './hooks/useGenerateTrainingAssessment';
import { SuggestionDrawer } from './SuggestionDrawer';
import { TrainingAssessmentAIResponse } from './types/trainingAssessment';

type Props = {
  onAccept: (questions: TrainingAssessmentAIResponse['questions']) => void;
};

export const GenerateTrainingAssessmentAI: React.VFC<Props> = ({
  onAccept,
}) => {
  const currentUser = useCurrentUser();
  const { editorAi } = useFlags();
  const aiEnabled = editorAi || currentUser.company.ai_enabled;
  const { qualioDocument } = useContext(DocumentOverviewContext);
  const { isLoading, mutate: generateTrainingAssessment } =
    useGenerateTrainingAssessment();
  const [isOpen, setIsOpen] = useState(false);
  const [suggestion, setSuggestion] =
    useState<TrainingAssessmentAIResponse | null>(null);
  const [accepted, setAccepted] = useState(false);
  const { showToast } = useToastProvider();

  const generateClick = useCallback(() => {
    setIsOpen(true);
    setAccepted(false);
    generateTrainingAssessment(undefined, {
      onSuccess: (assessment: TrainingAssessmentAIResponse) => {
        setSuggestion(assessment);
      },
    });
  }, [generateTrainingAssessment]);

  const onAcceptCallback = useCallback(() => {
    if (!suggestion?.rationale || accepted) {
      return;
    }

    setAccepted(true);
    setIsOpen(false);
    onAccept(suggestion.questions);
    showToast({
      status: 'success',
      title: 'Suggestions accepted!',
      description:
        'Suggested questions are added to the end of the assessment.',
      duration: 5000,
    });
  }, [suggestion, onAccept, accepted, showToast]);

  if (
    !aiEnabled ||
    qualioDocument.document_format === DocumentFormat.DocumentUpload
  ) {
    return null;
  }

  return (
    <>
      <SuggestionDrawer
        isLoading={isLoading}
        isOpen={isOpen}
        accepted={accepted}
        onGenerate={generateClick}
        onAccept={onAcceptCallback}
        suggestion={suggestion}
        onClose={() => setIsOpen(false)}
      />
      <QBox
        width="full"
        borderRadius="md"
        padding="16px 24px"
        background="linear-gradient(90.14deg, #3D4CF2 0%, #7B4FFF 100%)"
        data-cy="generate-banner"
      >
        <QStack
          justify="space-between"
          alignContent="center"
          alignItems="center"
          direction="row"
        >
          <QStack direction="column">
            <QText weight="semibold" color="white" fontSize="md">
              Need suggestions?
            </QText>
            <QText fontSize="xs" color="white">
              Use Qualio's AI to review your document and intelligently suggest
              a starting point for a training assessment or additional items to
              add.
            </QText>
          </QStack>
          {suggestion !== null ? (
            <QStack direction="row">
              <QButton
                data-cy="regenerate"
                onClick={generateClick}
                isDisabled={isLoading}
                leftIcon="QualioAI"
                variant="outline"
              >
                Regenerate
              </QButton>
              <QButton
                data-cy="view-suggestions"
                variant="outline"
                onClick={() => setIsOpen(true)}
                isDisabled={isLoading}
              >
                View suggestions
              </QButton>
            </QStack>
          ) : (
            <QButton
              data-cy="generate-ai-ta"
              variant="outline"
              onClick={generateClick}
              isDisabled={isLoading}
              leftIcon="QualioAI"
            >
              Generate
            </QButton>
          )}
        </QStack>
      </QBox>
    </>
  );
};

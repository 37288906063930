import {
  QButton,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QText,
} from '@qualio/ui-components';
import { useState } from 'react';

interface DocumentCollaborationSessionResetProps {
  isOpen: boolean;
  onClose: () => void;
  doResetSession: () => Promise<void>;
}

export const DocumentCollaborationSessionDeleteModal = ({
  isOpen,
  onClose,
  doResetSession,
}: DocumentCollaborationSessionResetProps) => {
  const [isBusy, setIsBusy] = useState(false);

  const resetEditorSession = async () => {
    setIsBusy(true);
    try {
      await doResetSession();
      window.location.reload();
    } catch (e) {
      console.log(e);
      setIsBusy(false);
    }
  };

  return (
    <QModal onClose={onClose} isOpen={isOpen}>
      <QModalHeader>
        <QText>Reset Document Editor Session</QText>
      </QModalHeader>
      <QModalBody>
        <QText>
          Resetting the editor session will reset the document to its last
          successful save state.
          <br />
          <br /> This should only be done if the editor cannot load for a user,
          and will cause any users on this page to need to reload.
        </QText>
      </QModalBody>
      <QModalActions>
        <QButton variant="outline" onClick={onClose}>
          Cancel
        </QButton>
        <QButton isLoading={isBusy} onClick={resetEditorSession}>
          Reset document session
        </QButton>
      </QModalActions>
    </QModal>
  );
};

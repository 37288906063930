import { Marker } from '@ckeditor/ckeditor5-engine';

export function formTargetsFromMarker(
  marker: Marker,
): Array<CommentsRepository.Target> {
  const items = [];
  for (const coveredItem of marker.getRange().getItems()) {
    if (coveredItem.is('$textProxy')) {
      items.push({ name: 'text', data: coveredItem.data });
    } else if (coveredItem.is('element')) {
      const name = coveredItem.name.toString();
      if (['imageInline', 'imageBlock'].includes(name)) {
        items.push({
          name: name,
          data: coveredItem.getAttribute('src')?.toString() ?? '',
        });
      } else if (
        ['table', 'codeBlock', 'checkbox', 'blockQuote'].includes(name)
      ) {
        items.push({ name: name, data: '' });
      }
    }
  }
  return items;
}

export function extractDocumentIdFromChannelId(channelId: string) {
  const documentSearchStrLength = 9;
  const documentStrIndex = channelId.indexOf('document-');
  const sectionStrIndex = channelId.indexOf('-section');
  if (documentStrIndex === -1 || sectionStrIndex === -1) {
    throw new Error('wrong channel id format');
  }
  return channelId.substring(
    documentStrIndex + documentSearchStrLength,
    sectionStrIndex,
  );
}

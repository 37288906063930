import { useQuery } from 'react-query';

import { useToastProvider } from '@qualio/ui-components';

import { AxiosError } from 'axios';
import { documentApi } from '../../../../api/document';
import { DocumentStatus, QualioDocument } from '../../../../api/model/document';

export const useNextVersionDetails = (
  qualioDocument: QualioDocument | undefined,
  companyId: number,
) => {
  const isEffective = qualioDocument?.status_id === DocumentStatus.Effective;
  const { showToast } = useToastProvider();
  return useQuery({
    queryKey: ['nextVersionDetails', qualioDocument?.id],
    queryFn: () =>
      qualioDocument &&
      documentApi.fetchNextVersion(qualioDocument.id, companyId),
    onError: (e: unknown) => {
      if ((e as AxiosError)?.response?.status !== 404) {
        showToast({
          status: 'error',
          title: 'Error',
          description: 'Failed to load document. Please refresh.',
        });
      }
    },
    refetchOnWindowFocus: false,
    enabled: isEffective,
  });
};

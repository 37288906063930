import { useState } from 'react';
import { useQuery } from 'react-query';

import {
  QButton,
  QSelect,
  QSelectItem,
  QSelectPlaceholder,
  QText,
  useToastProvider,
} from '@qualio/ui-components';

import { documentApi } from '../../../api/document';
import {
  QualioDocument,
  QualioDocumentAttachment,
} from '../../../api/model/document';
import { fileTypeFilter } from '../../../util/FileUploadUtils';

import {
  DOCUMENT_CONVERSION_CHANGE_FORMAT_ERROR,
  logCustomError,
} from '../../../messages/LogErrorMessages';
import { sortByLabel } from '../../../util/SelectionOptionUtils';
import { BaseDocumentConvertModal } from './BaseDocumentConvertModal';
import { DocumentConvertMessageConfig } from './DocumentConvertMessageConfig';
import {
  ConvertingStatus,
  ConvertionType,
  DocumentConvertFormatModalProps,
} from './types';

export const ConvertFileFormat = ({
  onClose,
  qualioDocument,
  onSuccess,
  onError,
}: DocumentConvertFormatModalProps) => {
  const { showToast } = useToastProvider();

  const [selectedAttachmentId, setSelectedAttachmentId] = useState<number>();
  const [convertingStatus, setConvertingStatus] = useState<ConvertingStatus>({
    converting: false,
  });

  const { company_id: companyId, id: documentId } = qualioDocument;
  const getAttachmentsOptions = async () =>
    documentApi.fetch(documentId.toString()).then((doc: QualioDocument) => {
      return doc.sections.reduce(
        (acc: QSelectItem<string, number>[], section) => {
          if (!section.section_attachments) {
            return acc;
          }
          return acc.concat(
            section.section_attachments
              .filter(fileTypeFilter)
              .map((attachment: QualioDocumentAttachment) => ({
                label: attachment.filename,
                value:
                  typeof attachment.id === 'string'
                    ? parseInt(attachment.id)
                    : attachment.id,
              }))
              .sort(sortByLabel),
          );
        },
        [],
      );
    });

  const {
    error,
    isLoading,
    data: attachmentOptions,
  } = useQuery({
    queryFn: getAttachmentsOptions,
    queryKey: ['attachmentOptions'],
  });

  if (error) {
    showToast({
      title: 'Error',
      description: 'There was an error loading attachments for this document',
      status: 'error',
    });
  }

  const handleDocumentConversion = (attachmentId?: number) => {
    setConvertingStatus({
      converting: true,
      type: attachmentId
        ? ConvertionType.WithAttachment
        : ConvertionType.WithoutAttachment,
    });
    documentApi
      .convertToFileDocument(companyId, documentId, attachmentId)
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
        onClose(true);
      })
      .catch((error) => {
        logCustomError(DOCUMENT_CONVERSION_CHANGE_FORMAT_ERROR, {
          error,
          companyId: companyId,
          documentId: documentId,
          entityId: attachmentId,
        });

        showToast({
          title: 'Error',
          description: 'Could not change the format of the document.',
          status: 'error',
        });

        if (onError) {
          onError();
        }

        onClose(false);
      });
  };

  const attachmentDropdown = (
    <div>
      <QText pb="2" weight="semibold">
        Attachment to convert
      </QText>
      <QSelect<string, number>
        isLoading={isLoading}
        options={attachmentOptions || []}
        value={selectedAttachmentId}
        onChange={(selected) => setSelectedAttachmentId(selected?.value)}
      >
        <QSelectPlaceholder>
          <QText>Select a file...</QText>
        </QSelectPlaceholder>
      </QSelect>
    </div>
  );

  const modalActions = [
    <QButton
      key="convert-file-format-cancel-button"
      data-cy="convert-file-format-cancel-button"
      variant="ghost"
      onClick={() => onClose(false)}
    >
      Cancel
    </QButton>,
    <QButton
      key="convert-file-format-upload-new-button"
      data-cy="convert-file-format-upload-new-button"
      variant={'outline'}
      onClick={() => handleDocumentConversion()}
      isLoading={
        convertingStatus.converting &&
        convertingStatus.type === ConvertionType.WithoutAttachment
      }
      isDisabled={convertingStatus.converting}
    >
      Upload new
    </QButton>,
    <QButton
      key="convert-file-format-confirm-button"
      data-cy="convert-file-format-confirm-button"
      isLoading={
        convertingStatus.converting &&
        convertingStatus.type === ConvertionType.WithAttachment
      }
      isDisabled={!selectedAttachmentId || convertingStatus.converting}
      onClick={() => handleDocumentConversion(selectedAttachmentId)}
    >
      Confirm
    </QButton>,
  ];

  return (
    <BaseDocumentConvertModal
      {...DocumentConvertMessageConfig.QualioNativeDocument}
      extendedModalBodyFragment={attachmentDropdown}
      modalActionsFragment={modalActions}
      onClose={onClose}
    />
  );
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  navigateAcrossMFEs,
  QButton,
  QFormControl,
  QHeading,
  QLink,
  QMultiSelect,
  QSelect,
  QSelectItem,
  QSelectPlaceholder,
  QStack,
  QText,
  QualioQThemeProvider,
  useToastProvider,
} from '@qualio/ui-components';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { documentApi } from '../../../api/document';
import {
  ExternalFile,
  migrationManagerApi,
  migrationManagerFileApi,
} from '../../../api/migrationManagerApi';
import {
  MigrationDTO,
  MigrationNotStartedStatusLabelMap,
  MigrationStatus,
} from '../../../api/model/migration';
import templateApi, { Template } from '../../../api/templateApi';
import { userApi } from '../../../api/user';

import { sleep } from '../../../util/TimerUtils';
import styles from './BulkFileUpload.module.css';

import { useErrorHandler } from '../ErrorHandler';

import { bulkFileUploadService } from '../../../api/bulkFileUploadService';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import {
  BULK_FILE_UPLOAD_ADD_FILE_ERROR,
  BULK_FILE_UPLOAD_IMPORT_ERROR,
  EXTERNAL_SOURCE_FILE_ERROR,
  logCustomError,
} from '../../../messages/LogErrorMessages';
import { getHttpError } from '../../../util/ErrorManagementUtils';
import {
  ACCEPTED_FILE_DOCUMENT_EXTS,
  buildErrorPickHandler,
  BULK_FILE_UPLOAD_PAGE_DESCRIPTION_MESSAGE,
  ExternalFileToastMessages,
  getExternalFileDownloadUrlProps,
} from '../../../util/FileUploadUtils';
import { redirectTo } from '../../../util/PageUtils';
import { sortByLabel } from '../../../util/SelectionOptionUtils';
import { ErrorToastManager } from '../ErrorToastManager/index';
import { FileUploadInput } from '../FileDocumentEditor/FileUploadInput';
import { DownloadUrlResponse } from '../FileDocumentEditor/types';
import { RemoteFileMetadata } from '../QFilePicker';
import {
  handleMigrationError,
  isMigrationComplete,
  MigrationStatusCompleteUserMessageMap,
  validateFilesBeforeUpload,
} from './BulkFileUploadHandlers';
import { EditFileModal } from './EditFileModal';
import {
  ApproverProperties,
  ApproverSelectionOption,
  MigrationFileWithOverrides,
  TemplateProperties,
  TemplateSelectionOption,
} from './types';
import { UploadedFilesList } from './UploadedFilesList';

export const BulkFileUpload: React.FC<{
  currentUser: CurrentUser;
}> = ({ currentUser }) => {
  const { companyId } = currentUser;
  const { showToast } = useToastProvider();
  const [uploadedFiles, setUploadedFiles] = useState<
    MigrationFileWithOverrides[]
  >([]);
  const [templateOptions, setTemplateOptions] = useState<
    TemplateSelectionOption[]
  >([]);
  const [selectedTemplate, setSelectedTemplate] = useState<
    TemplateProperties & { nextDocumentCode: number }
  >();
  const [approverSelectOptions, setApproverSelectOptions] = useState<
    ApproverSelectionOption[]
  >([]);
  const [ownerSelectOptions, setOwnerSelectOptions] = useState<QSelectItem[]>(
    [],
  );
  const [selectedApprovers, setSelectedApprovers] = useState<
    ApproverProperties[]
  >([]);
  const [migration, setMigration] = useState<MigrationDTO>();
  const [isProcessingBulkUpload, setIsProcessingBulkUpload] = useState(false);
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [fileToEdit, setFileToEdit] = useState<MigrationFileWithOverrides>();

  useDocumentTitle('Upload files');

  const areFilesValid = useMemo(() => {
    return uploadedFiles.every((file) => {
      return (
        (file.approvers.length || file.overrideOptions?.approvers?.length) &&
        (file.owner || file.overrideOptions?.owner) &&
        !!file.template
      );
    });
  }, [uploadedFiles]);

  const isMigrationStarted = useCallback(
    () =>
      (migration && !(migration.status in MigrationNotStartedStatusLabelMap)) ??
      false,
    [migration],
  );

  const { errorMsg, hasError, updateHasError } = useErrorHandler();

  const errorHandler = (e: unknown, defaultMsg: string) => {
    const isError = e instanceof Error;
    updateHasError(true, isError && e.message ? e.message : defaultMsg);
  };

  const handlePickError = (
    errorProps: (typeof ExternalFileToastMessages)[keyof typeof ExternalFileToastMessages],
    message?: string | undefined,
  ) => {
    showToast({
      status: 'error',
      title: errorProps.title,
      description: message ?? errorProps.message,
      id: 'onedrive-pick-error-toast',
    });
  };

  const errorPickerHandler = buildErrorPickHandler(handlePickError, true);

  const handlePick = async (files: RemoteFileMetadata[]) => {
    if (!files.length) {
      return;
    }

    const filesAreValid = validateFilesBeforeUpload(
      files,
      uploadedFiles.length,
      showToast,
      false,
      true,
    );

    if (!filesAreValid) {
      return;
    }

    setIsUploadingFiles(true);

    let downloadUrlsResponses: DownloadUrlResponse[];

    try {
      downloadUrlsResponses = await Promise.all(
        files.map((file: RemoteFileMetadata) => {
          return getExternalFileDownloadUrlProps(file);
        }),
      );
    } catch (error: unknown) {
      logCustomError(EXTERNAL_SOURCE_FILE_ERROR, { error });
      handlePickError(ExternalFileToastMessages.ADD_MULTIPLE_FILES_FAILED);
      setIsUploadingFiles(false);
      return;
    }

    if (downloadUrlsResponses) {
      const externalFiles: ExternalFile[] = files.map((file, idx) => {
        return {
          ...file,
          presignedUrl: downloadUrlsResponses[idx].location,
        };
      });

      await setupMigration(externalFiles, true);
    }
  };

  const handleMigrationComplete = (completedMigration: MigrationDTO) => {
    const { status: migrationStatus } = completedMigration;

    if (isMigrationComplete(completedMigration)) {
      const { message, status, title } = MigrationStatusCompleteUserMessageMap[
        migrationStatus
      ](uploadedFiles.length);

      showToast({
        title,
        description: message,
        status,
        id: 'bulk-upload-status-toast',
      });

      if (status === 'success') {
        setTimeout(() => {
          redirectTo('/workspace?subfilter=draft');
        });
      }
    }
  };

  const pollForMigrationCompletion = async (migration: MigrationDTO) => {
    // poll every 10 seconds for 2 minutes
    const maxPollsFirstPhase = 12;
    const intervalMillisFirstPhase = 10000;
    // then move to every 30 seconds for 5 minutes
    const maxPollsSecondPhase = 10;
    const intervalMillisSecondPhase = 30000;
    const maxPolls = maxPollsFirstPhase + maxPollsSecondPhase;
    let polls = 0;
    let isComplete = false;
    while (polls < maxPolls && !isComplete) {
      const migrationResponse = await migrationManagerApi.fetchMigration(
        currentUser.companyId,
        migration.id,
      );

      setMigration(migrationResponse);

      isComplete = isMigrationComplete(migrationResponse);

      if (!isComplete) {
        polls++;
        await sleep(
          polls < maxPollsFirstPhase
            ? intervalMillisFirstPhase
            : intervalMillisSecondPhase,
        );
      } else {
        handleMigrationComplete(migrationResponse);
      }
    }
    setIsProcessingBulkUpload(false);
  };

  useEffect(() => {
    if (!migration) {
      return;
    }

    if (isProcessingBulkUpload && !isMigrationComplete(migration)) {
      void pollForMigrationCompletion(migration);
    }
    // Explicitly ignoring `migration` as a dependency here because we want to update the migration in state while also fetching it until it is complete
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProcessingBulkUpload]);

  useEffect(() => {
    const fileUploadDropZoneWrapper = document.querySelector(
      '#dropzone',
    ) as HTMLElement;
    if (
      !fileUploadDropZoneWrapper ||
      fileUploadDropZoneWrapper.style.pointerEvents !== ''
    ) {
      return;
    }
    if (isProcessingBulkUpload || isMigrationComplete(migration)) {
      fileUploadDropZoneWrapper.style.pointerEvents = 'none';
    }
  }, [isProcessingBulkUpload, migration]);

  useEffect(() => {
    const getTemplatesForSelection = async () => {
      try {
        const availableTemplates = await templateApi.getTemplatesForCompany(
          currentUser.companyId,
        );
        const templateSelectOptions = availableTemplates
          .map((template: Template) => ({
            label: template.name,
            value: template.id.toString(),
            properties: {
              key: template.template_uuid,
              id: template.id,
              name: template.name,
              prefix: template.prefix,
            },
          }))
          .sort(sortByLabel);
        setTemplateOptions(templateSelectOptions);
      } catch (e: unknown) {
        errorHandler(
          e,
          'There was an error loading templates. Please reload the page.',
        );
      }
    };
    const getQualityUsersForApproverSelection = async () => {
      try {
        const users = await userApi.fetchUsers(currentUser.companyId);
        const qualityUsers = users.filter((user) =>
          user.usergroups.includes('quality'),
        );
        const ownerOptions = users
          .filter((user) => !user.usergroups.includes('read_only'))
          .map(({ email, full_name: fullName }) => ({
            value: email,
            label: fullName,
          }))
          .sort(sortByLabel);
        const approverOptions = qualityUsers
          .map((user) => ({
            label: user.full_name,
            value: user.email,
            properties: {
              email: user.email,
              fullName: user.full_name,
              isQM: true,
            },
          }))
          .sort(sortByLabel);
        setApproverSelectOptions(approverOptions);
        setOwnerSelectOptions(ownerOptions);
      } catch (e: unknown) {
        errorHandler(
          e,
          'There was an error loading approvers. Please reload the page.',
        );
      }
    };
    void getTemplatesForSelection();
    void getQualityUsersForApproverSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const startMigrationHandler = async () => {
    if (!migration) {
      return;
    }

    // Setting processing bool here will start a poll for the migration
    setIsProcessingBulkUpload(true);
    try {
      await migrationManagerFileApi.updateFiles(
        companyId,
        migration.id,
        uploadedFiles.map((file) => ({
          fileId: file.id,
          properties: {
            ordinal: file.ordinal,
            filename: file.filename,
            templateKey: file.templateKey,
            templateID: file.templateID,
            template: file.template,
            approvers: file.overrideOptions?.approvers ?? file.approvers,
            owner: file.overrideOptions?.owner ?? file.owner,
            documentCode: `${selectedTemplate?.prefix}${file.ordinal}`,
          },
        })),
      );
      await migrationManagerApi.updateStatus(
        companyId,
        migration.id,
        MigrationStatus.IN_REVIEW,
      );
      await migrationManagerApi.startMigration(companyId, migration.id);
      updateHasError(false);
    } catch (error: unknown) {
      const unhandledMigrationError = handleMigrationError(
        error,
        'There was an error with the bulk import. Please try again or contact support.',
        showToast,
      );
      if (unhandledMigrationError) {
        showToast({
          status: 'error',
          title: 'Import error',
          description: unhandledMigrationError.message,
          id: 'bulk-upload-error-toast',
        });
        logCustomError(BULK_FILE_UPLOAD_IMPORT_ERROR, { error });
        setIsProcessingBulkUpload(false);
      }
    }
  };

  const templateChangeHandler = async (
    selectedTemplateOption: TemplateSelectionOption,
  ) => {
    const selectedTemplate = selectedTemplateOption.properties;
    const nextAvailableDocumentCode =
      await documentApi.fetchNextAvailableDocumentCode(
        currentUser.companyId,
        selectedTemplate.id,
      );
    const nextDocumentCode = +nextAvailableDocumentCode.split('-')[1];
    setSelectedTemplate({ ...selectedTemplate, nextDocumentCode });
  };

  const approverChangeHandler = async (
    selectedApproversOptions: ApproverSelectionOption[],
  ) => {
    const selectedApprovers = selectedApproversOptions.map(
      (approver) => approver.properties,
    );
    setSelectedApprovers(selectedApprovers);
  };

  const getOrCreateMigration = async (): Promise<MigrationDTO | undefined> => {
    try {
      const migrationForUpload =
        migration ??
        (await bulkFileUploadService.createBulkUploadMigration(currentUser));
      if (!migration) {
        setMigration(migrationForUpload);
      }
      return migrationForUpload;
    } catch (e: unknown) {
      const unhandledMigrationError = handleMigrationError(
        e,
        `Could not create bulk import for company ID: ${currentUser.companyId.toLocaleString()}. Please try again or contact support.`,
        showToast,
      );
      if (unhandledMigrationError) {
        throw unhandledMigrationError;
      }
    }
  };

  const setUploadedFilesWithProps = useCallback(
    (files: MigrationFileWithOverrides[] = []) => {
      setUploadedFiles((currentUploadedFiles) => {
        const uploadedFilesWithProps = [...currentUploadedFiles, ...files].map(
          (file, index) => ({
            ...file,
            approvers: selectedApprovers,
            owner: {
              email: currentUser.email,
              fullName: currentUser.fullName,
            },
            filename: file.filename,
            templateKey: selectedTemplate?.key,
            templateID: selectedTemplate?.id,
            template: selectedTemplate?.name ?? '',
            ordinal: selectedTemplate
              ? selectedTemplate.nextDocumentCode + index
              : file.ordinal,
          }),
        );
        return uploadedFilesWithProps;
      });
    },
    [currentUser, selectedApprovers, selectedTemplate],
  );

  useEffect(() => {
    setUploadedFilesWithProps();
  }, [selectedApprovers, selectedTemplate, setUploadedFilesWithProps]);

  const setupMigration = async (
    files: File[] | ExternalFile[],
    isExternal: boolean,
  ) => {
    try {
      const migrationForUpload = await getOrCreateMigration();
      if (!migrationForUpload) {
        return;
      }
      const { id } = migrationForUpload;

      const { successfulFiles, errors } =
        await migrationManagerFileApi.uploadFiles(
          companyId,
          id,
          files,
          isExternal,
        );
      setUploadedFilesWithProps(successfulFiles);

      if (errors.length > 0) {
        // log all
        errors.forEach((error) =>
          logCustomError(BULK_FILE_UPLOAD_ADD_FILE_ERROR, { error }),
        );

        // raise 1st
        const e = errors[0];
        const unhandledMigrationError = handleMigrationError(
          e,
          'Error occurred while adding files. Please try again or contact support.',
          showToast,
        );
        if (unhandledMigrationError) {
          throw unhandledMigrationError;
        }
      }
    } catch (e: unknown) {
      const error = getHttpError(e, 'An error occurred while adding files.');
      showToast({
        status: 'error',
        title: 'Failed to add files',
        description: error.message,
        id: 'add-files-error-toast',
      });
    } finally {
      setIsUploadingFiles(false);
    }
  };

  const handleFileUpload = async (files: File[] | null) => {
    if (files === null || files.length === 0) {
      return;
    }
    const filesAreValid = validateFilesBeforeUpload(
      files,
      uploadedFiles.length,
      showToast,
    );
    if (!filesAreValid) {
      return;
    }

    setIsUploadingFiles(true);
    await setupMigration(files, false);
  };

  const removeFileHandler = async (
    fileToRemove: MigrationFileWithOverrides,
  ) => {
    const fileId = fileToRemove.id;
    const fileToDelete = uploadedFiles.find(
      (file: MigrationFileWithOverrides) => file.id === fileId,
    );
    const filename = fileToDelete?.filename;
    const ordinalToRemove = fileToDelete?.ordinal ?? 0;
    const uploadedFilesWithNewOrdinals = uploadedFiles.map(
      (file: MigrationFileWithOverrides) => {
        file.ordinal =
          !selectedTemplate?.nextDocumentCode || file.ordinal < ordinalToRemove
            ? file.ordinal
            : file.ordinal - 1;
        return file;
      },
    );
    try {
      await migrationManagerFileApi.deleteFile(companyId, fileId);
      setUploadedFiles(
        uploadedFilesWithNewOrdinals.filter(
          (file: MigrationFileWithOverrides) => file.id !== fileId,
        ),
      );
      updateHasError(false);
    } catch (e: unknown) {
      errorHandler(e, `There was a problem deleting the file ${filename}.`);
    }
  };

  const onEditFileClick = (file: MigrationFileWithOverrides) =>
    setFileToEdit(file);

  const editFileHandler = (file: MigrationFileWithOverrides) => {
    const updatedFiles = uploadedFiles.map((f) =>
      f.id === file.id ? file : f,
    );
    setUploadedFiles(updatedFiles);
    setFileToEdit(undefined);
  };

  return (
    <div className={styles['bulk-upload-container']}>
      <QualioQThemeProvider>
        <QStack
          w="full"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={5}
        >
          <QStack width="60%">
            <QButton
              size="xs"
              data-cy="back-to-dashboard-reports-button"
              leftIcon="ArrowLeft"
              variant="ghost"
              onClick={() => navigateAcrossMFEs('/')}
            >
              Back to Dashboard
            </QButton>
            <QHeading mb={1} size="lg" as="h1" data-cy="form-heading">
              <QStack direction="column">
                <QText noOfLines={1} data-cy="bulk-import-heading">
                  Import files
                </QText>
                <QText fontWeight={400} fontSize="md" color="gray.900">
                  {BULK_FILE_UPLOAD_PAGE_DESCRIPTION_MESSAGE}
                </QText>
              </QStack>
            </QHeading>
          </QStack>
          <QStack alignSelf={'start'} direction={['row']}>
            <QButton
              data-cy="bulk-import-import-button"
              onClick={() => startMigrationHandler()}
              isDisabled={
                !areFilesValid ||
                uploadedFiles.length === 0 ||
                isMigrationStarted() ||
                isMigrationComplete(migration)
              }
              isLoading={isProcessingBulkUpload}
            >
              Import
            </QButton>
          </QStack>
        </QStack>
        <QStack direction={['row']} spacing={'15px'} fontSize="sm">
          <QFormControl
            id={'documentType'}
            width={'50%'}
            label={'Document type'}
          >
            {currentUser.permissions?.can_manage_templates && (
              <QLink
                data-cy="bulk-import-create-template-link"
                href="/doc-templates/new"
                isCrossMFE
              >
                Create document template
              </QLink>
            )}
            <QSelect
              aria-label="documentType"
              size="sm"
              options={templateOptions}
              onChange={(value) => {
                void templateChangeHandler(value as TemplateSelectionOption);
              }}
              value={selectedTemplate?.id.toString() ?? ''}
              isDisabled={
                isProcessingBulkUpload || isMigrationComplete(migration)
              }
              isLoading={!templateOptions.length}
            >
              <QSelectPlaceholder>
                <QText>Select a document type...</QText>
              </QSelectPlaceholder>
            </QSelect>
          </QFormControl>
          <QFormControl
            id="qualityApproverSelect"
            width={'50%'}
            label={'Quality approvers'}
          >
            <QMultiSelect
              data-cy={'select-quality-approver'}
              aria-label="qualityApprovers"
              size="sm"
              options={approverSelectOptions}
              onChange={(values) => {
                void approverChangeHandler(values as ApproverSelectionOption[]);
              }}
              isDisabled={
                isProcessingBulkUpload || isMigrationComplete(migration)
              }
              isLoading={!approverSelectOptions.length}
            >
              <QSelectPlaceholder>
                <QText>Choose an approver...</QText>
              </QSelectPlaceholder>
            </QMultiSelect>
          </QFormControl>
        </QStack>
        <FileUploadInput
          handleUpload={handleFileUpload}
          isUploading={isUploadingFiles}
          acceptedFileFormats={ACCEPTED_FILE_DOCUMENT_EXTS}
          isDisabled={isProcessingBulkUpload || isMigrationComplete(migration)}
          isBulkUpload={true}
          handlePickError={errorPickerHandler}
          handlePick={handlePick}
        />
        <UploadedFilesList
          uploadedFiles={uploadedFiles}
          selectedTemplate={selectedTemplate}
          changeHandler={setUploadedFiles}
          removeHandler={removeFileHandler}
          editHandler={onEditFileClick}
          migrationOngoingOrCompleted={
            isProcessingBulkUpload || isMigrationComplete(migration)
          }
        />
        {fileToEdit && (
          <EditFileModal
            onClose={() => {
              setFileToEdit(undefined);
            }}
            handleEdit={editFileHandler}
            approversSelectOptions={approverSelectOptions}
            ownerSelectOptions={ownerSelectOptions}
            file={fileToEdit}
          />
        )}
      </QualioQThemeProvider>
      <ErrorToastManager msg={errorMsg} hasError={hasError} />
    </div>
  );
};

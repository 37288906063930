import { useContext } from 'react';

import { QBox, QMenuButton, QMenuItemType } from '@qualio/ui-components';

import { EditorMode } from '../../../../../util/EditModeUtils';
import { ButtonGroupContext } from '../../../Contexts/ButtonGroupContext';
import {
  DocumentOverviewContext,
  EditorStatus,
  EditorStatusContext,
} from '../../Context';
import { shouldRenderEditSuggestToggle } from '../../RenderLogic';

type EditButtonProps = {
  setEditorMode: (value: EditorMode) => void;
};

export const EditSuggestToggle = ({ setEditorMode }: EditButtonProps) => {
  const { qualioDocument, currentEditorMode } = useContext(
    DocumentOverviewContext,
  );

  const { isDisabled } = useContext(ButtonGroupContext);

  const { docEditorStatus, ccEditorStatus, trainingAssessmentStatus } =
    useContext(EditorStatusContext);

  const isStableOrSaved =
    (docEditorStatus === EditorStatus.STABLE ||
      docEditorStatus === EditorStatus.SAVED) &&
    (ccEditorStatus === EditorStatus.STABLE ||
      ccEditorStatus === EditorStatus.SAVED) &&
    (trainingAssessmentStatus === EditorStatus.STABLE ||
      trainingAssessmentStatus === EditorStatus.SAVED);

  if (!shouldRenderEditSuggestToggle(qualioDocument, currentEditorMode)) {
    return null;
  }

  const editOptions: QMenuItemType[] = [
    {
      label: 'Editing',
      id: EditorMode.EDIT.toString(),
    },
    {
      label: 'Suggesting',
      id: EditorMode.TRACKCHANGES.toString(),
    },
  ];

  const label =
    currentEditorMode === EditorMode.TRACKCHANGES ? 'Suggesting' : 'Editing';

  return (
    <QBox ml={'auto'} className="document-edit-suggest-toggle">
      <QMenuButton
        variant="ghost"
        isDisabled={isDisabled || !isStableOrSaved}
        items={editOptions}
        onItemClick={(item) => setEditorMode(Number(item.id))}
        buttonLabel={label}
      ></QMenuButton>
    </QBox>
  );
};

import { QTag } from '@qualio/ui-components';
import { DocumentStatus } from '../../../api/model/document';
import { DocumentStatusIdToStatusConfigMap } from '../../../util/WorkspaceUtils';

type DocumentStatusCellProps = {
  statusId: DocumentStatus;
};

export const DocumentStatusCell: React.FC<DocumentStatusCellProps> = ({
  statusId,
}) => {
  const config = DocumentStatusIdToStatusConfigMap[statusId];

  if (!config) {
    return null;
  }

  const { label, color } = config;

  return (
    <QTag key={statusId} variantColor={color}>
      {label}
    </QTag>
  );
};

import { useContext } from 'react';

import { QAlert, useCurrentUser } from '@qualio/ui-components';

import { DocumentOverviewContext } from '../../Context';
import { shouldRenderCompleteTraining } from '../../RenderLogic';

export const TrainingAlert = () => {
  const currentUser = useCurrentUser();
  const { qualioDocument, currentEditorMode } = useContext(
    DocumentOverviewContext,
  );
  if (
    !shouldRenderCompleteTraining(
      qualioDocument,
      currentUser,
      currentEditorMode,
    )
  ) {
    return null;
  }

  return (
    <QAlert
      title="You have training outstanding."
      description="Please complete your training by reading through the document entirely."
      status="info"
    />
  );
};

import { QStack, QText } from '@qualio/ui-components';
import {
  activityLogDetailMap,
  ActivityLogModificationAction,
} from '../../../../api/model/activityLog';

export const FormattedActionHeader = ({
  actionType,
}: {
  actionType: ActivityLogModificationAction;
}) => {
  return (
    <QStack data-cy="activity-log-heading" direction="row" spacing={1}>
      <QText>{activityLogDetailMap[actionType].verb}</QText>
      <QText ml="2px" weight="semibold" color="gray.700">
        {activityLogDetailMap[actionType].entity}
      </QText>
    </QStack>
  );
};

import { QStack, QTag } from '@qualio/ui-components';
import React, { useMemo } from 'react';
import { Group } from '../../../../../../../api/model/group';

type GroupCellProps = {
  groupIds: Group['id'][];
  availableGroups: readonly Group[];
  identifier: number;
};

export const GroupsCell: React.FC<GroupCellProps> = ({
  groupIds,
  availableGroups,
  identifier,
}) => {
  const groups = useMemo(
    () => availableGroups.filter((group) => groupIds.includes(group.id)),
    [availableGroups, groupIds],
  );

  const mapToQTag = (group: Group) => (
    <QTag key={`${identifier}-${group.id}`}>{group.name}</QTag>
  );

  const getRenderedGroups = (groups: Group[]) => {
    if (groups.length > 2) {
      const renderedGroups = groups.slice(0, 2);
      const clippedGroupsTitle = groups
        .slice(2)
        .map(({ name }) => name)
        .join(', ');

      return renderedGroups.map(mapToQTag).concat(
        <QTag key={`${identifier}-clipped-groups`}>
          <div title={clippedGroupsTitle}>...</div>
        </QTag>,
      );
    }
    return groups.map(mapToQTag);
  };

  return (
    <QStack direction="row" spacing={3}>
      {getRenderedGroups(groups)}
    </QStack>
  );
};

import { useContext, useMemo } from 'react';

import { QButton } from '@qualio/ui-components';

import { EditorMode } from '../../../../../util/EditModeUtils';
import { ButtonGroupContext } from '../../../Contexts/ButtonGroupContext';
import {
  DocumentOverviewContext,
  EditorStatus,
  EditorStatusContext,
} from '../../Context';

type EditButtonProps = {
  setEditorMode: (value: EditorMode) => void;
};

export const SaveAndExitButton = ({ setEditorMode }: EditButtonProps) => {
  const { currentEditorMode } = useContext(DocumentOverviewContext);
  const { isDisabled } = useContext(ButtonGroupContext);
  const { docEditorStatus, ccEditorStatus, trainingAssessmentStatus } =
    useContext(EditorStatusContext);

  const isStableOrSaved =
    (docEditorStatus === EditorStatus.STABLE ||
      docEditorStatus === EditorStatus.SAVED) &&
    (ccEditorStatus === EditorStatus.STABLE ||
      ccEditorStatus === EditorStatus.SAVED) &&
    (trainingAssessmentStatus === EditorStatus.STABLE ||
      trainingAssessmentStatus === EditorStatus.SAVED);

  const buttonLabel = useMemo(() => {
    if (currentEditorMode === EditorMode.EDIT) {
      return 'Done editing';
    }
    if (currentEditorMode === EditorMode.TRACKCHANGES) {
      return 'Done suggesting';
    }
    return '';
  }, [currentEditorMode]);

  if (currentEditorMode !== EditorMode.VIEW) {
    return (
      <QButton
        isDisabled={isDisabled || !isStableOrSaved}
        data-cy="document-save-and-exit-button"
        onClick={() => setEditorMode(EditorMode.VIEW)}
      >
        {buttonLabel}
      </QButton>
    );
  }
  return null;
};

import { useToastProvider } from '@qualio/ui-components';
import { useState } from 'react';
import { DocumentStatus } from '../../../../../../../api/model/document';
import { WorkspaceTabs } from '../../../../types';
import {
  hasAllowedStatusesForAction,
  hasRequiredPermissionsForAction,
  isValidTabForAction,
} from '../../../BulkActions';
import { BulkActionButton } from '../../BulkActionButton/index';
import { handleStatusChange } from '../ChangeStatusService';
import {
  ChangeStatusButtonProps,
  IsChangeStatusActionAllowedCallback,
} from '../types';

export const SendForReviewButton = (props: ChangeStatusButtonProps) => {
  const { showToast } = useToastProvider();
  const [isLoading, setIsLoading] = useState(false);

  const toastActionStringActive = 'send for review';
  const toastActionStringPassive = 'sent for review';

  const handleSendForReview = async () => {
    setIsLoading(true);
    await handleStatusChange(
      props.selectedDocuments,
      { status: DocumentStatus.For_Review },
      props.setSelectedDocumentsMap,
      showToast,
      toastActionStringActive,
      toastActionStringPassive,
    );
    setIsLoading(false);
    props.refetch();
  };

  const canSendForReview: IsChangeStatusActionAllowedCallback = (
    user,
    statuses,
    activeTab,
    requiredPermissions,
  ) => {
    if (
      !hasRequiredPermissionsForAction(requiredPermissions, user.permissions) ||
      !isValidTabForAction([WorkspaceTabs.YOUR_DOCUMENTS], activeTab)
    ) {
      return false;
    }
    return hasAllowedStatusesForAction([DocumentStatus.Draft], statuses);
  };

  return (
    <BulkActionButton
      {...props}
      isActionAllowed={canSendForReview}
      onClick={handleSendForReview}
      isLoading={isLoading}
    />
  );
};

import { QBox, QStack, QText } from '@qualio/ui-components';
import { useMemo } from 'react';
import { QualioDocumentAuditGroups } from '../../../../../api/model/document';
import { Group } from '../../../../../api/model/group';
import { Tag } from '../../../../../api/model/tag';
import { MedtechUserV2 } from '../../../../../api/user';
import {
  isNormalUsergroup,
  isQualityUsergroup,
} from '../../../../../util/CurrentUser';
import { sentenceCase } from '../../../../../util/StringUtils';
import { DocumentUsers } from '../DocumentUsers';
import {
  ManageApproversButton,
  ManageApproversButtonProps,
} from './ManageApproversButton';

export type ManageApproversProps = {
  approvers: QualioDocumentAuditGroups['approver_group'];
  groups: Group[];
  tags: Tag[];
  users: MedtechUserV2[];
  approverType: ManageApproversButtonProps['approverType'];
};

const ManageApprovers = ({
  approvers = [],
  groups,
  tags,
  approverType,
  users,
}: ManageApproversProps) => {
  const title = sentenceCase(`${approverType} approvers`);
  const modalSubtitle =
    approverType === 'quality'
      ? 'Select users who are required for final document approval and sign-off.'
      : 'Select users responsible for final document approval and sign-off.';
  return (
    <QBox data-cy={`document-${approverType}-approvers`}>
      <QStack
        isInline
        justifyContent="space-between"
        alignItems="center"
        data-cy={`document-manage-${approverType}-approvers`}
      >
        <QText fontWeight="semibold" fontSize="md">
          {title}
        </QText>
        <ManageApproversButton
          modalTitle={`Manage ${approverType.toLowerCase()} approvers`}
          modalSubtitle={modalSubtitle}
          tags={tags}
          groups={groups}
          users={users}
          currentApprovers={approvers}
          approverType={approverType}
        />
      </QStack>
      <DocumentUsers userType="approver" users={approvers} />
    </QBox>
  );
};

export const ManageQualityApprovers = ({
  approvers,
  tags,
  groups,
  users,
}: Pick<ManageApproversProps, 'approvers' | 'tags' | 'groups' | 'users'>) => {
  const currentApprovers = useMemo(
    () => approvers?.filter(isQualityUsergroup),
    [approvers],
  );
  const potentialApprovers = useMemo(
    () => users.filter(isQualityUsergroup),
    [users],
  );
  return (
    <ManageApprovers
      approverType="quality"
      approvers={currentApprovers}
      tags={tags}
      groups={groups}
      users={potentialApprovers}
      data-cy="document-manage-quality-approvers"
    />
  );
};

export const ManageOtherApprovers = ({
  approvers,
  tags,
  groups,
  users,
}: Pick<ManageApproversProps, 'approvers' | 'tags' | 'groups' | 'users'>) => {
  const currentApprovers = useMemo(
    () => approvers?.filter((user) => !isQualityUsergroup(user)),
    [approvers],
  );
  const potentialApprovers = useMemo(
    () => users.filter(isNormalUsergroup),
    [users],
  );
  return (
    <ManageApprovers
      approverType="other"
      approvers={currentApprovers}
      tags={tags}
      groups={groups}
      users={potentialApprovers}
    />
  );
};

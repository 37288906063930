import axios from 'axios';
import React, { useCallback, useState } from 'react';

import { useToastProvider } from '@qualio/ui-components';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';

import { documentApi } from '../../../api/document';
import {
  QualioDocument,
  QualioDocumentAttachment,
} from '../../../api/model/document';
import { qualioFileDocumentApi } from '../../../api/qualioFileDocumentApi';

import {
  EXTERNAL_SOURCE_FILE_ERROR,
  logCustomError,
} from '../../../messages/LogErrorMessages';
import { updateLastModifiedTimeOnSave } from '../../../util/EventHandlersUtils';
import {
  ACCEPTED_FILE_DOCUMENT_EXTS,
  buildErrorPickHandler,
  ExternalFileToastMessages,
  getExternalFileDownloadUrlProps,
  translateExtensionToMimeType,
} from '../../../util/FileUploadUtils';
import { validateFilesBeforeUpload } from '../BulkFileUpload/BulkFileUploadHandlers';
import { DeleteEntityModal } from '../DeleteEntityModal/index';
import {
  ModifiedRemoteFileMetadata,
  RemoteFileMetadata,
} from '../QFilePicker/index';
import { FileUploadInput } from './FileUploadInput';
import { DownloadUrlResponse } from './types';
import { UploadedFile } from './UploadedFile';

export const FileDocumentCustomUploadPanel: React.FC<{
  document: QualioDocument;
  attachments: QualioDocumentAttachment[];
  currentUser: CurrentUser & { tz?: string };
  setAttachments: (arg0: QualioDocumentAttachment[]) => void;
}> = ({ document, attachments, setAttachments, currentUser }) => {
  const [fileToDelete, setFileToDelete] =
    useState<QualioDocumentAttachment | null>(null);
  const [isDeletingFile, setIsDeletingFile] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isFetchingLatest, setIsFetchingLatest] = useState(false);

  const { showToast } = useToastProvider();

  const onCloseModal = () => setFileToDelete(null);
  const removeFileCallback = useCallback(async () => {
    setIsDeletingFile(true);
    await qualioFileDocumentApi.removeAttachmentAsDocumentContent(document);
    const date = Date.now();
    updateLastModifiedTimeOnSave(date, currentUser.tz);
    setAttachments([]);
    setFileToDelete(null);
    setIsDeletingFile(false);
  }, [currentUser.tz, document, setAttachments]);

  const handleDelete = () => {
    setFileToDelete(attachments[0]);
  };

  const handlePickError = (
    errorProps: (typeof ExternalFileToastMessages)[keyof typeof ExternalFileToastMessages],
    message?: string | undefined,
  ) => {
    showToast({
      id: 'handle-pick-error',
      status: 'error',
      title: errorProps.title,
      description: message ?? errorProps.message,
    });
    stopLoadingFlags();
  };

  const errorPickerHandler = buildErrorPickHandler(handlePickError, false);

  const stopLoadingFlags = () => {
    setIsUploading(false);
    setIsFetchingLatest(false);
  };

  const attachExternalFile = async (
    modifiedRemoteFileMetadata: ModifiedRemoteFileMetadata,
  ): Promise<void> => {
    const attachment = await documentApi.addExternalSourceFileAsAttachment(
      document.sections[0].id,
      document.company_id,
      modifiedRemoteFileMetadata,
    );

    if (attachment) {
      setAttachments([
        {
          ...attachment,
          filename: attachment.filename,
        },
      ]);
      await qualioFileDocumentApi.setAttachmentAsDocumentContent(
        attachment.id,
        document,
      );
      const date = Date.now();
      updateLastModifiedTimeOnSave(date, currentUser.tz);
    }
  };

  const getModifiedRemoteFileMataData = (
    remoteFileMetadata: RemoteFileMetadata,
    presignedUrl: string,
    lastModifiedAt?: string,
  ) => {
    return {
      ...remoteFileMetadata,
      lastModifiedAt,
      presignedUrl,
    };
  };

  const handleFetchLatest = async (files: RemoteFileMetadata[]) => {
    if (!files.length) {
      return;
    }
    const remoteFileMetadata = files[0];

    setIsFetchingLatest(true);

    let downloadUrlResponse: DownloadUrlResponse;
    try {
      downloadUrlResponse = await getExternalFileDownloadUrlProps(
        remoteFileMetadata,
      );
    } catch (error: unknown) {
      logCustomError(EXTERNAL_SOURCE_FILE_ERROR, { error });
      handlePickError(ExternalFileToastMessages.FETCH_LATEST_FAILED);
      return;
    }

    const modifiedRemoteFileMetaData = getModifiedRemoteFileMataData(
      remoteFileMetadata,
      downloadUrlResponse.location,
      downloadUrlResponse.lastModified,
    );

    try {
      await attachExternalFile(modifiedRemoteFileMetaData);
      showToast({
        id: 'fetch-latest-success',
        title: ExternalFileToastMessages.FETCH_LATEST_SUCCESS.title,
        description: ExternalFileToastMessages.FETCH_LATEST_SUCCESS.message,
        status: 'success',
      });
    } catch (error: unknown) {
      logCustomError(EXTERNAL_SOURCE_FILE_ERROR, { error });
      handlePickError(ExternalFileToastMessages.FETCH_LATEST_FAILED);
    } finally {
      stopLoadingFlags();
    }
  };

  const handlePick = async (files: RemoteFileMetadata[]) => {
    if (!files.length) {
      return;
    }
    setIsUploading(true);
    const remoteFileMetadata = files[0];

    let downloadUrlResponse: DownloadUrlResponse;

    try {
      downloadUrlResponse = await getExternalFileDownloadUrlProps(
        remoteFileMetadata,
      );
    } catch (error: unknown) {
      logCustomError(EXTERNAL_SOURCE_FILE_ERROR, { error });
      handlePickError(ExternalFileToastMessages.ADD_FILE_FAILED);
      return;
    }

    const modifiedRemoteFileMetaData = getModifiedRemoteFileMataData(
      remoteFileMetadata,
      downloadUrlResponse.location,
      downloadUrlResponse.lastModified,
    );

    try {
      await attachExternalFile(modifiedRemoteFileMetaData);
    } catch (error: unknown) {
      logCustomError(EXTERNAL_SOURCE_FILE_ERROR, { error });
      handlePickError(ExternalFileToastMessages.ADD_FILE_FAILED);
    } finally {
      stopLoadingFlags();
    }
  };

  const handleUpload = async (files: File[] | null) => {
    const cancelTokenSource = axios.CancelToken.source();
    if (files === null) {
      return;
    }
    const isValidFile = validateFilesBeforeUpload(files, 1, showToast, true);

    if (!isValidFile) {
      return;
    }

    setIsUploading(true);
    try {
      const attachment = await documentApi.uploadAttachment(
        files[0],
        (_progress) => void 0,
        cancelTokenSource.token,
        undefined,
        document.sections[0].id,
        'document.section',
      );

      if (attachment) {
        setAttachments([attachment]);
        await qualioFileDocumentApi.setAttachmentAsDocumentContent(
          attachment.id,
          document,
        );
        const date = Date.now();
        updateLastModifiedTimeOnSave(date, currentUser.tz);
      }
    } catch (e: unknown) {
      handlePickError(ExternalFileToastMessages.ADD_FILE_FAILED);
    } finally {
      stopLoadingFlags();
    }
  };

  const shouldRenderUploadedFile = !isUploading && attachments[0];

  return (
    <div style={{ width: '100%' }}>
      {!shouldRenderUploadedFile && (
        <FileUploadInput
          acceptedFileFormats={ACCEPTED_FILE_DOCUMENT_EXTS.map(
            translateExtensionToMimeType,
          )}
          isUploading={isUploading}
          handleUpload={handleUpload}
          handlePick={handlePick}
          handlePickError={errorPickerHandler}
        />
      )}
      {shouldRenderUploadedFile && (
        <UploadedFile
          attachment={attachments[0]}
          handleDelete={handleDelete}
          handleFetchLatest={handleFetchLatest}
          isFetchingLatest={isFetchingLatest}
          tz={currentUser.tz}
        />
      )}
      <DeleteEntityModal
        isDeletingEntity={isDeletingFile}
        isOpen={!!fileToDelete}
        onCloseModal={onCloseModal}
        deleteHandler={removeFileCallback}
      />
    </div>
  );
};

import { templateApiClient } from './index';
import { MedtechCompanyUserGroup } from './user';

export type Template = {
  id: number;
  template_uuid: string;
  name: string;
  prefix: string;
  company_id: number;
  status: string;
  version: number;
  document_effective_on_approval: boolean;
  training_required: boolean;
  training_available: boolean;
  document_review_period: boolean;
  document_review_due_in_days: number;
  document_approval_due_in_days: number;
  document_text_sections_visible: boolean;
  file_download_user_groups: MedtechCompanyUserGroup[];
  created_time: Date;
  modified_time?: Date;
};

const templateApi = {
  async getTemplatesForCompany(companyId: number): Promise<Template[]> {
    const res = await templateApiClient.get(
      `/companies/${companyId}/templates`,
    );
    return res.data.data as Template[];
  },
};

export default templateApi;

import { QText, QTextProps } from '@qualio/ui-components';

export const QActivityText = ({
  text,
  weight = 'regular',
}: {
  text: string;
  weight?: QTextProps['weight'];
}) => {
  return (
    <QText color="gray.700" fontSize="sm" weight={weight}>
      {text}
    </QText>
  );
};

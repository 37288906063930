import { GroupUser } from '../api/model/document';
import { MedtechUserV2 } from '../api/user';

export const groupUserToMedtechUser = (user: GroupUser): MedtechUserV2 => {
  return {
    id: user.user_id,
    email: user.email,
    full_name: user.full_name,
    groups: [],
    usergroups: user.usergroups,
    permissions: user.permissions,
    usercompany_id: user.usercompany_id,
  };
};

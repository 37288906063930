import { EditorName } from '../config/handlers/EditorEventsHandlers';

export const navigateToSection = (index: number, editorName: EditorName) => {
  const sectionsHeadings = document.querySelectorAll(
    `#${editorName} h1.section-heading`,
  );
  if (sectionsHeadings.length > 0 && index < sectionsHeadings.length) {
    sectionsHeadings[index].scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
};

import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import { useContext, useMemo, useState } from 'react';
import { ButtonGroupContext } from '../../../../Contexts/ButtonGroupContext';
import { DocumentConvertFormatModal } from '../../../../DocumentConvertFormatModal/index';
import { DocumentOverviewContext } from '../../../Context';
import { shouldRenderChangeFormatButton } from '../../../RenderLogic';

type ChangeFormatButtonProps = {
  renderClickableItem: (onClick: () => void) => JSX.Element;
};

export const ChangeFormatButton = ({
  renderClickableItem,
}: ChangeFormatButtonProps) => {
  const currentUser = useCurrentUser();
  const { qualioDocument, refetchDocument } = useContext(
    DocumentOverviewContext,
  );
  const { setDisabled } = useContext(ButtonGroupContext);
  const { showToast } = useToastProvider();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const canChangeFormat = useMemo(
    () => shouldRenderChangeFormatButton(qualioDocument, currentUser),
    [currentUser, qualioDocument],
  );

  const onSuccess = () => {
    refetchDocument();
    showToast({
      id: 'doc-overview-change-format-toast',
      replace: true,
      status: 'success',
      title: 'Document format changed!',
      description: 'Successfully changed document format!',
    });
    setDisabled(false);
  };

  const onError = () => {
    setDisabled(false);
  };

  if (!canChangeFormat) {
    return null;
  }

  return (
    <>
      {renderClickableItem(() => {
        setDisabled(true);
        setIsModalOpen(true);
      })}
      {isModalOpen && (
        <DocumentConvertFormatModal
          onClose={() => setDisabled(false, setIsModalOpen)}
          qualioDocument={qualioDocument}
          onSuccess={onSuccess}
          onError={onError}
        />
      )}
    </>
  );
};

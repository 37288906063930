import { ChangeControl, ChangeControlDTO } from '../types/DocumentCreate';
import { medtechApiClient } from './index';
import { QualioDocument } from './model/document';

export type ChangeControlComment = {
  change_id: string;
  content: string;
  id: number;
  reply_to: number | null;
  resolved: boolean;
  section_id: number;
  timestamp: number;
  user: {
    email: string;
    full_name: string;
    id: number;
  };
};

export type ChangeControlUpdateParams = {
  create_section_edit_activity_log?: boolean;
};

export const changeControlApi = {
  async createChangeControl(
    document: QualioDocument,
    changeControlTemplateId: number,
    changeControl: ChangeControlDTO,
  ): Promise<any> {
    const payload = {
      company_id: document.company_id,
      type: changeControlTemplateId,
      documents: [document.id],
      sections: changeControl.sections,
      title: document.title,
    };
    return await medtechApiClient
      .post(`/changes`, payload)
      .then((res) => res.data);
  },

  async getChangeControls(
    companyId: number,
    documentId: number,
  ): Promise<ChangeControlDTO[]> {
    return medtechApiClient
      .get<ChangeControlDTO[]>(
        `/changes?company_id=${companyId}&document_id=${documentId}`,
      )
      .then((res) => res.data);
  },

  async fetchHistoricalComments(
    changeControlId: string,
  ): Promise<ChangeControlComment[]> {
    const url = `/changes/${changeControlId}/comments`;
    const httpResp = await medtechApiClient.get(url);
    return httpResp.data;
  },

  async fetchChangeControlTemplates() {
    const httpResp = await medtechApiClient.get(`/change_templates`);
    return httpResp.data;
  },

  async fetchChangeControl(
    docId: number,
    companyId: number,
  ): Promise<ChangeControl[]> {
    const httpResp = await medtechApiClient.get('/changes', {
      params: {
        company_id: companyId,
        document_id: docId,
        include_attachments: true,
        process_references: 1,
      },
      paramsSerializer: {
        indexes: null,
      },
    });
    return httpResp.data;
  },

  async updateChangeControl(
    changeControlDto: ChangeControlDTO,
    shouldCreateSectionEditActivityLog: boolean,
  ): Promise<any> {
    const params: ChangeControlUpdateParams = {};
    if (shouldCreateSectionEditActivityLog) {
      params.create_section_edit_activity_log = true;
    }

    const httpResp = await medtechApiClient.patch(
      '/changes/' + changeControlDto.id,
      changeControlDto,
      { params: params },
    );
    return httpResp.data;
  },
};

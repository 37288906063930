import { QLookup, QModalHeader } from '@qualio/ui-components';
import { useCallback, useMemo } from 'react';
import { Group } from '../../../../../../api/model/group';
import { MedtechUserV2 } from '../../../../../../api/user';
import { QLookupModalComposedHeader } from '../../../../QLookupModalComposedHeader';
import {
  filterUserOptionLookup,
  getUserLookupViewWithAccessRestriction,
} from '../../utils';

type ReadOnlyTraineeLookupProps = {
  isReadOnly?: true;
  isTraineePreselected?: undefined;
  updateTrainees?: undefined;
  isTraineeDisabled?: undefined;
};

type ManageTraineeLookupProps = {
  isReadOnly?: false;
  isTraineePreselected: QLookup.MultiSelectProps<MedtechUserV2>['isItemPreSelected'];
  isTraineeDisabled: QLookup.MultiSelectProps<MedtechUserV2>['isItemDisabled'];
  updateTrainees: QLookup.MultiSelectProps<MedtechUserV2>['onSelect'];
};

type TraineesLookupProps = {
  title: string;
  subtitle: string;
  isOpen: boolean;
  onClose: () => void;
  trainees: MedtechUserV2[];
  usersWithoutAccess: MedtechUserV2[];
  groups: Group[];
} & (ReadOnlyTraineeLookupProps | ManageTraineeLookupProps);
export const TraineesLookup = ({
  title,
  subtitle,
  isOpen,
  onClose,
  trainees,
  usersWithoutAccess,
  groups,
  isReadOnly = false,
  updateTrainees = () => Promise.resolve(),
  isTraineePreselected = () => false,
  isTraineeDisabled = () => false,
}: TraineesLookupProps) => {
  const filterOptionCallback = useCallback(
    (user: MedtechUserV2, searchTerm: string | undefined) => {
      if (!searchTerm) {
        return true;
      }
      return filterUserOptionLookup(searchTerm, user, groups);
    },
    [groups],
  );

  const userDataView: QLookup.DataView<MedtechUserV2> = useMemo(
    () => getUserLookupViewWithAccessRestriction(groups, usersWithoutAccess),
    [groups, usersWithoutAccess],
  );

  return (
    <QLookup.DataProvider.Fixed
      data={trainees}
      filterOption={filterOptionCallback}
    >
      <QLookup.MultiSelect<MedtechUserV2>
        isOpen={isOpen}
        action="Apply changes"
        searchPlaceholder="Filter by name, email or group name..."
        accessors={{
          id: 'id',
        }}
        isItemPreSelected={isTraineePreselected}
        isItemDisabled={isTraineeDisabled}
        onSelect={updateTrainees}
        onCancel={onClose}
        view={userDataView}
        isReadOnly={isReadOnly}
        defaultSortBy={{
          id: 'full_name',
        }}
      >
        <QModalHeader>
          <QLookupModalComposedHeader title={title} subtitle={subtitle} />
        </QModalHeader>
      </QLookup.MultiSelect>
    </QLookup.DataProvider.Fixed>
  );
};

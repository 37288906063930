import { QButton, useCurrentUser } from '@qualio/ui-components';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { z } from 'zod';
import { useAccessToken } from './hooks/useAccessToken';
import {
  QFilePickerError,
  QFilePickerErrorType,
  QFilePickerProps,
  qPickerTypeOneDriveMapping,
  RemoteFileMetadata,
} from './types';

const log = (...args: any[]) => {
  if ((window as any).ONE_DRIVE_DEBUG) {
    console.log(args);
  }
};

const ZPickedItemResults = z
  .object({
    name: z.string().min(1),
    parentReference: z.object({
      driveId: z.string(),
    }),
    id: z.string().min(1),
    size: z.number().nonnegative(),
  })
  .array();

export const MAX_FILE_SIZE = 100 * 1024 * 1024;
export const MAX_FILES = 50;

export const QFilePicker: React.VFC<QFilePickerProps> = ({
  onPick,
  onError,
  mode,
  types,
  integrationBaseUrl,
}) => {
  const { accessToken, accessTokenBusiness, tenant } = useAccessToken({
    source: 'onedrive',
    onError,
  });

  const { openPopup } = useOnedriveListener({
    types,
    integrationBaseUrl,
    mode,
    accessToken,
    accessTokenBusiness,
    tenant,
    onPick,
    onError,
  });

  return (
    <QButton
      onClick={openPopup}
      data-cy="select-one-drive"
      isDisabled={!accessToken && !tenant}
      variant="outline"
      leftIcon="OneDrive"
    >
      OneDrive
    </QButton>
  );
};

const useOnedriveListener = ({
  types,
  integrationBaseUrl,
  mode,
  accessToken,
  accessTokenBusiness,
  tenant,
  onPick,
  onError,
}: QFilePickerProps & {
  accessToken?: string;
  accessTokenBusiness?: string;
  tenant?: string;
}) => {
  const { companyId } = useCurrentUser();
  const mappedTypes = useMemo(
    () => types.flatMap((type) => qPickerTypeOneDriveMapping[type]).join(','),
    [types],
  );

  const messagePort = useRef<MessagePort>();
  const popupWindow = useRef<Window | null>();

  const onClosePopupWindow = useCallback(() => {
    popupWindow.current?.close();
    popupWindow.current = null;
  }, []);

  const setupPort = useCallback(
    (event: MessageEvent) => {
      if (
        event.origin !== `https://${tenant}.sharepoint.com` ||
        event.source !== popupWindow.current
      ) {
        log('Channel setup mismatch');
        log('Expected source: ', popupWindow.current);
        log('Actual source: ', event.source);
        log(`Expected origin: https://${tenant}.sharepoint.com`);
        log(`Actual origin: ${event.origin}`);
        return;
      }
      const message = event.data;

      log(message);

      if (message.type === 'initialize') {
        const [port] = event.ports;
        messagePort.current = port;
        port.addEventListener('message', (e) =>
          handleMessage(
            port,
            e,
            accessToken as string,
            accessTokenBusiness as string,
            integrationBaseUrl,
            companyId,
            onPick,
            onClosePopupWindow,
            onError,
          ),
        );
        port.start();
        port.postMessage({
          type: 'activate',
        });
      }
    },
    [
      companyId,
      integrationBaseUrl,
      onClosePopupWindow,
      onError,
      onPick,
      tenant,
      accessToken,
      accessTokenBusiness,
    ],
  );

  useWindowEventListener('message', setupPort);

  const openPopup = useCallback(async () => {
    popupWindow.current = window.open(
      `${integrationBaseUrl}/company/${companyId}/source/onedrive/filepicker?types=${mappedTypes}&mode=${mode}`,
      '_blank',
      'width=1080,height=680',
    );
  }, [companyId, integrationBaseUrl, mappedTypes, mode]);

  // Uncomment the following for local testing
  // if (process.env.NODE_ENV === 'development') {
  //   return {
  //     openPopup: () => {
  //       // you can get all this from staging
  //       onPick([
  //         {
  //           filename: 'Another Document.docx',
  //           downloadUrl: `${process.env.REACT_APP_INTEGRATION_API_URL}/company/1/source/onedrive/01VUTHJMWDRMX4ZYDC25ELBXY3EQWPYHDV/downloadWithoutRedirect`,
  //           webUrl: 'https://foo.com',
  //           source: 'onedrive',
  //         },
  //       ]);
  //     },
  //   };
  // }

  return { openPopup };
};

export const handleMessage = (
  messagePort: MessagePort,
  event: MessageEvent,
  accessToken: string,
  accessTokenBusiness: string,
  integrationBaseUrl: QFilePickerProps['integrationBaseUrl'],
  companyId: number,
  onPick: QFilePickerProps['onPick'],
  onClose: () => void,
  onError: QFilePickerProps['onError'],
) => {
  log(event);

  const payload = event.data;

  switch (payload.type) {
    case 'command':
      const command = payload.data;

      messagePort.postMessage({
        type: 'acknowledge',
        id: payload.id,
      });

      switch (command.command) {
        case 'authenticate': {
          const accessTokenToUse = command.resource.includes('-my')
            ? accessToken
            : accessTokenBusiness;

          messagePort.postMessage({
            type: 'result',
            id: payload.id,
            data: {
              result: 'token',
              token: accessTokenToUse,
            },
          });
          break;
        }

        case 'pick': {
          log('pick', command);

          const erroredFiles: QFilePickerError[] = [];
          const validFiles: RemoteFileMetadata[] = [];

          try {
            const pickedItems = ZPickedItemResults.parse(command.items);

            pickedItems.forEach((item) => {
              const remoteFileMetadata: RemoteFileMetadata = {
                filename: item.name,
                downloadUrl: `${integrationBaseUrl}/company/${companyId}/source/onedrive/${item.parentReference.driveId}$${item.id}/downloadWithoutRedirect`,
                webUrl: `${integrationBaseUrl}/company/${companyId}/source/onedrive/${item.parentReference.driveId}$${item.id}/view`,
                source: 'onedrive',
              };
              if (validFiles.length >= MAX_FILES) {
                erroredFiles.push({
                  name: remoteFileMetadata.filename,
                  type: QFilePickerErrorType.TOO_MANY_FILES,
                  message: 'Too many files picked',
                });
              } else if (item.size > MAX_FILE_SIZE) {
                erroredFiles.push({
                  name: remoteFileMetadata.filename,
                  type: QFilePickerErrorType.FILE_TOO_LARGE,
                  message: 'The file size exceeds the maximum of 100 MB.',
                });
              } else {
                validFiles.push(remoteFileMetadata);
              }
            });

            onPick(validFiles);

            if (erroredFiles.length > 0) {
              onError(erroredFiles);
            }

            messagePort.postMessage({
              type: 'result',
              id: payload.id,
              data: {
                result: 'success',
              },
            });

            onClose();
          } catch (e: any) {
            onError(e.message);

            messagePort.postMessage({
              type: 'result',
              id: payload.id,
              data: {
                result: 'error',
                error: {
                  code: 'unusableItem',
                  message: e.message,
                },
              },
            });
          }
          break;
        }

        case 'close':
          onClose();
          break;

        default:
          messagePort.postMessage({
            type: 'result',
            id: payload.id,
            data: {
              result: 'error',
              error: {
                code: 'unsupportedCommand',
                message: command.command,
              },
            },
          });
          break;
      }
      break;
  }
};

const useWindowEventListener = <K extends keyof WindowEventMap>(
  type: K,
  listener: (this: Window, ev: WindowEventMap[K]) => any,
) => {
  useEffect(() => {
    window.addEventListener(type, listener);
    log('Adding window event listener');
    return () => {
      log('Removing window event listener');
      window.removeEventListener(type, listener);
    };
  }, [type, listener]);
};

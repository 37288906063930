import { Plugin } from '@ckeditor/ckeditor5-core';
import { Document, Item, Schema, Writer } from '@ckeditor/ckeditor5-engine';
import MentionCommand from '@ckeditor/ckeditor5-mention/src/mentioncommand';
import { uid } from '@ckeditor/ckeditor5-utils';
import ReactDOM from 'react-dom';
import { documentApi } from '../../api/document';
import { SmartLinkedDocumentSuggestion } from '../../api/model/document';
import {
  logCustomError,
  SMART_LINK_POSTFIXER_ERROR,
} from '../../messages/LogErrorMessages';
import { debounce } from '../../util/TimerUtils';
import styles from './QualioDocumentMentionMatrixId.module.css';

export type Mentionable = {
  id: string;
  text: string;
};

export class QualioDocumentMatrixIdMentionEditing extends Plugin {
  static get pluginName(): string {
    return 'QualioDocumentMentionEditing';
  }

  init(): void {
    const editor = this.editor;
    const downcastConversion: any = editor.conversion.for('downcast');
    const upcastConversion: any = editor.conversion.for('upcast');

    // Allow the mention attribute on all text nodes.
    editor.model.schema.extend('$text', { allowAttributes: 'mention' });

    editor.commands.add('mention', new MentionCommand(editor));

    upcastConversion.elementToAttribute({
      view: {
        name: 'a',
        classes: 'docreference',
        attributes: {
          href: true,
        },
      },
      model: {
        key: 'mention',
        value: (viewItem: any) => {
          return _toMentionAttribute(viewItem);
        },
      },
      converterPriority: 'high',
    });

    downcastConversion.attributeToElement({
      model: 'mention',
      view: (modelAttributeValue: any, { writer }: any) => {
        if (!modelAttributeValue) {
          return;
        }
        let referencedMatrixId;
        if (modelAttributeValue.id) {
          referencedMatrixId = modelAttributeValue.id.split('@')[1];
        }
        if (!referencedMatrixId) {
          return;
        }
        return writer.createAttributeElement(
          'a',
          {
            class: 'docreference',
            'mention-id': modelAttributeValue.id,
            href: `${process.env.REACT_APP_FRONTEND_HOSTNAME}/reference/${
              modelAttributeValue.id.split('@')[1]
            }`,
            target: '_blank',
          },
          {
            priority: 20,
            id: modelAttributeValue.uid,
          },
        );
      },
      converterPriority: 'high',
    });

    editor.conversion.for('downcast').add(preventPartialMentionDowncast);
    editor.model.document.registerPostFixer((writer) =>
      removePartialMentionPostFixer(
        writer,
        editor.model.document,
        editor.model.schema,
      ),
    );
    editor.model.document.registerPostFixer((writer) =>
      selectionMentionAttributePostFixer(writer, editor.model.document),
    );
  }
}

const selectionMentionAttributePostFixer = (writer: Writer, doc: Document) => {
  const selection = doc.selection;
  const focus = selection.focus;

  if (
    selection.isCollapsed &&
    selection.hasAttribute('mention') &&
    shouldNotTypeWithMentionAt(focus)
  ) {
    writer.removeSelectionAttribute('mention');

    return true;
  }
  return false;
};

// Helper function to detect if mention attribute should be removed from selection.
// This check makes only sense if the selection has mention attribute.
//
// The mention attribute should be removed from a selection when selection focus is placed:
// a) after a text node
// b) the position is at parents start - the selection will set attributes from node after.
const shouldNotTypeWithMentionAt = (position: any) => {
  const isAtStart = position.isAtStart;
  const isAfterAMention = position.nodeBefore?.is('$text');

  return isAfterAMention || isAtStart;
};

const preventPartialMentionDowncast = (dispatcher: any) => {
  dispatcher.on(
    'attribute:mention',
    (evt: any, data: any, conversionApi: any) => {
      const mention = data.attributeNewValue;

      if (!data.item.is('$textProxy') || !mention) {
        return;
      }

      const start = data.range.start;
      const textNode = start.textNode || start.nodeAfter;

      if (textNode.data !== mention._text) {
        // Consume item to prevent partial mention conversion.
        conversionApi.consumable.consume(data.item, evt.name);
      }
    },
    { priority: 'lowest' },
  );
};

const removePartialMentionPostFixer = (
  writer: Writer,
  doc: Document,
  schema: Schema,
) => {
  const changes = doc.differ.getChanges() as any;

  let wasChanged = false;

  try {
    for (const change of changes) {
      // Checks the text node on the current position.
      const position = change.position;
      if (!position) {
        continue;
      }
      const nodeBefore = position.nodeBefore;
      const nodeAfter = position.nodeAfter;

      if (change.name === '$text') {
        const nodeAfterInsertedTextNode = position.textNode?.nextSibling;

        // Checks the text node where the change occurred.
        wasChanged = checkAndFix(position.textNode, writer) || wasChanged;

        // Occurs on paste inside a text node with mention.
        wasChanged =
          checkAndFix(nodeAfterInsertedTextNode, writer) || wasChanged;
        wasChanged = checkAndFix(nodeBefore, writer) || wasChanged;
        wasChanged = checkAndFix(nodeAfter, writer) || wasChanged;
      }

      // Checks text nodes in inserted elements (might occur when splitting a paragraph or pasting content inside text with mention).
      if (change.name !== '$text' && change.type === 'insert') {
        const insertedNode = position.nodeAfter;

        for (const item of writer.createRangeIn(insertedNode).getItems()) {
          wasChanged = checkAndFix(item, writer) || wasChanged;
        }
      }

      // Inserted inline elements might break mention.
      if (change.type === 'insert' && schema.isInline(change.name)) {
        const nodeAfterInserted = nodeBefore && nodeAfter.nextSibling;

        wasChanged = checkAndFix(nodeBefore, writer) || wasChanged;
        wasChanged = checkAndFix(nodeAfterInserted, writer) || wasChanged;
      }
    }
  } catch (error) {
    logCustomError(SMART_LINK_POSTFIXER_ERROR, { error });
    wasChanged = false;
  }
  return wasChanged;
};

const isBrokenMentionNode = (node: Item) => {
  if (
    !node ||
    !(node.is('$text') || node.is('$textProxy')) ||
    !node.hasAttribute('mention')
  ) {
    return false;
  }

  const text = node.data;
  const mention = node.getAttribute('mention') as any;

  let expectedText;
  if (mention) {
    expectedText = mention._text;
  }

  return text !== expectedText;
};

const checkAndFix = (textNode: Item, writer: Writer) => {
  if (isBrokenMentionNode(textNode)) {
    writer.remove(textNode);
    return true;
  }

  return false;
};

export const _toMentionAttribute = (viewElementOrMention: any): any => {
  let mentionId = viewElementOrMention.getAttribute('mention-id');

  if (!mentionId) {
    mentionId = viewElementOrMention.getAttribute('data-mention');
  }

  if (!mentionId) {
    const href = viewElementOrMention.getAttribute('href');
    const urlSplitResults = href.split('reference/');
    if (urlSplitResults.length === 2) {
      mentionId = '@' + urlSplitResults[1];
    }
  }

  // Do not convert element with no mention id.
  if (!mentionId) {
    return;
  }

  const mentionText =
    extractMentionTextFromChildTextNodes(viewElementOrMention);

  // Do not convert empty mentions.
  if (!mentionText) {
    return;
  }

  const baseMentionData = {
    id: mentionId,
    _text: mentionText,
  };

  return { uid: uid(), ...baseMentionData };
};

const extractMentionTextFromChildTextNodes = (
  viewElementOrMention: any,
): string | undefined => {
  // if there's a (partial) comment on a smart link the view element holds several children
  // only some of them are text nodes representing parts of the text (other children represent comment markers)
  let mentionText = '';
  let hasTextChildNodes = false;
  const children = viewElementOrMention.getChildren();
  for (const child of children) {
    if (child.is('$text')) {
      hasTextChildNodes = true;
      if (child._textData) {
        mentionText = mentionText.concat(child._textData);
      }
    }
  }
  if (hasTextChildNodes) {
    return mentionText;
  } else {
    return undefined;
  }
};

const renderJSX = (jsxElement: any) => {
  const itemContainer = document.createElement('div');
  return ReactDOM.render(jsxElement, itemContainer);
};

export const renderBalloonMentionable = (item: Mentionable) => {
  let text;
  let classNameAddition = '';
  switch (item.id) {
    case '@no-match':
      text = 'No matching document found';
      classNameAddition = `${styles['no-click']}`;
      break;
    case '@info':
      text =
        'Type to look up a record by its ID, title or type (min. 3 characters)';
      classNameAddition = `${styles['no-click']}`;
      break;
    default:
      text = item.text;
  }

  return renderJSX(
    <span
      className={`${styles['mention-suggested-item']} ${classNameAddition}`}
      id={`mention-list-item-id-${item.id}`}
    >
      <span>{text}</span>
    </span>,
  );
};

const extractMentionable = (
  smartLinkedDocumentSuggestion: SmartLinkedDocumentSuggestion,
): Mentionable => {
  return {
    id: `@${smartLinkedDocumentSuggestion.document_matrix_id}`,
    text: `${smartLinkedDocumentSuggestion.code} ${smartLinkedDocumentSuggestion.title}`,
  };
};

const debouncedApi = debounce(documentApi.smartLinksSuggest, 500);

export const getMentionable =
  (companyId: number) =>
  (queryText: string): Promise<any> => {
    // exit suggestion mode if there are two whitespaces added to the query string
    if (queryText.includes('  ')) {
      return Promise.resolve();
    }
    if (queryText.length < 3) {
      return Promise.resolve([
        {
          id: `@info`, // alias for adding non-clickable info text when rendering dropdown
          text: '',
        },
      ]);
    }
    // allow whitespace at the end of the query param by replacing it with +
    queryText = queryText.replaceAll(' ', '+');
    return new Promise((resolve) => {
      void debouncedApi(companyId, { search: queryText }).then(
        (documents: SmartLinkedDocumentSuggestion[]) => {
          if (documents.length === 0) {
            return resolve([
              {
                id: `@no-match`, // alias for adding non-clickable info text when rendering dropdown
                text: '',
              },
            ]);
          }
          documents.sort(sortFn);
          const sortedArrayOfSuggestions = documents
            .slice(0, 25)
            .map(extractMentionable);
          resolve(sortedArrayOfSuggestions);
        },
      );
    });
  };

const sortFn = (
  a: SmartLinkedDocumentSuggestion,
  b: SmartLinkedDocumentSuggestion,
) => {
  const [codePrefixA, codeNumberA] = a.code.split('-');
  const [codePrefixB, codeNumberB] = b.code.split('-');

  // first sorting criterion: alphabetical order of code prefix, i.e. APREFIX-1 -> BPREFIX-1
  if (codePrefixA > codePrefixB) {
    return 1;
  }
  if (codePrefixA < codePrefixB) {
    return -1;
  } else {
    // second sorting criterion: numerical oder of code numbers, i.e. APREIFX-1 -> APREFIX-2 -> APREFIX-11
    if (+codeNumberA > +codeNumberB) {
      return 1;
    }
    if (+codeNumberA < +codeNumberB) {
      return -1;
    } else {
      return 0;
    }
  }
};

import {
  QButton,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';
import { memo, useContext, useState } from 'react';
import { documentApi } from '../../../../../../api/document';
import { DocumentStatus } from '../../../../../../api/model/document';
import { PeriodicReview } from '../../../../../../api/model/periodicReview';
import { ButtonGroupContext } from '../../../../Contexts/ButtonGroupContext';
import { CredentialConfirmationModal } from '../../../../CredentialConfirmationModal/index';
import { DocumentOverviewContext } from '../../../Context';

type DeclineButtonProps = {
  periodicReview?: PeriodicReview;
  refetchPeriodicReview: () => void;
};
export const DeclineButton = memo(
  ({ periodicReview, refetchPeriodicReview }: DeclineButtonProps) => {
    const currentUser = useCurrentUser();
    const { qualioDocument, refetchDocument } = useContext(
      DocumentOverviewContext,
    );
    const { isDisabled, setDisabled } = useContext(ButtonGroupContext);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
      useState(false);
    const { showToast } = useToastProvider();

    const openModal = () => {
      setDisabled(true);
      setIsConfirmationModalOpen(true);
    };

    const closeModal = () => {
      setDisabled(false);
      setIsConfirmationModalOpen(false);
    };

    const declineDocument = (
      email: string,
      password: string,
      comment: string,
    ) => {
      return documentApi
        .changeStatus(qualioDocument.id, {
          status: DocumentStatus.Approval_Declined,
          email,
          password,
          comment,
        })
        .then(() => {
          refetchDocument();
        });
    };

    const declinePeriodicReview = (
      email: string,
      password: string,
      comment: string,
    ) => {
      return documentApi
        .declinePeriodicReview(currentUser, qualioDocument.id, {
          email,
          password,
          comment,
        })
        .then(() => {
          refetchPeriodicReview();
        });
    };

    const handleDecline = async (
      email: string,
      password: string,
      comment = '',
    ) => {
      try {
        if (periodicReview) {
          await declinePeriodicReview(email, password, comment);
        } else {
          await declineDocument(email, password, comment);
        }
        closeModal();
      } catch (error) {
        throw error;
      }
    };

    const handleDeclineError = () => {
      showToast({
        id: 'decline-document-toast',
        status: 'error',
        title: 'Error',
        description: 'Failed to decline document. Please try again.',
        replace: true,
      });
    };

    return (
      <>
        <QButton
          data-cy="document-decline-button"
          variant="outline"
          onClick={openModal}
          isDestructive
          isDisabled={isDisabled}
        >
          Decline
        </QButton>
        {isConfirmationModalOpen && (
          <CredentialConfirmationModal
            isOpen={isConfirmationModalOpen}
            onCloseModal={closeModal}
            handleStatusChange={handleDecline}
            handleError={handleDeclineError}
            modalHeading="Decline document"
            modalText="Enter your digital signature to decline this document."
            buttonActionLabel="Decline"
            buttonProps={{
              isDestructive: true,
              variant: 'outline',
            }}
          />
        )}
      </>
    );
  },
);

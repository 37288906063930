import { QualioDocument } from '../../../api/model/document';

export type DocumentConvertFormatModalProps = {
  onClose: (success: boolean) => void;
  qualioDocument: QualioDocument;
  onSuccess?: () => void;
  onError?: () => void;
};

export enum ConvertionType {
  WithAttachment,
  WithoutAttachment,
}

export type ConvertingStatus = {
  converting: boolean;
  type?: ConvertionType;
};

import { Plugin } from '@ckeditor/ckeditor5-core';
import codeBlockIcon from '@ckeditor/ckeditor5-core/theme/icons/codeblock.svg';
import { DecoupledEditor } from '@ckeditor/ckeditor5-editor-decoupled';
import { ButtonView } from '@ckeditor/ckeditor5-ui';
import { QualioCodeBlockCommand } from './QualioCodeBlockCommand';

export class QualioCodeBlockPlugin extends Plugin {
  static get pluginName(): string {
    return 'QualioCodeBlockPlugin';
  }

  init(): void {
    const editor = this.editor as DecoupledEditor;
    this.editor.commands.add(
      QualioCodeBlockCommand.commandName,
      new QualioCodeBlockCommand(this.editor),
    );
    editor.ui.componentFactory.add(
      QualioCodeBlockCommand.commandName,
      (locale: any) => {
        const view = new ButtonView(locale);

        view.set({
          label: 'Insert Code Block',
          icon: codeBlockIcon,
          tooltip: true,
          isToggleable: true,
        });

        view
          .bind('isEnabled')
          .to(this.editor.commands.get(QualioCodeBlockCommand.commandName)!);

        view.on('execute', () => {
          editor.execute('codeBlock');
          editor.editing.view.focus();
        });
        return view;
      },
    );
  }
}

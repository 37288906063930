import { useToastProvider } from '@qualio/ui-components';
import { useQuery } from 'react-query';
import { TrainingAsessmentAPI } from '../api/trainingAssessmentApi';
import { TrainingAssessment } from '../types/trainingAssessment';

export const useTrainingAssessment = (
  documentId: TrainingAssessment['document_id'] | string | undefined,
) => {
  const nbrId =
    typeof documentId === 'string' ? parseInt(documentId) : documentId;
  const { showToast } = useToastProvider();
  return useQuery({
    queryKey: ['trainingAssessment', documentId],
    queryFn: () =>
      TrainingAsessmentAPI.getTrainingAssessmentForDocument(nbrId ?? 0),
    onError: () =>
      showToast({
        status: 'error',
        title: 'Error',
        description: 'Failed to load training assessment. Please refresh.',
      }),
    refetchOnWindowFocus: false,
  });
};

import { ReactNode, useMemo, useState } from 'react';

import {
  ButtonGroupContext,
  ButtonGroupContextType,
} from '../../../Contexts/ButtonGroupContext';

type ButtonsContextWrapperProps = {
  children?: ReactNode[];
};

export const ButtonsContextWrapper = ({
  children,
}: ButtonsContextWrapperProps) => {
  const [isButtonGroupDisabled, setIsButtonGroupDisabled] = useState(false);

  const buttonGroupContext: ButtonGroupContextType = useMemo(
    () => ({
      isDisabled: isButtonGroupDisabled,
      setDisabled: (
        isLoading: boolean,
        setLoading?: (isLoading: boolean) => void,
      ) => {
        setIsButtonGroupDisabled(isLoading);
        if (setLoading) {
          setLoading(isLoading);
        }
      },
    }),
    [isButtonGroupDisabled],
  );

  return (
    <ButtonGroupContext.Provider value={buttonGroupContext}>
      {children}
    </ButtonGroupContext.Provider>
  );
};

import { Control, Controller } from 'react-hook-form';

import {
  QBox,
  QFormControl,
  QRadio,
  QRadioGroup,
  QStack,
  QText,
} from '@qualio/ui-components';

import { Question } from '../../../../TrainingAssessment/types/trainingAssessment';

export const TrainingAssessmentQuestion = ({
  question,
  index,
  control,
}: {
  question: Question;
  index: number;
  control: Control;
}) => {
  return (
    <QBox p={4} border={'1px solid'} borderColor={'gray.100'} borderRadius={3}>
      <QStack spacing={7}>
        <QText weight="semibold">{`Question ${index}`}</QText>
        <QStack spacing={4}>
          <QText weight="semibold">{`${question.question}`}</QText>
          <Controller
            name={`question-${index}`}
            control={control}
            render={({ field: { onChange, value } }) => {
              const normalisedValue = parseInt(value);
              return (
                <QRadioGroup onChange={onChange} value={normalisedValue}>
                  <QStack spacing={4}>
                    {question.answers.map((answer, idx) => {
                      return (
                        <QFormControl key={`answer-${idx}`}>
                          <QRadio value={idx}>{answer.answer}</QRadio>
                        </QFormControl>
                      );
                    })}
                  </QStack>
                </QRadioGroup>
              );
            }}
          />
        </QStack>
      </QStack>
    </QBox>
  );
};

import { useToastProvider } from '@qualio/ui-components';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { documentApi } from '../../../../../../api/document';
import {
  DocumentStatus,
  QualioDocument,
} from '../../../../../../api/model/document';
import { userApi } from '../../../../../../api/user';
import { ChangeDocumentOwnerModal } from '../../../../ChangeDocumentOwnerModal/ChangeDocumentOwnerModal';
import {
  canUserAccessAtLeastOnePrivateTag,
  getDocumentTagIdsToGroupIdsMap,
} from '../../../../DocumentOverview/DocumentProperties/utils';
import { WorkspaceTabs } from '../../../types';
import {
  hasAllowedStatusesForAction,
  hasRequiredPermissionsForAction,
  isValidTabForAction,
} from '../../BulkActions';
import { BulkActionButton } from '../BulkActionButton';
import { ChangeOwnerButtonProps, IsActionAllowedCallback } from './types';

const canChangeOwner: IsActionAllowedCallback = (
  user,
  statuses,
  activeTab,
  requiredPermissions,
) => {
  if (
    !hasRequiredPermissionsForAction(requiredPermissions, user.permissions) ||
    !isValidTabForAction([WorkspaceTabs.ALL_DOCUMENTS], activeTab) ||
    !statuses.length
  ) {
    return false;
  }
  return hasAllowedStatusesForAction(
    [
      DocumentStatus.Draft,
      DocumentStatus.For_Review,
      DocumentStatus.For_Approval,
      DocumentStatus.Approved,
      DocumentStatus.Effective,
      DocumentStatus.For_Periodic_Review,
      DocumentStatus.Approval_Declined,
    ],
    statuses,
  );
};

export const ChangeOwnerButton = (props: ChangeOwnerButtonProps) => {
  const { currentUser, selectedDocuments, refetch, tags } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleChangeOwner = () => setIsModalOpen(true);
  const { showToast } = useToastProvider();

  const {
    isError: errorFetchingUsers,
    isLoading,
    data: users = [],
  } = useQuery({
    queryFn: () => userApi.fetchUsers(currentUser.companyId),
    queryKey: ['users'],
  });

  if (errorFetchingUsers) {
    showToast({
      status: 'error',
      id: 'change-owner-users-error',
      title: 'Error',
      description: 'Failed to load users. Please reload the page.',
      replace: true,
    });
  }

  const onSuccess = (result: PromiseSettledResult<void>[]) => {
    const successful = result.filter(
      (promise) => promise.status === 'fulfilled',
    );
    const failures = result.filter((promise) => promise.status === 'rejected');

    if (successful.length) {
      showToast({
        status: 'success',
        id: 'change-owner-success',
        title: 'Owner changed!',
        description: `Successfully changed owner of ${
          successful.length
        } document${successful.length > 1 ? 's' : ''}.`,
        replace: true,
      });
      refetch();
    }

    if (failures.length) {
      const multipleFailures = `${failures.length} document${
        failures.length > 1 ? 's' : ''
      } failed to change owner. Please try again.`;
      const singleFailure =
        (failures[0] as PromiseRejectedResult).reason?.response?.data
          ?.message || multipleFailures;
      const message = failures.length > 1 ? multipleFailures : singleFailure;
      showToast({
        status: 'error',
        id: 'change-owner-error',
        title: 'Error',
        description: message,
        replace: true,
      });
    }
    setIsModalOpen(false);
  };

  const handleConfirmChangeOwner = async (
    selectedNewOwnerId: number,
  ): Promise<PromiseSettledResult<void>[]> => {
    return Promise.allSettled(
      selectedDocuments.map((doc) => {
        return documentApi.update({ owner_id: selectedNewOwnerId }, doc.id);
      }),
    );
  };

  const changeOwnerText = `Change owner for ${
    selectedDocuments.length
  } document${selectedDocuments.length ? 's' : ''}.`;

  const documentIdToRequiredGroupIdsMap = useMemo(() => {
    return selectedDocuments.reduce((acc, qualioDocument) => {
      const tagIdsToGroupIds = getDocumentTagIdsToGroupIdsMap(
        qualioDocument,
        tags,
      );
      return acc.set(qualioDocument.id, tagIdsToGroupIds);
    }, new Map<QualioDocument['id'], Map<number, number[]>>());
  }, [selectedDocuments, tags]);

  const potentialOwners = useMemo(
    () =>
      users.filter((user) => {
        return selectedDocuments.every((qualioDocument) => {
          const requiredGroupIds = documentIdToRequiredGroupIdsMap.get(
            qualioDocument.id,
          );
          if (requiredGroupIds?.size) {
            return canUserAccessAtLeastOnePrivateTag(
              user,
              Array.from(requiredGroupIds.values()),
            );
          }
          return true;
        });
      }),
    [documentIdToRequiredGroupIdsMap, selectedDocuments, users],
  );

  return (
    <>
      <BulkActionButton
        isActionAllowed={canChangeOwner}
        {...props}
        onClick={handleChangeOwner}
      />
      <ChangeDocumentOwnerModal
        isModalOpen={isModalOpen}
        isLoading={isLoading}
        modalDescription={changeOwnerText}
        users={potentialOwners}
        onClose={() => setIsModalOpen(false)}
        handleChangeOwner={handleConfirmChangeOwner}
        onSuccess={onSuccess}
      />
    </>
  );
};

import { Plugin } from '@ckeditor/ckeditor5-core';
import { MentionUI } from '@ckeditor/ckeditor5-mention';
import {
  getMentionable,
  QualioDocumentMatrixIdMentionEditing,
  renderBalloonMentionable,
} from './QualioDocumentMatrixIdMentionEditing';

export class QualioDocumentMatrixIdMention extends Plugin {
  static get pluginName(): string {
    return 'QualioDocumentMatrixIdMention';
  }

  static get requires(): any {
    return [QualioDocumentMatrixIdMentionEditing, MentionUI];
  }
}

export const getMatrixIdMentionConfig = (companyId: number): any => ({
  dropdownLimit: 25,
  feeds: [
    {
      marker: '@',
      feed: getMentionable(companyId),
      itemRenderer: renderBalloonMentionable,
    },
  ],
});

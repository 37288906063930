import { QStack, QText } from '@qualio/ui-components';

type QLookupModalComposedHeaderProps = {
  title: string;
  subtitle: string;
};
export const QLookupModalComposedHeader = ({
  title,
  subtitle,
}: QLookupModalComposedHeaderProps) => {
  return (
    <QStack spacing="2">
      <QText>{title}</QText>
      <QText fontSize="md" fontWeight="normal">
        {subtitle}
      </QText>
    </QStack>
  );
};

import { GroupDefinition } from '@ckeditor/ckeditor5-ai/src/aiassistant';
import { QualioCodeBlockCommand } from '../plugins/CodeBlock/QualioCodeBlockCommand';

export const AI_TOOLBAR_ITEMS: string[] = ['aiCommands', '|'];

export const CONTENT_EDITOR_TOOLBAR_ITEMS: string[] = [
  'undo',
  'redo',
  '|',
  'heading',
  '|',
  'bold',
  'italic',
  'underline',
  'fontSize',
  'fontColor',
  '|',
  'link',
  'qualioFileUploadButton',
  'uploadImage',
  '|',
  'alignment',
  '|',
  'bulletedList',
  'numberedList',
  'qualioSectionListButton',
  'multiLevelList',
  '|',
  'outdent',
  'indent',
  '|',
  'insertTable',
  'qualioCheckboxButton',
  'specialCharacters',
  'subscript',
  'superscript',
  '|',
  QualioCodeBlockCommand.commandName,
  'blockQuote',
  '|',
  'fontBackgroundColor',
  'strikethrough',
  '|',
  'removeFormat',
  'pageBreak',
];

export const CHANGE_CONTROL_TOOLBAR_ITEMS: string[] = [
  'undo',
  'redo',
  '|',
  'heading',
  '|',
  'bold',
  'italic',
  'underline',
  'fontSize',
  'fontColor',
  '|',
  'link',
  'qualioFileUploadButton',
  'uploadImage',
  '|',
  'alignment',
  '|',
  'bulletedList',
  'numberedList',
  'qualioSectionListButton',
  '|',
  'outdent',
  'indent',
  '|',
  'insertTable',
  'qualioCheckboxButton',
  'specialCharacters',
  'subscript',
  'superscript',
  '|',
  QualioCodeBlockCommand.commandName,
  'blockQuote',
  '|',
  'fontBackgroundColor',
  'strikethrough',
  '|',
  'removeFormat',
];

export const TABLE_TOOLBAR_ITEMS: string[] = [
  'tableColumn',
  'tableRow',
  'mergeTableCells',
  'toggleTableCaption',
  'tableProperties',
  'tableCellProperties',
];
export const IMAGE_TOOLBAR_ITEMS: string[] = [
  'toggleImageCaption',
  'linkImage',
  'imageTextAlternative',
  '|',
  'imageStyle:alignLeft',
  'imageStyle:alignRight',
  'imageStyle:alignBlockLeft',
  'imageStyle:alignBlockRight',
  'imageStyle:alignCenter',
];

export const AI_COMMANDS: GroupDefinition[] = [
  {
    groupId: 'enhance',
    groupLabel: 'Enhance',
    commands: [
      {
        id: 'summarize',
        label: 'Summarize',
        prompt: `Your task is to take the text provided and create a concise summary that captures the essential information. Use clear and professional language,
          and organize the summary in a logical manner using appropriate formatting such as headings, subheadings, and bullet points. Ensure that the summary is
          easy to understand and provides a comprehensive but succinct overview of the text.  Do not add any additional remarks. Do not act like a chatbot or a real person.
          If the content is empty or only contains HTML tags, you must respond with "You must select content to summarize".
          `,
      },
      {
        id: 'improveWriting',
        label: 'Improve Writing',
        prompt: `Your task is to take the text provided and rewrite it into a clear,
          grammatically correct version while preserving the original meaning as closely as possible.
          Correct any spelling mistakes, punctuation errors, verb tense issues, word choice problems, and other grammatical mistakes.
          Do not add any additional remarks. Do not act like a chatbot or a real person.
          If the content is empty or only contains HTML tags, you must respond with "You must select content to improve writing".
          `,
      },
      {
        id: 'simplify',
        label: 'Simplify',
        prompt: `Your task is to take the text provided and rewrite to simplify the writing style and reduce the complexity, so that the text is easy to understand.
          You must keep the text formatting. Do not add any additional remarks. Do not act like a chatbot or a real person.
          If the content is empty or only contains HTML tags, you must respond with "You must select content to simplify".
          `,
      },
      {
        id: 'stepByStep',
        label: 'Step by step instructions',
        prompt: `Your task is to take the text provided of a process or task and transform it into clear, concise step-by-step directions that are logical, sequential,
          and easy to follow. Use imperative language and begin each step with an action verb. Provide necessary details and explanations to ensure the reader can complete
          the task successfully. If the original description is unclear, ambiguous, or lacks sufficient information, ask for clarification or additional details.
          Do not add any additional remarks. Do not act like a chatbot or a real person.
          If the content is empty or only contains HTML tags, you must respond with "You must select content to create a step by step instruction.".
          `,
      },
      {
        id: 'authoritative',
        label: 'Authoritative tone',
        prompt: `Your task is to take the text provided and rewrite  in an authoritative tone and style using formal language without losing the original meaning.
          If it is not possible to rewrite in this manner, output the original text.  Do not add any additional remarks. Do not act like a chatbot or a real person.
          If the content is empty or only contains HTML tags, you must respond with "You must select content to make authoritative.".
          `,
      },
    ],
  },
];

import {
  QButton,
  QFormControl,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QMultiSelect,
  QSelect,
  QSelectItem,
  QStack,
  QText,
} from '@qualio/ui-components';
import { useMemo, useState } from 'react';
import { ApproverSelectionOption, MigrationFileWithOverrides } from './types';

type EditFileModalProps = {
  file: MigrationFileWithOverrides;
  onClose: () => void;
  handleEdit: (file: MigrationFileWithOverrides) => void;
  approversSelectOptions: ApproverSelectionOption[];
  ownerSelectOptions: QSelectItem[];
};

export const EditFileModal: React.FC<EditFileModalProps> = ({
  file,
  onClose,
  handleEdit,
  approversSelectOptions,
  ownerSelectOptions,
}) => {
  const [isApproversDirty, setIsApproversDirty] = useState(false);
  const [isOwnerDirty, setIsOwnerDirty] = useState(false);
  const [selectedApprovers, setSelectedApprovers] = useState<
    MigrationFileWithOverrides['approvers']
  >(file.overrideOptions?.approvers ?? file.approvers);
  const [selectedOwner, setSelectedOwner] = useState<
    MigrationFileWithOverrides['owner'] | undefined
  >(file.overrideOptions?.owner ?? file.owner);

  const handleApproverChange = (approvers: ApproverSelectionOption[]) => {
    setSelectedApprovers(approvers.map(({ properties }) => properties));
    setIsApproversDirty(true);
  };

  const handleOwnerChange = ({ label, value }: QSelectItem) => {
    setSelectedOwner({ fullName: label, email: value });
    setIsOwnerDirty(true);
  };

  const approverValues = useMemo(
    () => selectedApprovers.map(({ email }) => email),
    [selectedApprovers],
  );

  const onSubmit = () => {
    const fileWithOverrideOptions: MigrationFileWithOverrides = {
      ...file,
      overrideOptions: {
        approvers: isApproversDirty
          ? selectedApprovers
          : file.overrideOptions?.approvers,
        owner: isOwnerDirty ? selectedOwner : file.overrideOptions?.owner,
      },
    };
    handleEdit(fileWithOverrideOptions);
  };

  return (
    <QModal onClose={onClose} isOpen={true}>
      <QModalHeader>
        <QText>Edit file</QText>
      </QModalHeader>
      <QModalBody>
        <QStack spacing={6}>
          <QText>
            Modify ownership and approvers for this file. Keep information
            up-to-date for efficient workflow management.
          </QText>
          <QStack spacing={6}>
            <QFormControl
              id="edit-file-owner-select"
              label="Owner"
              error="An owner is required."
              isInvalid={!selectedOwner}
            >
              <QSelect
                aria-label="owner"
                options={ownerSelectOptions}
                value={selectedOwner?.email}
                onChange={(item) => handleOwnerChange(item!)}
              />
            </QFormControl>
            <QFormControl
              id="edit-file-approvers-select"
              label="Approvers"
              error="At least one approver is required."
              helper="Multiple approvers may be assigned."
              isInvalid={!selectedApprovers.length}
            >
              <QMultiSelect
                aria-label="approvers"
                size="sm"
                options={approversSelectOptions}
                value={approverValues}
                onChange={(values) =>
                  handleApproverChange(values as ApproverSelectionOption[])
                }
              ></QMultiSelect>
            </QFormControl>
          </QStack>
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButton
          data-cy="edit-file-cancel-button"
          variant="outline"
          onClick={onClose}
        >
          Cancel
        </QButton>
        <QButton
          data-cy="edit-file-confirm-button"
          isLoading={false}
          onClick={onSubmit}
          isDisabled={!selectedApprovers.length || !selectedOwner}
        >
          Confirm
        </QButton>
      </QModalActions>
    </QModal>
  );
};

import Annotation from '@ckeditor/ckeditor5-comments/src/annotations/annotation';
import { Context } from '@ckeditor/ckeditor5-core';
import { useSearchParams } from 'react-router-dom';

export const useAnnotationHighlighting = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleHighlighting = (
    context: Context,
    identifierPredicate: (annotation: Annotation) => boolean,
    htmlSelector: string,
  ) =>
    setTimeout(() => {
      const annotationPlugin = context.plugins.get('Annotations');
      const visibleAnnotations = annotationPlugin.collection.filter(
        (annotation) => annotation.isVisible,
      );
      if (visibleAnnotations.length === 0) {
        return;
      }
      const annotationToActivate = visibleAnnotations.find(identifierPredicate);
      if (annotationToActivate) {
        annotationPlugin.activate(annotationToActivate);
        const targetElement = document.querySelector(htmlSelector);
        if (targetElement) {
          targetElement.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          });
        }
      }
      searchParams.delete('targetId');
      setSearchParams(searchParams);
    }, 250);
  return { handleHighlighting };
};

import {
  QAlert,
  QBox,
  QButton,
  QCenter,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QSpinner,
  QText,
  useToastProvider,
} from '@qualio/ui-components';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { documentApi } from '../../../api/document';
import {
  DOCMENT_TEMPLATE_UPGRADE,
  DOCMENT_TEMPLATE_UPGRADE_PREVIEW,
  logCustomError,
} from '../../../messages/LogErrorMessages';
import styles from '../DocumentOverview/DocumentEditor/DocumentEditor.module.css';
import { HTMLViewer } from '../DocumentOverview/HTMLViewer/index';

export interface DocumentUpgradePreviewModalProps {
  documentId: number;
  companyId: number;
  targetTemplate: { id: number; version: number };
  currentTemplate: { id: number; version: number };
  onSuccess: () => void;
  onClose: () => void;
}

export const DocumentUpgradePreviewModal = ({
  documentId,
  companyId,
  targetTemplate,
  currentTemplate,
  onSuccess,
  onClose,
}: DocumentUpgradePreviewModalProps) => {
  const { showToast } = useToastProvider();
  const [isUpgradingDoc, setIsUpgradingDoc] = useState(false);

  const commonToastProps = {
    id: 'upgrade-document-toast',
    replace: true,
  };

  const createDocumentPreview = () =>
    documentApi.createDocumentUpgradePreview(
      companyId,
      documentId,
      targetTemplate.id,
    );

  const upgradeDocument = async () => {
    setIsUpgradingDoc(true);
    try {
      await documentApi.upgradeDocument(
        companyId,
        documentId,
        targetTemplate.id,
      );
      showToast({
        ...commonToastProps,
        status: 'success',
        title: 'Template changes applied!',
        description: `The document has successfully been upgraded from template version 
        ${currentTemplate.version} to version 
        ${targetTemplate.version}.`,
      });
      onSuccess();
      setIsUpgradingDoc(false);
    } catch (error) {
      logCustomError(DOCMENT_TEMPLATE_UPGRADE, { error });
      showToast({
        ...commonToastProps,
        status: 'error',
        title: 'Error',
        description:
          'Failed apply templates changes and upgrade document. Please try again.',
      });
      setIsUpgradingDoc(false);
    }
  };

  const { isLoading: isGeneratingPreview, data: previewItems } = useQuery({
    queryFn: createDocumentPreview,
    queryKey: ['documentPreview', companyId, documentId, targetTemplate.id],
    onError: (error) => {
      logCustomError(DOCMENT_TEMPLATE_UPGRADE_PREVIEW, { error });
      showToast({
        ...commonToastProps,
        title: 'Error',
        description: 'Could not generate the preview of the pending changes.',
        status: 'error',
      });
    },
    refetchOnWindowFocus: false,
  });

  // the last element in the list includes the resulting document when upgrading to the latest version
  const previewedContent =
    previewItems?.preview_result_per_version_change[
      previewItems?.preview_result_per_version_change.length - 1
    ];

  return (
    <QModal size="2xl" isOpen onClose={onClose}>
      <QModalHeader>
        <QText>Review changes pending</QText>
      </QModalHeader>
      <QModalBody>
        {isGeneratingPreview && (
          <QCenter pb={4}>
            <QSpinner />
          </QCenter>
        )}
        {!isGeneratingPreview && previewedContent?.sections && (
          <>
            <QBox style={{ marginBottom: '10px' }}>
              <QAlert
                title="Permanently apply changes from newer template version"
                description={
                  <QText>
                    The document will be upgraded from template version{' '}
                    {currentTemplate.version} to version{' '}
                    {targetTemplate.version}.
                  </QText>
                }
                status="warning"
              />
            </QBox>
            <div
              id="documentHTMLViewerContent"
              data-cy="documentHTMLViewerContent"
              className={`${styles['document-editor__document__section-content']} document non-editable-mode`}
            >
              <HTMLViewer
                sections={previewedContent.sections}
                showSectionTitles={
                  previewedContent.document.text_section_titles_visible
                }
              />
            </div>
          </>
        )}
      </QModalBody>
      <QModalActions>
        <QButton
          isDisabled={isGeneratingPreview || isUpgradingDoc}
          variant="outline"
          onClick={onClose}
        >
          Cancel
        </QButton>
        <QButton
          isDisabled={isGeneratingPreview}
          isLoading={isUpgradingDoc}
          onClick={upgradeDocument}
          data-cy="apply-template-changes"
        >
          Apply
        </QButton>
      </QModalActions>
    </QModal>
  );
};

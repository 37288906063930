import { useToastProvider } from '@qualio/ui-components';
import { useState } from 'react';
import { DocumentStatus } from '../../../../../../../api/model/document';
import { WorkspaceTabs } from '../../../../types';
import {
  hasAllowedStatusesForAction,
  hasRequiredPermissionsForAction,
} from '../../../BulkActions';
import { BulkActionButton } from '../../BulkActionButton/index';
import { handleStatusChange } from '../ChangeStatusService';
import {
  ChangeStatusButtonProps,
  IsChangeStatusActionAllowedCallback,
} from '../types';

export const RevertToDraftButton = (props: ChangeStatusButtonProps) => {
  const { showToast } = useToastProvider();
  const [isLoading, setIsLoading] = useState(false);

  const toastActionStringActive = 'revert to draft';
  const toastActionStringPassive = 'reverted to draft';

  const handleRevertToDraft = async () => {
    setIsLoading(true);
    await handleStatusChange(
      props.selectedDocuments,
      { status: DocumentStatus.Draft },
      props.setSelectedDocumentsMap,
      showToast,
      toastActionStringActive,
      toastActionStringPassive,
    );
    setIsLoading(false);
    props.refetch();
  };

  const canRevertToDraft: IsChangeStatusActionAllowedCallback = (
    user,
    statuses,
    activeTab,
    requiredPermissions,
  ) => {
    if (
      !hasRequiredPermissionsForAction(requiredPermissions, user.permissions)
    ) {
      return false;
    }

    switch (activeTab) {
      case WorkspaceTabs.YOUR_DOCUMENTS:
        return hasAllowedStatusesForAction(
          [
            DocumentStatus.For_Review,
            DocumentStatus.For_Approval,
            DocumentStatus.Approval_Declined,
          ],
          statuses,
        );
      case WorkspaceTabs.ALL_DOCUMENTS:
        return hasAllowedStatusesForAction(
          [DocumentStatus.For_Review, DocumentStatus.For_Approval],
          statuses,
        );
      default:
        return false;
    }
  };

  return (
    <BulkActionButton
      {...props}
      isActionAllowed={canRevertToDraft}
      onClick={handleRevertToDraft}
      isLoading={isLoading}
    />
  );
};

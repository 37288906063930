import { Plugin } from '@ckeditor/ckeditor5-core';
import { DomConverter, ViewDocument } from '@ckeditor/ckeditor5-engine';
import { CKEditorInstance } from '../../types/CKEditorInstance';

import {
  parseHTMLString,
  removeStyleTags,
  removeTablesWidth,
  toDocumentFragment,
} from '../../util/HTMLElementUtils';

export class QualioClipboardPlugin extends Plugin {
  static get pluginName(): string {
    return 'QualioClipboardPlugin';
  }

  static handleClipboardInput(data: any, editorDocument: ViewDocument) {
    const dataTransfer = data.dataTransfer;
    const htmlContent = dataTransfer.getData('text/html');
    const hasTable = htmlContent?.includes('<table');
    if (!hasTable) {
      return;
    }

    const domConverter = new DomConverter(editorDocument, {
      renderingMode: 'data',
    });
    const parsedHTML = parseHTMLString(htmlContent);
    removeTablesWidth(parsedHTML);
    removeStyleTags(parsedHTML);
    const docFragment = toDocumentFragment(parsedHTML);
    const view = domConverter.domToView(docFragment);
    data.content = view;
  }

  init() {
    const editor = this.editor as CKEditorInstance;
    const editorDocument = editor.editing.view.document;
    editorDocument.on('clipboardInput', (_evt, data) => {
      QualioClipboardPlugin.handleClipboardInput(data, editorDocument);
    });
  }
}

import { Tooltip } from '@chakra-ui/react';
import { QIconButton } from '@qualio/ui-components';
import { forwardRef, ReactNode, useContext, useEffect } from 'react';
import { setupAttachmentPreviewListener } from '../../../../config/handlers';
import { ChangeControlDTO } from '../../../../types/DocumentCreate';
import { EditorMode } from '../../../../util/EditModeUtils';
import { TableOfContents } from '../../TableOfContents/index';
import {
  DocumentOverviewContext,
  EditorStatus,
  EditorStatusContext,
} from '../Context';
import styles from '../DocumentEditor/DocumentEditor.module.css';

type DocumentContentEditorProps = {
  changeControl: ChangeControlDTO;
  children: ReactNode;
  handleAttachmentClick: (attachmentId: string) => void;
};
export const ChangeControlSidebarWrapper = forwardRef<
  HTMLDivElement,
  DocumentContentEditorProps
>(({ changeControl, children, handleAttachmentClick }, ref) => {
  const { currentEditorMode, commentsPermission } = useContext(
    DocumentOverviewContext,
  );

  const { ccEditorStatus } = useContext(EditorStatusContext);

  useEffect(() => {
    const contentElement = document.querySelector(
      '#changeControlEditorContent',
    );
    if (contentElement) {
      setupAttachmentPreviewListener(contentElement, handleAttachmentClick);
    }
  }, [handleAttachmentClick]);

  const isEditMode =
    currentEditorMode !== EditorMode.VIEW &&
    ccEditorStatus !== EditorStatus.CONNECTING;

  const borderClass = isEditMode
    ? styles['document-editor__document__content-container-border']
    : '';

  const visibilityClass = `document-editor__document__content-container__show`;

  const editorViewingModeClass = !isEditMode
    ? `document-editor__document__content-container__viewing`
    : '';

  return (
    <div
      ref={ref}
      className={`${styles['document-editor__document__content-container']} ${styles[visibilityClass]} ${styles[editorViewingModeClass]} ${borderClass}`}
    >
      {
        <TableOfContents
          sections={changeControl.sections}
          sectionTitlesVisible={true}
          editorName="changeControlEditorContent"
        />
      }
      <div
        id="changeControlEditorContent"
        data-cy="changeControlEditorContent"
        className={`${
          styles['document-editor__document__section-content']
        } document ${!isEditMode ? 'non-editable-mode' : ''}`}
      >
        {children}
      </div>
      <div
        hidden={commentsPermission <= 1}
        id="changeControlCommentControl"
        data-cy="changeControlCommentControl"
        className={`${styles['document-editor__document__comment-control']} hidden`}
      >
        <Tooltip
          label={'Select content to add a comment'}
          hasArrow={true}
          placement={'top'}
        >
          <p>
            <QIconButton
              iconName={'MessageSquare'}
              aria-label="icon-button"
              size="md"
              data-cy="cc-comment-button"
            />
          </p>
        </Tooltip>
      </div>
      <div
        id="changeControlSidebar"
        data-cy="changeControlSidebar"
        className={`${styles['document-editor__document__sidebar']} ${
          ccEditorStatus === EditorStatus.CONNECTING ? 'ck-hidden' : ''
        } `}
      ></div>
    </div>
  );
});

import { useContext, useState } from 'react';

import {
  QButton,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';

import { documentApi } from '../../../../../api/document';

import { CredentialConfirmationModal } from '../../../CredentialConfirmationModal/index';

import { DocumentStatus } from '../../../../../api/model/document';
import { ButtonGroupContext } from '../../../Contexts/ButtonGroupContext';
import {
  ChangeStatusErrorResponseSchema,
  DEFAULT_FAILURE_MESSAGE,
} from '../../../DocumentWorkspace/BulkActions/BulkActionButtons/ChangeStatusButtons/ChangeStatusService';
import { DocumentOverviewContext } from '../../Context';
import { shouldRenderMakeEffectiveButton } from '../../RenderLogic';

export const MakeEffectiveButton = () => {
  const currentUser = useCurrentUser();
  const { qualioDocument, refetchDocument } = useContext(
    DocumentOverviewContext,
  );
  const { isDisabled, setDisabled } = useContext(ButtonGroupContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showToast } = useToastProvider();

  const commonToastProps = {
    id: 'make-effective-toast',
    replace: true,
  };

  if (!shouldRenderMakeEffectiveButton(qualioDocument, currentUser)) {
    return null;
  }

  const handleClose = () => {
    setDisabled(false);
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setDisabled(true);
    setIsModalOpen(true);
  };

  const handleMakeEffective = async (
    email: string,
    password: string,
    comment = '',
  ) => {
    const payload = {
      status: DocumentStatus.Effective,
      email,
      password,
      comment,
    };
    await documentApi
      .changeStatus(qualioDocument.id, payload)
      .then(() => {
        refetchDocument();
        showToast({
          ...commonToastProps,
          description: 'Successfully made document effective.',
          status: 'success',
          title: 'Success!',
        });
        handleClose();
      })
      .catch((error: unknown) => {
        throw error;
      });
  };

  const handleMakeEffectiveError = (error: unknown) => {
    const parsedError = ChangeStatusErrorResponseSchema.safeParse(error);
    showToast({
      ...commonToastProps,
      status: 'error',
      title: 'Failed to make effective',
      description: parsedError.success
        ? parsedError.data
        : DEFAULT_FAILURE_MESSAGE,
    });
  };

  return (
    <>
      <QButton
        data-cy="document-make-effective-button"
        isDisabled={isDisabled}
        variant="solid"
        onClick={handleOpenModal}
      >
        Make effective
      </QButton>
      <CredentialConfirmationModal
        isOpen={isModalOpen}
        onCloseModal={handleClose}
        modalHeading="Make document effective"
        modalText="Enter your digital signature to make this document effective."
        buttonActionLabel="Make effective"
        handleStatusChange={handleMakeEffective}
        handleError={handleMakeEffectiveError}
      />
    </>
  );
};

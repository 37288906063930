import { QTag, QTagProps } from '@qualio/ui-components';
import React from 'react';

type ChangeRequestStatusProps = {
  status: string;
};

export const CHANGE_REQUEST_STATUSES = [
  {
    name: 'draft',
    text: 'Draft',
    tagColor: 'default',
  },
  {
    name: 'for_approval',
    text: 'For approval',
    tagColor: 'blue',
  },
  {
    name: 'approval_declined',
    text: 'Approval declined',
    tagColor: 'red',
  },
  {
    name: 'in_progress',
    text: 'In progress',
    tagColor: 'blue',
  },
  {
    name: 'closed',
    text: 'Closed',
    tagColor: 'green',
  },
  {
    name: 'attention_required',
    text: 'Attention required',
    tagColor: 'yellow',
  },
] as const;

export enum ChangeRequestStatusEnum {
  DRAFT = 'draft',
  IN_PROGRESS = 'in_progress',
  CLOSED = 'closed',
  FOR_APPROVAL = 'for_approval',
  APPROVAL_DECLINED = 'approval_declined',
  ATTENTION_REQUIRED = 'attention_required',
}

export const ChangeRequestStatus: React.FC<ChangeRequestStatusProps> = ({
  status,
}) => {
  const changeRequestStatus =
    CHANGE_REQUEST_STATUSES.find((crStatus) => crStatus.name === status) ??
    CHANGE_REQUEST_STATUSES[0];

  return (
    <QTag
      data-cy={`cc-status-${changeRequestStatus.name}`}
      variantColor={changeRequestStatus.tagColor as QTagProps['variantColor']}
    >
      {changeRequestStatus.text}
    </QTag>
  );
};

import Annotation from '@ckeditor/ckeditor5-comments/src/annotations/annotation';
import { Context } from '@ckeditor/ckeditor5-core';
import DecoupledEditor from '@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor';
import { CKEditorContext } from '@ckeditor/ckeditor5-react';
import { EventInfo } from '@ckeditor/ckeditor5-utils';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useCallback, useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { QualioDocument } from '../../../api/model/document';
import { getIndependentContextConfig } from '../../../config/DocumentEditorConfig';
import {
  handleAddFileDocumentComment,
  setupEditorConnectionClosedHandler,
} from '../../../config/handlers';
import { useAnnotationHighlighting } from '../../../hooks/AnnotationHighlighting';
import { useElementIsRendered } from '../../../hooks/ElementRenderedCheck';
import { getHTMLElement } from '../../../util/HTMLElementUtils';
import { refreshDisplayMode } from '../../../util/SidebarUtils';
import { DocumentOverviewContext } from '../DocumentOverview/Context';
import styles from './FileDocumentCommentsWrapper.module.css';

export const FileDocumentCommentsWrapper: React.FC<{
  currentUser: CurrentUser;
  attachmentFilename?: string;
  attachmentId?: string;
  fileDocument: QualioDocument;
  collaborationToken: string;
  commentsPermission: number;
  children: any;
  contextHandler?: (context: Context) => void;
  handleSessionDisconnectionEvent?: (didReceive: boolean) => void;
}> = ({
  currentUser,
  attachmentFilename,
  attachmentId,
  fileDocument,
  collaborationToken,
  commentsPermission,
  children,
  contextHandler,
  handleSessionDisconnectionEvent,
}) => {
  const [searchParams] = useSearchParams();
  const targetId = searchParams.get('targetId') ?? undefined;
  const { handleHighlighting } = useAnnotationHighlighting();

  const [isSideBarRendered, sidebarElement] =
    useElementIsRendered('#commentsSidebar');

  const { mtbeRetrieveCollabComments: isMtbeRetrieveCollabCommentsEnabled } =
    useFlags();

  const { users, tags } = useContext(DocumentOverviewContext);

  const setupCommentButton = useCallback(
    (context: any) => {
      const channelId = context.config.get('collaboration').channelId;
      const commentsRepository = context.plugins.get('CommentsRepository');
      const commentButton = getHTMLElement(
        '#controls button[aria-label="Comment"]',
      );
      const { id } = fileDocument;

      // Only register event listener if both document id and attachment id are present
      if (id && attachmentId) {
        commentButton?.addEventListener('click', () => {
          const commentsAnchor = getHTMLElement('#commentsAnchor');
          const threadId = `${id}-${attachmentId}-${Date.now()}`;
          commentsRepository.openNewCommentThread({
            channelId,
            threadId,
            target: commentsAnchor,
          });
        });
      }
      if (commentButton?.hasAttribute('disabled')) {
        commentButton?.removeAttribute('disabled');
      }
    },
    [attachmentId, fileDocument],
  );

  const setupComments = useCallback(
    (context: any) => {
      const channelId = context.config.get('collaboration').channelId;
      const commentsRepository = context.plugins.get('CommentsRepository');

      for (const thread of commentsRepository.getCommentThreads({
        channelId,
      })) {
        handleCommentThread(thread);
      }

      commentsRepository.on(
        'addCommentThread:' + channelId,
        (_evt: any, data: any) => {
          handleCommentThread(
            commentsRepository.getCommentThread(data.threadId),
          );
        },
        { priority: 'low' },
      );

      commentsRepository?.on(
        'addComment',
        (evt: EventInfo, data: CommentsRepository.CommentData) => {
          if (attachmentFilename) {
            handleAddFileDocumentComment(
              evt,
              data,
              currentUser,
              attachmentFilename,
            );
          }
        },
        {
          priority: 'highest',
        },
      );

      function handleCommentThread(thread: any) {
        const commentsAnchor = getHTMLElement('#commentsAnchor');
        if (!thread.isAttached && !thread.deletedAt) {
          thread.attachTo(commentsAnchor);
        }
      }
    },
    [attachmentFilename, currentUser],
  );

  const targetIdentifierPredicate = useCallback(
    (annotation: Annotation): boolean => {
      if (annotation.view.content.length > 0) {
        return (annotation.view.content as any)._items.some(
          (element: any) => element.commentThread.id === targetId,
        );
      } else {
        return false;
      }
    },
    [targetId],
  );

  const contextReadyHandler = useCallback(
    (context: Context) => {
      refreshDisplayMode({
        instances: [context],
        windowWidth: window.innerWidth,
        breakpointOverride: 1800,
      });
      window.addEventListener('resize', () => {
        refreshDisplayMode({
          instances: [context],
          windowWidth: window.innerWidth,
          breakpointOverride: 1800,
        });
      });
      setupComments(context);
      setupCommentButton(context);
      contextHandler && contextHandler(context);
      if (handleSessionDisconnectionEvent) {
        setupEditorConnectionClosedHandler(
          context,
          handleSessionDisconnectionEvent,
        );
      }
      if (targetId) {
        handleHighlighting(
          context,
          targetIdentifierPredicate,
          `[data-thread-id="${targetId}"]`,
        );
      }
    },
    [
      handleSessionDisconnectionEvent,
      contextHandler,
      setupCommentButton,
      setupComments,
      targetId,
      targetIdentifierPredicate,
      handleHighlighting,
    ],
  );

  const contextConfig = useMemo(
    () =>
      getIndependentContextConfig({
        fileDocument,
        collaborationToken,
        commentsPermission,
        sidebarConfig: { sidebarElement, preventScrollOutOfView: true },
        users,
        tags,
        isMtbeRetrieveCollabCommentsEnabled,
      }),
    [
      fileDocument,
      collaborationToken,
      commentsPermission,
      sidebarElement,
      users,
      tags,
      isMtbeRetrieveCollabCommentsEnabled,
    ],
  );

  return (
    <>
      <CKEditorContext
        context={DecoupledEditor.Context}
        contextWatchdog={DecoupledEditor.ContextWatchdog}
        isLayoutReady={isSideBarRendered}
        config={contextConfig}
        onReady={(context: Context) => contextReadyHandler(context)}
      >
        {/*renders pdf viewer or image viewer depending on the preview type*/}
        {children}
      </CKEditorContext>
      <div
        id="commentsSidebar"
        data-cy="commentsSidebar"
        className={`${styles['file-document__sidebar']}`}
      ></div>
    </>
  );
};

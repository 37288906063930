import { useToastProvider } from '@qualio/ui-components';
import { useMutation, useQueryClient } from 'react-query';
import { EditorStatus } from '../../DocumentOverview/Context';
import {
  APICancelledError,
  TrainingAsessmentAPI,
} from '../api/trainingAssessmentApi';
import { TrainingAssessment } from '../types/trainingAssessment';

export const useDeleteTrainingAssessment = (
  documentId: TrainingAssessment['document_id'],
  setTrainingAssessmentStatus: (status: EditorStatus) => void,
) => {
  const { showToast } = useToastProvider();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () =>
      TrainingAsessmentAPI.deleteTrainingAssessmentForDocument(documentId),

    onMutate: async () => {
      setTrainingAssessmentStatus(EditorStatus.SAVING);
      await queryClient.cancelQueries(['trainingAssessment', documentId]);
    },

    onError: (error: any) => {
      if (error instanceof APICancelledError) {
        return;
      }

      setTrainingAssessmentStatus(EditorStatus.ERROR);

      showToast({
        status: 'error',
        title: 'Error',
        description: 'Failed to delete training assessment.',
      });
    },
    onSuccess: () => {
      setTrainingAssessmentStatus(EditorStatus.SAVED);
    },
    onSettled: async () =>
      await queryClient.invalidateQueries(['trainingAssessment', documentId]),
  });
};

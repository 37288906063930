import { Filtering } from '@qualio/ui-components';
import { BulkActions, BulkActionsProps } from './BulkActions/BulkActions';

export type BulkActionsWrapperProps = Omit<BulkActionsProps, 'refetch'>;

export const BulkActionsWrapper = (props: BulkActionsWrapperProps) => {
  const filtering = Filtering.useFilteringMaybe();

  // since we can't call the refetch method on the query used by the DataProvider, we indirectly trigger the refetch by resetting the search term
  const handleRefetch = () => {
    filtering?.setSearchTerm(null, Date.now());
  };

  return <BulkActions {...props} refetch={handleRefetch} />;
};

import { CurrentUser } from '@qualio/ui-components';
import { medtechApiClient } from '.';
import { Tag } from './model/tag';

export const TagApi = {
  fetchTags: (currentUser: CurrentUser) => {
    return medtechApiClient
      .get<Tag[]>('tags', {
        params: {
          company_id: currentUser.companyId,
        },
      })
      .then(({ data }) => data);
  },
};

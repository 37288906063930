import { QNotFound, QualioQThemeProvider } from '@qualio/ui-components';

import './NotFound.module.css';

export const NotFound = () => {
  return (
    <QualioQThemeProvider>
      <QNotFound />
    </QualioQThemeProvider>
  );
};

import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { documentApi } from '../../../api/document';

import { QButton, useToastProvider } from '@qualio/ui-components';
import { BaseDocumentConvertModal } from './BaseDocumentConvertModal';
import { DocumentConvertMessageConfig } from './DocumentConvertMessageConfig';
import { DocumentConvertFormatModalProps } from './types';

export const ConvertQualioFormat = ({
  onClose,
  qualioDocument,
  onSuccess,
  onError,
}: DocumentConvertFormatModalProps) => {
  const { showToast } = useToastProvider();

  const [isConverting, setIsConverting] = useState(false);

  const documentConvertFormatMessageConfig = useMemo(() => {
    // Add logic to determine if file is a OneDrive file here
    return DocumentConvertMessageConfig.DocumentUpload;
  }, []);

  const { id: documentId } = qualioDocument;
  const getDocument = async () => documentApi.fetch(documentId.toString());

  const {
    error,
    isLoading,
    data: latestDocument,
  } = useQuery({
    queryFn: getDocument,
    queryKey: ['latestDocument'],
  });

  if (error) {
    showToast({
      title: 'Error',
      description: 'There was an error loading attachments for this document',
      status: 'error',
    });
  }

  /**
   * @description Converts the file document to a Qualio document
   **/
  const handleDocumentConversion = () => {
    if (latestDocument) {
      setIsConverting(true);
      documentApi
        .convertToQualioDocument(latestDocument)
        .then(() => {
          if (onSuccess) {
            onSuccess();
          }
          onClose(true);
        })
        .catch((e) => {
          console.error({
            message: 'Error changing document format',
            e,
          });

          showToast({
            title: 'Error',
            description: 'Could not change the format of the document.',
            status: 'error',
          });

          if (onError) {
            onError();
          }

          onClose(false);
        });
    }
  };

  const modalActions = [
    <QButton
      key="convert-qualio-format-cancel-button"
      data-cy="convert-qualio-format-cancel-button"
      variant="ghost"
      isDestructive
      onClick={() => onClose(false)}
    >
      Cancel
    </QButton>,
    <QButton
      key="convert-qualio-format-confirm-button"
      data-cy="convert-qualio-format-confirm-button"
      isLoading={isLoading || isConverting}
      isDisabled={isLoading || isConverting}
      onClick={() => handleDocumentConversion()}
    >
      Confirm
    </QButton>,
  ];

  return (
    <BaseDocumentConvertModal
      {...documentConvertFormatMessageConfig}
      modalActionsFragment={modalActions}
      onClose={onClose}
    />
  );
};

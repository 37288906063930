import {
  QDrawer,
  QTab,
  QTabList,
  QTabPanel,
  QTabPanels,
  QTabs,
} from '@qualio/ui-components';
import { useState } from 'react';
import { ActivityHistoryTab } from './Activity/ActivityHistoryTab';
import { CommentHistoryTab } from './Comments/CommentHistoryTab';

type DocumentHistoryPanelProps = {
  isOpen: boolean;
  onClose: any;
  companyId: number;
  documentId: string;
};

export enum HistoryTabs {
  ACTIVITY = 'Activity',
  COMMENTS = 'Comments',
}

export const DocumentHistoryPanel = ({
  isOpen,
  onClose,
  companyId,
  documentId,
}: DocumentHistoryPanelProps) => {
  const tabsInDisplayedOrder = [HistoryTabs.ACTIVITY, HistoryTabs.COMMENTS];

  const [currentTab, setCurrentTab] = useState<HistoryTabs>(
    HistoryTabs.ACTIVITY,
  );

  const handleTabClick = (tabIndex: number) => {
    setCurrentTab(tabsInDisplayedOrder[tabIndex]);
  };

  return (
    <QDrawer
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      title={<div data-cy="document-history-title">History</div>}
    >
      <QTabs
        variant="enclosed"
        onChange={handleTabClick}
        index={tabsInDisplayedOrder.indexOf(currentTab)}
      >
        <QTabList>
          <QTab data-cy="history-activity-tab">{HistoryTabs.ACTIVITY}</QTab>
          <QTab data-cy="history-comment-tab">{HistoryTabs.COMMENTS}</QTab>
        </QTabList>
        <QTabPanels>
          <QTabPanel p="0">
            {currentTab === HistoryTabs.ACTIVITY && (
              <ActivityHistoryTab
                companyId={companyId}
                documentId={documentId}
                isOpen={isOpen}
              />
            )}
          </QTabPanel>
          <QTabPanel p="0">
            {currentTab === HistoryTabs.COMMENTS && (
              <CommentHistoryTab
                companyId={companyId}
                documentId={documentId}
                isOpen={isOpen}
              />
            )}
          </QTabPanel>
        </QTabPanels>
      </QTabs>
    </QDrawer>
  );
};

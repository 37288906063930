import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { sleep } from '../util/TimerUtils';
import { ExportFormat } from '../views/components/Export/ExportDocumentListButton/types';
import {
  getOptionsWithStatusFiltersForActionableDocs,
  getStatusFilterParamsForDocs,
} from './document';
import { medtechApiClient } from './index';

export type StartTaskResponse = { task_id: string };

export type ExportTaskStatusResponse = {
  ready: boolean;
  successful: boolean;
  status: string;
  payload: string;
};

export type AttachmentsPayload = {
  attachment_count: number;
  download_url: string;
};

export type AttachmentsTaskStatusResponse = {
  ready: boolean;
  successful: boolean;
  status: string;
  payload?: AttachmentsPayload;
};

export type TaskStatusResponse = AttachmentsTaskStatusResponse &
  ExportTaskStatusResponse;

interface ExportTaskParams {
  query: string | null;
  tag_id: string[];
  export_format: ExportFormat;
  export: number;
  order_by?: string;
}

const getDocumentsExportTaskParams = (
  format: ExportFormat,
  searchQuery: string | null,
  tagIds: string[],
  orderBy: string | null | undefined,
  statusFilters?: string[] | null | undefined,
  current_major_version_only = false,
) => {
  const params: ExportTaskParams = {
    query: searchQuery,
    tag_id: tagIds,
    export_format: format,
    export: 1,
  };

  if (orderBy !== null) {
    params.order_by = orderBy;
  }

  if (statusFilters === null || statusFilters === undefined) {
    return {
      params,
      paramsSerializer: {
        indexes: null,
      },
    };
  }

  return {
    params: {
      ...params,
      ...getStatusFilterParamsForDocs(
        statusFilters,
        current_major_version_only,
      ),
    },
    paramsSerializer: {
      indexes: null,
    },
  };
};

const getActionableDocumentsExportTaskPayloadObject = (
  format: ExportFormat,
  searchQuery: string | null,
  tagIds: string[],
  orderBy: string | null | undefined,
  statusFilters: string[] | null | undefined,
) => {
  const params: ExportTaskParams = {
    query: searchQuery,
    tag_id: tagIds,
    export_format: format,
    export: 1,
  };

  if (orderBy !== null) {
    params.order_by = orderBy;
  }

  const options = {
    params,
    paramsSerializer: {
      indexes: null,
    },
  };

  if (statusFilters === null || statusFilters === undefined) {
    return options;
  }

  return getOptionsWithStatusFiltersForActionableDocs(statusFilters, options);
};

export const backendTaskApi = {
  async startAttachmentDownloadTask(
    documentId: string,
  ): Promise<StartTaskResponse> {
    return medtechApiClient
      .get(`/documents/${documentId}/attachments/export`)
      .then((res) => res.data);
  },

  async startPDFExportTask(
    documentId: string,
    coverPageEnabled: boolean,
    controlledCopy: boolean,
  ): Promise<StartTaskResponse> {
    const exportUrl =
      `/documents/${documentId}/export?` +
      `controlled_copy=${controlledCopy}` +
      `&include_cover_page=${coverPageEnabled}`;

    return medtechApiClient.get(exportUrl).then((res) => res.data);
  },

  async startUserDocumentsExportTask(
    currentUser: CurrentUser,
    format: ExportFormat,
    tagsFilter: string[],
    searchQuery: string | null,
    orderBy: string | null | undefined,
    statusFilters?: string[] | null | undefined,
  ): Promise<StartTaskResponse> {
    const current_major_version_only = true;
    const exportTaskUrl = `/v2/${currentUser.companyId}/users/${currentUser.userId}/documents`;
    const params = getDocumentsExportTaskParams(
      format,
      searchQuery,
      tagsFilter,
      orderBy,
      statusFilters,
      current_major_version_only,
    );
    return medtechApiClient.get(exportTaskUrl, params).then(({ data }) => data);
  },

  async startAllDocumentsExportTask(
    currentUser: CurrentUser,
    format: ExportFormat,
    tagsFilter: string[],
    searchQuery: string | null,
    orderBy: string | null | undefined,
    statusFilters?: string[] | null | undefined,
  ): Promise<StartTaskResponse> {
    const exportTaskUrl = `/v2/${currentUser.companyId}/documents`;
    const params = getDocumentsExportTaskParams(
      format,
      searchQuery,
      tagsFilter,
      orderBy,
      statusFilters,
    );
    return medtechApiClient.get(exportTaskUrl, params).then(({ data }) => data);
  },

  async startUserActionableDocumentsExportTask(
    currentUser: CurrentUser,
    format: ExportFormat,
    tagsFilter: string[],
    searchQuery: string | null,
    orderBy: string | null | undefined,
    statusFilters?: string[] | null | undefined,
  ): Promise<StartTaskResponse> {
    const exportTaskUrl = `/v2/${currentUser.companyId}/users/${currentUser.userId}/actionable_documents`;

    const options = getActionableDocumentsExportTaskPayloadObject(
      format,
      searchQuery,
      tagsFilter,
      orderBy,
      statusFilters,
    );

    return medtechApiClient.get(exportTaskUrl, options).then((res) => res.data);
  },

  async startUserTrainingDocumentsExportTask(
    currentUser: CurrentUser,
    format: ExportFormat,
    tagsFilter: string[],
    searchQuery: string | null,
    orderBy: string | null | undefined,
    statusFilters?: string[] | null | undefined,
  ): Promise<StartTaskResponse> {
    const exportTaskUrl = `v2/${currentUser.companyId}/users/${currentUser.userId}/training`;
    const options = getActionableDocumentsExportTaskPayloadObject(
      format,
      searchQuery,
      tagsFilter,
      orderBy,
      statusFilters,
    );

    return medtechApiClient.get(exportTaskUrl, options).then((res) => res.data);
  },

  async getTaskStatus(
    companyId: number,
    taskId: string,
  ): Promise<TaskStatusResponse> {
    return medtechApiClient
      .get(`/v2/${companyId}/tasks/${taskId}`)
      .then((res) => res.data);
  },

  async pollTaskUntilCompletion(
    companyId: number,
    taskId: string,
    pollIntervalMillis?: number,
  ): Promise<TaskStatusResponse> {
    let currentAttempt = 1;
    let isFinished = false;
    let taskStatus: TaskStatusResponse | undefined;
    while (!isFinished && currentAttempt <= 240) {
      taskStatus = await backendTaskApi.getTaskStatus(companyId, taskId);
      isFinished = taskStatus.ready;
      currentAttempt++;
      if (!isFinished) {
        await sleep(pollIntervalMillis ?? 2000);
      }
    }
    if (!taskStatus) {
      throw new Error(`Error determining task ${taskId} status`);
    }
    return taskStatus;
  },
};

import {
  QButton,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';
import { useContext, useState } from 'react';
import { documentApi } from '../../../../../../api/document';
import { DocumentStatus } from '../../../../../../api/model/document';
import { PeriodicReview } from '../../../../../../api/model/periodicReview';
import { ButtonGroupContext } from '../../../../Contexts/ButtonGroupContext';
import { CredentialConfirmationModal } from '../../../../CredentialConfirmationModal/index';
import { DocumentOverviewContext } from '../../../Context';

type ApproveButtonProps = {
  periodicReview?: PeriodicReview;
  refetchPeriodicReview: () => void;
};

export const ApproveButton = (props: ApproveButtonProps) => {
  const { periodicReview, refetchPeriodicReview } = props;
  const currentUser = useCurrentUser();
  const { qualioDocument, refetchDocument } = useContext(
    DocumentOverviewContext,
  );
  const { isDisabled, setDisabled } = useContext(ButtonGroupContext);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const { showToast } = useToastProvider();

  const openModal = () => {
    setDisabled(true);
    setIsConfirmationModalOpen(true);
  };

  const closeModal = () => {
    setDisabled(false);
    setIsConfirmationModalOpen(false);
  };

  const approveDocument = (
    email: string,
    password: string,
    comment: string,
  ) => {
    return documentApi
      .changeStatus(qualioDocument.id, {
        status: DocumentStatus.Approved,
        email,
        password,
        comment,
      })
      .then(() => {
        refetchDocument();
      });
  };

  const approvePeriodicReview = (
    email: string,
    password: string,
    comment: string,
  ) => {
    return documentApi
      .approvePeriodicReview(currentUser, qualioDocument.id, {
        email,
        password,
        comment,
      })
      .then(() => {
        refetchPeriodicReview();
        refetchDocument();
      });
  };

  const handleApproval = async (
    email: string,
    password: string,
    comment = '',
  ) => {
    try {
      if (periodicReview) {
        await approvePeriodicReview(email, password, comment);
      } else {
        await approveDocument(email, password, comment);
      }

      setDisabled(false);
      setIsConfirmationModalOpen(false);

      showToast({
        id: 'approve-document-toast',
        status: 'success',
        title: 'Success!',
        description: 'Approval action completed',
        replace: true,
      });
    } catch (error: unknown) {
      throw error;
    }
  };

  const handleApprovalError = () => {
    showToast({
      id: 'approve-document-toast',
      status: 'error',
      title: 'Error',
      description: 'Failed to approve document. Please try again.',
      replace: true,
    });
  };

  return (
    <>
      <QButton
        data-cy="document-approve-button"
        isDisabled={isDisabled}
        onClick={openModal}
      >
        Approve
      </QButton>
      <CredentialConfirmationModal
        isOpen={isConfirmationModalOpen}
        onCloseModal={closeModal}
        handleStatusChange={handleApproval}
        handleError={handleApprovalError}
        modalHeading="Approve document"
        modalText="Enter your digital signature to approve this document."
        buttonActionLabel="Approve"
      />
    </>
  );
};

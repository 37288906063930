import { useCallback, useContext, useMemo, useState } from 'react';

import {
  QButton,
  QLookup,
  QModalHeader,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';

import { documentApi } from '../../../../../../api/document';
import {
  DocumentCategory,
  GroupUser,
  QualioDocumentAuditGroups,
  QualioDocumentAuditGroupsPatch,
  QualioDocumentPatch,
} from '../../../../../../api/model/document';
import { Group } from '../../../../../../api/model/group';
import { Tag } from '../../../../../../api/model/tag';
import { MedtechUserV2 } from '../../../../../../api/user';

import { DocumentOverviewContext } from '../../../Context';
import { shouldRenderManageReviewersButton } from '../../../RenderLogic';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { groupUserToMedtechUser } from '../../../../../../util/UserUtils';
import { QLookupModalComposedHeader } from '../../../../QLookupModalComposedHeader';
import {
  canUserAccessAtLeastOnePrivateTag,
  filterUserOptionLookup,
  getDocumentTagIdsToGroupIdsMap,
  getUserLookupView,
} from '../../utils';

const mapToId = ({ id }: { id: number }) => id;

export type ManageReviewersButtonProps = {
  tags: Tag[];
  groups: Group[];
  users: MedtechUserV2[];
  currentReviewers: QualioDocumentAuditGroups['review_group'];
};

export const ManageReviewersButton = ({
  tags,
  groups,
  users,
  currentReviewers = [],
}: ManageReviewersButtonProps) => {
  const currentUser = useCurrentUser();
  const { showToast } = useToastProvider();
  const [isLookupModalOpen, setIsLookupModalOpen] = useState(false);
  const { qePricingAndPackaging2024 } = useFlags();

  const onClose = useCallback(() => {
    setIsLookupModalOpen(false);
  }, []);

  const commonToastProps = {
    id: 'manage-reviewers-property-toast',
    replace: true,
  };

  const onSuccess = () => {
    refetchDocument();
    showToast({
      ...commonToastProps,
      status: 'success',
      title: 'Reviewers assigned!',
      description: 'Successfully changed the Reviewers of the document.',
    });
    onClose();
  };

  const onError = () => {
    showToast({
      ...commonToastProps,
      status: 'error',
      title: 'Error',
      description: 'Failed to apply changes to Reviewers. Please try again.',
    });
  };

  const { qualioDocument, refetchDocument } = useContext(
    DocumentOverviewContext,
  );

  const canManageReviewers = useMemo(
    () => shouldRenderManageReviewersButton(qualioDocument, currentUser),
    [currentUser, qualioDocument],
  );

  const currentReviewersMap = useMemo(
    () =>
      currentReviewers.reduce(
        (acc: Map<GroupUser['id'], GroupUser>, Reviewer) => {
          return acc.set(Reviewer.id, Reviewer);
        },
        new Map(),
      ),
    [currentReviewers],
  );

  const documentTagIdsToGroupIdsMap = useMemo(
    () => getDocumentTagIdsToGroupIdsMap(qualioDocument, tags),
    [qualioDocument, tags],
  );

  const validReviewers = useMemo(
    () =>
      users.filter((user) => {
        const { full_name, permissions, id } = user;
        const permissionToReview = (permissions: string[]) => {
          if (!qePricingAndPackaging2024) {
            return permissions.includes('can_review_doc');
          }
          return (
            (permissions.includes('can_review_doc') &&
              qualioDocument.category === DocumentCategory.REGULAR) ||
            (permissions.includes('can_work_on_issue') &&
              qualioDocument.category === DocumentCategory.ISSUE_RELATED)
          );
        };
        const isValidDocumentReviewer =
          !!full_name &&
          (currentReviewersMap.has(id) ||
            ((documentTagIdsToGroupIdsMap.size === 0 ||
              canUserAccessAtLeastOnePrivateTag(
                user,
                Array.from(documentTagIdsToGroupIdsMap.values()),
              )) &&
              permissionToReview(permissions) &&
              id !== currentUser.userId));
        return isValidDocumentReviewer;
      }),
    [
      documentTagIdsToGroupIdsMap,
      users,
      currentUser.userId,
      qualioDocument.category,
      qePricingAndPackaging2024,
      currentReviewersMap,
    ],
  );

  // In case an approver has been deleted, we need to display it to be deselected
  const deletedReviewers: MedtechUserV2[] = useMemo(
    () =>
      currentReviewers
        .filter(
          (reviewer) => !validReviewers.find((vr) => vr.id === reviewer.id),
        )
        .map((deletedReviewer) => groupUserToMedtechUser(deletedReviewer)),
    [currentReviewers, validReviewers],
  );

  const reviewersPool = useMemo(
    () => [...validReviewers, ...deletedReviewers],
    [deletedReviewers, validReviewers],
  );

  const userDataView = useMemo(() => getUserLookupView(groups), [groups]);

  const filterOptionCallback = useCallback(
    (user: MedtechUserV2, searchTerm: string | undefined) => {
      if (!searchTerm) {
        return true;
      }
      return filterUserOptionLookup(searchTerm, user, groups);
    },
    [groups],
  );

  const isCurrentReviewer = useCallback(
    (user: Pick<MedtechUserV2, 'id'>) => currentReviewersMap.has(user.id),
    [currentReviewersMap],
  );

  const buildAuditGroupPatch = (reviewers: number[]): QualioDocumentPatch => {
    const auditGroupPatch: QualioDocumentAuditGroupsPatch = {
      review_group: reviewers,
      approver_group: (qualioDocument.audit_groups?.approver_group ?? []).map(
        mapToId,
      ),
    };
    return {
      audit_groups: auditGroupPatch,
    };
  };

  const applyReviewerChanges = async (selections: readonly MedtechUserV2[]) => {
    const selectedReviewerIds = selections.map(mapToId);
    const documentPatch: QualioDocumentPatch =
      buildAuditGroupPatch(selectedReviewerIds);
    try {
      await documentApi.update(documentPatch, qualioDocument.id, true);
      onSuccess();
    } catch (error) {
      onError();
    }
  };

  if (!canManageReviewers) {
    return null;
  }

  return (
    <>
      <QButton
        data-cy="document-manage-reviewers-button"
        variant="ghost"
        onClick={() => setIsLookupModalOpen(true)}
      >
        Manage
      </QButton>
      {isLookupModalOpen && (
        <QLookup.DataProvider.Fixed
          data={reviewersPool}
          filterOption={filterOptionCallback}
        >
          <QLookup.MultiSelect<MedtechUserV2>
            isOpen={isLookupModalOpen}
            action="Apply changes"
            searchPlaceholder="Filter by name, email or group name..."
            accessors={{
              id: 'id',
            }}
            isItemPreSelected={isCurrentReviewer}
            onSelect={applyReviewerChanges}
            onCancel={onClose}
            view={userDataView}
            defaultSortBy={{ id: 'full_name' }}
          >
            <QModalHeader>
              <QLookupModalComposedHeader
                title="Manage reviewers"
                subtitle="Select users to provide feedback and suggestions on the
                  document."
              />
            </QModalHeader>
          </QLookup.MultiSelect>
        </QLookup.DataProvider.Fixed>
      )}
    </>
  );
};

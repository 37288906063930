import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles';
import { Editor, Plugin } from '@ckeditor/ckeditor5-core';
import { ExportPdfConfig } from '@ckeditor/ckeditor5-export-pdf/src/exportpdf';
import { Mention, MentionConfig } from '@ckeditor/ckeditor5-mention';
import { BaseSuggestionThreadView } from '@ckeditor/ckeditor5-track-changes';
import { medtechApiClient, MEDTECH_UPLOAD_URL } from '../api';
import {
  DocumentStatus,
  PageOrientation,
  QualioDocument,
} from '../api/model/document';
import { Tag } from '../api/model/tag';
import { tokenApi } from '../api/token';
import { MedtechUserV2 } from '../api/user';
import { CKEditorInstance } from '../types/CKEditorInstance';
import { isEditor, isOwner, isReviewer } from '../util/CurrentUser';
import { formatDate } from '../util/DateUtils';
import { qualioCommentThreadBuilder } from '../views/ckeditor/QualioCommentThreadView';
import QualioCommentView from '../views/ckeditor/QualioCommentView';
import {
  canUserAccessAtLeastOnePrivateTag,
  getDocumentTagIdsToGroupIdsMap,
} from '../views/components/DocumentOverview/DocumentProperties/utils';
import { CKEditorConfig } from './CKEditorConfig';
import {
  AI_COMMANDS,
  IMAGE_TOOLBAR_ITEMS,
  TABLE_TOOLBAR_ITEMS,
} from './ToolbarItems';

export function MentionLinks(editor: Editor) {
  // The upcast converter will convert a view
  //
  //		<a href="..." class="mention" data-mention="...">...</a>
  //
  // element to the model "mention" text attribute.
  editor.conversion.for('upcast').elementToAttribute({
    view: {
      name: 'span',
      classes: 'mention',
      attributes: {
        'data-mention': true,
        'data-user-id': true,
      },
    },
    model: {
      key: 'mention',
      value: (viewItem: any) => {
        return editor.plugins.get('Mention').toMentionAttribute(viewItem, {
          userId: viewItem.getAttribute('data-user-id'),
          name: viewItem.getAttribute('data-mention'),
        });
      },
    },
    converterPriority: 'high',
  });

  // Downcast the model "mention" text attribute to a view
  //
  //		<a href="..." class="mention" data-mention="...">...</a>
  //
  // element.
  editor.conversion.for('downcast').attributeToElement({
    model: 'mention',
    view: (modelAttributeValue: any, { writer }: any) => {
      // Do not convert empty attributes (lack of value means no mention).
      if (!modelAttributeValue) {
        return;
      }

      return writer.createAttributeElement(
        'span',
        {
          class: 'mention mention__user',
          'data-mention': modelAttributeValue.name,
          'data-user-id': modelAttributeValue.userId,
        },
        {
          // Make mention attribute to be wrapped by other attribute elements.
          priority: 20,
          // Prevent merging mentions together.
          id: modelAttributeValue.uid,
        },
      );
    },
    converterPriority: 'high',
  });
}

/**
 * https://docs.aws.amazon.com/bedrock/latest/userguide/model-ids.html
 */
enum AWSBedrockModelId {
  INSTANT = 'anthropic.claude-instant-v1',
  V2_1 = 'anthropic.claude-v2:1',
  V3_SONNET = 'anthropic.claude-3-sonnet-20240229-v1:0',
  V3_HAIKU = 'anthropic.claude-3-haiku-20240307-v1:0',
}

export const getUsersForComments = (users: MedtechUserV2[]) => {
  const feedArray = users.map((user) => ({
    id: '@' + user.full_name,
    userId: user.id,
    name: user.full_name,
  }));

  return (queryText: string) => {
    const isItemMatching = (item: (typeof feedArray)[number]) => {
      const searchString = queryText.toLowerCase();
      return item.name.toLowerCase().includes(searchString);
    };

    return new Promise((resolve) => {
      const itemsToDisplay = feedArray
        // Filter out the full list of all items to only those matching the query text.
        .filter(isItemMatching)
        // Return 10 items max - needed for generic queries when the list may contain hundreds of elements.
        .slice(0, 10)
        .sort((a, b) => a.name.localeCompare(b.name));

      resolve(itemsToDisplay);
    });
  };
};

export const getMentionableUsers = (
  qualioDocument: QualioDocument,
  users: MedtechUserV2[],
  tags: Tag[],
) => {
  const documentTagIdsToGroupIdsMap = getDocumentTagIdsToGroupIdsMap(
    qualioDocument,
    tags,
  );

  return users.filter((user) => {
    const userIdObj = { userId: user.id };
    const isReviewerOrQualityUserForDocumentForReview =
      qualioDocument.status_id === DocumentStatus.For_Review &&
      (isReviewer(userIdObj, qualioDocument) ||
        user.usergroups.includes('quality'));
    const isDocEditor = isEditor(userIdObj, qualioDocument);
    const isDocOwner = isOwner(userIdObj, qualioDocument);

    const canAccessComments =
      isReviewerOrQualityUserForDocumentForReview || isDocEditor || isDocOwner;

    // If private tags exist on document, then check if users can access any of the private tags
    if (documentTagIdsToGroupIdsMap.size > 0) {
      const canAccessDocumentTags = canUserAccessAtLeastOnePrivateTag(
        user,
        Array.from(documentTagIdsToGroupIdsMap.values()),
      );

      return canAccessComments && canAccessDocumentTags;
    }

    return canAccessComments;
  });
};

export class DocumentEditorConfigFactory {
  editorConfig: CKEditorConfig;

  constructor() {
    this.editorConfig = {
      ui: {
        viewportOffset: {
          top: 180,
        },
      },
      fontFamily: {
        options: ['default'],
      },
      fontSize: {
        options: [
          'small',
          {
            title: 'Normal',
            model: '14px',
            upcastAlso: [],
            view: '',
          },
          {
            title: 'Large',
            model: '19.6px',
            upcastAlso: [],
            view: '',
          },
        ],
      },
      simpleFileUpload: {
        url: MEDTECH_UPLOAD_URL,
      },
      simpleUpload: {
        uploadUrl: MEDTECH_UPLOAD_URL,
        withCredentials: true,
      },
      heading: {
        options: [
          {
            model: 'paragraph',
            title: 'Paragraph',
            class: 'ck-heading_p',
          },
          {
            model: 'heading1',
            view: 'h1',
            title: 'Heading 1',
            class: 'ck-heading_h1',
          },
          {
            model: 'heading2',
            view: 'h2',
            title: 'Heading 2',
            class: 'ck-heading_h2',
          },
          {
            model: 'heading3',
            view: 'h3',
            title: 'Heading 3',
            class: 'ck-heading_h3',
          },
          {
            model: 'heading4',
            view: 'h4',
            title: 'Heading 4',
            class: 'ck-heading_h4',
          },
        ],
      },
      list: {
        properties: {
          styles: true,
          startIndex: false,
          reversed: false,
        },
      },
      link: {
        addTargetToExternalLinks: true,
        defaultProtocol: 'https://',
      },
      table: {
        contentToolbar: TABLE_TOOLBAR_ITEMS,
      },
      image: {
        toolbar: IMAGE_TOOLBAR_ITEMS,
      },
      licenseKey: process.env.REACT_APP_CKE_PREMIUM_FEATURES_LICENSE_KEY,
      indentBlock: {
        offset: 2,
        unit: 'em',
      },
      orientation: PageOrientation.Portrait,
      locale: {
        dateTimeFormat: formatDate,
      },
    };
  }

  getConfig = () => {
    return this.editorConfig;
  };

  withAI = (companyId: number) => {
    this.editorConfig.ai = {
      aws: {
        apiUrl: `${process.env.REACT_APP_AI_API_URL}/ckeditor-proxy`,
        requestParameters: {
          model: AWSBedrockModelId.V2_1,
          stream: false,
        },
        requestHeaders: async () => {
          const jwt = (
            await medtechApiClient.post<string>(
              `${companyId}/auth/token/service/qualio-ai`,
            )
          ).data;
          return {
            Authorization: jwt,
          };
        },
      },
      useTheme: false,
    };
    this.editorConfig.ai.aiAssistant = {
      commands: AI_COMMANDS,
    };

    return this;
  };

  withCompanyId = (companyId: number) => {
    this.editorConfig.companyId = companyId;
    return this;
  };

  withPlugins = (plugins: (typeof Plugin)[]) => {
    this.editorConfig.plugins = plugins;
    return this;
  };

  withSectionId = (sectionId: number) => {
    this.editorConfig.sectionId = sectionId;
    return this;
  };

  withDocumentId = (documentId: number) => {
    this.editorConfig.documentId = documentId;
    return this;
  };

  withSectionPosition = (position: number) => {
    this.editorConfig.position = position;
    return this;
  };

  withEntityType = (entityType: string) => {
    this.editorConfig.entityType = entityType;
    return this;
  };

  withAutosave = (onAutoSave: (editor: CKEditorInstance) => Promise<void>) => {
    this.editorConfig.autosave = {
      save: onAutoSave,
      waitingTime: 1000,
    };
    return this;
  };

  withMention = (mentionConfig: MentionConfig) => {
    this.editorConfig.mention = mentionConfig;
    return this;
  };

  withCollaboration = (channelIDSuffix: string) => {
    this.editorConfig.collaboration = {
      channelId: `${process.env.REACT_APP_ENV}${
        process.env.REACT_APP_CHANNEL_ID_SUFFIX ?? ''
      }-${channelIDSuffix}`,
    };
    return this;
  };

  withPDFExport = (exportPdfConfig: ExportPdfConfig) => {
    this.editorConfig.exportPdf = {
      ...exportPdfConfig,
      converterUrl: process.env.REACT_APP_CKE_PDF_SERVICE_URL,
      tokenUrl: () => tokenApi.fetch(),
    };
    return this;
  };

  withComments = (
    commentsPermission: number,
    qualioDocument: QualioDocument,
    users: MedtechUserV2[] = [],
    tags: Tag[] = [],
    isMtbeRetrieveCollabCommentsEnabled = false,
  ) => {
    const commentThreadView = qualioCommentThreadBuilder(
      commentsPermission,
      qualioDocument,
    );
    const mentionableUsers = getMentionableUsers(qualioDocument, users, tags);
    this.editorConfig.comments = {
      // https://ckeditor.com/docs/ckeditor5/latest/api/module_comments_comments-CommentsConfig.html
      editorConfig: {
        extraPlugins: [Bold, Italic, Mention, MentionLinks],
        mention: isMtbeRetrieveCollabCommentsEnabled
          ? {
              feeds: [
                {
                  marker: '@',
                  feed: getUsersForComments(mentionableUsers) as any,
                  itemRenderer: (item: any) => {
                    const itemElement = document.createElement('button');
                    itemElement.classList.add('mention__item_user_btn');
                    const fullNameElement = document.createElement('span');

                    fullNameElement.classList.add('mention__item_user');
                    fullNameElement.classList.add('mention__item');
                    fullNameElement.textContent = item.name;
                    itemElement.appendChild(fullNameElement);

                    return itemElement;
                  },
                },
              ],
            }
          : undefined,
      },
      CommentView: QualioCommentView as any,
      CommentThreadView: commentThreadView as any,
    };
    return this;
  };

  withTrackChanges = (suggestionThreadView: BaseSuggestionThreadView) => {
    this.editorConfig.trackChanges = {
      SuggestionThreadView: suggestionThreadView as any,
      disableComments: false,
      trackFormatChanges: 'default',
      mergeNestedSuggestions: true,
    };
    return this;
  };

  withToolbarItems = (toolbarItems: string[]) => {
    this.editorConfig.toolbar = toolbarItems;
    return this;
  };

  withLists = (customStartIndexes: boolean) => {
    this.editorConfig.list = {
      properties: {
        styles: true,
        startIndex: customStartIndexes,
        reversed: customStartIndexes,
      },
    };

    return this;
  };

  withMediaEmbed = () => {
    this.editorConfig.mediaEmbed = {
      previewsInData: true,
    };

    return this;
  };
}

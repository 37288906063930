import { QMenuButton, QMenuItem } from '@qualio/ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext } from 'react';
import { Tag } from '../../../../../api/model/tag';
import { MedtechUserV2 } from '../../../../../api/user';
import { EditorMode } from '../../../../../util/EditModeUtils';
import { ButtonGroupContext } from '../../../Contexts/ButtonGroupContext';
import { DocumentOverviewContext } from '../../Context';
import { ActivityReportAction } from './ActivityReportAction';
import { ApplyTemplateChangesButton } from './ApplyTemplateChangesButton/index';
import { ChangeFormatButton } from './ChangeFormatButton';
import { ChangeOwnerButton } from './ChangeOwnerButton';
import { CommentHistoryAction } from './CommentHistoryAction';
import { DeleteDocumentAction } from './DeleteDocumentAction';
import { HistoryAction } from './HistoryAction';
import './MoreActions.module.css';
import { ResetEditorSessionButton } from './ResetEditorSessionButton';
import { RetireAction } from './RetireAction';

const renderQMenuItem = (
  onClick: () => void,
  key: string,
  testId: string,
  label: string,
) => {
  return (
    <QMenuItem key={key} data-cy={testId} onClick={onClick} fontSize="sm">
      {label}
    </QMenuItem>
  );
};

const renderDeleteItem = (onClick: () => void) => {
  return renderQMenuItem(
    onClick,
    'delete-doc',
    'doc-overview-kebab-menu-delete',
    'Delete',
  );
};

const renderCommentHistoryItem = (onClick: () => void) => {
  return renderQMenuItem(
    onClick,
    'comment-history-doc',
    'doc-overview-kebab-menu-comment-history',
    'Comment history',
  );
};

const renderHistoryItem = (onClick: () => void) => {
  return renderQMenuItem(
    onClick,
    'history-doc',
    'doc-overview-kebab-menu-history',
    'History',
  );
};

const renderRetireDocItem = (onClick: () => void) => {
  return renderQMenuItem(
    onClick,
    'retire-doc',
    'doc-overview-kebab-menu-retire',
    'Retire',
  );
};

const renderChangeOwnerButton = (onClick: () => void) => {
  return renderQMenuItem(
    onClick,
    'change-owner',
    'doc-overview-kebab-menu-change-owner',
    'Change owner',
  );
};

const renderResetEditorSessionButton = (onClick: () => void) => {
  return renderQMenuItem(
    onClick,
    'reset-editor',
    'doc-overview-kebab-menu-reset-editor',
    'Reset editor session',
  );
};

const renderChangeFormatButton = (onClick: () => void) => {
  return renderQMenuItem(
    onClick,
    'change-format',
    'doc-overview-kebab-menu-change-format',
    'Change format',
  );
};

const renderApplyTemplateChangesButton = (onClick: () => void) => {
  return renderQMenuItem(
    onClick,
    'apply-template-changes',
    'doc-overview-kebab-menu-apply-template-changes',
    'Apply template changes',
  );
};

const renderActivityReportAction = (onClick: () => void, label: string) => {
  return renderQMenuItem(
    onClick,
    'activity-report',
    'doc-overview-kebab-menu-activity-report',
    label,
  );
};

type MoreActionsButtonProps = {
  users: MedtechUserV2[];
  tags: Tag[];
};

export const MoreActionsButton = ({ users, tags }: MoreActionsButtonProps) => {
  const { currentEditorMode } = useContext(DocumentOverviewContext);
  const { isDisabled } = useContext(ButtonGroupContext);

  const { documentActivityLog } = useFlags();

  if (currentEditorMode !== EditorMode.VIEW) {
    return null;
  }
  return (
    <div className="MoreActions__kebab-menu-wrapper">
      <QMenuButton
        buttonLabel="Options"
        data-cy="doc-overview-kebab-menu"
        iconName="MoreVertical"
        itemSize="sm"
        variant="icon"
        isDisabled={isDisabled}
      >
        <ChangeOwnerButton
          renderClickableItem={renderChangeOwnerButton}
          users={users}
          tags={tags}
        />
        <ChangeFormatButton renderClickableItem={renderChangeFormatButton} />
        <ActivityReportAction
          renderClickableItem={renderActivityReportAction}
        />

        {documentActivityLog ? (
          <HistoryAction renderClickableItem={renderHistoryItem} />
        ) : (
          <CommentHistoryAction
            renderClickableItem={renderCommentHistoryItem}
          />
        )}

        <ApplyTemplateChangesButton
          renderClickableItem={renderApplyTemplateChangesButton}
        />
        <ResetEditorSessionButton
          renderClickableItem={renderResetEditorSessionButton}
        />
        <RetireAction renderClickableItem={renderRetireDocItem} />
        <DeleteDocumentAction renderClickableItem={renderDeleteItem} />
      </QMenuButton>
    </div>
  );
};

import {
  QCloseButton,
  QModal,
  QModalBody,
  QModalHeader,
  QText,
} from '@qualio/ui-components';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { FileDocumentPreviewer } from '../FileDocumentPreviewer/FileDocumentPreviewer';
import styles from './InlineAttachmentPreviewModal.module.css';

export const InlineAttachmentPreviewModal: React.FC<{
  currentUser: CurrentUser;
  attachmentId: string;
  onClose: () => void;
}> = ({ currentUser, attachmentId, onClose }) => {
  return (
    <div
      id="inline-attachment-preview-modal"
      className={`${styles['document-editor__file_preview']}`}
    >
      <QModal onClose={onClose} isOpen={true} size={'4xl'}>
        <QModalHeader>
          <QText>Preview Attachment</QText>
          <QCloseButton onClick={onClose} />
        </QModalHeader>
        <QModalBody>
          <FileDocumentPreviewer
            currentUser={currentUser}
            attachmentId={attachmentId}
            showDownloadButton={true}
            isInlineAttachment={true}
          ></FileDocumentPreviewer>
        </QModalBody>
      </QModal>
    </div>
  );
};

import { QStack, QTag, QText } from '@qualio/ui-components';
import { useContext, useMemo } from 'react';
import { Group } from '../../../../../api/model/group';
import { Tag } from '../../../../../api/model/tag';
import { DocumentOverviewContext } from '../../Context';
import { ManageTagsButton } from './ManageTagsButton';

export type ManageTagsProps = {
  tags: Tag[];
  groups: Group[];
};
export const ManageTags = ({ tags, groups }: ManageTagsProps) => {
  const { qualioDocument } = useContext(DocumentOverviewContext);
  const documentTagsElements = useMemo(() => {
    return tags
      .filter(({ id }) => qualioDocument.tag_ids.includes(id))
      .map((tag) => (
        <QTag
          data-cy={`document-tag-${tag.name}`}
          key={tag.id}
          rightIcon={tag.group_ids.length ? 'Lock' : undefined}
        >
          {tag.name}
        </QTag>
      ));
  }, [tags, qualioDocument]);

  return (
    <>
      <QStack isInline justifyContent="space-between" alignItems="center">
        <QText fontWeight="semibold" fontSize="md">
          Tags
        </QText>
        <ManageTagsButton tags={tags} groups={groups} />
      </QStack>
      {documentTagsElements.length > 0 && (
        <QStack
          data-cy="document-tags"
          isInline
          spacing="2"
          flexWrap="wrap"
          mt="3"
          gap="8px"
          sx={{
            span: {
              marginInlineStart: '0 !important',
            },
          }}
        >
          {documentTagsElements}
        </QStack>
      )}
      {!documentTagsElements.length && <QText mt="3">None</QText>}
    </>
  );
};

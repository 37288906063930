import { ActivityLogEntityType } from '../api/model/activityLog';
import { getDocumentEditorGlobalWindowObject } from './AppUtils';

export const initialiseGlobalEditedSections = (): void => {
  getDocumentEditorGlobalWindowObject().activityLogTracking = {
    editedDocumentSections: new Set<number>(),
    editedChangeControlSections: new Set<number>(),
  };
};

export const addSectionToEditedSectionsList = (
  editedSectionId: number,
  entityType: ActivityLogEntityType,
): boolean => {
  let editedSections;
  switch (entityType) {
    case 'change_control':
      editedSections =
        getDocumentEditorGlobalWindowObject().activityLogTracking
          .editedChangeControlSections;
      break;
    case 'document':
    default:
      editedSections =
        getDocumentEditorGlobalWindowObject().activityLogTracking
          .editedDocumentSections;
      break;
  }

  if (!editedSections.has(editedSectionId)) {
    editedSections.add(editedSectionId);
    return true;
  }
  return false;
};

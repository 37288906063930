import { CurrentUser, QButton } from '@qualio/ui-components';
import { QualioDocument } from '../../../../../../api/model/document';
import { WorkspaceTabs } from '../../../types';

export type BulkActionButtonProps = {
  label?: string;
  requiredPermissions: readonly string[];
  isActionAllowed: (
    user: CurrentUser,
    statuses: QualioDocument['status_id'][],
    currentTab: WorkspaceTabs,
    requiredPermissions: readonly string[],
    selectedDocuments: QualioDocument[],
  ) => boolean;
  currentUser: CurrentUser;
  currentTab: WorkspaceTabs;
  statuses: QualioDocument['status_id'][];
  selectedDocuments: QualioDocument[];
  onClick: () => void;
  isLoading?: boolean;
};

export const BulkActionButton = ({
  isActionAllowed,
  label,
  currentUser,
  currentTab,
  statuses,
  requiredPermissions,
  selectedDocuments,
  onClick,
  isLoading,
}: BulkActionButtonProps) => {
  if (
    !isActionAllowed(
      currentUser,
      statuses,
      currentTab,
      requiredPermissions,
      selectedDocuments,
    )
  ) {
    return null;
  }
  return (
    <QButton variant="outline" onClick={onClick} isLoading={isLoading}>
      {label}
    </QButton>
  );
};

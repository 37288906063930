import { deprecated, QText } from '@qualio/ui-components';
import { useCallback, useState } from 'react';
import { MigrationFile } from '../../../api/model/migration';
import { removeFileExtension } from '../../../util/FileUploadUtils';
import { DeleteEntityModal } from '../DeleteEntityModal/index';
import styles from './BulkFileUpload.module.css';
import { MigrationFileWithOverrides, TemplateProperties } from './types';
import { UploadedFileRowActions } from './UploadedFileRowActions';

export interface UploadedFilesListProps {
  uploadedFiles: MigrationFile[];
  selectedTemplate: TemplateProperties | undefined;
  migrationOngoingOrCompleted: boolean;
  changeHandler: (files: MigrationFile[]) => void;
  removeHandler: (fileToRemove: MigrationFile) => Promise<void>;
  editHandler: (file: MigrationFile) => void;
}

const getApprovers = (data: MigrationFileWithOverrides) => {
  const approvers = data.overrideOptions?.approvers ?? data.approvers;
  return approvers.map((a) => a.fullName).join(', ');
};

export const UploadedFilesList = ({
  uploadedFiles,
  selectedTemplate,
  migrationOngoingOrCompleted,
  changeHandler,
  removeHandler,
  editHandler,
}: UploadedFilesListProps) => {
  const [fileToRemove, setFileToRemove] = useState<MigrationFile>();
  const [isDeletingFile, setIsDeletingFile] = useState(false);

  const getTemplateCode = useCallback(
    (data: MigrationFile) => {
      if (selectedTemplate) {
        return selectedTemplate.prefix + data.ordinal;
      }
      return '';
    },
    [selectedTemplate],
  );

  const deleteConfirmHandler = async () => {
    if (fileToRemove) {
      setIsDeletingFile(true);
      await removeHandler(fileToRemove);
    }
    setFileToRemove(undefined);
    setIsDeletingFile(false);
  };

  const deleteActionHandler = (fileToRemove: MigrationFile) => {
    setFileToRemove(fileToRemove);
  };

  const orderChangeHandler = (file: MigrationFile, direction: string) => {
    const newOrderFiles = [...uploadedFiles];
    const fileIndex = newOrderFiles.findIndex(
      (f: MigrationFile) => f.id === file.id,
    );
    const nextIndex = direction === 'up' ? fileIndex - 1 : fileIndex + 1;
    const selectedFile = newOrderFiles[fileIndex];
    const nextFile = newOrderFiles[nextIndex];
    const selectedFileOrdinal = selectedFile.ordinal;
    const nextFileOrdinal = nextFile.ordinal;
    selectedFile.ordinal = nextFileOrdinal;
    nextFile.ordinal = selectedFileOrdinal;
    newOrderFiles[fileIndex] = nextFile;
    newOrderFiles[nextIndex] = selectedFile;
    changeHandler(newOrderFiles);
  };

  const uploadedFilesWithActions = uploadedFiles.map((file, idx) => ({
    ...file,
    actions: (
      <UploadedFileRowActions
        uploadedFile={file}
        isLast={idx === uploadedFiles.length - 1}
        isFirst={idx === 0}
        handleOrderChange={orderChangeHandler}
        removeHandler={deleteActionHandler}
        editHandler={editHandler}
        disableActions={
          fileToRemove !== undefined || migrationOngoingOrCompleted
        }
      />
    ),
  }));

  return (
    <>
      <deprecated.QDataTable
        data={uploadedFilesWithActions as any}
        hasPagination={false}
        columns={[
          {
            Header: 'ID',
            accessor: getTemplateCode,
            disableSortBy: true,
            width: 'auto',
          },
          {
            Header: 'Title',
            accessor: (file: MigrationFileWithOverrides) =>
              removeFileExtension(file.filename).trim() || 'Untitled file',
            disableSortBy: true,
            width: 'auto',
          },
          {
            Header: 'Owner',
            accessor: (file: MigrationFileWithOverrides) =>
              file.overrideOptions?.owner?.fullName ?? file.owner.fullName,
            disableSortBy: true,
            width: 'auto',
          },
          {
            Header: 'Approver',
            accessor: getApprovers,
            disableSortBy: true,
            width: 'auto',
          },
          {
            Header: '',
            accessor: 'actions',
            disableSortBy: true,
            width: '10%',
          },
        ]}
      />
      {uploadedFiles.length === 0 && (
        <QText fontSize="sm" className={styles['empty-uploaded-files-list']}>
          No files added
        </QText>
      )}
      {!!fileToRemove && (
        <DeleteEntityModal
          isOpen={true}
          isDeletingEntity={isDeletingFile}
          deleteHandler={deleteConfirmHandler}
          onCloseModal={() => {
            setIsDeletingFile(false);
            setFileToRemove(undefined);
          }}
          modalText={
            <p>
              Are you certain you want to permanently remove the file{' '}
              <b>{fileToRemove.filename}</b>? Once deleted, it cannot be
              recovered.
            </p>
          }
        />
      )}
    </>
  );
};

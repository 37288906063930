import { useContext, useState } from 'react';

import {
  QButton,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';

import { documentApi } from '../../../../../api/document';
import { hasReviewed } from '../../../../../util/CurrentUser';
import { ButtonGroupContext } from '../../../Contexts/ButtonGroupContext';
import { DocumentOverviewContext } from '../../Context';
import { shouldRenderMarkAsReviewedButton } from '../../RenderLogic';

export const MarkAsReviewedButton = () => {
  const currentUser = useCurrentUser();
  const { qualioDocument, refetchDocument } = useContext(
    DocumentOverviewContext,
  );
  const { isDisabled, setDisabled } = useContext(ButtonGroupContext);
  const { showToast } = useToastProvider();
  const [isReviewing, setIsReviewing] = useState(false);

  const commonToastProps = {
    id: 'mark-as-reviewed-toast',
    replace: true,
  };

  const handleMarkAsReviewed = () => {
    setDisabled(true, setIsReviewing);
    documentApi
      .review(qualioDocument.id)
      .then(() => {
        refetchDocument();
        setDisabled(false, setIsReviewing);
        showToast({
          ...commonToastProps,
          description: 'Your review has been submitted.',
          status: 'success',
          title: 'Success!',
        });
      })
      .catch((e) => {
        setDisabled(false, setIsReviewing);
        const failureMessage =
          e.response?.data?.message ||
          'Failed to mark as reviewed. Please try again.';
        showToast({
          ...commonToastProps,
          status: 'error',
          title: 'Error',
          description: failureMessage,
        });
        setIsReviewing(false);
      });
  };

  if (!shouldRenderMarkAsReviewedButton(qualioDocument, currentUser)) {
    return null;
  }

  return (
    <QButton
      isLoading={isReviewing}
      variant="solid"
      onClick={handleMarkAsReviewed}
      data-cy="doc-overview-mark-as-reviewed-button"
      isDisabled={
        hasReviewed(currentUser, qualioDocument) || isReviewing || isDisabled
      }
    >
      Mark as reviewed
    </QButton>
  );
};

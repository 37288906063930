import { QualioAttachmentPreviewType } from '../api/document';

export const printFile = async (
  src: string,
  previewType: QualioAttachmentPreviewType | undefined,
) => {
  let iframeSrc = '';

  if (previewType === 'document') {
    const iframeContent = await fetch(src, {
      credentials: 'include',
    }).then((res) => res.blob());
    iframeSrc = window.URL.createObjectURL(iframeContent);
  }
  const iframe = document.createElement('iframe');
  iframe.addEventListener('load', () => {
    if (previewType === 'image') {
      const image = document.createElement('img');
      image.src = src;
      image.style.maxWidth = '100%';
      const body = iframe?.contentDocument?.body;
      body?.appendChild(image);
      image.addEventListener('load', function () {
        iframe?.contentWindow?.print();
      });
    } else {
      iframe?.contentWindow?.print();
    }
  });
  iframe?.contentWindow?.addEventListener('afterprint', function () {
    iframe?.parentNode?.removeChild(iframe);
  });
  iframe.src = iframeSrc;
  iframe.style.width = '100%';
  iframe.style.height = '100%';
  iframe.style.display = 'none';
  const inlineModal = document.getElementById(
    'inline-attachment-preview-modal',
  );
  inlineModal?.appendChild(iframe);

  return iframe;
};

import {
  Filtering,
  Pagination,
  QBox,
  QMultiSelect,
  QSelectItem,
  QSelectPlaceholder,
  QText,
} from '@qualio/ui-components';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './DocumentLibrary.module.css';

import { Tag } from '../../../api/model/tag';

import { QualioDocument } from '../../../api/model/document';
import { TagLabel } from '../TagLabel/TagLabel';

type TagsFilterProps = {
  isLoadingTags: boolean;
  fetchedTags: Tag[];
  setSelectedDocumentsMap?: (selected: Map<number, QualioDocument>) => void;
};

const getTagsItems = (fetchedTags: Tag[]) => {
  return fetchedTags.map((tag) => ({
    label: <TagLabel tag={tag} />,
    value: String(tag.id),
  }));
};

type QSelectTagItem = QSelectItem<JSX.Element, string>;

export const TagsFilter = ({
  fetchedTags,
  isLoadingTags,
  setSelectedDocumentsMap,
}: TagsFilterProps) => {
  const [searchParams] = useSearchParams();
  const filtering = Filtering.useFiltering();
  const pagination = Pagination.usePaginationMaybe();
  const tagItems: QSelectTagItem[] = getTagsItems(fetchedTags);
  const tagsParams = searchParams.get('filteringTag');
  const selectedTags = tagsParams !== null ? tagsParams.split(',') : [];

  useEffect(() => {
    pagination?.setPageIndex(0);
    // Can't have `paginaton` as a dependency or else pagination will not work
    // eslint-disable-next-line
  }, [tagsParams]);

  const onTagFilterChange = (
    tagItems: readonly QSelectItem<JSX.Element, string>[],
  ) => {
    const tagIds = tagItems.map((tagItem) => tagItem.value);
    filtering.setFilters({
      filteringTag: tagIds.join(','),
    });
    // reset selection in workspace
    if (setSelectedDocumentsMap) {
      setSelectedDocumentsMap(new Map());
    }
  };

  const filterTagsBySearchTerm = (
    option: QSelectTagItem,
    searchTerm: string,
  ) => {
    if (!searchTerm) {
      return true;
    }

    return option.label.props.tag.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
  };

  return (
    <QBox className={`${styles['document-library__tag-multiselect']}`}>
      <QMultiSelect
        isLoading={isLoadingTags}
        size="sm"
        options={tagItems}
        onChange={onTagFilterChange}
        value={selectedTags}
        data-cy={'library-tags'}
        filterOption={({ data: option }, searchTerm) =>
          filterTagsBySearchTerm(option, searchTerm)
        }
      >
        <QSelectPlaceholder>
          <QText fontSize="sm" data-cy="library-tag-filter">
            Filter by tags...
          </QText>
        </QSelectPlaceholder>
      </QMultiSelect>
    </QBox>
  );
};

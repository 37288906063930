import {
  ActivityStatus,
  CurrentUserContextType,
  QActivitySequence,
  QStack,
  QText,
} from '@qualio/ui-components';
import { Parser } from 'html-to-react';
import { ChangeControlComment } from '../../../../../api/changeControl';
import { HistoricalCommentThread } from './HistoricalCommentThreadList';

interface HistoricalCommentThreadProps {
  thread: HistoricalCommentThread;
  currentUser: CurrentUserContextType;
}

export const parseHistoricalCommentInThread = (
  thread: HistoricalCommentThread,
  currentUser: CurrentUserContextType,
) => {
  const htmlToReactParser = new Parser();
  const allComments = [thread, ...thread.replies];
  return allComments.map((comment: ChangeControlComment) => ({
    status: 'commented' as ActivityStatus,
    actionLabel: 'commented on historical thread',
    fullName: comment.user.full_name ?? comment.user.id,
    actionTimestamp: currentUser.formatDate(
      new Date(comment.timestamp * 1000),
      true,
    ),
    children: (
      <QStack>
        <QText>{htmlToReactParser.parse(comment.content)}</QText>
      </QStack>
    ),
  }));
};

export const HistoricalOpenCommentThread = ({
  thread,
  currentUser,
}: HistoricalCommentThreadProps) => {
  const activities = parseHistoricalCommentInThread(thread, currentUser);
  return <QActivitySequence activities={activities} key={thread.id} />;
};

export const HistoricalResolvedCommentThread = ({
  thread,
  currentUser,
}: HistoricalCommentThreadProps) => {
  const activities = [
    ...parseHistoricalCommentInThread(thread, currentUser),
    {
      status: 'markedResolved' as ActivityStatus,
      fullName: thread.user.full_name,
      actionTimestamp: 'closed historical thread',
    },
  ];
  return <QActivitySequence activities={activities} key={thread.id} />;
};

import { useState } from 'react';

import { useZoomHandler } from '../../../hooks/ZoomHandler';
import { FileDocumentControls } from './FileDocumentControls';

import styles from './ImageDocumentViewer.module.css';

export const ImageDocumentViewer = ({
  attachmentPreviewUrl,
  showDownloadButton,
  handleDownload,
  handlePrint,
  commentsPermission,
  handleZoomChange,
}: {
  attachmentPreviewUrl: string;
  showDownloadButton: boolean;
  handleDownload: () => void;
  handlePrint?: () => void;
  commentsPermission: number;
  handleZoomChange?: (zoom: number) => void;
}) => {
  const [zoom, setZoom] = useState<number>(1.0);

  const imgStyle = {
    maxWidth: '100%',
    transform: `scale(${zoom})`,
    transformOrigin: 'top left',
  };

  const handleZoom = useZoomHandler(setZoom, handleZoomChange);

  return (
    <>
      <FileDocumentControls
        handleZoom={handleZoom}
        zoom={zoom}
        isImage={true}
        handleDownload={handleDownload}
        showDownloadButton={showDownloadButton}
        showCommentButton={commentsPermission > 1}
        handlePrint={handlePrint}
      />
      <div
        id={'fileWrapper'}
        data-cy="imageViewer"
        className={
          styles[
            commentsPermission > 0
              ? 'file-document__image-wrapper-comments'
              : 'file-document__image-wrapper'
          ]
        }
      >
        <img style={imgStyle} src={attachmentPreviewUrl} alt="Preview" />
      </div>
    </>
  );
};

import { DocumentConvertFormatModalProps } from './types';

import { DocumentFormat } from '../../../api/model/document';
import { ConvertFileFormat } from './ConvertFileFormat';
import { ConvertQualioFormat } from './ConvertQualioFormat';

export const DocumentConvertFormatModal = (
  props: DocumentConvertFormatModalProps,
) => {
  return props.qualioDocument.document_format ===
    DocumentFormat.DocumentUpload ? (
    <ConvertQualioFormat {...props} />
  ) : (
    <ConvertFileFormat {...props} />
  );
};

import { PeriodicReviewUser } from '../../../../../api/model/periodicReview';

export enum PeriodicReviewDetailsStatus {
  approved = 'Approved',
  declined = 'Approval declined',
  in_progress = 'In progress',
}

export type Approval = {
  timestamp?: number;
  approved?: boolean;
  user: PeriodicReviewUser;
};

export type PeriodicReviewDetails = {
  status: PeriodicReviewDetailsStatus;
  sent_by: string;
  sent_at: string;
  comment: string;
  review_period: string;
  approvals: Approval[];
};

import {
  CurrentUserContextType,
  QCloseButton,
  QDrawer,
  QDrawerHeader,
  QDrawerProps,
  QStack,
  useCurrentUser,
} from '@qualio/ui-components';

import {
  PeriodicReviewExtended,
  PeriodicReviewUser,
} from '../../../../../api/model/periodicReview';
import { PeriodicReviewDetails } from './types';

import { usePeriodicReview } from './hooks';

import { CenteredSpinner } from './CenteredSpinner';
import { DetailCardLayout } from './DetailCardLayout';
import { ReviewDetails } from './ReviewDetails';

const buildStatus = (periodicReview?: PeriodicReviewExtended) => {
  if (!periodicReview) {
    return '';
  }

  const notApproved = periodicReview.round_stopped
    ? 'Approval declined'
    : 'In progress';

  return periodicReview.conclusion ? 'Approved' : notApproved;
};

const buildReviewPeriod = (periodicReview?: PeriodicReviewExtended) => {
  if (!periodicReview) {
    return 0;
  }

  const reviewDiscussionAfterRoundStarted = periodicReview.discussion.filter(
    (item) => {
      return item.timestamp >= periodicReview.review_round_started_at;
    },
  )[0];

  return reviewDiscussionAfterRoundStarted?.suggested_review_period || 0;
};

const getApproverDetails = (
  approver: PeriodicReviewUser,
  periodicReview: PeriodicReviewExtended,
) => {
  const discussionItem = periodicReview.discussion.find(
    (item) => item.user.id === approver.id && item.hasOwnProperty('approved'),
  );

  if (!discussionItem) {
    return {
      user: approver,
    };
  }

  const { timestamp, approved } = discussionItem;

  return {
    timestamp,
    approved,
    user: approver,
  };
};

const getPeriodicReviewDetails = (
  currentUser: CurrentUserContextType,
  periodicReview?: PeriodicReviewExtended,
) => {
  if (!periodicReview) {
    return {};
  }

  const reviewPeriod = buildReviewPeriod(periodicReview);
  const period = reviewPeriod || 'None';
  const periodLabel = reviewPeriod ? 'month' : '';
  const pluralSuffix = reviewPeriod > 1 ? 's' : '';
  const reviewPeriodStr = `${period} ${periodLabel}${pluralSuffix}`;
  const reviewApprovals = periodicReview.approvers.map((approver) =>
    getApproverDetails(approver, periodicReview),
  );

  return {
    status: buildStatus(periodicReview),
    sent_by: periodicReview.author.full_name,
    sent_at:
      periodicReview.review_started_at &&
      currentUser.formatDate(
        new Date(periodicReview.review_round_started_at * 1000),
      ),
    comment: periodicReview.discussion[0]?.comment || 'None',
    review_period: reviewPeriodStr,
    approvals: reviewApprovals,
  };
};

export type PeriodicReviewDetailsPanelProps = QDrawerProps & {
  periodicReviewId?: number;
};

export const PeriodicReviewDetailsPanel = ({
  isOpen,
  onClose,
  periodicReviewId,
}: PeriodicReviewDetailsPanelProps) => {
  const currentUser = useCurrentUser();
  const {
    data: periodicReview,
    isLoading,
    isError,
  } = usePeriodicReview(periodicReviewId);

  return (
    <QDrawer isOpen={isOpen} onClose={onClose} size="lg">
      <QDrawerHeader>
        Periodic review
        <QCloseButton onClick={onClose} />
      </QDrawerHeader>
      <QStack>
        {isLoading && <CenteredSpinner />}
        {!isLoading && !isError && (
          <QStack spacing="6">
            <DetailCardLayout title="Review details">
              <ReviewDetails
                details={
                  getPeriodicReviewDetails(
                    currentUser,
                    periodicReview,
                  ) as PeriodicReviewDetails
                }
              />
            </DetailCardLayout>
          </QStack>
        )}
      </QStack>
    </QDrawer>
  );
};

import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { medtechApiClient } from './index';

export const userApi = {
  async fetchCurrentUser(): Promise<CurrentUser> {
    const { id } = await userApi.fetchSession();

    const mtbeUser = await userApi.fetchUser(id);

    const activeCompany = mtbeUser.companies.find((company) => {
      return company.currently_selected === true;
    });
    if (!activeCompany) {
      throw new Error('Cannot find active company for user');
    }

    return {
      companyId: activeCompany.id,
      email: mtbeUser.email,
      userId: mtbeUser.id,
      fullName: mtbeUser.full_name,
      capabilities: activeCompany.capabilities,
      permissions: {}, // not used anywhere in editor
      userGroups: activeCompany.usergroups,
      companyName: activeCompany.name,
      company: {
        createdTime: activeCompany.created_time,
        status: activeCompany.status,
        pricingPlan: activeCompany.pricing_plan,
        ai_enabled: activeCompany.ai_enabled,
      },
      tz: mtbeUser.tz,
    };
  },

  async fetchSession(): Promise<{ id: number }> {
    return (await medtechApiClient.get<{ id: number }>(`/session`)).data;
  },

  async fetchUser(userId: number): Promise<MedtechUserV1> {
    return (await medtechApiClient.get<MedtechUserV1>(`/users/${userId}`)).data;
  },

  async fetchUsers(companyId: number): Promise<MedtechUserV2[]> {
    return (
      await medtechApiClient.get<MedtechUserV2[]>(`/v2/${companyId}/users`)
    ).data;
  },
};

export type MedtechUserV1 = {
  id: number;
  staff: boolean;
  email: string;
  full_name: string;
  companies: MTBEUserCompany[];
  tz: string;
};

type MTBECapabilities = {
  premium_integrations: boolean;
  sso: boolean;
  api: boolean;
  design_controls: boolean;
  suppliers: boolean;
  events: boolean;
  basic_users_review_docs: boolean;
  ai: boolean;
};

export type MTBEUserCompany = {
  id: number;
  active: boolean;
  name: string;
  capabilities: MTBECapabilities | null;
  usergroups: MedtechCompanyUserGroup[];
  currently_selected: boolean;
  permissions: string[];
  pricing_plan: string | null;
  status: string;
  created_time: number;
  ai_enabled: boolean;
};

export type MedtechUserV2 = {
  id: number;
  email: string;
  full_name: string;
  groups: number[];
  usergroups: MedtechCompanyUserGroup[];
  permissions: string[];
  usercompany_id: number;
};

export type MedtechCompanyUserGroup = 'quality' | 'normal' | 'read_only';

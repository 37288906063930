import React, { useCallback, useMemo } from 'react';

import { QMenuButton, QMenuItemType } from '@qualio/ui-components';

import { QualioDocumentSection } from '../../../api/model/document';
import { EditorName } from '../../../config/handlers/EditorEventsHandlers';
import { ChangeControlSection } from '../../../types/DocumentCreate';
import { navigateToSection } from '../../../util/DocumentNavigationUtils';
import { DocumentPresentationUtils } from '../../../util/DocumentPresentationUtils';
import styles from './TableOfContents.module.css';

export const TableOfContents: React.FC<{
  sections: QualioDocumentSection[] | ChangeControlSection[];
  sectionTitlesVisible: boolean;
  editorName?: EditorName;
}> = ({
  sections,
  sectionTitlesVisible,
  editorName = 'documentEditorContent',
}) => {
  const handleItemClick = useCallback(
    (item: QMenuItemType) => {
      const index = parseInt(item.id) - 1;
      navigateToSection(index, editorName);
    },
    [editorName],
  );

  const TOCItems: QMenuItemType[] = useMemo(
    () =>
      DocumentPresentationUtils.getDocumentSectionsTitles(sections).map(
        (title, idx) => ({
          id: title.split('.')[0],
          label: title,
          'data-cy': `toc-element-${idx + 1}`,
        }),
      ),
    [sections],
  );

  return (
    <div
      id="documentTocControl"
      data-cy="documentTocControl"
      className={`${styles['document-editor__document__toc-control']}`}
    >
      {sectionTitlesVisible && (
        <QMenuButton
          items={TOCItems}
          onItemClick={handleItemClick}
          variant="icon"
          buttonLabel=""
          aria-label="toc-button"
          iconName="Menu"
          data-cy="toc-button"
        ></QMenuButton>
      )}
    </div>
  );
};

export const wait = (delay: number) => {
  return new Promise((resolve) => setTimeout(resolve, delay));
};

export const retryOnError = (
  toRetry: () => Promise<any>,
  delay: number,
  error: any,
  tries: number,
) => {
  if (tries <= 0) {
    console.log(error);
    return;
  }

  void wait(delay).then(() => {
    toRetry().catch((error) => {
      retryOnError(toRetry, delay, error, tries - 1);
    });
  });
};

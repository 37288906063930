import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';

import { QualioDocument } from '../../api/model/document';
import { isEditor, isOwner, isReviewer, userIsInGroup } from './User';

// /*
//  * Permissions levels for comments:
//  * 1: comments:read
//  * 2: comments:write
//  * 3: comments:admin
//  * more info in https://ckeditor.com/docs/cs/latest/guides/security/roles.html#permissions
// */

const draftPermission = (
  currentUser: CurrentUser,
  document: QualioDocument,
): number => {
  if (isOwner(currentUser, document) || isEditor(currentUser, document)) {
    return 3;
  }

  return 0;
};

const reviewPermission = (
  currentUser: CurrentUser,
  document: QualioDocument,
): number => {
  if (
    currentUser.userId === document.author.id ||
    currentUser.userId === document.owner.id
  ) {
    return 3;
  }

  const { audit_groups } = document;
  if (audit_groups) {
    const { qm_group = [] } = audit_groups;

    if (
      isReviewer(currentUser, document) ||
      isEditor(currentUser, document) ||
      userIsInGroup(currentUser, qm_group)
    ) {
      return 2;
    }
  }

  return 0;
};

const approvalPermission = (
  currentUser: CurrentUser,
  document: QualioDocument,
): number => {
  const { audit_groups } = document;

  if (audit_groups) {
    const { approver_group = [] } = audit_groups;
    if (
      currentUser.userId === document.author.id ||
      currentUser.userId === document.owner.id ||
      isEditor(currentUser, document) ||
      userIsInGroup(currentUser, approver_group)
    ) {
      return 1;
    }
  }

  return 0;
};

export const getUserCommentsPermission = (
  currentUser: CurrentUser,
  document: QualioDocument,
): number => {
  const { status_id } = document;

  if ((currentUser.company as any).ghost) {
    return 1;
  }

  if (status_id === 'draft') {
    return draftPermission(currentUser, document);
  }

  if (status_id === 'for_review') {
    return reviewPermission(currentUser, document);
  }

  if (status_id === 'for_approval') {
    return approvalPermission(currentUser, document);
  }

  // default, this will be disregarded by the token service
  return 0;
};

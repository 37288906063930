import axios, { CancelTokenSource } from 'axios';
import { documentApi } from '../../api/document';
import { isEnv } from '../../util/EnvUtils';

export class QualioAttachmentCKUploadAdapter {
  loader: { file: Promise<any>; uploadTotal?: number; uploaded?: number };
  cancelTokenSource: CancelTokenSource;
  entityId?: number;
  entityType?: string;
  constructor(
    loader: {
      file: Promise<any>;
      uploadTotal: number;
      uploaded: number;
    },
    entityId: number,
    entityType: string,
  ) {
    this.loader = loader;
    this.cancelTokenSource = axios.CancelToken.source();
    this.entityId = entityId;
    this.entityType = entityType;
  }

  async upload(): Promise<any> {
    const file = await this.loader.file;
    const attachment = await documentApi.uploadAttachment(
      file,
      (progress) => {
        this.loader.uploadTotal = progress.total;
        this.loader.uploaded = progress.loaded;
      },
      this.cancelTokenSource.token,
      {},
      this.entityId,
      this.entityType,
    );
    if (attachment) {
      const imageUrl = isEnv('development')
        ? `http:${attachment.url}`
        : `https:${attachment.url}`;
      return {
        urls: { default: imageUrl },
      };
    }
  }

  abort(): void {
    this.cancelTokenSource.cancel('User requested');
  }
}

import {
  Filtering,
  QAlert,
  QButton,
  QLink,
  QLookup,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QStack,
  QText,
  useToastProvider,
} from '@qualio/ui-components';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  documentApi,
  SuppressedAssignedTraineesToDocuments,
} from '../../../../../../api/document';
import { GroupApi } from '../../../../../../api/group';
import {
  DocumentStatus,
  QualioDocument,
} from '../../../../../../api/model/document';
import { Tag } from '../../../../../../api/model/tag';
import { MedtechUserV2, userApi } from '../../../../../../api/user';
import { canUserAccessAtLeastOnePrivateTag } from '../../../../DocumentOverview/DocumentProperties/utils';
import { hasRequiredPermissionsForAction } from '../../BulkActions';
import { BulkActionButton, BulkActionButtonProps } from '../BulkActionButton';
import { GroupsCell } from './GroupsCell/GroupsCell';
import { SuppressedAssignmentsModal } from './SuppressedAssignmentsModal/SuppressedAssignmentsModal';

type AssignTraineesButtonProps = Omit<
  BulkActionButtonProps,
  'isActionAllowed' | 'onClick' | 'label'
> & { tags: Tag[] };

type DocumentForNoTrainingModal = {
  title: QualioDocument['title'];
  id: QualioDocument['id'];
};

type DocumentsWithNoTrainingModalProps = {
  documents: DocumentForNoTrainingModal[];
  handleSubmit: () => void;
  handleCancel: () => void;
};

type NoDocumentsHaveTrainingModalProps = {
  handleCancel: DocumentsWithNoTrainingModalProps['handleCancel'];
};

const FilterDefinitions = {
  filteringTrainees: {
    label: 'trainees',
    schema: Filtering.schemas.StringSchema(),
  },
} as const;

export const NoDocumentsHaveTrainingModal = ({
  handleCancel,
}: NoDocumentsHaveTrainingModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const onClose = useMemo(
    () => () => {
      handleCancel();
      setIsModalOpen(false);
    },
    [handleCancel],
  );

  return (
    <>
      {isModalOpen && (
        <QModal isOpen onClose={onClose}>
          <QModalHeader>
            <QText>Assign Trainees</QText>
          </QModalHeader>
          <QModalBody>
            <QAlert
              data-cy="no-documents-have-training-alert"
              title="No selected documents have training available"
              description="Trainees cannot be assigned to these documents"
              status="error"
            />

            <QText marginTop={5} marginBottom={5}>
              Please select documents that have training available to assign
              trainees.
            </QText>
          </QModalBody>
          <QModalActions>
            <QButton variant="solid" onClick={onClose}>
              Dismiss
            </QButton>
          </QModalActions>
        </QModal>
      )}
    </>
  );
};

export const DocumentsWithNoTrainingModal = ({
  documents,
  handleSubmit,
  handleCancel,
}: DocumentsWithNoTrainingModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onSubmit = async () => {
    handleSubmit();
    setIsModalOpen(false);
  };

  useEffect(() => {
    setIsModalOpen(documents.length > 0);
  }, [documents]);

  const onClose = useMemo(
    () => () => {
      handleCancel();
      setIsModalOpen(false);
    },
    [handleCancel],
  );

  return (
    <>
      {isModalOpen && (
        <QModal isOpen onClose={onClose}>
          <QModalHeader>
            <QText>Assign Trainees</QText>
          </QModalHeader>
          <QModalBody>
            <QAlert
              data-cy="some-documents-training-unavailable-alert"
              title="Some documents do not have training available"
              description="Trainees will not be assigned to these documents"
              status="warning"
            />

            <QText marginTop={5} marginBottom={5}>
              The following documents do not have training available:
            </QText>
            {documents.map((document) => (
              <QStack direction="column" key={document.id}>
                <QLink
                  href={`/workspace/documents/${document.id}`}
                  isExternal
                  tabIndex={-1}
                  marginBottom={1}
                  data-cy={`no-training-document-${document.id}`}
                >
                  {document.title}
                </QLink>
              </QStack>
            ))}
          </QModalBody>
          <QModalActions>
            <QButton variant="outline" onClick={onClose}>
              Cancel
            </QButton>
            <QButton
              data-cy="proceed-without-docs-with-no-training-button"
              onClick={onSubmit}
            >
              Continue
            </QButton>
          </QModalActions>
        </QModal>
      )}
    </>
  );
};

export const AssignTraineesButtonV2 = (props: AssignTraineesButtonProps) => {
  const [isAssignTraineesClicked, setIsAssignTraineesClicked] = useState(false);
  const [isAssignTraineesOpen, setIsAssignTraineesOpen] = useState(false);

  const [suppressedAssignments, setSuppressedAssignments] =
    useState<SuppressedAssignedTraineesToDocuments[]>();

  const { showToast } = useToastProvider();

  const commonToastProps = {
    id: 'assign-trainees-toast',
    replace: true,
  };

  const { selectedDocuments, currentUser, tags } = props;

  const selectedDocumentsWithNoTraining = useMemo(() => {
    return selectedDocuments.filter(
      (document: QualioDocument) => !document.training_available,
    );
  }, [selectedDocuments]);

  const selectedDocumentsWithTraining = useMemo(() => {
    return selectedDocuments.filter(
      (document: QualioDocument) => document.training_available,
    );
  }, [selectedDocuments]);

  const privateTagIdsToGroupIdsMap = useMemo(
    () =>
      tags.reduce((acc: Map<Tag['id'], Tag['group_ids']>, tag) => {
        if (tag.group_ids.length > 0) {
          acc.set(tag.id, tag.group_ids);
        }
        return acc;
      }, new Map()),
    [tags],
  );

  const selectedDocumentsPrivateTagIdsToGroupIdsMap = useMemo(() => {
    return selectedDocumentsWithTraining.reduce(
      (acc: Map<Tag['id'], Tag['group_ids']>, document) => {
        document.tag_ids.forEach((tagId) => {
          const groupIds = privateTagIdsToGroupIdsMap.get(tagId);
          if (groupIds) {
            acc.set(tagId, groupIds);
          }
        });
        return acc;
      },
      new Map(),
    );
  }, [privateTagIdsToGroupIdsMap, selectedDocumentsWithTraining]);

  const documentsText = useMemo(() => {
    const documentsCount = selectedDocumentsWithTraining.length;
    const documentsText = documentsCount > 1 ? 'documents' : 'document';
    return documentsText;
  }, [selectedDocumentsWithTraining.length]);

  const { data: users = [] } = useQuery({
    queryFn: async () => {
      const users = await userApi.fetchUsers(currentUser.companyId);
      return users ?? [];
    },
    queryKey: ['userList'],
    refetchOnWindowFocus: false,
  });

  const { data: groupData = [] } = useQuery({
    queryFn: async () => {
      const groups = await GroupApi.fetchGroups();
      return groups ?? [];
    },
    queryKey: ['groups'],
    refetchOnWindowFocus: false,
  });

  const potentialTrainees = useMemo(() => {
    if (selectedDocumentsPrivateTagIdsToGroupIdsMap.size > 0) {
      const groupIds: Tag['group_ids'][] = Array.from(
        selectedDocumentsPrivateTagIdsToGroupIdsMap.values(),
      );
      return users.filter((user) =>
        canUserAccessAtLeastOnePrivateTag(user, groupIds),
      );
    }
    return users;
  }, [selectedDocumentsPrivateTagIdsToGroupIdsMap, users]);

  const canAssignTrainees: BulkActionButtonProps['isActionAllowed'] = (
    user,
    statuses,
    _activeTab,
    requiredPermissions,
  ) => {
    if (
      !hasRequiredPermissionsForAction(requiredPermissions, user.permissions) ||
      !statuses.length
    ) {
      return false;
    }
    return [
      DocumentStatus.Retired.valueOf(),
      DocumentStatus.Deleted.valueOf(),
    ].every((disallowedStatus) => !statuses.includes(disallowedStatus));
  };

  const filterOption = (
    item: MedtechUserV2,
    searchTerm: string | undefined,
  ) => {
    const groups = groupData
      .filter(({ id }) => item.groups.includes(id))
      .map(({ name }) => name);
    if (!searchTerm) {
      return true;
    }
    return `${item.full_name} ${item.email} ${groups}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
  };

  const assignTraineesToDocuments = async (
    selectedUsers: readonly MedtechUserV2[],
  ) => {
    const documentIds = selectedDocumentsWithTraining.map(({ id }) => id);
    const userIds = selectedUsers.map(({ id }) => id);
    try {
      const response = await documentApi.assignTraineesToDocuments(
        currentUser,
        documentIds,
        userIds,
      );
      if (response.done.length > 0) {
        showToast({
          ...commonToastProps,
          status: 'success',
          title: 'Trainees assigned!',
          description: `Successfully assigned ${response.done.length} trainee${
            response.done.length === 1 ? '' : 's'
          }.`,
        });
      }
      if (response.suppressed.length > 0) {
        setSuppressedAssignments(response.suppressed);
      }
      setIsAssignTraineesClicked(false);
      setIsAssignTraineesOpen(false);
    } catch (error) {
      showToast({
        ...commonToastProps,
        status: 'error',
        title: 'Error',
        description: 'Failed to assign trainees. Please try again.',
      });
    }
  };

  const userDataView: QLookup.DataView<MedtechUserV2> = useMemo(
    () => ({
      full_name: {
        header: 'Full name',
        width: 'auto',
      },
      email: {
        header: 'Email',
        width: 'auto',
      },
      groups: {
        header: 'Groups',
        width: 'auto',
        render: (groups: MedtechUserV2['groups'], user: MedtechUserV2) => (
          <GroupsCell
            groupIds={groups}
            availableGroups={groupData}
            identifier={user.id}
          />
        ),
      },
    }),
    [groupData],
  );

  const handleDocumentsWithNoTrainingModalSubmit = useMemo(
    () => () => {
      setIsAssignTraineesClicked(false);
      setIsAssignTraineesOpen(true);
    },
    [],
  );

  const handleAssignTraineeModalClose = useMemo(
    () => () => {
      setIsAssignTraineesClicked(false);
      setIsAssignTraineesOpen(false);
    },
    [],
  );

  const handlePreAssignTrainingModalCancel = useMemo(
    () => () => {
      setIsAssignTraineesClicked(false);
    },
    [],
  );

  return (
    <Filtering.ScopedFilterProvider definitions={FilterDefinitions}>
      <QLookup.DataProvider.Fixed
        data={potentialTrainees}
        filterOption={filterOption}
      >
        <BulkActionButton
          {...props}
          isActionAllowed={canAssignTrainees}
          onClick={() => setIsAssignTraineesClicked(true)}
          label="Assign trainees"
        />

        {isAssignTraineesClicked &&
          selectedDocumentsWithTraining.length === 0 && (
            <NoDocumentsHaveTrainingModal
              handleCancel={handlePreAssignTrainingModalCancel}
            />
          )}

        {isAssignTraineesClicked &&
          selectedDocumentsWithTraining.length > 0 && (
            <DocumentsWithNoTrainingModal
              documents={selectedDocumentsWithNoTraining}
              handleSubmit={handleDocumentsWithNoTrainingModalSubmit}
              handleCancel={handlePreAssignTrainingModalCancel}
            />
          )}

        <QLookup.MultiSelect<MedtechUserV2>
          isOpen={
            (isAssignTraineesClicked &&
              selectedDocumentsWithNoTraining.length === 0) ||
            isAssignTraineesOpen
          }
          action="Assign"
          searchPlaceholder="Filter by name, email or group name..."
          accessors={{
            id: 'id',
          }}
          onSelect={assignTraineesToDocuments}
          onCancel={handleAssignTraineeModalClose}
          view={userDataView}
          defaultSortBy={{ id: 'full_name' }}
        >
          <QModalHeader>
            <QText data-cy="assign-trainees-modal-header" fontSize="xl">
              Assign trainees
            </QText>
            <QText fontWeight="400" fontSize="md" pt="6">
              Assign trainees to {selectedDocumentsWithTraining.length}{' '}
              {documentsText}.
            </QText>
          </QModalHeader>
        </QLookup.MultiSelect>
      </QLookup.DataProvider.Fixed>
      <SuppressedAssignmentsModal
        suppressedAssignments={suppressedAssignments}
        onClose={() => setSuppressedAssignments(undefined)}
      />
    </Filtering.ScopedFilterProvider>
  );
};

import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  QButton,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';

import { DocumentOverviewContext } from '../../Context';
import { shouldRenderUndoDeleteButton } from '../../RenderLogic';

import { documentApi } from '../../../../../api/document';
import { ButtonGroupContext } from '../../../Contexts/ButtonGroupContext';

export const UndoDeleteButton = () => {
  const currentUser = useCurrentUser();
  const { qualioDocument } = useContext(DocumentOverviewContext);
  const { isDisabled, setDisabled } = useContext(ButtonGroupContext);
  const [isUndoingDelete, setIsUndoingDelete] = useState(false);
  const navigate = useNavigate();
  const { showToast } = useToastProvider();

  const commonToastProps = {
    id: 'undo-delete-toast',
    replace: true,
  };

  const handleUndoDelete = async () => {
    const { id: documentId, major_version, minor_version } = qualioDocument;
    const version = `${major_version}.${minor_version}`;
    setDisabled(true, setIsUndoingDelete);
    documentApi
      .undoDelete(documentId.toString(), version)
      .then(() => {
        setDisabled(false, setIsUndoingDelete);
        showToast({
          ...commonToastProps,
          status: 'success',
          title: 'Success!',
          description: 'Successfully restored document.',
        });
        navigate('/workspace');
      })
      .catch((e) => {
        setDisabled(false, setIsUndoingDelete);
        const failureMessage =
          e?.response?.data?.message ||
          'Failed to undo delete. Please try again.';
        showToast({
          ...commonToastProps,
          status: 'error',
          title: 'Error',
          description: failureMessage,
        });
      });
  };

  if (!shouldRenderUndoDeleteButton(qualioDocument, currentUser)) {
    return null;
  }

  return (
    <QButton
      isDisabled={isDisabled}
      isLoading={isUndoingDelete}
      variant="outline"
      onClick={handleUndoDelete}
      data-cy={'undo-delete-button'}
    >
      Undo delete
    </QButton>
  );
};

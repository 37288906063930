import { useCurrentUser } from '@qualio/ui-components';
import { useQuery, UseQueryResult } from 'react-query';
import { IntegrationsAPI } from '../api/integrationsApi';
import { IntegrationConfig } from '../types/integrationConfig';

const Duration = {
  seconds: (seconds: number) => seconds * 1000,
  minutes: (minutes: number) => minutes * Duration.seconds(60),
} as const;

export const useIntegrationEnabled = (
  source: IntegrationConfig['source'],
): UseQueryResult<boolean> => {
  const { companyId } = useCurrentUser();

  return useQuery(
    ['integration', source, companyId],
    () => IntegrationsAPI.isSourceEnabled(source, companyId),
    {
      staleTime: Duration.minutes(5),
    },
  );
};

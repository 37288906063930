import { useContext } from 'react';

import { QBadge, QDivider, QLink, QStack, QText } from '@qualio/ui-components';

import { DocumentOverviewContext } from '../../Context';
import { shouldRenderRelatedIssues } from '../../RenderLogic';

export const ViewIssue = () => {
  const { qualioDocument } = useContext(DocumentOverviewContext);

  const url = `/quality-events/events/${qualioDocument.issue_id}`;

  if (!shouldRenderRelatedIssues(qualioDocument)) {
    return null;
  }

  const issueCode = qualioDocument.issue?.code;

  return (
    <>
      <QStack>
        <QText fontWeight="semibold" fontSize="md">
          Event
        </QText>
        <QStack>
          <QBadge mt="4.5">
            <QLink isCrossMFE href={url}>
              {issueCode}
            </QLink>
          </QBadge>
        </QStack>
      </QStack>
      <QDivider mt="6" mb="6" />
    </>
  );
};

import {
  TrackChanges,
  TrackChangesEditing,
} from '@ckeditor/ckeditor5-track-changes';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { documentApi } from '../../api/document';
import {
  logCustomError,
  PATCH_SUGGESTION_DATA_ERROR,
} from '../../messages/LogErrorMessages';
import { CKEditorInstance } from '../../types/CKEditorInstance';
import { retryOnError } from '../../util/APIUtils';
import { formTargetsFromMarker } from './eventHandlerUtil';

const RETRIES = 3;

const setSuggestionAttributes = (
  suggestionId: string,
  trackChanges: TrackChanges,
  trackChangesEditing: TrackChangesEditing,
  currentUser: CurrentUser,
  documentId: string,
) => {
  if (!trackChanges || !trackChangesEditing) {
    return;
  }

  const suggestion = trackChanges.getSuggestion(suggestionId);
  const marker = suggestion.getFirstMarker();
  const suggestionChain = suggestion.getAllAdjacentSuggestions();
  let descriptions: any[] = [];
  try {
    descriptions = (
      trackChangesEditing as any
    ).descriptionFactory.getDescriptions(suggestionChain);
  } catch (err) {
    descriptions = (
      trackChangesEditing as any
    ).descriptionFactory.getDescriptions([suggestion]);
  }
  // descriptions are always an array but can be empty e.g. when using Undo after adding a suggestion
  // also we can check if the content is changing and call `.setAttribute()` method only with new content value
  suggestionChain.forEach((s) => {
    const attributes = new Map();

    if (
      descriptions.length > 0 &&
      s.attributes.content !== descriptions[0].content
    ) {
      attributes.set('content', descriptions[0].content);
      marker && attributes.set('suggestedOn', formTargetsFromMarker(marker));
    }

    attributes.set('authorFullName', s.author.name);

    if (attributes.size > 0) {
      const commentThread = s.commentThread as any;
      const channelId = commentThread.channelId;
      documentApi
        .updateSuggestionAttributes(
          currentUser.companyId,
          documentId,
          s.id,
          channelId,
          attributes,
        )
        .catch((error) => {
          retryOnError(
            () =>
              documentApi.updateSuggestionAttributes(
                currentUser.companyId,
                documentId,
                s.id,
                channelId,
                attributes,
              ),
            200,
            error,
            RETRIES,
          );
        });
    }
  });
};

export const setupSuggestionUpdateHandler = (
  currentUser: CurrentUser,
  editors: CKEditorInstance[],
): void => {
  editors.forEach((editor) => {
    const trackChanges = editor.plugins.get('TrackChanges') as TrackChanges;
    const trackChangesEditing = editor.plugins.get('TrackChangesEditing');

    editor.commands.get('acceptSuggestion')?.on(
      'execute',
      (evt, suggestionIds: string[]) => {
        const config = (evt.source as any).editor.config;
        const documentId = config ? config.get('documentId') : undefined;
        if (!config || !documentId) {
          logCustomError(PATCH_SUGGESTION_DATA_ERROR);
          return;
        }
        setSuggestionAttributes(
          suggestionIds[0],
          trackChanges,
          trackChangesEditing,
          currentUser,
          documentId.toString(),
        );
      },
      { priority: 'highest' },
    );
    editor.commands.get('discardSuggestion')?.on(
      'execute',
      (evt, suggestionIds: string[]) => {
        const config = (evt.source as any).editor.config;
        const documentId = config ? config.get('documentId') : undefined;
        if (!config || !documentId) {
          logCustomError(PATCH_SUGGESTION_DATA_ERROR);
          return;
        }
        setSuggestionAttributes(
          suggestionIds[0],
          trackChanges,
          trackChangesEditing,
          currentUser,
          documentId.toString(),
        );
      },
      { priority: 'highest' },
    );
  });
};

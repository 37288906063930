import { QFlex, QSpinner, QStack } from '@qualio/ui-components';
import React from 'react';

export const CenteredSpinner: React.FC = () => {
  return (
    <QFlex height="100vh" justify="center" align="center">
      <QStack direction="column" align="center">
        <QSpinner data-cy="loading-periodic-review-details" />
      </QStack>
    </QFlex>
  );
};

import { QBox, QStack, QText } from '@qualio/ui-components';
import { QualioDocumentAuditGroups } from '../../../../../api/model/document';
import { DocumentUsers } from '../DocumentUsers';
import {
  ManageEditorsButton,
  ManageEditorsButtonProps,
} from './ManageEditorsButton';

export type ManageEditorsProps = {
  editors: QualioDocumentAuditGroups['editor_group'];
} & ManageEditorsButtonProps;

export const ManageEditors = ({
  editors = [],
  groups,
  users,
  tags,
}: ManageEditorsProps) => {
  return (
    <QBox data-cy={`document-overview-editors`}>
      <QStack isInline justifyContent="space-between" alignItems="center">
        <QText fontWeight="semibold" fontSize="md">
          Editors
        </QText>
        <ManageEditorsButton groups={groups} users={users} tags={tags} />
      </QStack>
      <DocumentUsers userType="editor" users={editors} />
    </QBox>
  );
};

import {
  QAlert,
  QAlertProps,
  QBox,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QStack,
  QText,
} from '@qualio/ui-components';
import { ReactFragment } from 'react';
import styles from './BaseDocumentConvertModal.module.css';

export type BaseDocumentConvertModalProps = {
  warningText: QAlertProps['title'];
  messageList: string[];
  modalActionsFragment: JSX.Element[];
  onClose: (success: boolean) => void;
  messageListPrompt?: string;
  extendedModalBodyFragment?: ReactFragment;
};

export const BaseDocumentConvertModal: React.FC<
  BaseDocumentConvertModalProps
> = ({
  onClose,
  warningText,
  messageListPrompt = 'Before changing the format, be sure to remember:',
  messageList,
  modalActionsFragment,
  extendedModalBodyFragment,
}) => {
  return (
    <QModal onClose={() => onClose(false)} isOpen={true} size="xl">
      <QModalHeader>
        <QText>Change format</QText>
      </QModalHeader>
      <QModalBody>
        <QStack spacing="6">
          <div className={`${styles['document-file-format-warning']}`}>
            <QAlert
              title={warningText}
              description="This change can be undone by reverting to a previous document version."
              status="warning"
            />
          </div>
          <div>
            <QText>{messageListPrompt}</QText>
            <QBox pt="4" pl="6">
              <ul>
                {messageList.map((message) => (
                  <li key={message}>
                    <QText>{message}</QText>
                  </li>
                ))}
              </ul>
            </QBox>
          </div>
          {extendedModalBodyFragment}
        </QStack>
      </QModalBody>
      <QModalActions>{modalActionsFragment}</QModalActions>
    </QModal>
  );
};

import { createContext } from 'react';

export type ButtonGroupContextType = {
  isDisabled: boolean;
  setDisabled: (
    isDisabled: boolean,
    setLoadingCallback?: (isLoading: boolean) => void,
  ) => void;
};
export const ButtonGroupContext = createContext<ButtonGroupContextType>(
  {} as any,
);

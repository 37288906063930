import { QLookup } from '@qualio/ui-components';
import { QualioDocument } from '../../../../api/model/document';
import { Group } from '../../../../api/model/group';
import { Tag } from '../../../../api/model/tag';
import { MedtechUserV2 } from '../../../../api/user';
import { GroupsCell } from '../../DocumentWorkspace/BulkActions/BulkActionButtons/AssignTrainees/GroupsCell/GroupsCell';
import { NameCell } from '../../DocumentWorkspace/BulkActions/BulkActionButtons/AssignTrainees/NameCell/NameCell';

export const getUserLookupView = (
  groups: Group[],
): QLookup.DataView<MedtechUserV2> => ({
  full_name: {
    header: 'Full name',
    width: 'auto',
  },
  email: {
    header: 'Email',
    width: 'auto',
  },
  groups: {
    header: 'Groups',
    width: 'auto',
    render: (groupsFromUser: MedtechUserV2['groups'], user: MedtechUserV2) => (
      <GroupsCell
        groupIds={groupsFromUser}
        availableGroups={groups}
        identifier={user.id}
      />
    ),
  },
});

export const getUserLookupViewWithAccessRestriction = (
  groups: Group[],
  usersWithoutAccess: MedtechUserV2[],
): QLookup.DataView<MedtechUserV2> => ({
  full_name: {
    header: 'Full name',
    width: 'auto',
    render: (userName: MedtechUserV2['full_name'], user: MedtechUserV2) => (
      <NameCell user={user} usersWithoutAccess={usersWithoutAccess} />
    ),
  },
  email: {
    header: 'Email',
    width: 'auto',
  },
  groups: {
    header: 'Groups',
    width: 'auto',
    render: (groupsFromUser: MedtechUserV2['groups'], user: MedtechUserV2) => (
      <GroupsCell
        groupIds={groupsFromUser}
        availableGroups={groups}
        identifier={user.id}
      />
    ),
  },
});

export const filterUserOptionLookup = (
  query: string,
  user: MedtechUserV2,
  companyGroups: Group[],
) => {
  const groupsSearch = companyGroups
    .filter(({ id }) => user.groups.includes(id))
    .map(({ name }) => name);
  return `${user.full_name} ${user.email} ${groupsSearch}`
    .toLowerCase()
    .includes(query.toLowerCase());
};

export const getDocumentTagIdsToGroupIdsMap = (
  qualioDocument: QualioDocument,
  availableTags: Tag[],
): Map<Tag['id'], Tag['group_ids']> => {
  if (qualioDocument.tag_ids) {
    const documentTags = availableTags.filter((tag) =>
      qualioDocument.tag_ids.includes(tag.id),
    );
    return documentTags.reduce((acc: Map<Tag['id'], Tag['group_ids']>, tag) => {
      if (tag.group_ids.length) {
        acc.set(tag.id, tag.group_ids);
      }
      return acc;
    }, new Map());
  }
  return new Map();
};

export const canUserAccessAtLeastOnePrivateTag = (
  user: MedtechUserV2,
  privateGroupIdsLists: Group['id'][][],
) =>
  // User must be in at leat one group for at least one private tag's groups
  privateGroupIdsLists.some((privateGroupIds) =>
    user.groups.some((groupId) => privateGroupIds.includes(groupId)),
  );

export const FileDownloadPermissionOptions = {
  owner: 'Owner',
  quality: 'Owner, Quality users',
  normal: 'Owner, Quality, Normal users',
  read_only: 'Owner, Quality, Normal, Basic users',
};

export const determineFileDownloadUsergroupPermissionsFromModel = (
  qualioDocument: QualioDocument,
): keyof typeof FileDownloadPermissionOptions => {
  const { file_download_user_groups } = qualioDocument;
  if (file_download_user_groups?.length) {
    if (file_download_user_groups.includes('read_only')) {
      return 'read_only';
    } else if (file_download_user_groups.includes('normal')) {
      return 'normal';
    } else if (file_download_user_groups.includes('quality')) {
      return 'quality';
    }
  }
  return 'owner';
};

export const determineFileDownloadUsergroupsPermissionsModelValue = (
  value: keyof typeof FileDownloadPermissionOptions,
) => {
  switch (value) {
    case 'quality':
      return ['quality'];
    case 'normal':
      return ['quality', 'normal'];
    case 'read_only':
      return ['quality', 'normal', 'read_only'];
    default:
      return [];
  }
};

import { Context } from '@ckeditor/ckeditor5-core';
import { ViewElement } from '@ckeditor/ckeditor5-engine';

import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';

import { CKEditorInstance } from '../../types/CKEditorInstance';
import { CKEditorUtils } from '../../util/CKEditorUtils';
import {
  configureBalloonPanels,
  configureQualioPluginsForTrackChanges,
  EditorMode,
  enableUploadAdapter,
  isCommentsOnlyMode,
  setCommentsOnlyModeTo,
  toggleCheckboxMode,
  toggleTrackChanges,
} from '../../util/EditModeUtils';
import {
  extractAttachmentIdFromHref,
  getHTMLElement,
  setElementDisabledState,
} from '../../util/HTMLElementUtils';
import { refreshDisplayMode } from '../../util/SidebarUtils';
import { ToolbarManager } from '../../util/ToolbarManager';

import Annotation from '@ckeditor/ckeditor5-comments/src/annotations/annotation';
import { EventInfo, Rect } from '@ckeditor/ckeditor5-utils';
import { SetStateAction } from 'react';
import {
  EDITOR_CONTEXT_READY_ERROR,
  EDITOR_READY_ERROR,
  logCustomError,
} from '../../messages/LogErrorMessages';
import { updateOutdatedSmartLinkTitlesV2 } from '../../plugins/Mention/UpdateOutdatedSmartLinkTitlesV2';
import { EditorStatus } from '../../views/components/DocumentOverview/Context';
import { handleComment } from './CommentEventHandler';
import { setupSuggestionUpdateHandler } from './SuggestionEventHandler';

export type EditorName =
  | 'documentEditorContent'
  | 'changeControlEditorContent'
  | 'documentHTMLViewerContent';

export const createContextReadyHandler = (
  currentUser: CurrentUser,
  readyHandler: (editors: CKEditorInstance[]) => void,
  commentsPermissions: number,
  toolbarManager: ToolbarManager,
  createNewCommentButton: string,
  setAttachmentForPreview: (id: string) => void,
  editorName: EditorName,
  setEditorStatus: React.Dispatch<SetStateAction<EditorStatus>>,
  handleHighlighting: (
    context: Context,
    identifierPredicate: (annotation: Annotation) => boolean,
    htmlSelector: string,
  ) => NodeJS.Timeout,
  setDidReceiveDisconnectionEvent?: (didReceive: boolean) => void,
  targetId?: string,
  isUpdateSmartlinkTitlesEnabled = false,
) => {
  return (context: Context) => {
    try {
      const { editors } = context;
      const editorsArr: CKEditorInstance[] = Array.from(editors) as any[];
      readyHandler(editorsArr);
      setupCommentButton(
        currentUser,
        editorsArr,
        commentsPermissions,
        createNewCommentButton,
        editorName,
      );
      editorsArr.forEach((editor) => {
        editorReadyHandler(
          editor,
          toolbarManager,
          setEditorStatus,
          isUpdateSmartlinkTitlesEnabled,
        );
      });

      setupSuggestionUpdateHandler(currentUser, editorsArr);
      setupClickListener(editorsArr, setAttachmentForPreview);
      if (setDidReceiveDisconnectionEvent) {
        setupEditorConnectionClosedHandler(
          context,
          setDidReceiveDisconnectionEvent,
        );
      }
      const tabPanels = document.getElementById('document-overview-tab-panels');

      refreshDisplayMode({
        instances: editorsArr,
        windowWidth: (tabPanels && tabPanels.offsetWidth) ?? undefined,
        forceNarrow: false,
        breakpointOverride: 1000,
      });
      const targetIdentifierPredicate = (annotation: Annotation): boolean => {
        if (annotation.target && isHTMLElementArray(annotation.target)) {
          return annotation.target.some(
            (element: any) =>
              element.getAttribute('data-comment') === targetId ||
              element.getAttribute('data-suggestion') === targetId,
          );
        } else {
          return false;
        }
      };

      setEditorStatus(EditorStatus.STABLE);
      if (targetId) {
        handleHighlighting(
          context,
          targetIdentifierPredicate,
          `[data-comment="${targetId}"], [data-suggestion="${targetId}"]`,
        );
      }
    } catch (error) {
      logCustomError(EDITOR_CONTEXT_READY_ERROR, {
        error,
        companyId: currentUser.companyId,
      });
    }
  };
};

export const setupCommentButton = (
  currentUser: CurrentUser,
  editors: CKEditorInstance[],
  commentsPermissions: number,
  createNewCommentButtonName: string,
  editorName: EditorName,
): void => {
  const commentButton = getHTMLElement(
    `div#${createNewCommentButtonName} button`,
  );
  const commentButtonParagraph = getHTMLElement(
    `div#${createNewCommentButtonName} p`,
  );
  const commentDiv = getHTMLElement(`div#${createNewCommentButtonName}`);
  // we do not have to set up the button for adding comments if a user has read-only permissions for commenting
  if (commentsPermissions < 2) {
    return;
  }
  commentDiv?.classList.remove('hidden');
  if (commentButton && commentButtonParagraph) {
    setElementDisabledState(commentButton, true);
    const editorContent = getHTMLElement(`#${editorName}`);
    editorContent?.addEventListener('mouseup', (event: any) => {
      const offsetY =
        event.clientY -
        editorContent.getBoundingClientRect().top +
        editorContent.scrollTop;

      commentButtonParagraph.style.marginTop =
        Math.max(offsetY - commentButtonParagraph.clientHeight / 2, 20) + 'px';
    });
    editors.forEach((editor, index) => {
      const addCommentThreadCommand = editor.commands.get('addCommentThread');
      addCommentThreadCommand?.on(
        'change:isEnabled',
        (_eventInfo, _name, value) => {
          setElementDisabledState(commentButton, !value);
          commentButton.setAttribute('data-index', index.toString());
        },
      );

      editor.ui?.view.listenTo(commentButton, 'click', () => {
        if (commentButton.getAttribute('data-index') === index.toString())
          addCommentThreadCommand?.execute();
      });

      const commentsRepository = editor.plugins.get(
        'CommentsRepository',
      ) as any;
      commentsRepository?.on(
        'addComment',
        (evt: EventInfo, data: CommentsRepository.CommentData) => {
          handleComment(evt, data, editor.model.markers, currentUser);
        },
        {
          priority: 'highest',
        },
      );
    });
  }
};

export const setupClickListener = (
  editors: CKEditorInstance[],
  setAttachmentForPreview: (id: string) => void,
): void => {
  editors.forEach((editor) => {
    editor.editing.view.document.on(
      'click',
      handleClick(editor, setAttachmentForPreview),
    );
    editor.editing.view.document.on(
      'selectionChangeDone',
      handleSelectionChange(editor),
    );
  });
};

export const setupAttachmentPreviewListener = (
  contentElement: Element,
  handleAttachmentClick: (attachmentId: string) => void,
): void => {
  const anchors = contentElement.getElementsByTagName('a');
  for (const anchor of anchors) {
    anchor.addEventListener('click', (evt) => {
      evt.preventDefault();
      const href = (evt.currentTarget as any).href;
      if (!href) {
        return;
      }
      const attachmentId = extractAttachmentIdFromHref(href);
      if (attachmentId && handleAttachmentClick) {
        handleAttachmentClick(attachmentId);
      } else {
        window.open(href, '_blank');
      }
    });
  }
};

const handleClick =
  (editor: CKEditorInstance, setAttachmentForPreview: (id: string) => void) =>
  (
    evt: any,
    data: {
      target?: ViewElement;
      domEvent?: PointerEvent;
    },
  ) => {
    const clickedViewElement = data.target;
    if (!clickedViewElement) {
      return;
    }
    if (clickedViewElement.is('$text')) {
      return;
    }
    const href = clickedViewElement?.getAttribute('href');
    const isCommentOrReadOnlyEditor =
      isCommentsOnlyMode(editor) || editor.isReadOnly;
    // open links in a new tab in comment or read-only mode
    if (clickedViewElement.name === 'a' && href && isCommentOrReadOnlyEditor) {
      data.domEvent?.preventDefault();
      data.domEvent?.stopPropagation();
      handleLinkClick(href, setAttachmentForPreview);
      return;
    }
    // open/download links/attachments that are commented on and/or are styled spans or bold
    if (
      (clickedViewElement.name === 'span' ||
        clickedViewElement.name === 'strong' ||
        clickedViewElement.name === 'i' ||
        clickedViewElement.name === 's' ||
        clickedViewElement.name === 'u') &&
      isCommentOrReadOnlyEditor
    ) {
      data.domEvent?.preventDefault();
      data.domEvent?.stopPropagation();
      openPotentiallyClickedAnchor(clickedViewElement, setAttachmentForPreview);
      return;
    }
  };

const handleSelectionChange = (editor: CKEditorInstance) => () => {
  // disable comment button when input is only selected element
  const selectedElement =
    editor.editing.view.document.selection.getSelectedElement();
  if (selectedElement?.name === 'input') {
    const addCommentThreadCommand = editor.commands.get('addCommentThread');
    addCommentThreadCommand?.fire('change:isEnabled', false);
  }
};

const openPotentiallyClickedAnchor = (
  clickedViewElement: ViewElement,
  setAttachmentForPreview: (id: string) => void,
): void => {
  const href = CKEditorUtils.getFirstParentHref(clickedViewElement);
  if (!href) {
    return;
  }
  handleLinkClick(href, setAttachmentForPreview);
};

const handleLinkClick = (
  href: string,
  setAttachmentForPreview: (id: string) => void,
): void => {
  const attachmentId = extractAttachmentIdFromHref(href);
  if (attachmentId) {
    setAttachmentForPreview(attachmentId);
  } else {
    // open links that are not inline attachments in a new tab in comment- or read-only mode
    window.open(href, '_blank');
  }
};

export const setupEditorConnectionClosedHandler = (
  context: Context,
  setDidReceiveDisconnectionEvent: (didReceive: boolean) => void,
) => {
  const webSocketGateway = context.plugins.get('WebSocketGateway') as any;
  webSocketGateway.on(
    'change:state',
    (eventInfo: any, _name: any, value: any, _oldValue: any) => {
      if (!eventInfo?.source?._isBrowserOffline && value === 'disconnected') {
        setDidReceiveDisconnectionEvent(true);
      }
    },
  );
};

function configureAutosaveStateHandler(
  editor: CKEditorInstance,
  setEditorStatus: React.Dispatch<SetStateAction<EditorStatus>>,
) {
  editor.plugins
    .get('Autosave')
    .on('change:state', (evt, propertyName, newValue) => {
      if (newValue === 'waiting') {
        setEditorStatus(EditorStatus.PENDING_AUTOSAVE);
      } else if (newValue === 'saving') {
        setEditorStatus(EditorStatus.SAVING);
      } else if (newValue === 'synchronized') {
        setEditorStatus((previousValue) => {
          if (previousValue !== EditorStatus.ERROR) {
            return EditorStatus.SAVED;
          }
          return previousValue;
        });
      }
    });
}

export const editorReadyHandler = (
  editor: CKEditorInstance,
  toolbarManager: ToolbarManager,
  setEditorStatus: React.Dispatch<SetStateAction<EditorStatus>>,
  isUpdateSmartlinkTitlesEnabled = false,
) => {
  try {
    if (isUpdateSmartlinkTitlesEnabled) {
      void updateOutdatedSmartLinkTitlesV2(editor);
    }
    setCommentsOnlyModeTo([editor], true);
    toolbarManager.configureEditorToShowToolbarOnFocus(editor);
    toolbarManager.hideToolbar(editor);
    CKEditorUtils.configureEditorDelete(editor);
    enableUploadAdapter(editor);
    configureQualioPluginsForTrackChanges(editor);
    mathCharactersExtended(editor);
    editor.model.schema.setAttributeProperties('htmlAttributes', {
      isFormatting: true,
    });
    configureBalloonPanels(editor);
    configureAutosaveStateHandler(editor, setEditorStatus);
  } catch (error) {
    logCustomError(EDITOR_READY_ERROR, {
      error,
    });
  }
};

export const enableCKEditorMode = (
  mode: EditorMode,
  editors: CKEditorInstance[],
  toolbarManager: ToolbarManager,
  inEditModeCallback: (inEditMode: boolean) => void,
) => {
  const inEditMode = mode !== EditorMode.VIEW;

  toggleCheckboxMode(mode === EditorMode.EDIT);
  setCommentsOnlyModeTo(editors, !inEditMode);
  inEditModeCallback(inEditMode);

  if (inEditMode) {
    // focus and show first editor
    editors[0].focus();
    toolbarManager.setToolbarFromEditor(editors[0]);
    toggleTrackChanges(editors, mode);
  } else {
    editors.forEach((editor) => {
      toolbarManager.hideToolbar(editor);
    });
  }
};

function mathCharactersExtended(editor: CKEditorInstance): void {
  const scp = editor.plugins.get('SpecialCharacters');
  scp.addItems('Mathematical', [{ title: 'delta', character: 'Δ' }]);
}

function isHTMLElementArray(
  value: Rect | HTMLElement[],
): value is HTMLElement[] {
  return (
    Array.isArray(value) && value.every((item) => item instanceof HTMLElement)
  );
}

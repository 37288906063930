import { z } from 'zod';
import { QualioDocument } from '../../../api/model/document';
import { Tag } from '../../../api/model/tag';

const MedtechUserVisibilityErrorData = z.object({
  id: z.number(),
  full_name: z.string(),
  documents: z.custom<QualioDocument>().array(),
});

const MedtechDocsVisibilityChangedErrorData = z.object({
  documents_visibility_changed: z.number().array(),
  documents_hidden_from_authors: z
    .object({
      users: MedtechUserVisibilityErrorData.array(),
    })
    .optional(),
});

export const VisibilityErrorEnum = z.enum([
  'documents_visibility_changed',
  'authors_lost_docs_and_docs_visibility_changed',
]);

const MedtechDocsVisibilityChangedErrorResponseData = z
  .object({
    slug: VisibilityErrorEnum,
    error_data: MedtechDocsVisibilityChangedErrorData,
  })
  .passthrough();

export const MedtechDocsVisibilityChangedErrorResponse = z
  .object({
    response: z.object({
      data: MedtechDocsVisibilityChangedErrorResponseData,
    }),
  })
  .transform(({ response: { data } }) => data);

export type MedtechDocsVisibilityChangedError = z.infer<
  typeof MedtechDocsVisibilityChangedErrorData
>;

export type MedtechUserVisiblityError = z.infer<
  typeof MedtechUserVisibilityErrorData
>;

export type TagId = Tag['id'];

export type VisibilityError = z.infer<typeof VisibilityErrorEnum>;

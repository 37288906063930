import {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { genericApplicationErrorMessage } from '../../../messages/UserErrorMessages';
import { CKEditorInstance } from '../../../types/CKEditorInstance';

interface ErrorState {
  hasError: boolean;
  errorMsg?: string | null;
}

interface ErrorContextValue extends ErrorState {
  updateHasError: (
    hasError: boolean,
    errorMsg?: string | null,
    notRecoverable?: boolean,
    sectionEditor?: CKEditorInstance,
    isForceDisplay?: boolean,
  ) => void;
}

const ErrorHandlerContext = createContext<ErrorContextValue>({
  hasError: false,
  updateHasError: () => null,
});

export const useAsyncError = () => {
  // eslint-disable-next-line
  const [_, setError] = useState();
  return useCallback(
    (e) => {
      setError(() => {
        throw e;
      });
    },
    [setError],
  );
};

export const ErrorHandlerProvider: FC = ({ children }) => {
  const [errorState, setErrorStateState] = useState<ErrorState>({
    hasError: false,
  });
  const throwAsyncError = useAsyncError();

  const updateHasError = useCallback(
    (
      hasErrorUpdate: boolean,
      errorMsgUpdate?: string | null,
      notRecoverable?: boolean,
      _sectionEditor?: CKEditorInstance,
      isForceDisplay?: boolean,
    ) => {
      if (notRecoverable) {
        throwAsyncError(new Error(genericApplicationErrorMessage));
      } else if (
        errorState.hasError !== hasErrorUpdate ||
        errorState.errorMsg !== errorMsgUpdate
      ) {
        setErrorStateState({
          hasError: hasErrorUpdate,
          errorMsg: errorMsgUpdate,
        });
      } else if (isForceDisplay) {
        // Handle when we want to re-render an error
        setErrorStateState({ hasError: false });
        setErrorStateState({
          hasError: hasErrorUpdate,
          errorMsg: errorMsgUpdate,
        });
      }
    },
    [errorState, throwAsyncError],
  );

  window.addEventListener(
    'unhandledrejection',
    function (promiseRejectionEvent) {
      // We prevent the error thrown when we navigate away from the editor to bubble up.
      // The error is thrown by the editor watchdog, to which we don't have access from
      // the react component version of CKEditor, so our only option is to prevent the error event
      // from bubbling up
      if (
        promiseRejectionEvent?.reason?.message ===
        "Cannot read properties of null (reading 'model')"
      ) {
        promiseRejectionEvent.preventDefault();
      }
    },
  );

  const errorContextValue = useMemo(
    () => ({ ...errorState, updateHasError }),
    [errorState, updateHasError],
  );

  return (
    <ErrorHandlerContext.Provider value={errorContextValue}>
      {children}
    </ErrorHandlerContext.Provider>
  );
};

export const useErrorHandler = () => {
  const { hasError, updateHasError, errorMsg } =
    useContext(ErrorHandlerContext);

  return { hasError, updateHasError, errorMsg };
};

import { useToastProvider } from '@qualio/ui-components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { changeControlApi } from '../../../../../../api/changeControl';
import { documentApi } from '../../../../../../api/document';
import {
  DocumentStatus,
  QualioDocument,
} from '../../../../../../api/model/document';
import { isIssueRelated } from '../../../../DocumentOverview/RenderLogic';
import { WorkspaceTabs } from '../../../types';
import {
  hasAllowedStatusesForAction,
  hasRequiredPermissionsForAction,
} from '../../BulkActions';
import { BulkActionButton } from '../BulkActionButton';
import { IsActionAllowedCallback } from '../ChangeOwnerButton/types';
import { CreateNewDraftProps } from './types';

export const CreateNewDraftButton = (props: CreateNewDraftProps) => {
  const { showToast } = useToastProvider();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleCreateNewDraft = async () => {
    setIsLoading(true);
    let newDraft: QualioDocument;
    let ccTab = '';
    try {
      newDraft = await documentApi.createDraftFromEffectiveDoc(
        props.currentUser.companyId,
        props.selectedDocuments[0].document_matrix_id,
      );
    } catch (e: any) {
      const failureMessage =
        e.response?.data?.message ||
        'Failed to create new draft. Please try again.';
      showToast({
        id: 'create-new-draft-error-toast',
        status: 'error',
        title: 'Error',
        description: failureMessage,
      });
      setIsLoading(false);
      return;
    }

    try {
      const changeControlTemplates =
        await changeControlApi.fetchChangeControlTemplates();
      if (changeControlTemplates && changeControlTemplates.length > 0) {
        const changeControlTemplate = changeControlTemplates[0];
        await changeControlApi.createChangeControl(
          newDraft,
          changeControlTemplate.id as number,
          { sections: changeControlTemplate.sections },
        );
      }
    } catch (e: any) {
      ccTab = '&tab=change-control';
    }

    navigate(`/workspace/documents/${newDraft.id}?intent=edit${ccTab}`);
  };

  const canCreateNewDraft: IsActionAllowedCallback = (
    user,
    statuses,
    activeTab,
    requiredPermissions,
    selectedDocuments,
  ) => {
    if (
      !selectedDocuments.length ||
      selectedDocuments.length > 1 ||
      !hasRequiredPermissionsForAction(requiredPermissions, user.permissions) ||
      isIssueRelated(selectedDocuments[0])
    ) {
      return false;
    }

    if (activeTab === WorkspaceTabs.YOUR_DOCUMENTS) {
      return hasAllowedStatusesForAction(
        [
          DocumentStatus.Approved,
          DocumentStatus.Effective,
          DocumentStatus.For_Retirement,
          DocumentStatus.Retired,
          DocumentStatus.For_Periodic_Review,
        ],
        statuses,
      );
    }
    return hasAllowedStatusesForAction(
      [
        DocumentStatus.Approved,
        DocumentStatus.Effective,
        DocumentStatus.For_Periodic_Review,
      ],
      statuses,
    );
  };

  return (
    <BulkActionButton
      {...props}
      isActionAllowed={canCreateNewDraft}
      onClick={handleCreateNewDraft}
      isLoading={isLoading}
    />
  );
};

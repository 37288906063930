export const MigrationStatusValueEnum = {
  ERROR: 'ERROR',
  DRAFT: 'DRAFT',
  PREPARING: 'PREPARING',
  IN_REVIEW: 'IN_REVIEW',
  SCHEDULED: 'SCHEDULED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE',
} as const;

export const MigrationStatusLabelMap = {
  [MigrationStatusValueEnum.ERROR]: 'Error',
  [MigrationStatusValueEnum.DRAFT]: 'Draft',
  [MigrationStatusValueEnum.PREPARING]: 'Preparing',
  [MigrationStatusValueEnum.IN_REVIEW]: 'In Review',
  [MigrationStatusValueEnum.SCHEDULED]: 'Scheduled',
  [MigrationStatusValueEnum.IN_PROGRESS]: 'In Progress',
  [MigrationStatusValueEnum.COMPLETE]: 'Complete',
} as const;

export const MigrationFinishedStatusLabelMap = {
  [MigrationStatusValueEnum.ERROR]:
    MigrationStatusLabelMap[MigrationStatusValueEnum.ERROR],
  [MigrationStatusValueEnum.COMPLETE]:
    MigrationStatusLabelMap[MigrationStatusValueEnum.COMPLETE],
} as const;

export const MigrationNotStartedStatusLabelMap = {
  [MigrationStatusValueEnum.DRAFT]:
    MigrationStatusLabelMap[MigrationStatusValueEnum.DRAFT],
  [MigrationStatusValueEnum.PREPARING]:
    MigrationStatusLabelMap[MigrationStatusValueEnum.PREPARING],
  [MigrationStatusValueEnum.IN_REVIEW]:
    MigrationStatusLabelMap[MigrationStatusValueEnum.IN_REVIEW],
} as const;

export type MigrationStatusValue = keyof typeof MigrationStatusValueEnum;

export enum MigrationStatus {
  'ERROR' = -1,
  'DRAFT' = 0,
  'PREPARING' = 1,
  'IN_REVIEW' = 2,
  'SCHEDULED' = 3,
  'IN_PROGRESS' = 4,
  'COMPLETE' = 5,
}

export type MigrationDTO = {
  id: string;
  name: string;
  progress: number;
  startedAt: string | null;
  completedAt: string | null;
  status: keyof typeof MigrationStatusValueEnum;
  indexFileDownloadURL: string | null;
  changeControlStatement: string;
  logs?: MigrationError[];
};

export type MigrationError = {
  type?: string;
  filePath?: string;
  fileId?: string;
  error: string;
};

export type MigrationSummaryDTO = {
  id: string;
  name: string;
  changeControlStatement: string;
  progress: number;
  status: number;
  startedAt: string | null;
  createdAt: string | null;
  completedAt: string | null;
};

export const mapSummaryDtoToMigrationDto = (
  m: MigrationSummaryDTO,
): MigrationDTO => ({
  ...m,
  indexFileDownloadURL: '',
  status: MigrationStatus[m.status] as keyof typeof MigrationStatusValueEnum,
});

export enum UploadFileStatus {
  Error,
  Pending,
  InProgress,
  Success,
}

export declare const COMMON_MIME_TYPES: Map<string, string>;

export declare function toFileWithPath(
  file: FileWithPath,
  path?: string,
): FileWithPath;

export type ErrorType = {
  key: string;
  message: string;
};

export type ApiUserDTO = {
  permissions: string[];
  full_name: string;
  email: string;
  invite_status: string;
};

export type ApiApprover = {
  fullName: string;
  email: string;
  isQM: boolean;
};

export type ApiOwner = {
  fullName: string;
  email: string;
};

export interface FileWithPath extends File {
  readonly path?: string;
}

export type ApiTag = {
  id: number;
  group?: number[];
  name: string;
};

export type UploadFile = {
  file: FileWithPath;
  path: string;
  migrationId?: string;
  template?: string;
  status: UploadFileStatus;
  error?: ErrorType;
};

export type MigrationFile = {
  id: string;
  ordinal: number;
  migrationID: string;
  template: string;
  templateOrdinal?: number;
  templateID?: number;
  templateKey?: string;
  filename: string;
  isDocumentContent: boolean;
  bulkApprove: boolean;
  approvers: ApiApprover[];
  owner: ApiOwner;
  parentDocument?: string;
  hash: string;
  isPlaceholder?: boolean;
  tags?: ApiTag[];
  doNotImport: boolean;
  size?: number;
};

export type FileUri = {
  fileHash: string;
  url: string;
};

export type FileUpdates = {
  [key in keyof MigrationFile as string]:
    | number
    | string
    | boolean
    | ApiApprover[]
    | ApiOwner
    | null
    | undefined;
};

export type FileUpdate = {
  fileId: string;
  original?: MigrationFile;
  properties: FileUpdates;
};

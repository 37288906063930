import {
  QBox,
  QPerson,
  QStack,
  QTag,
  QTagProps,
  QText,
  useCurrentUser,
} from '@qualio/ui-components';
import {
  Approval,
  PeriodicReviewDetails,
  PeriodicReviewDetailsStatus,
} from './types';

const statusToColorMap = {
  [PeriodicReviewDetailsStatus.approved]: 'purple',
  [PeriodicReviewDetailsStatus.declined]: 'red',
  [PeriodicReviewDetailsStatus.in_progress]: 'blue',
};

export const mapStatus = (approvalStatus: Approval['approved']) => {
  if (approvalStatus) {
    return 'approved';
  } else if (approvalStatus === false) {
    return 'declined';
  } else {
    return 'default';
  }
};

export const ReviewDetails = ({
  details,
}: {
  details?: PeriodicReviewDetails;
}) => {
  const currentUser = useCurrentUser();

  if (!details) {
    return null;
  }

  return (
    <QStack direction="column" spacing="4">
      <QStack direction="row">
        <QText weight="semibold" fontSize="sm" width="50%">
          Status
        </QText>
        <QBox fontSize="sm" width="50%">
          <QTag
            variantColor={
              statusToColorMap[details.status] as QTagProps['variantColor']
            }
          >
            {details.status}
          </QTag>
        </QBox>
      </QStack>

      <QStack direction="row">
        <QText weight="semibold" fontSize="sm" width="50%">
          Sent for approval by
        </QText>
        <QText fontSize="sm" width="50%">
          {details.sent_by}
        </QText>
      </QStack>

      <QStack direction="row">
        <QText weight="semibold" fontSize="sm" width="50%">
          Sent for approval at
        </QText>
        <QText fontSize="sm" width="50%">
          {details.sent_at}
        </QText>
      </QStack>

      <QStack direction="row">
        <QText weight="semibold" fontSize="sm" width="50%">
          Needs updating
        </QText>
        <QText fontSize="sm" width="50%">
          No
        </QText>
      </QStack>

      <QStack direction="row">
        <QText weight="semibold" fontSize="sm" width="50%">
          Comment
        </QText>
        <QText fontSize="sm" width="50%">
          {details.comment}
        </QText>
      </QStack>

      <QStack direction="row">
        <QText weight="semibold" fontSize="sm" width="50%">
          Review Period
        </QText>
        <QText fontSize="sm" width="50%">
          {details.review_period}
        </QText>
      </QStack>

      <QStack direction="row">
        <QText weight="semibold" fontSize="sm" width="50%">
          Approvals
        </QText>
        <QStack fontSize="sm" width="50%" spacing={1}>
          {details.approvals.map((approval) => {
            return (
              <QPerson
                key={`${approval.user.id}-${approval.timestamp}`}
                status={mapStatus(approval.approved)}
                fullName={approval.user.full_name}
                actionTimestamp={
                  approval.timestamp
                    ? currentUser.formatDate(
                        new Date(approval.timestamp * 1000),
                        true,
                      )
                    : undefined
                }
              />
            );
          })}
        </QStack>
      </QStack>
    </QStack>
  );
};

import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  QButton,
  QCenter,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';

import { documentApi } from '../../../../../api/document';

import { CredentialConfirmationModal } from '../../../CredentialConfirmationModal/index';
import { useTrainingAssessment } from '../../../TrainingAssessment/hooks/useTrainingAssessment';
import { TrainingAssessment } from '../../../TrainingAssessment/types/trainingAssessment';

import { DocumentOverviewContext } from '../../Context';
import { shouldRenderCompleteTraining } from '../../RenderLogic';
import { useNavigateAfterTraining } from '../hooks';

const hasTrainingAssessment = (trainingAssessment?: TrainingAssessment) => {
  if (!trainingAssessment) {
    return false;
  } else {
    return Boolean(trainingAssessment?.questions?.length);
  }
};

export const DocumentTrainingButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const navigateAfterTraining = useNavigateAfterTraining();
  const { showToast } = useToastProvider();
  const { qualioDocument, currentEditorMode } = useContext(
    DocumentOverviewContext,
  );
  const {
    data: trainingAssessment,
    isLoading,
    isError,
  } = useTrainingAssessment(qualioDocument.id);

  const handleStartTrainingAssessment = () => {
    const basePath = location.pathname.split('/training')[0];
    navigate(`${basePath}/training/assessment`);
  };

  if (
    !shouldRenderCompleteTraining(
      qualioDocument,
      currentUser,
      currentEditorMode,
    )
  ) {
    return null;
  }

  const handleTraining = () => {
    if (!hasTrainingAssessment(trainingAssessment)) {
      setIsModalOpen(true);
    } else {
      handleStartTrainingAssessment();
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmTraining = async (email: string, password: string) => {
    const emptyAssessment = !trainingAssessment
      ? undefined
      : {
          documentId: qualioDocument.id,
          questions: trainingAssessment.questions,
          id: trainingAssessment.id,
        };
    try {
      await documentApi.signOffTraining(
        qualioDocument.id,
        email,
        password,
        emptyAssessment,
      );
      showToast({
        id: 'training-sign-off-success',
        description: 'Successfully completed required training.',
        status: 'success',
        title: 'Training complete!',
        replace: true,
      });
      navigateAfterTraining();
    } catch (error: unknown) {
      throw error;
    }
  };

  const handleSignOffError = () => {
    showToast({
      id: 'training-assessment-sign-off-error',
      title: 'Error',
      description: 'Could not sign off assessment. Please try again',
      status: 'error',
      replace: true,
    });
  };

  const buttonText = hasTrainingAssessment(trainingAssessment)
    ? 'Start training assessment'
    : 'Complete training';

  return (
    <>
      <QCenter mt={6}>
        <QButton
          isLoading={isLoading}
          isDisabled={isError}
          variant="solid"
          onClick={handleTraining}
        >
          {buttonText}
        </QButton>
      </QCenter>
      <CredentialConfirmationModal
        isOpen={isModalOpen}
        onCloseModal={handleCloseModal}
        handleStatusChange={handleConfirmTraining}
        modalHeading="Complete training"
        modalText="Enter your digital signature to confirm you have been trained."
        buttonActionLabel="Sign off"
        withComment={false}
        handleError={handleSignOffError}
      />
    </>
  );
};

import { useEffect, useState } from 'react';

export const useElementIsRendered: any = (selector: string) => {
  const [element, setElement] = useState<Element | null>(null);

  useEffect(() => {
    const element = document.querySelector(selector);
    if (element !== null) {
      setElement(element);
    }
  }, [selector]);

  return [element !== null, element];
};

import * as React from 'react';
import { genericApplicationErrorMessage } from '../messages/UserErrorMessages';
import styles from './ErrorBoundary.module.css';

type State = {
  hasError: boolean;
  e: any;
};

type Props = unknown;

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, e: undefined };
  }

  static getDerivedStateFromError(e: any): State {
    return { hasError: true, e: e };
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      if (this.state.e) {
        console.error(this.state.e);
      }
      return (
        <div className={styles['document-editor__error-boundary']}>
          <div className="locked">
            <i className="icon ion-alert-circled"></i>&nbsp;{' '}
            {genericApplicationErrorMessage}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

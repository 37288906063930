import React, { useEffect, useState } from 'react';

import { QAttachment } from '@qualio/ui-components';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import {
  QualioDocument,
  QualioDocumentAttachment,
} from '../../../api/model/document';

import { tokenApi } from '../../../api/token';
import { MedtechUserV1, userApi } from '../../../api/user';
import { getDocumentEditorGlobalWindowObject } from '../../../util/AppUtils';
import { getFileAttachmentFromFileUploadDocument } from '../../../util/FileUploadUtils';
import {
  setElementDisabledState,
  toggleDisableClass,
  toggleFakeLinkDisableClass,
} from '../../../util/HTMLElementUtils';
import { FileDocumentCommentsWrapper } from '../FileDocumentPreviewer/FileDocumentCommentsWrapper';
import { FileDocumentPreviewer } from '../FileDocumentPreviewer/FileDocumentPreviewer';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { FileDocumentCustomUploadPanel } from './FileDocumentCustomUploadPanel';
import styles from './FileDocumentEditor.module.css';

export const FileDocumentEditor: React.FC<{
  currentUser: CurrentUser;
  fileDocument: QualioDocument;
  isOwner: boolean;
  commentsPermission: number;
  handleSessionDisconnectionEvent?: (didReceive: boolean) => void;
}> = ({
  currentUser,
  fileDocument,
  isOwner,
  commentsPermission,
  handleSessionDisconnectionEvent,
}) => {
  const [attachments, setAttachments] =
    useState<(QualioDocumentAttachment | QAttachment)[]>();
  const [user, setUser] = useState<MedtechUserV1>();
  const [collaborationToken, setCollaborationToken] = useState<string>();

  useEffect(() => {
    const loadCollaborationToken = async (permissions: number) => {
      const token = await tokenApi.fetch({ comments: permissions });
      setCollaborationToken(token);
    };
    void loadCollaborationToken(commentsPermission);
  }, [currentUser, commentsPermission]);

  // indicate to medtech-frontend that the editor is loaded to enable the export/edit buttons
  getDocumentEditorGlobalWindowObject().loaded = true;

  useEffect(() => {
    const fileAttachment =
      getFileAttachmentFromFileUploadDocument(fileDocument);
    if (fileAttachment) {
      setAttachments([fileAttachment]);
    } else {
      setAttachments([]);
    }
  }, [fileDocument]);

  useEffect(() => {
    void userApi.fetchUser(currentUser.userId).then(setUser);
  }, [currentUser.userId]);

  const exportButton = document.getElementById('documentEditorExport');
  const convertButton = document.getElementById('documentConversion');
  const isDraft = fileDocument.status_id === 'draft';
  if (!attachments || attachments.length === 0) {
    toggleDisableClass(exportButton, true);
    setElementDisabledState(convertButton, true);
    toggleFakeLinkDisableClass(convertButton, true);
  }

  if (attachments === undefined || !user || !collaborationToken) {
    return <LoadingSpinner />;
  }

  return (
    <div
      id="fileUploadEditor"
      data-cy="fileUploadEditor"
      className={styles['file-upload-editor']}
    >
      {isOwner && isDraft && (
        <FileDocumentCustomUploadPanel
          document={fileDocument}
          attachments={attachments as unknown as QualioDocumentAttachment[]}
          setAttachments={setAttachments}
          currentUser={currentUser}
        />
      )}
      {(!isOwner || !isDraft) && attachments.length === 0 && (
        <p className={styles['file-preview-placeholder']}>
          Upload a file to preview
        </p>
      )}
      <div hidden id={'commentsAnchor'}></div>
      {attachments.length === 0 && (
        <div
          id={'filePreviewerWrapper'}
          className={styles['file-document-editor__preview-wrapper']}
        >
          <FileDocumentCommentsWrapper
            currentUser={currentUser}
            fileDocument={fileDocument}
            collaborationToken={collaborationToken}
            commentsPermission={commentsPermission}
            handleSessionDisconnectionEvent={handleSessionDisconnectionEvent}
          >
            <div
              id={'fileWrapper'}
              className={styles['file-document-editor__preview-placeholder']}
            ></div>
          </FileDocumentCommentsWrapper>
        </div>
      )}
      {attachments.length > 0 && (
        <FileDocumentPreviewer
          currentUser={currentUser}
          attachmentId={String(attachments[0].id)}
          attachmentFilename={attachments[0].filename}
          showDownloadButton={shouldShowDownloadButton(user, fileDocument)}
          collaborationToken={collaborationToken}
          commentsPermission={commentsPermission}
          fileDocument={fileDocument}
          handleSessionDisconnectionEvent={handleSessionDisconnectionEvent}
        />
      )}
    </div>
  );
};

export const shouldShowDownloadButton = (
  user: MedtechUserV1,
  document: QualioDocument,
): boolean => {
  const userCompany = user.companies.find(
    (userCompany) => userCompany.id === document.company_id,
  );
  if (!userCompany) {
    return false;
  }

  const isOwner = document.owner.id === user.id;
  if (isOwner) {
    return true;
  }

  for (const documentUserGroup of document.file_download_user_groups ?? []) {
    if (userCompany.usergroups.includes(documentUserGroup)) {
      return true;
    }
  }
  return false;
};

import { useQuery } from 'react-query';

import { useCurrentUser, useToastProvider } from '@qualio/ui-components';

import { documentApi } from '../../../../../api/document';

export const usePeriodicReview = (periodicReviewId: number | undefined) => {
  const user = useCurrentUser();
  const { showToast } = useToastProvider();
  return useQuery(
    ['getPeriodicReviewById', periodicReviewId],
    () =>
      documentApi.getPeriodicReviewByReviewId(
        user.companyId,
        periodicReviewId as number,
      ),
    {
      enabled: Boolean(periodicReviewId),
      onError: () => {
        showToast({
          status: 'error',
          title: 'Error',
          description: 'Failed to load periodic review. Please try again.',
        });
      },
    },
  );
};

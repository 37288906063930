import { useSearchParams } from 'react-router-dom';

import { useCurrentUser } from '@qualio/ui-components';

import { backendTaskApi } from '../../../api/backendTask';
import { ExportDocumentListButton } from '../Export/ExportDocumentListButton/ExportDocumentListButton';
import { FOR_TRAINING_STATUS, WorkspaceTabProps, WorkspaceTabs } from './types';
import { getDefaultStatus } from './utils';

export type ExportButtonWrapperProps = {
  currentTab: WorkspaceTabs | null;
  currentTabConfig: WorkspaceTabProps;
  isReadyForExport: boolean;
};

export const ExportButtonWrapper = ({
  currentTabConfig,
  currentTab,
  isReadyForExport,
}: ExportButtonWrapperProps) => {
  const currentUser = useCurrentUser();
  const [searchParams] = useSearchParams();
  const tagsParams = searchParams.get('filteringTag');
  const currentTagsFilter = tagsParams !== null ? tagsParams.split(',') : [];
  const searchQuery = searchParams.get('query');
  const sortingValue = searchParams.get('order_by');
  const statusParam = searchParams.get('subfilter');
  const selectedStatus =
    statusParam ?? getDefaultStatus(currentTab, currentUser);
  const { statusFilters } = currentTabConfig.statusFiltersMap[selectedStatus];
  const exportTaskMethod =
    selectedStatus === FOR_TRAINING_STATUS
      ? backendTaskApi.startUserTrainingDocumentsExportTask
      : currentTabConfig.defaultExportTaskMethod;

  return (
    <ExportDocumentListButton
      currentUser={currentUser}
      tagsFilter={currentTagsFilter}
      isDisabled={!isReadyForExport}
      searchQuery={searchQuery}
      exportTaskMethod={exportTaskMethod}
      statusFilters={statusFilters}
      sortingValue={sortingValue}
    />
  );
};

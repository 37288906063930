import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  Filtering,
  QBox,
  QSelect,
  QSelectItem,
  useCurrentUser,
} from '@qualio/ui-components';

export const FOR_TRAINING_FILTER = 'for_training';
export const ALL_DOCS_FILTER = 'all_docs';

const disabledTrainingFilterOption = {
  label: 'All documents',
  value: ALL_DOCS_FILTER,
};
const enabledTrainingFilterOption = {
  label: 'Training documents',
  value: FOR_TRAINING_FILTER,
};
const trainingFilterOptions: QSelectItem[] = [
  disabledTrainingFilterOption,
  enabledTrainingFilterOption,
];

export const TrainingFilter = () => {
  const currentUser = useCurrentUser();
  const [searchParams] = useSearchParams();
  const filterBy = searchParams.get('filter_by') ?? undefined;
  const [trainingFilter, setTrainingFilter] = useState<string | undefined>(
    filterBy,
  );
  const filtering = Filtering.useFiltering();

  const onTrainingFilterChange = (option: QSelectItem | null) => {
    if (option !== null) {
      setTrainingFilter(option.value);
      filtering.setFilters({ filter_by: option.value });
    }
  };

  if (currentUser.permissions.can_view_workspace) {
    return null;
  }

  return (
    <QBox width={'25%'}>
      <QSelect
        size="sm"
        options={trainingFilterOptions}
        onChange={onTrainingFilterChange}
        value={trainingFilter}
        data-cy={'library-training-filter'}
        aria-label="Training filter"
      />
    </QBox>
  );
};

export const getHTMLElement = (selector: string): HTMLElement | null => {
  const element = document.querySelector(selector) as HTMLElement;
  if (!element) {
    console.warn('Could not find element with selector: ' + selector);
  }
  return element;
};

export const toggleDisableClass = (
  element: HTMLElement | null,
  disable: boolean,
) => {
  if (element === null) {
    return;
  }

  if (disable) {
    element.classList.add('disabled');
  } else {
    element.classList.remove('disabled');
  }
};

export const setElementDisabledState = (
  element: HTMLElement | null,
  disabled: boolean,
): void => {
  if (disabled) {
    element?.setAttribute('disabled', '');
  } else {
    element?.removeAttribute('disabled');
  }
};

export const toggleFakeLinkDisableClass = (
  element: HTMLElement | null,
  disable: boolean,
) => {
  if (element === null) {
    return;
  }

  if (disable) {
    element.classList.add('fake-link-disabled');
  } else {
    element.classList.remove('fake-link-disabled');
  }
};

export const calculatePageOffsetY = (element: HTMLElement): number => {
  let offsetY = 0;
  let el: HTMLElement | null = element;
  while (el !== null) {
    offsetY += el.offsetTop;
    el = el.offsetParent as HTMLElement;
  }
  return offsetY;
};

export const parseHTMLString = (htmlStr: string) => {
  const parser = new DOMParser();
  return parser.parseFromString(htmlStr, 'text/html');
};

export const toDocumentFragment = (doc: Document) => {
  const fragment = doc.createDocumentFragment();
  const bodyChildNodes = doc.body.childNodes;

  while (bodyChildNodes.length > 0) {
    fragment.appendChild(bodyChildNodes[0]);
  }

  return fragment;
};

export const removeTablesWidth = (doc: Document) => {
  const tables = doc.querySelectorAll('table');
  const tds = doc.querySelectorAll('td');

  tables.forEach((table) => {
    table.style.removeProperty('width');
  });

  tds.forEach((td) => {
    td.style.removeProperty('width');
  });
};

export const removeStyleTags = (doc: Document) => {
  const styles = doc.querySelectorAll('style');

  styles.forEach((style) => {
    style.remove();
  });
};

export const extractAttachmentIdFromHref = (
  href: string,
): string | undefined => {
  const regex = /\/attachments\/(\d+)/;
  let m;
  let attachmentId;

  if ((m = regex.exec(href)) !== null) {
    // access the capture group
    attachmentId = m[1];
  }

  return attachmentId;
};

import { useContext, useState } from 'react';

import { useCurrentUser } from '@qualio/ui-components';
import { documentApi } from '../../../../../../api/document';
import { ButtonGroupContext } from '../../../../Contexts/ButtonGroupContext';
import { DocumentCollaborationSessionDeleteModal } from '../../../../DocumentCollaborationSessionDeleteModal/index';
import { DocumentOverviewContext } from '../../../Context';
import { shouldRenderResetEditSessionButton } from '../../../RenderLogic';

type ResetEditorSessionButtonProps = {
  renderClickableItem: (onClick: () => void) => JSX.Element;
};

export const ResetEditorSessionButton = ({
  renderClickableItem,
}: ResetEditorSessionButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentUser = useCurrentUser();
  const { qualioDocument } = useContext(DocumentOverviewContext);
  const { setDisabled } = useContext(ButtonGroupContext);

  const resetSession = async () => {
    await documentApi.deleteDocumentCollaborationSession(
      qualioDocument.company_id,
      qualioDocument.id,
      true,
    );
  };

  if (!shouldRenderResetEditSessionButton(qualioDocument, currentUser)) {
    return null;
  }

  return (
    <>
      {renderClickableItem(() => {
        setDisabled(true);
        setIsModalOpen(true);
      })}
      <DocumentCollaborationSessionDeleteModal
        isOpen={isModalOpen}
        onClose={() => setDisabled(false, setIsModalOpen)}
        doResetSession={resetSession}
      />
    </>
  );
};

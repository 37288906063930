import MarkerCollection, {
  Marker,
} from '@ckeditor/ckeditor5-engine/src/model/markercollection';
import { EventInfo } from '@ckeditor/ckeditor5-utils';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { documentApi } from '../../api/document';
import { retryOnError } from '../../util/APIUtils';
import { formTargetsFromMarker } from './eventHandlerUtil';

const RETRIES = 3;

/**
 * @description Handles events for removing/resolving comment threads
 */
export function handleRemoveCommentThread(
  channelId: string,
  threadId: string,
  companyId: number,
  documentId: number,
) {
  documentApi
    .setDeletedByUserCommentThreads(companyId, documentId, threadId, channelId)
    .catch((error) => {
      retryOnError(
        () =>
          documentApi.setDeletedByUserCommentThreads(
            companyId,
            documentId,
            threadId,
            channelId,
          ),
        200,
        error,
        RETRIES,
      );
    });
}

export function handleComment(
  evt: EventInfo,
  commentData: CommentsRepository.CommentData,
  markerCollection: MarkerCollection,
  currentUser: CurrentUser,
) {
  if (commentData.isFromAdapter) {
    return; // this is a comment loaded from the backend
  }
  setAuthorNameAttribute(commentData, currentUser);
  const commentIsFirstInThread =
    (evt.source as any).getCommentThread(commentData.threadId)?.comments
      .length === 0;
  const commentMarker = getCommentMarker(
    markerCollection,
    commentData.threadId,
  );
  const suggestionMarker = getSuggestionMarker(
    markerCollection,
    commentData.threadId,
  );
  if (commentMarker && commentIsFirstInThread) {
    setContentCommentedOnAttribute(commentData, commentMarker);
  }
  if (suggestionMarker) {
    setSuggestionIdAttribute(commentData);
  }
}

export function handleAddFileDocumentComment(
  evt: EventInfo,
  commentData: CommentsRepository.CommentData,
  currentUser: CurrentUser,
  filename: string,
): void {
  const isFirstCommentInThread =
    (evt.source as any).getCommentThread(commentData.threadId)?.comments
      .length === 0;
  setAuthorNameAttribute(commentData, currentUser);
  if (isFirstCommentInThread) {
    setFileCommentedOnAttribute(commentData, filename);
  }
}

function setAuthorNameAttribute(
  commentData: CommentsRepository.CommentData,
  currentUser: CurrentUser,
) {
  if (commentData.attributes === undefined) {
    commentData.attributes = {
      authorFullName: currentUser.fullName,
    };
  } else {
    commentData.attributes.authorFullName = currentUser.fullName;
  }
}

function setContentCommentedOnAttribute(
  commentData: CommentsRepository.CommentData,
  marker: Marker,
) {
  let commentTargets: CommentsRepository.Target[] = [];
  commentTargets = commentTargets.concat(formTargetsFromMarker(marker));
  commentData.attributes.contentCommentedOn = commentTargets;
}

function setFileCommentedOnAttribute(
  commentData: CommentsRepository.CommentData,
  filename: string,
) {
  // There are no markers available on file document comments,
  // so just set the target to the filename
  commentData.attributes.contentCommentedOn = [
    {
      name: 'text',
      data: filename,
    },
  ];
}

function setSuggestionIdAttribute(commentData: CommentsRepository.CommentData) {
  commentData.attributes.suggestionId = commentData.threadId;
}

function getSuggestionMarker(
  markerCollection: MarkerCollection,
  threadId: string,
): Marker | undefined {
  let suggestionMarker;
  for (const suggestionMarkers of [
    markerCollection.getMarkersGroup('suggestion'),
  ]) {
    for (const marker of suggestionMarkers) {
      if (marker.name.indexOf(threadId) > -1) {
        suggestionMarker = marker;
      }
    }
  }
  return suggestionMarker;
}

function getCommentMarker(
  markerCollection: MarkerCollection,
  threadId: string,
): Marker | undefined {
  let commentMarker;
  for (const commentMarkers of [markerCollection.getMarkersGroup('comment')]) {
    for (const marker of commentMarkers) {
      if (marker.name.indexOf(threadId) > -1) {
        commentMarker = marker;
      }
    }
  }
  return commentMarker;
}

import { MedtechUserV2 } from '../user';

export type ActivityLogAction =
  | ActivityLogModificationAction
  | 'approved'
  | 'declined'
  | 'reviewed'
  | 'changed_status';

export type ActivityLogModificationAction =
  | 'modified_quality_approvers'
  | 'modified_other_approvers'
  | 'modified_reviewers'
  | 'modified_editors'
  | 'modified_doc_title'
  | 'modified_doc_owner'
  | 'modified_doc_review_period'
  | 'modified_doc_training_required'
  | 'modified_doc_effective_on_approval'
  | 'modified_doc_format'
  | 'modified_doc_tags'
  | 'modified_file_download_permission_group'
  | 'upgraded_document_template'
  | 'created_new_draft'
  | 'edited_sections'
  | 'modified_external_file_upload'
  | 'modified_upload_file';

export type ActivityLogEntityType = 'document' | 'change_control';

export type ActivityLog = {
  entity_id: string;
  entity_type: ActivityLogEntityType;
  action: ActivityLogAction;
  timestamp: number;
  actor: MedtechUserV2 | null;
  data: any;
  key?: number;
};

type ActivityLogActionVerb =
  | 'modified'
  | 'approved'
  | 'declined'
  | 'upgraded'
  | 'reviewed'
  | 'created'
  | 'edited'
  | 'updated';

type ActivityLogActionEntity =
  | 'Quality approvers'
  | 'Other approvers'
  | 'Reviewers'
  | 'Editors'
  | 'Owner'
  | 'Title'
  | 'Review period'
  | 'Retraining required'
  | 'Effective on approval'
  | 'Format'
  | 'Tags'
  | 'Download permissions'
  | 'Document template'
  | 'New draft'
  | 'Section'
  | 'External file'
  | 'Upload file';

type ActivityLogActionToEntityAndVerb = {
  [key in ActivityLogModificationAction]: {
    verb: ActivityLogActionVerb;
    entity: ActivityLogActionEntity;
  };
};

export const activityLogDetailMap: ActivityLogActionToEntityAndVerb = {
  modified_quality_approvers: { verb: 'modified', entity: 'Quality approvers' },
  modified_other_approvers: { verb: 'modified', entity: 'Other approvers' },
  modified_reviewers: { verb: 'modified', entity: 'Reviewers' },
  modified_editors: { verb: 'modified', entity: 'Editors' },
  modified_doc_owner: { verb: 'modified', entity: 'Owner' },
  modified_doc_title: { verb: 'modified', entity: 'Title' },
  modified_doc_review_period: { verb: 'modified', entity: 'Review period' },
  modified_doc_training_required: {
    verb: 'modified',
    entity: 'Retraining required',
  },
  modified_doc_effective_on_approval: {
    verb: 'modified',
    entity: 'Effective on approval',
  },
  modified_doc_format: {
    verb: 'modified',
    entity: 'Format',
  },
  modified_file_download_permission_group: {
    verb: 'modified',
    entity: 'Download permissions',
  },
  modified_doc_tags: {
    verb: 'modified',
    entity: 'Tags',
  },
  upgraded_document_template: {
    verb: 'upgraded',
    entity: 'Document template',
  },
  created_new_draft: {
    verb: 'created',
    entity: 'New draft',
  },
  edited_sections: {
    verb: 'edited',
    entity: 'Section',
  },
  modified_external_file_upload: {
    verb: 'updated',
    entity: 'External file',
  },
  modified_upload_file: {
    verb: 'modified',
    entity: 'Upload file',
  },
};

import { useCurrentUser } from '@qualio/ui-components';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { isBasicUserInAccount } from '../../../../../util/CurrentUser';
import {
  FOR_TRAINING_STATUS,
  WorkspaceTabs,
} from '../../../DocumentWorkspace/types';

export const useNavigateAfterTraining = () => {
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const isBasicUser = isBasicUserInAccount(currentUser);
  const pathname = isBasicUser ? '/library' : '/workspace';
  const search = isBasicUser
    ? ''
    : createSearchParams({
        filter: WorkspaceTabs.YOUR_ACTIONS,
        subfilter: FOR_TRAINING_STATUS,
      }).toString();

  return () => {
    navigate({
      pathname,
      search,
    });
  };
};

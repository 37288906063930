import { CurrentUser, QBox, QButton, QTitle } from '@qualio/ui-components';
import { Parser } from 'html-to-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { documentApi } from '../../../../api/document';
import { QualioDocument } from '../../../../api/model/document';
import { aiService } from '../ai-service';
import styles from './GenerateTA.module.css';

const parseHtmlToReact = (html: string) => {
  const htmlToReactParser = new Parser();
  return htmlToReactParser.parse(html);
};

export const GenerateTrainingAssessmentComponent: React.FC<{
  currentUser: CurrentUser;
}> = ({ currentUser }) => {
  const [document, setDocument] = useState<QualioDocument>();
  const { id } = useParams();

  useEffect(() => {
    if (id && Number(id)) {
      void documentApi.fetch(id).then(setDocument);
    }
  }, [id, currentUser.companyId]);

  if (!id || !document) {
    return <></>;
  }
  return <GenerateTABox document={document} />;
};

const helpText = `<p>We can automatically provide suggestions for training assessment questions. Wanna give it a try?</p>`;

const GenerateTABox: React.FC<{
  document: QualioDocument;
}> = ({ document }) => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [generatedTAText, setGeneratedTAText] = useState<string>(helpText);
  const onGenerateClicked = async () => {
    setIsFetching(true);
    setGeneratedTAText('Please wait');

    try {
      const generatedTAText = await aiService.generateTrainingAssessment(
        document,
      );
      setGeneratedTAText(generatedTAText ?? '');
    } catch (e: any) {
      console.error(e);
      setGeneratedTAText(helpText);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <QBox
      width="full"
      pt={0}
      pl={8}
      pr={8}
      pb={3}
      boxShadow="lg"
      borderRadius="md"
      border="1px"
      borderColor="gray.300"
      bg="white"
      className={styles['generate-ta-component']}
    >
      <QTitle>Suggest training assessment questions</QTitle>
      {parseHtmlToReact(generatedTAText)}
      <br></br>
      <QButton
        onClick={onGenerateClicked}
        isDisabled={isFetching}
        isLoading={isFetching}
      >
        Generate
      </QButton>
    </QBox>
  );
};

import { MutableRefObject, useEffect, useState } from 'react';

export const useElementSize = (
  ref: MutableRefObject<HTMLElement | undefined>,
) => {
  const [elementRect, setElementRect] = useState<{
    width: number | undefined;
    height: number | undefined;
    node: HTMLElement | undefined;
  }>({
    width: undefined,
    height: undefined,
    node: undefined,
  });

  const handleResize = () => {
    const element = elementRect.node;
    const rect = element?.getBoundingClientRect();
    if (rect) {
      setElementRect({
        width: rect.width,
        height: rect.height,
        node: element,
      });
    }
  };

  useEffect(() => {
    if (ref?.current) {
      const rect = ref.current.getBoundingClientRect();
      if (
        rect.width !== elementRect.width ||
        rect.height !== elementRect.height
      ) {
        setElementRect({
          width: rect.width,
          height: rect.height,
          node: ref.current,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return elementRect;
};

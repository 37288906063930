import { Selecting } from '@qualio/ui-components';
import { QualioDocument } from '../../../api/model/document';
import { buildDocMap } from './WorkspaceList';

type ListSelectionWrapperProps = {
  isReadOnly: boolean;
  selectableDocuments: QualioDocument[];
  selectedDocumentsMap: Map<number, QualioDocument>;
  setSelectedDocumentsMap: (selected: Map<number, QualioDocument>) => void;
};

export const ListSelectionWrapper = ({
  isReadOnly,
  children,
  selectableDocuments,
  selectedDocumentsMap,
  setSelectedDocumentsMap,
}: React.PropsWithChildren<ListSelectionWrapperProps>) => {
  if (isReadOnly) {
    return <>{children}</>;
  }

  return (
    <Selecting.DefaultSelectionProvider
      onChange={(selectedDocIds) => {
        const updatedSelectedDocMap = buildDocMap(
          selectedDocIds,
          selectableDocuments,
          selectedDocumentsMap,
        );
        setSelectedDocumentsMap(updatedSelectedDocMap);
      }}
    >
      {children}
    </Selecting.DefaultSelectionProvider>
  );
};

import axios from 'axios';
import { medtechApiClient } from '../../../../api';
import {
  TrainingAssessment,
  TrainingAssessmentPayload,
} from '../types/trainingAssessment';

export class APICancelledError extends Error {}

export const TrainingAsessmentAPI = {
  async getTrainingAssessmentForDocument(
    documentId: TrainingAssessment['document_id'],
  ) {
    const response = await medtechApiClient.get<TrainingAssessment | undefined>(
      `documents/${documentId}/assessment`,
    );
    if (response.status === 204) {
      return undefined;
    }
    return TrainingAssessment.parse(response.data);
  },

  async deleteTrainingAssessmentForDocument(
    documentId: TrainingAssessment['document_id'],
  ) {
    const response = await medtechApiClient
      .delete<TrainingAssessment>(`documents/${documentId}/assessment`)
      .catch((error) => {
        if (
          axios.isCancel(error) ||
          (axios.isAxiosError(error) && error.code === 'ECONNABORTED')
        ) {
          throw new APICancelledError();
        }
        throw error;
      });

    if (response.status === 204) {
      return undefined;
    }
  },

  async patchTrainingAssessmentForDocument(
    documentId: TrainingAssessment['document_id'],
    payload: TrainingAssessment,
  ) {
    const response = await medtechApiClient
      .patch<TrainingAssessment>(`documents/${documentId}/assessment`, payload)
      .catch((error) => {
        if (
          axios.isCancel(error) ||
          (axios.isAxiosError(error) && error.code === 'ECONNABORTED')
        ) {
          throw new APICancelledError();
        }
        throw error;
      });
    return TrainingAssessment.parse(response.data);
  },

  async postTrainingAssessmentForDocument(
    documentId: TrainingAssessment['document_id'],
    payload: TrainingAssessmentPayload,
  ) {
    const response = await medtechApiClient
      .post<TrainingAssessment>(`documents/${documentId}/assessment`, payload)
      .catch((error) => {
        if (
          axios.isCancel(error) ||
          (axios.isAxiosError(error) && error.code === 'ECONNABORTED')
        ) {
          throw new APICancelledError();
        }
        throw error;
      });
    return TrainingAssessment.parse(response.data);
  },
} as const;

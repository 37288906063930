import { useToastProvider } from '@qualio/ui-components';
import { useMutation, useQueryClient } from 'react-query';
import { EditorStatus } from '../../DocumentOverview/Context';
import {
  APICancelledError,
  TrainingAsessmentAPI,
} from '../api/trainingAssessmentApi';
import {
  TrainingAssessment,
  TrainingAssessmentPayload,
} from '../types/trainingAssessment';

export const useSaveTrainingAssessment = (
  documentId: TrainingAssessment['document_id'],
  setTraingingAssessmentStatus: (status: EditorStatus) => void,
) => {
  const { showToast } = useToastProvider();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      payload: TrainingAssessment | TrainingAssessmentPayload,
    ) => {
      if (TrainingAssessment.safeParse(payload).success) {
        return TrainingAsessmentAPI.patchTrainingAssessmentForDocument(
          documentId,
          payload as TrainingAssessment,
        );
      } else {
        return TrainingAsessmentAPI.postTrainingAssessmentForDocument(
          documentId,
          payload as TrainingAssessmentPayload,
        );
      }
    },
    onMutate: async () => {
      setTraingingAssessmentStatus(EditorStatus.SAVING);
      await queryClient.cancelQueries(['trainingAssessment', documentId]);
    },
    onError: (error: any) => {
      if (error instanceof APICancelledError) {
        return;
      }

      setTraingingAssessmentStatus(EditorStatus.ERROR);

      showToast({
        status: 'error',
        title: 'Error',
        description: 'Failed to save training assessment.',
      });
    },
    onSuccess: async (trainingAssessment) => {
      setTraingingAssessmentStatus(EditorStatus.SAVED);
      await queryClient.cancelQueries(['trainingAssessment', documentId]);
      queryClient.setQueryData(
        ['trainingAssessment', documentId],
        trainingAssessment,
      );
    },
  });
};

import { User } from '@ckeditor/ckeditor5-collaboration-core';
import CommentThreadView from '@ckeditor/ckeditor5-comments/src/comments/ui/view/commentthreadview';
import { View } from '@ckeditor/ckeditor5-ui';
import { Locale } from '@ckeditor/ckeditor5-utils';
import { DocumentStatus, QualioDocument } from '../../api/model/document';
import { handleRemoveCommentThread } from '../../config/handlers';

interface Comment {
  author: User;
}

export default class QualioCommentThreadView extends CommentThreadView {
  static permission: number;
  static qualioDocument: QualioDocument;

  private commentThread: any;

  constructor(
    locale: Locale,
    commentThread: CommentsRepository.CommentThreadToDisplay,
    user: User,
    config: any,
  ) {
    super(locale, commentThread as any, user, config);
    this.commentThread = commentThread;
    this.commentThreadInputView.commentInputView.showButtons = true;
    this.resolveButton.set({
      isVisible: false,
    });
    this.addThreadRemoveButton(user);
    this.hideEditButton();
    this.on('change:isActive', () => {
      this.addThreadRemoveButton(user);
      this.hideEditButton();
      const submitLabel = this.isNewThread() ? 'Reply' : 'Comment';
      this.commentThreadInputView.commentInputView.cancelButtonView.set({
        label: 'Cancel',
        withText: true,
        icon: null,
      });
      this.commentThreadInputView.commentInputView.submitButtonView.set({
        label: submitLabel,
        withText: true,
        icon: null,
      });
    });
  }

  private addThreadRemoveButton(user: User) {
    if (
      this.commentsListView?.commentViews.first &&
      this.shouldDisplayResolveButton(user)
    ) {
      const { removeButton } = this.commentsListView?.commentViews
        ?.first as any;
      if (removeButton) {
        removeButton.set({
          isEnabled: true,
          isVisible: true,
          tooltip: true,
          label: 'Mark as resolved',
        });
        removeButton.off('execute');
        removeButton.on('execute', () => {
          this.fire('removeCommentThread');
          handleRemoveCommentThread(
            this.commentThread.channelId,
            this.commentThread.id,
            QualioCommentThreadView.qualioDocument.company_id,
            QualioCommentThreadView.qualioDocument.id,
          );
        });
      }
    }
  }

  private hideEditButton() {
    if (!this.isCommentThreadEditable()) {
      const commentsViews = this.commentsListView?.commentViews;
      commentsViews?.map((commentView: View) => {
        const { editButton } = commentView as any;
        if (editButton) {
          editButton.set({
            isEnabled: false,
            isVisible: false,
          });
        }
        return commentView;
      });
    }
  }

  private isNewThread() {
    return this.commentsListView.length > 0;
  }

  private shouldDisplayResolveButton = (user: User) => {
    const { id: currentUserEmail } = user;
    const commentsArr: Comment[] = Array.from(this.commentThread.comments);
    const [commentThreadStart] = commentsArr;

    if (!commentThreadStart) {
      return false;
    }

    const { author: commentThreadAuthor } = commentThreadStart;

    if (!this.isCommentThreadEditable()) {
      return false;
    }

    const isCommentAuthor = user.id === commentThreadAuthor?.id;
    const isDocumentOwner =
      QualioCommentThreadView.qualioDocument.owner.email === currentUserEmail;

    return isCommentAuthor || isDocumentOwner;
  };

  private isCommentThreadEditable = () => {
    return (
      QualioCommentThreadView.qualioDocument.status_id ===
        DocumentStatus.Draft ||
      QualioCommentThreadView.qualioDocument.status_id ===
        DocumentStatus.For_Review
    );
  };
}

export const qualioCommentThreadBuilder = (
  commentsPermission: number,
  qualioDocument: QualioDocument,
) => {
  QualioCommentThreadView.permission = commentsPermission;
  QualioCommentThreadView.qualioDocument = qualioDocument;
  return QualioCommentThreadView;
};

import { QBox } from '@qualio/ui-components';
import { useContext } from 'react';
import { EditorMode } from '../../../util/EditModeUtils';
import { DocumentOverviewContext } from '../DocumentOverview/Context';
import styles from './DocumentStatusBanner.module.css';

export const DocumentStatusBanner = () => {
  const { currentEditorMode } = useContext(DocumentOverviewContext);

  const overriddenQualioFrontendPadding = {
    width: 'calc(100% + 48px)',
    marginTop: '-10px',
    marginLeft: '-24px',
  };

  if (currentEditorMode === EditorMode.TRACKCHANGES) {
    return (
      <QBox
        data-cy="suggest-banner"
        style={overriddenQualioFrontendPadding}
        className={styles['document__status-banner-suggest']}
      >
        You are currently suggesting edits for this document
      </QBox>
    );
  } else if (currentEditorMode === EditorMode.EDIT) {
    return (
      <QBox
        data-cy="edit-banner"
        style={overriddenQualioFrontendPadding}
        className={styles['document__status-banner-edit']}
      >
        You are currently editing this document
      </QBox>
    );
  }
  return (
    <QBox
      data-cy="view-banner"
      style={overriddenQualioFrontendPadding}
      className={styles['document__status-banner']}
    ></QBox>
  );
};

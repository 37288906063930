import {
  DocumentStatus,
  GroupUser,
  QualioDocument,
} from '../../../../../api/model/document';
import { DocumentUserProps, GetDocumentUserProps, UserType } from './types';

const getReviewerProps = (
  reviewer: GroupUser,
  qualioDocument: QualioDocument,
): DocumentUserProps => {
  const notReviewed =
    qualioDocument.status_id === DocumentStatus.For_Review
      ? 'pending'
      : 'default';
  return {
    status: reviewer.action_done ? 'reviewed' : notReviewed,
    timestamp: reviewer.action_time,
  };
};

const getApproverProps = ({
  qualioDocument,
  user: approver,
}: GetDocumentUserProps): DocumentUserProps => {
  if (qualioDocument.status_id === DocumentStatus.Draft) {
    return { status: 'default' };
  }

  const isInApprovalProcess = [
    DocumentStatus.For_Approval.valueOf(),
    DocumentStatus.Approved.valueOf(),
    DocumentStatus.Approval_Declined.valueOf(),
  ].includes(qualioDocument.status_id);

  if (isInApprovalProcess) {
    const relatedDocumentUserAudits = qualioDocument.audits
      .filter((audit) => {
        return (
          (audit.user_id === approver.user_id &&
            (audit.action ===
              'doc_status_change_for_approval-approval_declined' ||
              audit.action === 'doc_status_change_for_approval-approved')) ||
          audit.action === 'doc_status_change_draft-for_approval' ||
          audit.action === 'doc_status_change_for_review-for_approval'
        );
      })
      .reverse();

    if (relatedDocumentUserAudits.length) {
      const [mostRecentDocumentUserAudit] = relatedDocumentUserAudits;
      const { action, timestamp, comment } = mostRecentDocumentUserAudit;
      switch (action) {
        case 'doc_status_change_for_approval-approval_declined':
          return {
            status: 'declined',
            timestamp,
            comment,
          };
        case 'doc_status_change_for_approval-approved':
          return {
            status: 'approved',
            timestamp,
            comment,
          };
        default:
          return { status: 'pending' };
      }
    }
  }

  return { status: 'default' };
};

export const getDocumentUserProps = ({
  userType,
  qualioDocument,
  user,
}: GetDocumentUserProps & { userType: UserType }): DocumentUserProps => {
  switch (userType) {
    case 'approver':
      return getApproverProps({ qualioDocument, user });
    case 'reviewer':
      return getReviewerProps(user, qualioDocument);
    default:
      return { status: 'default' };
  }
};

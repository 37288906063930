import { QualioDocumentSection } from '../../../../api/model/document';
import { aiApiClient } from '../../AiPoc/api';
import { TrainingAssessmentAIResponse } from '../types/trainingAssessment';

const partialDocument = (section: QualioDocumentSection) => ({
  title: section.title,
  content: section.content,
  position: section.position,
  section_attachments: section.section_attachments,
});

export const TrainingAiAssessmentApi = {
  async generateTrainingAssessment(
    title: string,
    document: QualioDocumentSection[],
  ): Promise<TrainingAssessmentAIResponse> {
    const documentSections = document.map(partialDocument);
    const response = await aiApiClient.post(`/training-assessment`, {
      document: { sections: documentSections },
      title,
    });

    return TrainingAssessmentAIResponse.parse(response.data);
  },
};

import { navigateAcrossMFEs, useCurrentUser } from '@qualio/ui-components';
import { useContext, useMemo } from 'react';
import { DocumentStatus } from '../../../../../api/model/document';
import { DocumentOverviewContext } from '../../Context';
import {
  documentIsInApprovalStatus,
  documentIsInTrainingStatus,
  shouldRenderActivityReportItem,
} from '../../RenderLogic';

type ActivityReportActionProps = {
  renderClickableItem: (onClick: () => void, label: string) => JSX.Element;
};

const getActivityReportActionItemLabel = (
  documentStatus: string,
  trainingAvailable: boolean,
) => {
  if (documentStatus === DocumentStatus.For_Review) {
    return 'Review';
  } else if (documentIsInApprovalStatus(documentStatus)) {
    return 'Approval';
  } else if (documentIsInTrainingStatus(documentStatus, trainingAvailable))
    return 'Training';

  return '';
};

export const ActivityReportAction = ({
  renderClickableItem,
}: ActivityReportActionProps) => {
  const { qualioDocument, currentEditorMode } = useContext(
    DocumentOverviewContext,
  );
  const currentUser = useCurrentUser();

  const documentStatus = qualioDocument.status_id;
  const trainingAvailable = qualioDocument.training_available;

  const reportStatusLabel = useMemo(() => {
    return getActivityReportActionItemLabel(documentStatus, trainingAvailable);
  }, [documentStatus, trainingAvailable]);

  const handleClick = () => {
    const activityReportPath = `/reports/activity/${
      qualioDocument.id
    }/${reportStatusLabel.toLowerCase()}`;
    navigateAcrossMFEs(activityReportPath);
  };

  if (
    !shouldRenderActivityReportItem(
      qualioDocument,
      currentUser,
      currentEditorMode,
    )
  ) {
    return null;
  }

  return <>{renderClickableItem(handleClick, `${reportStatusLabel} report`)}</>;
};

import { QText, QToastProps } from '@qualio/ui-components';
import { z } from 'zod';
import {
  ChangeStatusPayload,
  ChangeStatusResponse,
  documentApi,
} from '../../../../../../api/document';
import { QualioDocument } from '../../../../../../api/model/document';

export const ChangeStatusErrorResponseSchema = z
  .object({
    response: z
      .object({
        data: z
          .object({
            message: z.string(),
          })
          .passthrough(),
      })
      .passthrough(),
  })
  .transform((result) => result.response.data.message);

class ChangeStatusError extends Error {
  constructor(public document: QualioDocument, public message: string) {
    super(message);
  }
}

export const DEFAULT_FAILURE_MESSAGE =
  'We have encountered issues changing the status of the selected document.';

export const handleStatusChange = async (
  selectedDocs: QualioDocument[],
  changeStatusPayload: ChangeStatusPayload,
  setSelectedDocumentsMap: (
    selectedDocuments: Map<QualioDocument['id'], QualioDocument>,
  ) => void,
  showToast: (options: QToastProps) => void,
  toastActionStringActive: string,
  toastActionStringPassive: string,
) => {
  const processFailedStatusChange = (
    document: QualioDocument,
    error: unknown,
  ) => {
    const parsedError = ChangeStatusErrorResponseSchema.safeParse(error);
    if (parsedError.success) {
      const { data: message } = parsedError;
      throw new ChangeStatusError(document, message);
    }
    throw new ChangeStatusError(document, DEFAULT_FAILURE_MESSAGE);
  };

  const promises: Promise<ChangeStatusResponse>[] = selectedDocs.map(
    (doc: QualioDocument) =>
      documentApi
        .changeStatus(doc.id, changeStatusPayload)
        .catch((error: unknown) => processFailedStatusChange(doc, error)),
  );
  const results = await Promise.allSettled(promises);

  const indicesOfFailedDocs: number[] = [];
  const failedDocPromises: ChangeStatusError[] = [];
  results.forEach((result, index) => {
    if (result.status === 'rejected') {
      indicesOfFailedDocs.push(index);
      failedDocPromises.push(result.reason);
    }
  });
  const numberOfFailedDocs = indicesOfFailedDocs.length;
  const numberOfSuccessfullyChangedDocs = results.length - numberOfFailedDocs;
  if (numberOfSuccessfullyChangedDocs > 0) {
    showSuccessToast(
      showToast,
      numberOfSuccessfullyChangedDocs,
      toastActionStringPassive,
      toastActionStringPassive,
    );
  }
  if (numberOfFailedDocs === 1) {
    showSingleFailureToast(
      showToast,
      toastActionStringActive,
      failedDocPromises[0].message,
    );
  } else if (numberOfFailedDocs > 1) {
    showMultipleFailureToast(
      showToast,
      toastActionStringActive,
      failedDocPromises,
    );
  }
  updateSelectedDocuments(
    selectedDocs,
    indicesOfFailedDocs,
    setSelectedDocumentsMap,
  );
};

const showSuccessToast = (
  showToast: (options: QToastProps) => void,
  numberOfSuccessfullyChangedDocs: number,
  toastActionStringPassive: string,
  toastActionStringActive: string,
) => {
  const successTitle = `${
    numberOfSuccessfullyChangedDocs === 1 ? 'Document' : 'Documents'
  } ${toastActionStringPassive}!`;
  const successMessage = `${numberOfSuccessfullyChangedDocs} ${
    numberOfSuccessfullyChangedDocs === 1 ? 'document' : 'documents'
  } successfully ${toastActionStringPassive}.`;
  showToast({
    id: `change-status-${toastActionStringActive}-success-toast`,
    replace: true,
    status: 'success',
    title: successTitle,
    description: successMessage,
  });
};

const showSingleFailureToast = (
  showToast: (options: QToastProps) => void,
  toastActionStringActive: string,
  message = DEFAULT_FAILURE_MESSAGE,
) => {
  showToast({
    id: `change-status-${toastActionStringActive}-error-toast`,
    replace: true,
    status: 'error',
    title: 'Error',
    description: message,
  });
};

const showMultipleFailureToast = (
  showToast: (options: QToastProps) => void,
  toastActionStringActive: string,
  failedDocumentPromises: ChangeStatusError[],
) => {
  const failureCount = failedDocumentPromises.length;
  const failureMessage = `${failureCount} documents failed to ${toastActionStringActive}:`;
  const description = (
    <>
      <QText>{failureMessage}</QText>
      <br />
      {failedDocumentPromises.map(({ document: { id, code, title } }) => (
        <QText key={id}>
          {code} {title}
        </QText>
      ))}
    </>
  );
  showToast({
    id: `change-status-${toastActionStringActive}-multiple-error-toast`,
    replace: true,
    status: 'error',
    title: 'Error',
    description,
  });
};

const updateSelectedDocuments = (
  selectedDocs: QualioDocument[],
  indicesOfFailedDocs: number[],
  setSelectedDocumentsMap: (
    selectedDocuments: Map<QualioDocument['id'], QualioDocument>,
  ) => void,
) => {
  const remainingSelectedDocuments = new Map<
    QualioDocument['id'],
    QualioDocument
  >();
  selectedDocs
    .filter((_, index) => indicesOfFailedDocs.includes(index))
    .forEach((failedDoc) => {
      remainingSelectedDocuments.set(failedDoc.id, failedDoc);
    });
  setSelectedDocumentsMap(remainingSelectedDocuments);
};

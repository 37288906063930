import { QStack, QText } from '@qualio/ui-components';
import { useContext, useMemo } from 'react';
import { DocumentVersion } from '../../../../../api/model/document';
import { Group } from '../../../../../api/model/group';
import { Tag } from '../../../../../api/model/tag';
import { MedtechUserV2 } from '../../../../../api/user';
import { DocumentOverviewContext } from '../../Context';
import { ManageTraineesButton } from './ManageTraineesButton';
import { ViewTraineesButton } from './ViewTraineesButton/index';

export type ManageTraineesProps = {
  versions?: DocumentVersion[];
  groups: Group[];
  tags: Tag[];
  users: MedtechUserV2[];
};
export const ManageTrainees = ({
  versions = [],
  groups,
  tags,
  users,
}: ManageTraineesProps) => {
  const { qualioDocument } = useContext(DocumentOverviewContext);
  const traineesDetail = useMemo(() => {
    const numberOfTrainees = qualioDocument.trainees.filter(
      ({ training_removed }) => !training_removed,
    ).length;
    if (numberOfTrainees > 0) {
      const traineeText = numberOfTrainees === 1 ? 'trainee' : 'trainees';
      return `${numberOfTrainees} ${traineeText}`;
    }
    return 'None';
  }, [qualioDocument.trainees]);
  return (
    <>
      <QStack
        isInline
        justifyContent="space-between"
        data-cy="document-trainees"
        alignItems="center"
      >
        <QText fontWeight="semibold" fontSize="md">
          Trainees
        </QText>
        <ManageTraineesButton
          versions={versions}
          groups={groups}
          users={users}
          tags={tags}
        />
        <ViewTraineesButton versions={versions} groups={groups} users={users} />
      </QStack>
      <QText mt="3" data-cy="document-trainees-count">
        {traineesDetail}
      </QText>
    </>
  );
};

import { ReactNode } from 'react';

import { NotFound } from './views/components/NotFound';

type ProtectedRouteProps = {
  predicate: () => boolean;
  children: ReactNode;
};

export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const isAccessible = props.predicate();
  if (isAccessible) {
    return <>{props.children}</>;
  }

  return <NotFound />;
};

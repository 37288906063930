import { ToastProvider, useCurrentUser } from '@qualio/ui-components';
import { useContext, useState } from 'react';
import { DocumentHistoryPanel } from '../../../DocumentHistoryPanel/DocumentHistoryPanel';
import { DocumentOverviewContext } from '../../Context';
import { shouldRenderHistory } from '../../RenderLogic';

type HistoryActionProps = {
  renderClickableItem: (onClick: () => void) => JSX.Element;
};

export const HistoryAction = ({ renderClickableItem }: HistoryActionProps) => {
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const { qualioDocument } = useContext(DocumentOverviewContext);
  const currentUser = useCurrentUser();

  if (!shouldRenderHistory(qualioDocument, currentUser)) {
    return null;
  }

  return (
    <>
      {renderClickableItem(() => setIsHistoryOpen(true))}
      {isHistoryOpen && (
        <ToastProvider enableDelegation={true}>
          <DocumentHistoryPanel
            isOpen={true}
            onClose={() => setIsHistoryOpen(false)}
            companyId={currentUser.companyId}
            documentId={qualioDocument.id.toString()}
          />
        </ToastProvider>
      )}
    </>
  );
};

import { Avatar } from '@chakra-ui/react';
import {
  QBox,
  QCenter,
  QDivider,
  QStack,
  QText,
  useCurrentUser,
} from '@qualio/ui-components';
import React from 'react';
import { convertEpochToDate } from '../../../../util/DateUtils';
import { htmlToReact } from '../DocumentCommentHistoryCard/DocumentCommentHistoryCard';
import styles from '../DocumentCommentHistoryCard/DocumentCommentHistoryCard.module.css';

type HistoricalCommentHistoryCardProps = {
  commentThread: {
    resolved: boolean;
    timestamp: number;
    content: string;
    user: {
      full_name: string;
    };
    replies: any[];
  };
};

export const HistoricalCommentHistoryCard: React.FC<
  HistoricalCommentHistoryCardProps
> = ({ commentThread }) => {
  const cardClasses = `${styles['Document-Comment-History__Card']} ${
    styles[`Document-Comment-History__Card__color__comment`]
  }`;
  const currentUser = useCurrentUser();

  return (
    <QCenter pb={6}>
      <QBox className={cardClasses} data-cy={'comment-history-thread'}>
        <QBox
          bg="gray.100"
          className={styles['Document-Comment-History__Card__thread-status']}
        >
          <QBox display="flex" p={2}>
            <QBox
              display="flex"
              data-cy="comment-history-thread-commented-on-short"
            >
              <QText fontWeight="bold" whiteSpace="nowrap">
                Historical comment thread
              </QText>
            </QBox>
          </QBox>
        </QBox>
        <QStack
          direction={'row'}
          data-cy={'comment-history-thread-comment-element'}
        >
          <QBox pl={2} pt={2}>
            <Avatar size="sm" name={commentThread.user.full_name} />
          </QBox>
          <QBox>
            <QBox display="flex" pl={2} pt={2}>
              <QText data-cy="commentAuthor">
                {commentThread.user.full_name}
              </QText>
              <QText pl={2} color="gray.500">
                {currentUser.formatDate(
                  convertEpochToDate(commentThread.timestamp),
                  true,
                )}
              </QText>
            </QBox>
            <QBox
              p={2}
              wordBreak="break-word"
              data-cy={'comment-history-thread-comment-element-text'}
            >
              {htmlToReact(commentThread.content)}
            </QBox>
          </QBox>
        </QStack>
        {commentThread.replies.length > 0 && (
          <QCenter>
            <QDivider
              orientation="horizontal"
              color={'gray.200'}
              width={'100%'}
            />
          </QCenter>
        )}
        {commentThread.replies.map((comment, index) => (
          <>
            <QStack
              key={comment.id}
              direction={'row'}
              data-cy={'comment-history-thread-comment-element'}
            >
              <QBox pl={2} pt={2}>
                <Avatar size="sm" name={comment.user.full_name} />
              </QBox>
              <QBox>
                <QBox display="flex" pl={2} pt={2}>
                  <QText data-cy="commentAuthor">
                    {comment.user.full_name}
                  </QText>
                  <QText pl={2} color="gray.500">
                    {currentUser.formatDate(
                      convertEpochToDate(comment.timestamp),
                      true,
                    )}
                  </QText>
                </QBox>
                <QBox
                  p={2}
                  wordBreak="break-word"
                  data-cy={'comment-history-thread-comment-element-text'}
                >
                  {htmlToReact(comment.content)}
                </QBox>
              </QBox>
            </QStack>
            {index + 1 < commentThread.replies.length && (
              <QCenter>
                <QDivider
                  orientation="horizontal"
                  color={'gray.200'}
                  width={'90%'}
                />
              </QCenter>
            )}
          </>
        ))}
        {commentThread.resolved && (
          <>
            <QCenter>
              <QDivider
                orientation="horizontal"
                color={'gray.200'}
                width={'100%'}
              />
            </QCenter>
            <QStack
              direction={'row'}
              data-cy={'comment-history-thread-comment-element-resolved'}
            >
              <QBox>
                <QBox
                  p={2}
                  wordBreak="break-word"
                  data-cy="comment-history-thread-resolved-statement"
                >
                  <i>Comment thread resolved</i>
                </QBox>
              </QBox>
            </QStack>
          </>
        )}
      </QBox>
    </QCenter>
  );
};

export type Source = 'onedrive' | 'onedrive-business';

export type RemoteFileMetadata = {
  filename: string;
  downloadUrl: string;
  webUrl: string;
  source: Source;
};

export type ModifiedRemoteFileMetadata = {
  filename: string;
  downloadUrl: string;
  webUrl: string;
  source: Source;
  presignedUrl: string;
  lastModifiedAt?: string;
};

export type QFilePickerError = {
  name?: RemoteFileMetadata['filename'];
  type: QFilePickerErrorType;
  message?: string;
};

export enum QFilePickerErrorType {
  FILE_TOO_LARGE,
  TOO_MANY_FILES,
  CANNOT_ACCESS,
}

export enum QFilePickerMode {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

export enum QFilePickerType {
  DOCUMENT,
  IMAGE,
  PRESENTATION,
}

type QPickerMappingType = Record<QFilePickerType, readonly string[]>;

export const qPickerTypeOneDriveMapping: QPickerMappingType = {
  [QFilePickerType.IMAGE]: ['png', 'bmp', 'jpg'],
  [QFilePickerType.DOCUMENT]: ['pdf', 'doc', 'docx'],
  [QFilePickerType.PRESENTATION]: ['odp', 'pptx'],
};

export type QFilePickerProps = {
  mode: QFilePickerMode;
  onPick: (files: RemoteFileMetadata[]) => void;
  onError: (errors: QFilePickerError[]) => void;
  types: QFilePickerType[];
  integrationBaseUrl: string;
};

import { zodResolver } from '@hookform/resolvers/zod';
import {
  QBox,
  QButton,
  QReorderableCard,
  QReorderableCardStack,
} from '@qualio/ui-components';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { QualioDocument } from '../../../api/model/document';
import { EditorStatusContext } from '../DocumentOverview/Context';
import { GenerateTrainingAssessmentAI } from './GenerateTrainingAssessmentAI';
import { useDeleteTrainingAssessment } from './hooks/useDeleteTrainingAssessment';
import { useSaveTrainingAssessment } from './hooks/useSaveTrainingAssessment';
import { TrainingAssessmentEmptyState } from './TrainingAssessmentEmptyState';
import { TrainingAssessmentFormQuestion } from './TrainingAssessmentFormQuestion';
import {
  TrainingAssessment,
  TrainingAssessmentAIResponse,
  TrainingAssessmentForm,
} from './types/trainingAssessment';

type TrainingAssessmentProps = {
  documentId: QualioDocument['id'];
  trainingAssessment?: TrainingAssessment;
};

export const EditTrainingAssessment: React.VFC<TrainingAssessmentProps> = ({
  documentId,
  trainingAssessment,
}) => {
  const queryClient = useQueryClient();
  const { setTrainingAssessmentStatus } = useContext(EditorStatusContext);
  const { mutate: saveTrainingAssessment } = useSaveTrainingAssessment(
    documentId,
    setTrainingAssessmentStatus,
  );

  const { mutate: deleteTrainingAssessment } = useDeleteTrainingAssessment(
    documentId,
    setTrainingAssessmentStatus,
  );

  const methods = useForm<TrainingAssessmentForm>({
    defaultValues: {
      questions: trainingAssessment?.questions ?? [],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(TrainingAssessmentForm),
  });

  const { append, fields, swap, remove } = useFieldArray({
    control: methods.control,
    name: 'questions' as never,
  });

  const handleSubmit = (data: TrainingAssessmentForm) => {
    const currentTrainingAssessment: TrainingAssessment | undefined =
      queryClient.getQueryData(['trainingAssessment', documentId]);
    const questions = data.questions.map((question) => {
      if (question.id !== undefined) {
        return question;
      }
      const { id, ...questionDTO } = question;
      return questionDTO;
    });

    if (questions.length === 0) {
      deleteTrainingAssessment();
    } else {
      const payload = {
        ...(currentTrainingAssessment && {
          id: currentTrainingAssessment.id,
          description: currentTrainingAssessment.description,
        }),
        document_id: documentId,
        questions,
      };
      saveTrainingAssessment(payload);
    }
  };

  const handleSubmission = () => {
    void methods.handleSubmit(handleSubmit)();
    methods.reset(methods.getValues());
  };

  const submissionCallback = useRef(handleSubmission);

  useEffect(() => {
    const submissionHandler = submissionCallback.current;
    return () => {
      void submissionHandler();
    };
  }, [submissionCallback]);

  const addQuestionCallback = useCallback(() => {
    append({
      id: undefined,
      question: '',
      answers: [
        { answer: '', is_correct: true },
        { answer: '', is_correct: false },
      ],
    });
  }, [append]);

  const addSuggestedQuestions = async (
    questions: TrainingAssessmentAIResponse['questions'],
  ) => {
    for (const question of questions) {
      append({
        id: undefined,
        question: question.question,
        answers: question.answers,
      });
    }
    await methods.handleSubmit(handleSubmit)();
  };

  const formIds = useMemo(() => fields.map((field) => field.id), [fields]);

  const swapCards = useCallback(
    (updatedIds: string[]) => {
      for (const [index, formId] of formIds.entries()) {
        const correctIndex = updatedIds.findIndex((id) => formId === id);
        if (correctIndex !== index) {
          swap(index, correctIndex);
          break;
        }
      }
    },
    [formIds, swap],
  );

  const deleteCard = useCallback(
    (updatedIds: string[]) => {
      for (const [index, formId] of formIds.entries()) {
        if (!updatedIds.includes(formId)) {
          remove(index);
          break;
        }
      }
    },
    [formIds, remove],
  );

  const onQuestionChange = useCallback(
    (ids: string[]) => {
      if (formIds.length === ids.length) {
        swapCards(ids);
      } else {
        deleteCard(ids);
      }
    },
    [formIds.length, swapCards, deleteCard],
  );

  if (!fields?.length) {
    return (
      <>
        <GenerateTrainingAssessmentAI onAccept={addSuggestedQuestions} />
        <TrainingAssessmentEmptyState onClick={addQuestionCallback} />
      </>
    );
  }

  return (
    <>
      <GenerateTrainingAssessmentAI onAccept={addSuggestedQuestions} />
      <QBox marginTop="16px" data-cy="edit-training-assessment-layout">
        <QBox width="648px">
          <FormProvider {...methods}>
            <QReorderableCardStack onChange={onQuestionChange}>
              {fields.map((field, index) => (
                <QReorderableCard id={field.id} key={field.id}>
                  <TrainingAssessmentFormQuestion index={index} />
                </QReorderableCard>
              ))}
              <QButton
                variant="outline"
                leftIcon="Plus"
                onClick={addQuestionCallback}
              >
                Add question
              </QButton>
            </QReorderableCardStack>
          </FormProvider>
        </QBox>
      </QBox>
    </>
  );
};

import {
  QButton,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';
import { useCallback, useContext, useMemo, useState } from 'react';
import { documentApi } from '../../../../../api/document';
import { GroupUser } from '../../../../../api/model/document';
import { Group } from '../../../../../api/model/group';
import { Tag } from '../../../../../api/model/tag';
import { MedtechUserV2 } from '../../../../../api/user';
import { groupUserToMedtechUser } from '../../../../../util/UserUtils';
import { DocumentOverviewContext } from '../../Context';
import { canManageEditors } from '../../RenderLogic';
import { EditorsLookup } from './EditorsLookup';

export type ManageEditorsButtonProps = {
  groups: Group[];
  users: MedtechUserV2[];
  tags: Tag[];
};
export const ManageEditorsButton = ({
  groups,
  users,
  tags,
}: ManageEditorsButtonProps) => {
  const currentUser = useCurrentUser();
  const { qualioDocument, refetchDocument } = useContext(
    DocumentOverviewContext,
  );
  const { showToast } = useToastProvider();
  const [isLookupModalOpen, setIsLookupModalOpen] = useState(false);
  const onClose = useCallback(() => {
    setIsLookupModalOpen(false);
  }, []);

  const canManageDocumentEditors = canManageEditors(
    currentUser,
    qualioDocument,
  );

  const currentEditors = useMemo(
    () =>
      (qualioDocument.audit_groups?.editor_group || []).reduce(
        (acc: Map<number, GroupUser>, user: GroupUser) => {
          acc.set(user.id, user);
          return acc;
        },
        new Map(),
      ),
    [qualioDocument.audit_groups?.editor_group],
  );

  const isEditorRowPreselected = (user: MedtechUserV2) =>
    currentEditors.has(user.id);

  const mapToId = ({ id }: { id: number }) => id;

  // In case an editor has been deleted, we need to display it to be deselected
  const deletedEditors: MedtechUserV2[] = useMemo(
    () =>
      (qualioDocument.audit_groups?.editor_group || [])
        .filter((editor) => !users.find((user) => user.id === editor.id))
        .map((deletedEditor) => groupUserToMedtechUser(deletedEditor)),
    [users, qualioDocument],
  );

  const editorsPool = useMemo(
    () => [...users, ...deletedEditors],
    [users, deletedEditors],
  );

  const updateEditors = async (selections: readonly MedtechUserV2[]) => {
    const selectedUserIds = selections.map(({ id }) => id);
    const commonToastProps = {
      id: 'manage-editors-overview-toast',
      replace: true,
    };
    try {
      await documentApi.update(
        {
          audit_groups: {
            approver_group: (
              qualioDocument.audit_groups?.approver_group ?? []
            ).map(mapToId),
            review_group: (qualioDocument.audit_groups?.review_group ?? []).map(
              mapToId,
            ),
            editor_group: selectedUserIds,
          },
        },
        qualioDocument.id,
      );
      showToast({
        ...commonToastProps,
        status: 'success',
        title: 'Editors updated!',
        description: 'Successfully updated document editors.',
      });
      refetchDocument();
      onClose();
    } catch (error) {
      showToast({
        ...commonToastProps,
        status: 'error',
        title: 'Error',
        description: 'Failed to apply changes to editors. Please try again.',
      });
      // Re-throw error so that QLookup does not reset the selections
      throw error;
    }
  };

  if (!canManageDocumentEditors) {
    return null;
  }

  return (
    <>
      <QButton
        data-cy={`document-manage-editors-button`}
        variant="ghost"
        onClick={() => setIsLookupModalOpen(true)}
      >
        Manage
      </QButton>
      <EditorsLookup
        tags={tags}
        isOpen={isLookupModalOpen}
        onClose={onClose}
        users={editorsPool}
        groups={groups}
        isEditorPreselected={isEditorRowPreselected}
        updateEditors={updateEditors}
      />
    </>
  );
};

import {
  navigateAcrossMFEs,
  QBadge,
  QBox,
  QButton,
  QCloseButton,
  QDivider,
  QDrawer,
  QDrawerHeader,
  QFlex,
  QHeading,
  QSpacer,
  QSpinner,
  QStack,
  useCurrentUser,
} from '@qualio/ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useQuery } from 'react-query';
import { changeManagementApi } from '../../../api/changeManagementApi';
import {
  isNormalUserInAccount,
  isQualityUserInAccount,
} from '../../../util/CurrentUser/index';
import { HTMLViewer } from '../DocumentOverview/HTMLViewer/index';
import { ChangeRequestSideDrawerHeaderInfoRow } from './components/ChangeRequestSideDrawerHeaderInfoRow';
import { ChangeRequestSectionType } from './types/ChangeRequest';

type ChangeRequestSideDrawerProps = {
  changeRequestId: number;
  onClose: () => void;
};

const ChangeRequestSideDrawer: React.FC<ChangeRequestSideDrawerProps> = ({
  changeRequestId,
  onClose,
}) => {
  const { changeRequestExtended } = useFlags();
  const currentUser = useCurrentUser();

  const fetchChangeRequest = async () => {
    return changeManagementApi.getChangeRequestById(
      currentUser.companyId,
      changeRequestId,
    );
  };

  const { data: changeRequest, isLoading } = useQuery({
    queryKey: ['changeRequestId'],
    queryFn: fetchChangeRequest,
    refetchOnWindowFocus: false,
  });

  return (
    <QFlex direction="column" minH="80vh">
      <QDrawer isOpen={true} onClose={onClose} size={'xl'}>
        <QDrawerHeader alignItems="center">
          {isLoading || !changeRequest ? (
            <QFlex justify="space-around" width="full">
              <QSpinner role="actions-loading" data-cy="loading-spinner" />
            </QFlex>
          ) : (
            <QStack flexDirection="column" spacing={2} width="100%">
              <QBadge data-cy={`cr-reference-${changeRequest.id}`}>
                {changeRequest.code}
              </QBadge>
              ;
              <QFlex justifyContent={'space-between'} alignItems="center">
                <QHeading
                  as="h1"
                  size="lg"
                  color="gray.900"
                  data-cy={'change-request-details-title'}
                >
                  {changeRequest.title}
                </QHeading>
                <QBox display="flex" justifyContent="flex-end">
                  {(changeRequestExtended &&
                    isNormalUserInAccount(currentUser)) ||
                  isQualityUserInAccount(currentUser) ? (
                    <QButton
                      data-cy={'change-request-view-button'}
                      variant="outline"
                      onClick={() =>
                        navigateAcrossMFEs(
                          `/change-management/change-requests/${changeRequest.id}`,
                        )
                      }
                    >
                      View Change Request
                    </QButton>
                  ) : null}
                  <QCloseButton
                    onClick={onClose}
                    data-cy={'change-request-side-drawer-close-button'}
                    ml={2}
                  />
                </QBox>
              </QFlex>
              <ChangeRequestSideDrawerHeaderInfoRow
                changeRequest={changeRequest}
              />
            </QStack>
          )}
        </QDrawerHeader>
        {changeRequest && (
          <>
            <QDivider mb={5} />
            <QBox data-cy="change-request-sections" className="document">
              <HTMLViewer
                sections={changeRequest.sections as ChangeRequestSectionType[]}
                disableCheckBoxes={true}
              />
            </QBox>
          </>
        )}
        <QSpacer data-cy={'details-drawer-footer-padding'} mb={70} />
      </QDrawer>
    </QFlex>
  );
};

export default ChangeRequestSideDrawer;

import { QualioDocumentSection } from '../api/model/document';

const stripHtmlAttribute = (content: string): string => {
  return content.replace(/<(\w+)(\s+[^>]*?)?(\/?)>/g, '<$1$3>');
};

export const sanitizeDocumentSections = (sections: QualioDocumentSection[]) => {
  return sections.map((section) => ({
    ...section,
    content: stripHtmlAttribute(section.content),
  }));
};

import { Comment, CommentView } from '@ckeditor/ckeditor5-comments';
import CommentInputView from '@ckeditor/ckeditor5-comments/src/comments/ui/view/commentinputview';
import { Locale } from '@ckeditor/ckeditor5-utils';
import editButton from '../../assets/editButton.svg';
import resolveIcon from '../../assets/resolveButton.svg';

export default class QualioCommentView extends CommentView {
  constructor(locale: Locale, model: Comment, config: any) {
    super(locale, model as any, config);
    if (model.id !== 'resolved') {
      this.removeButton.set({
        icon: resolveIcon,
        label: '',
        isEnabled: false,
        isVisible: false,
        withText: false,
      });
      this.editButton.set({
        label: 'Edit',
        tooltip: true,
        icon: editButton,
        withText: false,
      });
      this.dropdown.isVisible = false;
      this.actionBar.remove(0);
      this.actionBar.add(this.editButton);
      this.actionBar.add(this.removeButton);
    }
  }

  switchToEditMode = () => {
    super.switchToEditMode();
    const editingView = this.visibleView.get(0) as CommentInputView;
    editingView.cancelButtonView.set({
      label: 'Cancel',
      withText: true,
      icon: null,
    });
    editingView.submitButtonView.set({
      label: 'Save',
      withText: true,
      icon: null,
    });
  };
}

import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import { useContext, useState } from 'react';
import { documentApi } from '../../../../../api/document';
import { DocumentStatus } from '../../../../../api/model/document';
import { ButtonGroupContext } from '../../../Contexts/ButtonGroupContext';
import { CredentialConfirmationModal } from '../../../CredentialConfirmationModal';
import {
  ChangeStatusErrorResponseSchema,
  DEFAULT_FAILURE_MESSAGE,
} from '../../../DocumentWorkspace/BulkActions/BulkActionButtons/ChangeStatusButtons/ChangeStatusService';
import { DocumentOverviewContext } from '../../Context';
import { shouldRenderRetireButton } from '../../RenderLogic';

type RetireActionProps = {
  renderClickableItem: (onClick: () => void) => JSX.Element;
};

export const RetireAction = ({ renderClickableItem }: RetireActionProps) => {
  const [isRetireModalOpen, setIsRetireModalOpen] = useState(false);
  const { qualioDocument, refetchDocument } = useContext(
    DocumentOverviewContext,
  );
  const { setDisabled } = useContext(ButtonGroupContext);
  const currentUser = useCurrentUser();
  const { showToast } = useToastProvider();

  const commonToastProps = {
    id: 'retire-toast',
    replace: true,
  };

  const openConfirmationModal = () => {
    setIsRetireModalOpen(true);
    setDisabled(true);
  };

  const closeConfirmationModal = () => {
    setIsRetireModalOpen(false);
    setDisabled(false);
  };

  if (!shouldRenderRetireButton(qualioDocument, currentUser)) {
    return null;
  }

  const retireDoc = async (email: string, password: string, comment = '') => {
    const payload = {
      status: DocumentStatus.Retired,
      email,
      password,
      comment,
    };
    await documentApi
      .changeStatus(qualioDocument.id, payload)
      .then(() => {
        showToast({
          ...commonToastProps,
          description: 'Successfully retired document.',
          status: 'success',
          title: 'Success!',
        });
        refetchDocument();
        closeConfirmationModal();
      })
      .catch((error: unknown) => {
        throw error;
      });
  };

  const handleRetireDocumentError = (error: unknown) => {
    const parsedError = ChangeStatusErrorResponseSchema.safeParse(error);
    showToast({
      ...commonToastProps,
      status: 'error',
      title: 'Failed to retire',
      description: parsedError.success
        ? parsedError.data
        : DEFAULT_FAILURE_MESSAGE,
    });
  };

  return (
    <>
      {renderClickableItem(openConfirmationModal)}
      <CredentialConfirmationModal
        isOpen={isRetireModalOpen}
        onCloseModal={closeConfirmationModal}
        modalHeading="Retire document"
        modalText={
          <>
            Retiring a document that is part of a Qualio Training Plan can
            impact your training assignments. Please check the Training Matrix
            to identify impacted plans.
            <br />
            <br />
            Enter your digital signature to retire this document.
          </>
        }
        buttonActionLabel="Retire"
        handleError={handleRetireDocumentError}
        buttonProps={{ isDestructive: true }}
        handleStatusChange={retireDoc}
      />
    </>
  );
};

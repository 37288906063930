import {
  QAlert,
  QButton,
  QFormControl,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QStack,
  QText,
  QTextarea,
} from '@qualio/ui-components';
import { useMemo, useState } from 'react';
import { MedtechUserV2 } from '../../../../../../../api/user';

type ChangedApprover = {
  id: number;
  full_name: string;
};

type ChangedApproversListProps = {
  users: ChangedApprover[];
  label: 'Approvers added' | 'Approvers removed';
};

const ChangedApproversList = ({ users, label }: ChangedApproversListProps) => {
  return (
    <QStack spacing="1">
      <QText fontWeight="semibold">{label}</QText>
      {users.length > 0 ? (
        users.map((user) => <QText key={user.id}>{user.full_name}</QText>)
      ) : (
        <QText>None</QText>
      )}
    </QStack>
  );
};

export type ManageApproversConfirmationModalProps = {
  isOpen: boolean;
  modalTitle: string;
  displayWarning?: boolean;
  addedApprovers: ChangedApprover[];
  removedApprovers: ChangedApprover[];
  selections: readonly MedtechUserV2[];
  requiresComment?: boolean;
  onClose: () => void;
  onPrevious: () => void;
  onSubmit: (
    selections: readonly MedtechUserV2[],
    comment: string,
  ) => Promise<void>;
};

export const ManageApproversConfirmationModal = ({
  isOpen,
  modalTitle,
  addedApprovers = [],
  removedApprovers = [],
  selections = [],
  displayWarning = false,
  requiresComment = false,
  onClose,
  onSubmit,
  onPrevious,
}: ManageApproversConfirmationModalProps) => {
  const [isApplyingChanges, setIsApplyingChanges] = useState(false);
  const textareaPlaceholder = useMemo(
    () =>
      `Reason for removing the approver${
        removedApprovers.length > 1 ? 's' : ''
      }`,
    [removedApprovers.length],
  );

  const [reason, setReason] = useState('');

  const close = () => {
    setReason('');
    onClose();
  };

  const applyChanges = async () => {
    setIsApplyingChanges(true);
    await onSubmit(selections, reason);
    setIsApplyingChanges(false);
  };

  return (
    <QModal isOpen={isOpen} onClose={close}>
      <QModalHeader>
        <QText fontSize="xl">{modalTitle}</QText>
      </QModalHeader>
      <QModalBody>
        <QStack spacing="6">
          {displayWarning && (
            <QAlert
              title="This action affects document approval status"
              description={
                <QText>
                  Removing these approvers will change the document from{' '}
                  <i>for approval</i> to <i>approved</i> (and then{' '}
                  <i>effective</i> if enabled).
                </QText>
              }
              status="warning"
            />
          )}
          <ChangedApproversList
            users={addedApprovers}
            label={'Approvers added'}
          />
          <ChangedApproversList
            users={removedApprovers}
            label={'Approvers removed'}
          />
          {requiresComment && (
            <QFormControl label="Reason" isRequired>
              <QTextarea
                data-cy="manage-approver-modal-comment"
                placeholder={textareaPlaceholder}
                value={reason}
                onChange={(event) => setReason(event.target.value)}
              />
            </QFormControl>
          )}
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButton
          data-cy="document-manage-approvers-cancel-button"
          variant="ghost"
          onClick={close}
        >
          Cancel
        </QButton>
        <QButton
          data-cy="document-manage-approvers-previous-button"
          variant="outline"
          onClick={onPrevious}
        >
          Previous
        </QButton>
        <QButton
          data-cy="document-manage-approvers-apply-changes-button"
          isLoading={isApplyingChanges}
          isDisabled={requiresComment && !reason}
          onClick={applyChanges}
        >
          Apply changes
        </QButton>
      </QModalActions>
    </QModal>
  );
};

import { z } from 'zod';

export const Answer = z.object({
  answer: z.string(),
  is_correct: z.boolean().optional(),
});

export const Question = z.object({
  id: z.number().optional(),
  question: z.string(),
  answers: Answer.array(),
});

export const TrainingAssessment = z.object({
  id: z.number(),
  description: z.string(),
  document_id: z
    .string()
    .transform((value) => Number.parseInt(value))
    .or(z.number()),
  questions: Question.array(),
});

export const TrainingAssessmentForm = z.object({
  questions: Question.array(),
});

export const TrainingAssessmentPayload = z.object({
  document_id: z.number(),
  questions: Question.omit({ id: true }).array(),
});

export const TrainingAssessmentAIResponse = z.object({
  rationale: z.string().array(),
  questions: z
    .object({
      question: z.string(),
      answers: z
        .object({
          answer: z.string(),
          is_correct: z.boolean(),
        })
        .array(),
    })
    .array(),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TrainingAssessment = z.infer<typeof TrainingAssessment>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Question = z.infer<typeof Question>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TrainingAssessmentPayload = z.infer<
  typeof TrainingAssessmentPayload
>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TrainingAssessmentForm = z.infer<typeof TrainingAssessmentForm>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TrainingAssessmentAIResponse = z.infer<
  typeof TrainingAssessmentAIResponse
>;

import { QBox, QStack, QText } from '@qualio/ui-components';

import { QualioDocumentAuditGroups } from '../../../../../api/model/document';
import { Group } from '../../../../../api/model/group';
import { Tag } from '../../../../../api/model/tag';
import { MedtechUserV2 } from '../../../../../api/user';

import { DocumentUsers } from '../DocumentUsers';
import { ManageReviewersButton } from './ManageReviewersButton';

export type ManageReviewersProps = {
  reviewers: QualioDocumentAuditGroups['review_group'];
  groups: Group[];
  tags: Tag[];
  users: MedtechUserV2[];
};

export const ManageReviewers = ({
  reviewers = [],
  tags,
  groups,
  users,
}: ManageReviewersProps) => {
  return (
    <QBox data-cy={`document-reviewers`}>
      <QStack
        isInline
        justifyContent="space-between"
        alignItems="center"
        data-cy="document-manage-reviewers"
      >
        <QText fontWeight="semibold" fontSize="md">
          Reviewers
        </QText>
        <ManageReviewersButton
          tags={tags}
          groups={groups}
          users={users}
          currentReviewers={reviewers}
        />
      </QStack>
      <DocumentUsers userType="reviewer" users={reviewers} />
    </QBox>
  );
};

import { z } from 'zod';

export const BadApproversProvidedErrorSchema = z
  .object({
    response: z.object({
      data: z.object({
        slug: z.literal('bad_approvers_provided'),
        bad_user_ids: z.number().array(),
      }),
    }),
  })
  .transform((data) => ({
    slug: data.response.data.slug,
    userIds: data.response.data.bad_user_ids,
  }));

import { CKEditorContext } from '@ckeditor/ckeditor5-react';
import { memo, useCallback, useContext, useEffect, useMemo } from 'react';

import { Context } from '@ckeditor/ckeditor5-core';
import DecoupledEditor from '@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor';
import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSearchParams } from 'react-router-dom';
import { changeControlApi } from '../../../../api/changeControl';
import { getContextConfig } from '../../../../config/DocumentEditorConfig';
import {
  createContextReadyHandler,
  enableCKEditorMode,
} from '../../../../config/handlers';
import { useAnnotationHighlighting } from '../../../../hooks/AnnotationHighlighting';
import { useCollabServerConnectionManager } from '../../../../hooks/CollabServerConnectionManager';
import { useElementIsRendered } from '../../../../hooks/ElementRenderedCheck';
import { CKEditorInstance } from '../../../../types/CKEditorInstance';
import { ChangeControlDTO } from '../../../../types/DocumentCreate';
import { addSectionToEditedSectionsList } from '../../../../util/ActivityLogUtils';
import { CKEditorUtils } from '../../../../util/CKEditorUtils';
import { isCommentsOnlyMode } from '../../../../util/EditModeUtils';
import { refreshDisplayMode } from '../../../../util/SidebarUtils';
import { ToolbarManager } from '../../../../util/ToolbarManager';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import {
  DocumentOverviewContext,
  EditorStatus,
  EditorStatusContext,
} from '../Context';
import { SectionedContentEditor } from '../DocumentEditor/DocumentSectionsManager/DocumentSectionEditor/SectionedContentEditor';
import { HTMLViewer } from '../HTMLViewer';

export interface ChangeControlSectionsManagerProps {
  changeControl: ChangeControlDTO;
  setEditors: any;
  editors: CKEditorInstance[];
  handleAttachmentClick: (attachmentId: string) => void;
}

export const ChangeControlSectionsManager = memo(
  ({
    changeControl,
    setEditors,
    editors,
    handleAttachmentClick,
  }: ChangeControlSectionsManagerProps) => {
    const {
      currentEditorMode,
      commentsPermission,
      collaborationToken,
      qualioDocument,
    } = useContext(DocumentOverviewContext);

    const { handleSessionDisconnectionEvent } =
      useCollabServerConnectionManager(qualioDocument);
    const currentUser = useCurrentUser();

    const { allowScrollOutOfView } = useFlags();

    const [isSideBarRendered, sidebarElement] = useElementIsRendered(
      '#changeControlSidebar',
    );
    const [, ccPresentListContainer] =
      useElementIsRendered('.cc-presence-list');

    const [isToolbarWrapperRendered, toolbarWrapper] = useElementIsRendered(
      '#changeControlToolbarWrapper',
    );
    const { editorBundleEnabled, editorAi, updateSmartlinkTitles } = useFlags();
    const aiEnabled = editorAi || currentUser.company.ai_enabled;
    const { showToast } = useToastProvider();
    const { setCCEditorStatus } = useContext(EditorStatusContext);
    const [searchParams] = useSearchParams();
    const targetId = searchParams.get('targetId') ?? undefined;
    const { handleHighlighting } = useAnnotationHighlighting();
    const contextConfig = useMemo(() => {
      return getContextConfig({
        collaborationToken,
        sidebarConfig: {
          sidebarElement,
          preventScrollOutOfView: !allowScrollOutOfView,
        },
        presenceListConfig: {
          containerElement: ccPresentListContainer,
        },
        editorBundleVersion: editorBundleEnabled
          ? process.env.REACT_APP_EDITOR_BUNDLE_VERSION
          : undefined,
        editorAiEnabled: aiEnabled,
      });
    }, [
      collaborationToken,
      sidebarElement,
      editorBundleEnabled,
      aiEnabled,
      ccPresentListContainer,
      allowScrollOutOfView,
    ]);

    useEffect(() => {
      setCCEditorStatus(EditorStatus.CONNECTING);

      return () => {
        setCCEditorStatus(EditorStatus.STABLE);
      };
    }, [setCCEditorStatus]);

    useEffect(() => {
      const tabPanels = document.getElementById('document-overview-tab-panels');
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.contentBoxSize) {
            refreshDisplayMode({
              instances: editors,
              windowWidth: entries[0].borderBoxSize[0].inlineSize ?? undefined,
              breakpointOverride: 1000,
            });
          }
        }
      });
      if (tabPanels) {
        observer.observe(tabPanels);
      }
    }, [editors]);

    useEffect(() => {
      if (editors.length > 0) {
        if (commentsPermission <= 1) {
          return;
        }
        enableCKEditorMode(
          currentEditorMode,
          editors,
          new ToolbarManager(toolbarWrapper),
          () => {
            //still do nothing?
          },
        );
      }
    }, [
      currentEditorMode,
      editors,
      handleAttachmentClick,
      toolbarWrapper,
      commentsPermission,
    ]);

    const onContextReady = useCallback(
      (context: Context) =>
        createContextReadyHandler(
          currentUser,
          (editors: CKEditorInstance[]) => {
            setEditors(editors);
          },
          commentsPermission,
          new ToolbarManager(toolbarWrapper),
          'changeControlCommentControl',
          handleAttachmentClick,
          'changeControlEditorContent',
          setCCEditorStatus,
          handleHighlighting,
          handleSessionDisconnectionEvent,
          targetId,
          updateSmartlinkTitles,
        )(context),
      [
        currentUser,
        commentsPermission,
        toolbarWrapper,
        handleAttachmentClick,
        setEditors,
        setCCEditorStatus,
        handleHighlighting,
        handleSessionDisconnectionEvent,
        targetId,
        updateSmartlinkTitles,
      ],
    );

    const autoSaveChangeControl = useCallback(
      async (editor: CKEditorInstance) => {
        if (commentsPermission <= 1) {
          return;
        }
        const sectionIdToUpdate = editor.config.get('sectionId') as number;
        const sectionData = editor.getData();
        const cloudSectionVersion =
          CKEditorUtils.getCloudDocumentVersion(editor) ?? null;
        const updatedSection = changeControl?.sections.find(
          (section) => section.id === sectionIdToUpdate,
        );
        if (!updatedSection) {
          return Promise.resolve();
        }
        const isCommentOrReadOnlyEditor =
          isCommentsOnlyMode(editor) || editor.isReadOnly;
        const trackChangesCommand = editor.commands.get('trackChanges');
        const isTrackChangesModeEnabled =
          trackChangesCommand && trackChangesCommand.value;

        const shouldCreateSectionEditActivityLog =
          !isCommentOrReadOnlyEditor && !isTrackChangesModeEnabled
            ? addSectionToEditedSectionsList(
                sectionIdToUpdate,
                'change_control',
              )
            : false;

        updatedSection.content = sectionData;
        updatedSection.type = 'text';
        updatedSection.cloud_section_version = cloudSectionVersion;

        return await changeControlApi
          .updateChangeControl(
            {
              ...changeControl,
              content_last_modified_at: Date.now() / 1000,
              sections: [updatedSection],
            },
            shouldCreateSectionEditActivityLog,
          )
          .catch((e) => {
            setCCEditorStatus(EditorStatus.ERROR);
            showToast({
              id: 'change-control-save-error',
              status: 'error',
              title: 'Error',
              description: `An error occurred during saving of this Change Control. Please try make another change to ensure data is saved. Error was ${e.message}`,
              replace: true,
            });
            return Promise.resolve();
          });
      },
      // Do not add showToast to this array, it will crash the editor mid render
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [changeControl, setCCEditorStatus, commentsPermission],
    );

    // https://github.com/ckeditor/ckeditor5/issues/13098
    const watchdogConfig = useMemo(() => {
      return {
        saveInterval: 2147483647,
      };
    }, []);

    const onContextError = useCallback(() => {
      return (msg: any) => {
        console.log('Error' + msg);
      };
    }, []);

    const isEditingSessionLoading = editors.length === 0;

    if (!isSideBarRendered || !isToolbarWrapperRendered) {
      return <LoadingSpinner></LoadingSpinner>;
    }

    return (
      <>
        {isEditingSessionLoading && (
          <HTMLViewer sections={changeControl.sections} />
        )}
        <div className={`${isEditingSessionLoading ? 'ck-hidden' : ''}`}>
          <CKEditorContext
            context={DecoupledEditor.Context}
            contextWatchdog={DecoupledEditor.ContextWatchdog}
            isLayoutReady={isSideBarRendered}
            config={contextConfig}
            watchdogConfig={watchdogConfig}
            onChangeInitializedEditors={(editors: any) => {
              CKEditorUtils.waitForAllEditorsToStartAndFireReadyHandler(
                editors,
                changeControl.sections.length,
                onContextReady,
              );
            }}
            onError={onContextError}
          >
            {changeControl.sections.map((section) => (
              <SectionedContentEditor
                key={`sectionEditor${section.position}`}
                section={section}
                handleAutoSave={autoSaveChangeControl}
                //always show section titles for change controls
                showSectionTitles={true}
                entityType={'change.section'}
                collaborationEntityId={changeControl.id!}
                collaborationEntityName={'cc'}
              />
            ))}
          </CKEditorContext>
        </div>
      </>
    );
  },
);

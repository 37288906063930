import { Dispatch, SetStateAction } from 'react';

export const useZoomHandler = (
  setZoom: Dispatch<SetStateAction<number>>,
  handleZoomChange?: (zoom: number) => void,
) => {
  return (offset: number) => {
    setZoom((prevZoom) => {
      if (prevZoom < 0.1 || (prevZoom === 0.1 && offset < 0)) {
        const newZoom = prevZoom + offset / 5;
        handleZoomChange && handleZoomChange(newZoom);
        return newZoom;
      } else {
        handleZoomChange && handleZoomChange(prevZoom + offset);
        return prevZoom + offset;
      }
    });
  };
};

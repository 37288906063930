import { QBox, QIcon, QSpinner, QStack, QText } from '@qualio/ui-components';
import { useContext } from 'react';
import { EditorMode } from '../../../../../util/EditModeUtils';
import {
  DocumentOverviewContext,
  EditorStatus,
  EditorStatusContext,
} from '../../Context';
import styles from './AutoSaveNotification.module.css';

export const AutoSaveNotification = () => {
  const { docEditorStatus, ccEditorStatus, trainingAssessmentStatus } =
    useContext(EditorStatusContext);

  const { currentEditorMode } = useContext(DocumentOverviewContext);

  const isConnecting =
    docEditorStatus === EditorStatus.CONNECTING ||
    ccEditorStatus === EditorStatus.CONNECTING;

  if (isConnecting) {
    return (
      <QStack className={`${styles['autosave-notification']}`}>
        <QSpinner data-cy="autosave-spinner" size="sm" />
      </QStack>
    );
  }

  //we don't show anything outside of edit/suggest
  if (currentEditorMode === EditorMode.VIEW) {
    return <QStack className={`${styles['autosave-notification']}`}></QStack>;
  }

  //handling saving states

  const isSaving =
    docEditorStatus === EditorStatus.SAVING ||
    ccEditorStatus === EditorStatus.SAVING ||
    trainingAssessmentStatus === EditorStatus.SAVING;

  const hasFinishedSaving =
    docEditorStatus === EditorStatus.SAVED ||
    ccEditorStatus === EditorStatus.SAVED ||
    trainingAssessmentStatus === EditorStatus.SAVED;

  if (docEditorStatus === EditorStatus.ERROR) {
    return (
      <QStack className={`${styles['autosave-notification']}`}>
        <QText>
          There was an error saving the recent Document edit. Please try again.
        </QText>
      </QStack>
    );
  } else if (ccEditorStatus === EditorStatus.ERROR) {
    return (
      <QStack className={`${styles['autosave-notification']}`}>
        <QText>
          There was an error saving the recent Change Control edit. Please try
          again.
        </QText>
      </QStack>
    );
  }

  if (isSaving) {
    return (
      <QStack
        direction={'row'}
        className={`${styles['autosave-notification']} ${styles['autosave-notification__fix-width']}`}
      >
        <QBox w="16px" h="16px" />
        <QText mt={0} ml={'2px !important'}>
          Saving changes
        </QText>
      </QStack>
    );
  } else if (hasFinishedSaving) {
    return (
      <QStack
        direction={'row'}
        className={`${styles['autosave-notification']} ${styles['autosave-notification__fix-width']}`}
      >
        <QIcon iconName={'Check'} size={'sm'} />
        <QText mt={0} ml={'2px !important'}>
          Changes saved
        </QText>
      </QStack>
    );
  }

  return (
    <QStack
      className={`${styles['autosave-notification']} ${styles['autosave-notification__fix-width']}`}
    ></QStack>
  );
};

export function debounce<T extends (...args: any[]) => any>(
  fn: T,
  wait: number,
) {
  let cancel: () => void;
  type ReturnT = Awaited<ReturnType<T>>;
  const wrapFunc = (...args: Parameters<T>): Promise<ReturnT> => {
    cancel && cancel();
    return new Promise((resolve) => {
      const timer = setTimeout(() => resolve(fn(...args)), wait);
      cancel = () => {
        clearTimeout(timer);
      };
    });
  };
  return wrapFunc;
}

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

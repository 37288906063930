import {
  QCenter,
  QDrawer,
  QSpinner,
  QStack,
  QText,
  useToastProvider,
} from '@qualio/ui-components';
import { documentApi } from '../../../api/document';
import { DocumentCommentHistoryCard } from './DocumentCommentHistoryCard/DocumentCommentHistoryCard';
import {
  parseFetchedCommentThreadsForDisplay,
  parseHistoricalCommentThreadsForDisplay,
} from './DocumentCommentHistoryUtil';

import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { changeControlApi } from '../../../api/changeControl';
import {
  COMMENT_HISTORY_FETCH_DATA_ERROR,
  logCustomError,
} from '../../../messages/LogErrorMessages';
import { ChangeControl } from '../../../types/DocumentCreate';
import { OverviewTabs } from '../DocumentOverview/DocumentOverview';
import styles from './DocumentCommentHistoryPanel.module.css';
import { HistoricalCommentHistoryCard } from './HistoricalCommentHistoryCard/HistoricalCommentHistoryCard';

type DocumentCommentHistoryPanelProps = {
  isOpen: boolean;
  onClose: any;
  companyId: number;
  documentId: string;
  changeControl?: ChangeControl;
};

const FROALA_CC_RETIRE_DATE = 1728691200;

export const DocumentCommentHistoryPanel = ({
  isOpen,
  onClose,
  companyId,
  documentId,
  changeControl,
}: DocumentCommentHistoryPanelProps) => {
  const { showToast } = useToastProvider();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab') ?? OverviewTabs.DOCUMENT;
  const title = `Comment history - ${
    tab === OverviewTabs.CHANGE_CONTROL ? 'Change control' : 'Document'
  }`;
  const loadComments = async (companyId: number, documentId: string) => {
    const context = tab === 'change-control' ? 'change-control' : 'document';
    return await documentApi
      .fetchCommentThreads(companyId, documentId, context)
      .then((fetchedThreads) =>
        parseFetchedCommentThreadsForDisplay(fetchedThreads),
      );
  };

  const { isFetching, data: commentThreads } = useQuery({
    queryKey: ['commentHistory', companyId, documentId],
    queryFn: () => loadComments(companyId, documentId),
    refetchOnWindowFocus: false,
    enabled: isOpen,
    onError: (error) => {
      showToast({
        id: 'error-comment-history',
        title: 'Error',
        description: 'Could not retrieve comment history',
        status: 'error',
      });
      logCustomError(COMMENT_HISTORY_FETCH_DATA_ERROR, { error });
    },
  });

  const loadHistoricalComments = async (
    changeControl: ChangeControl | undefined,
    tab: string,
  ) => {
    if (
      changeControl &&
      changeControl.created_time < FROALA_CC_RETIRE_DATE &&
      changeControl.status === 'open' &&
      tab === OverviewTabs.CHANGE_CONTROL
    ) {
      const fetchedCommentThreads =
        await changeControlApi.fetchHistoricalComments(changeControl.id);

      return parseHistoricalCommentThreadsForDisplay(fetchedCommentThreads);
    } else {
      return [];
    }
  };

  const { isFetching: isFetchingHistoricalComments, data: historicalComments } =
    useQuery({
      queryKey: ['historicalComments', companyId, changeControl, tab],
      queryFn: () => loadHistoricalComments(changeControl, tab),
      refetchOnWindowFocus: false,
      enabled: isOpen,
      onError: (error) => {
        showToast({
          id: 'error-comment-history',
          title: 'Error',
          description: 'Could not retrieve comment history',
          status: 'error',
        });
        logCustomError(COMMENT_HISTORY_FETCH_DATA_ERROR, { error });
      },
    });

  return (
    <QDrawer
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      title={
        <div
          className={styles['Document-Comment-History-Panel__drawer__title']}
          data-cy={'comment-history-title'}
        >
          {title}
        </div>
      }
    >
      <QStack fontSize="sm">
        {(isFetching || isFetchingHistoricalComments) && (
          <QStack
            height="100%"
            minHeight="60vh"
            justify="center"
            align="center"
          >
            <QSpinner />
            <QText data-cy="loading-comments-history">
              Loading comments...
            </QText>
          </QStack>
        )}
        {!isFetching &&
          !isFetchingHistoricalComments &&
          historicalComments?.length === 0 &&
          commentThreads?.length === 0 && (
            <QCenter data-cy="comments-history-empty-placeholder" height="100%">
              There are no comments yet
            </QCenter>
          )}
        {!isFetching &&
          commentThreads?.map((commentThread: any) => (
            <DocumentCommentHistoryCard
              commentThread={commentThread}
              key={commentThread.id}
            />
          ))}
        {!isFetchingHistoricalComments &&
          tab === OverviewTabs.CHANGE_CONTROL &&
          historicalComments?.map((commentThread: any) => (
            <HistoricalCommentHistoryCard
              commentThread={commentThread}
              key={commentThread.id}
            />
          ))}
      </QStack>
    </QDrawer>
  );
};

import { QButton, useCurrentUser } from '@qualio/ui-components';
import { useContext, useMemo, useState } from 'react';
import { DocumentVersion } from '../../../../../../api/model/document';
import { Group } from '../../../../../../api/model/group';
import { MedtechUserV2 } from '../../../../../../api/user';
import { DocumentOverviewContext } from '../../../Context';
import { shouldShowManageTraineesButton } from '../../../RenderLogic';
import { TraineesLookup } from '../TraineesLookup';

type ViewTraineesButtonProps = {
  versions?: DocumentVersion[];
  groups: Group[];
  users: MedtechUserV2[];
};
export const ViewTraineesButton = ({
  versions,
  groups,
  users,
}: ViewTraineesButtonProps) => {
  const currentUser = useCurrentUser();
  const { qualioDocument } = useContext(DocumentOverviewContext);
  const [isLookupModalOpen, setIsLookupModalOpen] = useState(false);
  const canViewTrainees =
    !shouldShowManageTraineesButton(qualioDocument, currentUser, versions) &&
    qualioDocument.trainees.length > 0;

  const currentTrainees = useMemo(
    () =>
      qualioDocument.trainees
        .filter((trainee) => !trainee.training_removed)
        .reduce((trainees: MedtechUserV2[], trainee) => {
          const user = users.find(({ id }) => id === trainee.id);
          return user ? trainees.concat(user) : trainees;
        }, []),
    [qualioDocument.trainees, users],
  );

  if (!canViewTrainees) {
    return null;
  }

  return (
    <>
      <QButton
        data-cy="document-view-trainees-button"
        variant="ghost"
        onClick={() => setIsLookupModalOpen(true)}
      >
        View
      </QButton>
      <TraineesLookup
        title="View trainees"
        subtitle="Users who have been assigned training on this document."
        isOpen={isLookupModalOpen}
        onClose={() => setIsLookupModalOpen(false)}
        trainees={currentTrainees}
        usersWithoutAccess={[]} // we do not care about document access in this modal
        groups={groups}
        isReadOnly
      />
    </>
  );
};

import { useRef } from 'react';

import {
  QButton,
  QIcon,
  QProgress,
  QStack,
  QText,
} from '@qualio/ui-components';

import {
  BULK_FILE_UPLOAD_FILES_LIMIT,
  FILE_UPLOAD_SIZE_LIMIT_MB,
  getFilesFromList,
} from '../../../util/FileUploadUtils';

import styles from '../BulkFileUpload/FileUploadDropzone.module.css';

import { IntegrationPicker } from '../IntegrationPicker';
import {
  QFilePickerError,
  QFilePickerMode,
  RemoteFileMetadata,
} from '../QFilePicker/index';

type FileUploadInputProps = {
  acceptedFileFormats: string[];
  isBulkUpload?: boolean;
  isDisabled?: boolean;
  isUploading: boolean;
  handleUpload: (files: File[] | null) => Promise<void>;
  handlePick?: (files: RemoteFileMetadata[]) => void;
  handlePickError?: (errors: QFilePickerError[]) => void;
};

export const FileUploadInput = ({
  handleUpload,
  isUploading,
  acceptedFileFormats,
  handlePick,
  handlePickError,
  isBulkUpload,
  isDisabled,
}: FileUploadInputProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFiles = async (files: FileList | null) => {
    if (isUploading) {
      return;
    }

    const filesArray = getFilesFromList(files);

    try {
      await handleUpload(filesArray);
    } finally {
      const input = fileInputRef.current;
      if (input) {
        input.value = '';
      }
    }
  };

  const handleBrowse = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const instructions = `Drag and drop your file${isBulkUpload ? 's' : ''} or`;
  const limits = `${
    isBulkUpload ? `Maximum ${BULK_FILE_UPLOAD_FILES_LIMIT} files. ` : ''
  } Up to ${FILE_UPLOAD_SIZE_LIMIT_MB} MB per file`;
  const loadingText = `Adding your file${isBulkUpload ? 's' : ''}...`;

  const shouldRenderIntegration = handlePick && handlePickError;

  const integrationPickerMode = isBulkUpload
    ? QFilePickerMode.MULTIPLE
    : QFilePickerMode.SINGLE;

  return (
    <>
      <input
        className={styles['dropzone-file-input']}
        aria-label="fileInput"
        id="fileInput"
        type="file"
        data-cy="fileInput"
        onChange={(e) => handleFiles(e.target.files)}
        ref={fileInputRef}
        accept={acceptedFileFormats.join(',')}
        multiple={isBulkUpload}
      />
      <div
        className={styles['dropzone-container']}
        style={{
          pointerEvents: isUploading || isDisabled ? 'none' : undefined,
        }}
        id="dropzone"
        data-cy="dropzone"
        // onDragOver needs to be defined here or else the browser just downloads the dropped files
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={(e) => {
          e.preventDefault();
          if (e.dataTransfer.files.length) {
            void handleFiles(e.dataTransfer.files);
          }
        }}
      >
        <div className={styles['dropzone-upload-label']}>
          {isUploading && (
            <QStack direction="column">
              <QStack
                direction="row"
                alignContent="center"
                justifyContent="center"
                color="blue"
                alignItems="center"
              >
                <QIcon iconName="Upload" />
                <QText data-cy="uploading-file-text">{loadingText}</QText>
              </QStack>
              <QProgress colorScheme="blue" size="sm" isIndeterminate />
            </QStack>
          )}
          {!isUploading && (
            <QStack spacing={4} paddingTop={8} paddingBottom={8}>
              <QText weight="semibold">{instructions}</QText>
              <QStack spacing="6px">
                <QStack spacing={2} direction="row" justifyContent="center">
                  <QButton
                    data-cy="bulk-import-browse-button"
                    variant="outline"
                    onClick={handleBrowse}
                  >
                    Browse
                  </QButton>
                  {shouldRenderIntegration && (
                    <IntegrationPicker
                      handlePick={handlePick}
                      handlePickError={handlePickError}
                      mode={integrationPickerMode}
                    />
                  )}
                </QStack>
                <QText color="gray.500">{limits}</QText>
              </QStack>
            </QStack>
          )}
        </div>
      </div>
    </>
  );
};

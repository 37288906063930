import {
  QAlert,
  QButton,
  QLink,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QStack,
  QText,
} from '@qualio/ui-components';
import { useMemo } from 'react';
import { SuppressedAssignedTraineesToDocuments } from '../../../../../../../api/document';
import { MedtechUserV2 } from '../../../../../../../api/user';

type SuppressedAssignmentsModalProps = {
  suppressedAssignments?: SuppressedAssignedTraineesToDocuments[];
  onClose: () => void;
};

const getElement = (
  code: string,
  documentId: number,
  suppressedAssignments?: Pick<MedtechUserV2, 'email' | 'full_name' | 'id'>[],
) =>
  suppressedAssignments?.map((trainee) => (
    <QStack key={trainee.id} direction="row">
      <QLink
        href={`/workspace/documents/${documentId}`}
        isExternal
        tabIndex={-1}
        marginBottom={1}
        data-cy={`trainee-already-assigned-${documentId}`}
      >
        {code}
      </QLink>
      <QText>-</QText>
      <QText>{trainee.full_name}</QText>
    </QStack>
  ));

export const SuppressedAssignmentsModal = ({
  suppressedAssignments,
  onClose,
}: SuppressedAssignmentsModalProps) => {
  const suppressedDescriptions = useMemo(
    () =>
      suppressedAssignments?.map((assignment) => {
        const alreadyAssigned = getElement(
          assignment.code,
          assignment.id,
          assignment?.suppressed?.current_trainee,
        );
        return [...(alreadyAssigned ?? [])];
      }),
    [suppressedAssignments],
  );

  return (
    <QModal
      onClose={onClose}
      isOpen={!!suppressedAssignments}
      data-cy="suppressed-training-assignment-modal"
      size="xl"
    >
      <QModalHeader>
        <QText>Assign trainees</QText>
      </QModalHeader>
      <QModalBody>
        <QStack spacing={6}>
          <QAlert
            title="Some users were unable to be assigned training."
            description="The users already have training assigned."
            status="warning"
          />
          <QText>
            The following users were unable to be assigned training on the
            following documents:
          </QText>
          <QStack spacing={3}>{suppressedDescriptions}</QStack>
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButton data-cy="close-suppressed-assignments-modal" onClick={onClose}>
          Dismiss
        </QButton>
      </QModalActions>
    </QModal>
  );
};

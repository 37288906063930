export const isEnv = (...env: string[]): boolean => {
  const curEnv = process.env.REACT_APP_ENV ?? '';
  return env.includes(curEnv);
};

export const getEditorVersionEnvironmentVariable = () => {
  const editorVersionNumber = process.env.REACT_APP_CKE_VERSION;
  if (!editorVersionNumber) {
    throw new Error('REACT_APP_CKE_VERSION undefined');
  }
  // ensure version matches semver format e.g.: 30.0.0
  if (!/\d{1,3}\.\d{1,3}\.\d{1,3}/.test(editorVersionNumber)) {
    throw new Error(
      `invalid format for REACT_APP_CKE_VERSION: ${editorVersionNumber}`,
    );
  }
  return `ckeditor-v${process.env.REACT_APP_CKE_VERSION}`;
};

import { QualioDocument } from '../../api/model/document';

export const buildDocumentCoverPage = (
  qualioDocument: QualioDocument,
  userTimeZone?: string,
): string => {
  return `
    <div>
        ${
          qualioDocument.company_logo
            ? `
          <div class="company-logo">
            <img alt='Company Logo Image' src="data:image/png;base64,${qualioDocument.company_logo}" />
          </div>`
            : ''
        }
        <table class='doc-cover${
          qualioDocument.company_logo ? ' doc-cover-with-image' : ''
        }'>
          <tr>
            <td class='pdf-left-col'>
              <p>Title:</p>
            </td>
            <td class='pdf-right-col'>
              <div class='doctitle'>
                <p style='font-weight: bold;'>${qualioDocument.title}</p>
              </div>
            </td>
          </tr>
          <tr>
            <td class='pdf-left-col'>
              <p>Document type:</p>
            </td>
            <td class='pdf-right-col'>
              <div class='doctitle'>
                <p style='font-weight: bold;'>${qualioDocument.type}</p>
              </div>
            </td>
          </tr>
          <tr>
            <td class='pdf-left-col'>
              <p>Document ID:</p>
            </td>
            <td class='pdf-right-col'>
              <div class='doctitle'>
                <p style='font-weight: bold;'>${qualioDocument.code}</p>
              </div>
            </td>
          </tr>
          ${
            qualioDocument.issue?.code
              ? `<tr>
                <td class='pdf-left-col'>
                    <p>Issue ID:</p>
                </td>
                <td class='pdf-right-col'>
                    <div class='doctitle'>
                        <p style='font-weight: bold;'>${qualioDocument.issue.code}</p>
                    </div>
                </td>
            </tr>`
              : ''
          }
          <tr>
            <td class='pdf-left-col separator' colspan='2'>
            </td>
          </tr>
          <tr>
            <td class='pdf-left-col'>
              <p>Author:</p>
            </td>
            <td class='pdf-right-col'>
              <p>${qualioDocument.author.full_name}</p>
            </td>
          </tr>
          <tr>
            <td class='pdf-left-col'>
              <p>Owner:</p>
            </td>
            <td class='pdf-right-col'>
              <p>${qualioDocument.owner.full_name}</p>
            </td>
          </tr>
          ${
            qualioDocument.reviewers_and_timestamps &&
            qualioDocument.reviewers_and_timestamps.length > 0
              ? `<tr>
              <td class='pdf-left-col'>
                <p>Reviewer(s):</p>
              </td>
              <td class='pdf-right-col'>
              ${buildReviewerString(qualioDocument.reviewers_and_timestamps)}
              </td>
            </tr>`
              : ''
          }
          <tr>
              <td class='pdf-left-col'>
                  <p>Approver(s):</p>
              </td>
              <td class='pdf-right-col'>
                ${buildApproverString(qualioDocument)}
              </td>
          </tr>
          <tr>
            <td class='pdf-left-col'>
              <p>Approval date:</p>
            </td>
            <td class='pdf-right-col'>
              <p>${parseTimestamp(
                `${qualioDocument.approval_date ?? ''}`,
                userTimeZone,
              )}</p>
            </td>
          </tr>
          <tr>
            <td class='pdf-left-col'>
              <p>Effective date:</p>
            </td>
            <td class='pdf-right-col'>
              <p>${parseTimestamp(
                `${qualioDocument.effective_date ?? ''}`,
                userTimeZone,
              )}</p>
            </td>
          </tr>
          <tr>
            <td class='pdf-left-col'>
              <p>Next periodic review date:</p>
            </td>
            <td class='pdf-right-col'>        
              <p>${
                qualioDocument.review_date
                  ? parseTimestamp(
                      qualioDocument.review_date.toString(),
                      userTimeZone,
                    )
                  : 'N/A'
              }</p>
            </td>
          </tr>
          ${
            qualioDocument.status_id !== 'effective'
              ? `<tr>
                <td class='pdf-left-col'>
                  <p>Superseded date:</p>
                </td>
                <td class='pdf-right-col'>
                  <p>${parseTimestamp(
                    qualioDocument.superseded_date ?? '',
                    userTimeZone,
                  )}</p>
                </td>
            </tr>`
              : ''
          }
       </table>
    </div>
  <div class='page-break' style='page-break-after:always;'><span style='display:none;'>&nbsp;</span></div>`;
};

const parseTimestamp = (timestamp: string, tz: string | undefined) => {
  const dateTime = new Date(parseInt(timestamp) * 1000);
  if (!timestamp || timestamp === '') {
    return '';
  }
  if (tz) {
    return dateTime.toLocaleDateString('en-CA', { timeZone: tz });
  } else {
    return dateTime.toISOString().split('T')[0];
  }
};

const buildApproverString = (qualioDocument: QualioDocument) => {
  let result = '';
  if (qualioDocument.approvers_and_timestamps) {
    qualioDocument.approvers_and_timestamps.forEach((audit) => {
      result += `              
            <div class="avoid-break">
                <p>${audit[0]}<br/>
                    <span class="pdf-approve-date">approved at ${audit[1]}</span>
                </p>
            </div>`;
    });
  }
  return result;
};

const buildReviewerString = (reviewersAndTimestamps: string[][]) => {
  let result = '';
  reviewersAndTimestamps.forEach((audit) => {
    result += `              
          <div class="avoid-break">
              <p>${audit[0]}<br/>
                  <span class="pdf-approve-date">
                  reviewed at ${audit[1]}
                  </span></p>
          </div>`;
  });
  return result;
};

import { CKEditorInstance } from '../types/CKEditorInstance';
import { isCommentsOnlyMode } from './EditModeUtils';

export class ToolbarManager {
  private readonly toolbarWrapperElement;

  constructor(toolbarWrapperElement: HTMLElement) {
    this.toolbarWrapperElement = toolbarWrapperElement;
  }

  hideToolbar = (editor: CKEditorInstance): void => {
    const toolbarElement = editor.ui.view.toolbar.element;
    if (toolbarElement) {
      toolbarElement.style.display = 'none';
    }
  };

  showToolbar = (editor: CKEditorInstance): void => {
    const toolbarElement = editor.ui.view.toolbar.element;
    const toolbarWrapper = this.toolbarWrapperElement;
    if (toolbarElement && toolbarWrapper) {
      toolbarElement.style.display = 'flex';
    }
  };

  getEditorToolbarElement = (editor: CKEditorInstance): HTMLElement => {
    const toolbarElement = editor.ui.view.toolbar.element;
    if (toolbarElement) {
      return toolbarElement;
    }
    throw Error('could not obtain editor toolbar');
  };

  removeEditorToolbar = (toolbar: Element): void => {
    this.toolbarWrapperElement.removeChild(toolbar);
  };

  addEditorToolbar = (toolbarElement: Element): void => {
    const toolbarParentElementFirstChild =
      this.toolbarWrapperElement.firstChild;
    this.toolbarWrapperElement.insertBefore(
      toolbarElement,
      toolbarParentElementFirstChild,
    );
  };

  setToolbarFromEditor = (editor: CKEditorInstance): void => {
    const existingToolbarElement =
      this.toolbarWrapperElement.querySelector('[role="toolbar"]');
    if (existingToolbarElement) {
      this.removeEditorToolbar(existingToolbarElement);
    }
    const toolbarElement = this.getEditorToolbarElement(editor);
    this.addEditorToolbar(toolbarElement);
    if (!isCommentsOnlyMode(editor) && !editor.isReadOnly) {
      this.showToolbar(editor);
    }
  };

  configureEditorToShowToolbarOnFocus = (editor: CKEditorInstance): void => {
    editor.editing.view.document.on(
      'change:isFocused',
      (event: any, data: any, isFocused: boolean) => {
        if (isFocused) {
          this.setToolbarFromEditor(editor);
        }
      },
    );
  };
}

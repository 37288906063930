import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext, useState } from 'react';
import { useQuery } from 'react-query';

import {
  QDivider,
  QIcon,
  QLink,
  QStack,
  QText,
  useCurrentUser,
} from '@qualio/ui-components';

import { changeManagementApi } from '../../../../../api/changeManagementApi';

import ChangeRequestSideDrawer from '../../../ChangeRequestSideDrawer/ChangeRequestSideDrawer';
import { DocumentOverviewContext } from '../../Context';
import { shouldRenderChangeRequest } from '../../RenderLogic';

export const ChangeRequest = () => {
  const currentUser = useCurrentUser();
  const { qualioDocument } = useContext(DocumentOverviewContext);
  const { changeRequestTransition } = useFlags();
  const [sidePanelOpen, setSidePanelOpen] = useState(false);

  const fetchChangeRequestItem = async () => {
    return changeManagementApi.getChangeRequestForDoc(
      currentUser.companyId,
      qualioDocument,
    );
  };

  const { data: changeRequestItem, isLoading } = useQuery({
    queryKey: ['changeRequest'],
    queryFn: fetchChangeRequestItem,
    refetchOnWindowFocus: false,
  });

  if (
    isLoading ||
    !changeRequestItem ||
    !shouldRenderChangeRequest(
      currentUser,
      changeRequestItem,
      changeRequestTransition,
    )
  ) {
    return null;
  }

  return (
    <>
      <QStack spacing="18px">
        <QText fontWeight="semibold" fontSize="md">
          Change requests
        </QText>
        <QStack isInline spacing={3}>
          <QIcon iconName="ResourceCR" size="md" />
          <QLink
            data-cy="change-request-sidedrawer"
            onClick={() => setSidePanelOpen(true)}
          >
            {changeRequestItem.code}
          </QLink>
        </QStack>
      </QStack>
      <QDivider mt="6" mb="6" />
      {sidePanelOpen && (
        <ChangeRequestSideDrawer
          changeRequestId={changeRequestItem.id}
          onClose={() => setSidePanelOpen(false)}
        />
      )}
    </>
  );
};

import { formatDateFromTimestamp } from './DateUtils';

export const updateLastModifiedTimeOnSave = (
  lastUpdated: string | number,
  tz?: string | undefined,
) => {
  const date = formatDateFromTimestamp(lastUpdated, tz);
  const documentPopertiesTitles = document.querySelectorAll(
    '.document-properties b',
  );
  const lastModifiedTitle = Array.from(documentPopertiesTitles).find(
    (title) => title.textContent === 'Last modified',
  );
  const lastModifiedValue = lastModifiedTitle?.nextElementSibling;
  if (lastModifiedValue) {
    // we assign the correct date to the
    // last modified value span in MTFE
    lastModifiedValue.textContent = date;
  }
};

import { CurrentUser } from '@qualio/ui-components';

import { DocumentStatus } from '../../../api/model/document';
import { ALL_OPEN_STATUSES, WorkspaceTabProps, WorkspaceTabs } from './types';

export const getDefaultStatus = (
  tab: WorkspaceTabs | null,
  currentUser: CurrentUser,
) => {
  return !currentUser.permissions.can_view_all_docs &&
    tab === WorkspaceTabs.ALL_DOCUMENTS
    ? DocumentStatus.Effective
    : ALL_OPEN_STATUSES;
};

export const buildSelectedStatus = (
  statusParam: string | null,
  currentUser: CurrentUser,
  currentTab: WorkspaceTabs | null,
  currentTabConfig: WorkspaceTabProps,
) => {
  if (statusParam === null) {
    const defaultStatus = getDefaultStatus(currentTab, currentUser);
    return currentTabConfig.statusFiltersMap[defaultStatus].statusFilters;
  } else {
    return currentTabConfig.statusFiltersMap[statusParam].statusFilters;
  }
};

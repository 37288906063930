import { AxiosError } from 'axios';
import { MouseEvent } from 'react';
import { QualioDocument, QualioDocumentSection } from '../api/model/document';
import { EditorName } from '../config/handlers';
import { CKEditorInstance } from '../types/CKEditorInstance';
import { navigateToSection } from './DocumentNavigationUtils';

const attachmentsDoNotExist = (msg?: string) =>
  msg && msg.indexOf('do not exist') > -1;

const attachmentsDoNotHavePermissions = (msg?: string) =>
  msg && msg.indexOf('permission') > -1;

const buildAttachmentsDoNotExistMsg = (
  section: QualioDocumentSection | undefined,
  msg?: string,
) => {
  if (!msg || !section) {
    return '';
  }
  const msgParts = msg.split('content');

  return `${msgParts[0]} section <section-link>${section.title}</section-link> does not exist`;
};

const buildMissingAttachmentsPermissionsMsg = (
  section: QualioDocumentSection | undefined,
  msg?: string,
) => {
  if (!msg || !section) {
    return '';
  }
  const msgParts = msg.split('referenced');

  return `${msgParts[0]}one or more attachments in section <section-link>${section.title}</section-link>`;
};

const buildGenericAttachmentsErrorMsg = (
  section: QualioDocumentSection | undefined,
) => {
  if (!section) {
    return '';
  }

  return `There is an issue with one or more attachments in section <section-link>${section.title}</section-link>`;
};

export const buildAttachmentsErrorMessage = (
  msg?: string,
  sectionEditor?: CKEditorInstance,
  doc?: QualioDocument,
) => {
  const sectionId = sectionEditor?.config.get('sectionId');
  const section = doc?.sections.reduce((s, sec, idx) => {
    if (sec.id === sectionId) {
      return { ...sec, title: `${idx + 1}. ${sec.title}` };
    } else {
      return s;
    }
  }, {} as QualioDocumentSection);

  let newMsg;

  if (attachmentsDoNotExist(msg)) {
    newMsg = buildAttachmentsDoNotExistMsg(section, msg);
  } else if (attachmentsDoNotHavePermissions(msg)) {
    newMsg = buildMissingAttachmentsPermissionsMsg(section, msg);
  } else {
    newMsg = buildGenericAttachmentsErrorMsg(section);
  }

  return newMsg;
};

export const isAttachmentError = (msg?: string) => {
  return msg?.length && msg?.indexOf('attachment') > -1;
};

export const buildToastDescription = (
  msg?: string | undefined | null,
  editorName: EditorName = 'documentEditorContent',
) => {
  if (!msg || !msg.length || msg.indexOf('section-link') === -1) {
    return msg;
  }
  const sectionLinkStartTag = '<section-link>';
  const sectionLinkEndTag = '</section-link>';
  const startLink = msg.indexOf(sectionLinkStartTag);
  const endLink = msg.indexOf(sectionLinkEndTag);
  const sectionTitle = msg.substring(
    startLink + sectionLinkStartTag.length,
    endLink,
  );

  const clickHandler = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    navigateToSection(parseInt(sectionTitle[0]) - 1, editorName);
  };

  return (
    <>
      {`${msg.substring(0, startLink)}`}
      <button role="link" style={{ color: '#0069ff' }} onClick={clickHandler}>
        {sectionTitle}
      </button>
      {` ${msg.substring(endLink + sectionLinkEndTag.length + 1)}`}.{' '}
      <b>Content will not be saved</b>
    </>
  );
};

export const getHttpError = (
  e: unknown,
  defaultMessage = 'Something went wrong. Please try again.',
) => {
  if (
    e instanceof AxiosError &&
    e.response?.data.message &&
    typeof e.response?.data.message === 'string'
  ) {
    return new Error(e.response?.data.message);
  }
  if (e instanceof Error) {
    return e;
  }
  return new Error(defaultMessage);
};

import { BaseDocumentConvertModalProps } from './BaseDocumentConvertModal';

type DocumentFileFormat =
  | 'DocumentUpload'
  | 'DocumentUploadOneDrive'
  | 'QualioNativeDocument';
type DocumentFileFormatMessageConfig = Pick<
  BaseDocumentConvertModalProps,
  'messageList' | 'warningText' | 'messageListPrompt'
>;

export const DocumentConvertMessageConfig: {
  [k in DocumentFileFormat]: DocumentFileFormatMessageConfig;
} = {
  DocumentUploadOneDrive: {
    warningText: "This document's connection to OneDrive will be removed.",
    messageListPrompt:
      "You're converting this file to an editable Qualio document. Before changing the format, be sure to remember:",
    messageList: [
      'The connection to the OneDrive source document will be lost.',
      'The document will now be editable in Qualio, and the original file from OneDrive will be attached in the first section.',
    ],
  },
  DocumentUpload: {
    warningText: 'This file will become an editable Qualio document.',
    messageListPrompt:
      "You're converting this file to an editable Qualio document. Before changing the format, be sure to remember:",
    messageList: [
      'The document will now be editable in Qualio.',
      'If a file was added, it will be attached in the first section.',
    ],
  },
  QualioNativeDocument: {
    warningText:
      'This document will no longer be editable within the Qualio editor.',
    messageList: [
      'The selected attachment will replace all content and other attachments',
      'Any edits on this minor version will be lost upon format change.',
    ],
  },
};

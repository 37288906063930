import { Element, Model, Text } from '@ckeditor/ckeditor5-engine';
import { documentApi } from '../../api/document';
import { QriBulkDetails, QriDetails } from '../../api/model/qri';
import qriServiceApi from '../../api/qriServiceApi';
import {
  logCustomError,
  SMART_LINK_PLUGIN_BULK_RESOLVE_ERROR,
} from '../../messages/LogErrorMessages';
import { CKEditorInstance } from '../../types/CKEditorInstance';
import { StringEscapeUtils } from '../../util/StringEscapeUtils';

interface ResolvedSmartLinkInformation {
  code: string;
  document_matrix_id: string;
  id: number;
  modified_time: number;
  rank: number;
  status_id: string;
  title: string;
}

const unresolvedLinkToken = '[unresolved link]';

type SmartlinkInfoMap = { [code: string]: ResolvedSmartLinkInformation };

const parser = new DOMParser();

export const getDocCodeOrMatrixId = (
  docCodeOrMatrixIdWithSign: string,
): string => {
  return docCodeOrMatrixIdWithSign.split('@')[1]!;
};

export const extractQrisAndCodesAndMatrixId = (
  smartLinkElements: HTMLCollectionOf<globalThis.Element>,
) => {
  const qris = new Set<string>();
  const docCodes: Set<string> = new Set();
  const docMatrixIds: Set<string> = new Set();
  [...smartLinkElements].forEach((smartLink) => {
    const qri = smartLink.getAttribute('data-qri');
    if (qri && qri !== 'undefined') {
      qris.add(qri);
      return;
    }
    const dataMentionWithSign = smartLink.getAttribute('data-mention');
    if (dataMentionWithSign) {
      docCodes.add(getDocCodeOrMatrixId(dataMentionWithSign)!);
      return;
    }
    const mentionIdWithSign = smartLink.getAttribute('mention-id');
    if (mentionIdWithSign) {
      const mentionId = getDocCodeOrMatrixId(mentionIdWithSign);
      if (smartLink.innerHTML.includes(mentionId)) {
        docCodes.add(mentionId);
      } else {
        docMatrixIds.add(mentionId);
      }
      return;
    }
    const docCode = getMentionIdFromText(smartLink.innerHTML);
    docCodes.add(docCode);
  });

  return { qris, docCodes, docMatrixIds };
};

export const generateSmartlinkInfoMap = async (
  companyId: number,
  docCodes: Set<string>,
  docMatrixIds: Set<string>,
) => {
  const smartlinkInfoMap: SmartlinkInfoMap = {};

  if (docCodes.size > 0) {
    const codesDetails = await documentApi.smartLinksResolve(
      companyId,
      docCodes,
    );
    codesDetails.forEach((smartlinkInfo) => {
      smartlinkInfoMap[smartlinkInfo.code] = smartlinkInfo;
    });
  }

  if (docMatrixIds.size > 0) {
    const matrixIdsDetails = await documentApi.smartLinksMatrixResolve(
      companyId,
      docMatrixIds,
    );
    matrixIdsDetails.forEach((smartlinkInfo) => {
      smartlinkInfoMap[smartlinkInfo.document_matrix_id] = smartlinkInfo;
    });
  }

  return smartlinkInfoMap;
};

export const formResolvedQriDisplayText = (qriDetails: QriDetails) => {
  if (qriDetails.identifiers.code) {
    if (['user', 'registry-item'].includes(qriDetails.resource)) {
      return `${qriDetails.name} (${qriDetails.identifiers.code})`;
    }
    return `${qriDetails.identifiers.code} ${qriDetails.name}`;
  }
  return qriDetails.name;
};

export const getSmartlinkTextByQri = (
  smartlinkText: string,
  qriBulkDetails: QriBulkDetails,
  qri: string,
) => {
  const qriDetails = qriBulkDetails[qri];

  if (qriDetails) {
    return getNewQriSmartlinkText(qriDetails);
  } else if (!smartlinkText.includes(unresolvedLinkToken)) {
    return smartlinkText + ' ' + unresolvedLinkToken;
  }
};

export const getNewQriSmartlinkText = (qriDetails: QriDetails): string => {
  return StringEscapeUtils.specialEscapeForCkEditor(
    formResolvedQriDisplayText(qriDetails),
  );
};

export const updateOutdatedSmartLinkTitlesV2 = async (
  editor: CKEditorInstance,
) => {
  const root = editor.model.document.getRoot('main');
  const companyId = editor.config.get('companyId') as number;
  if (!root || !companyId) {
    return;
  }

  const editorContent = editor.getData() ?? '';

  const doc = parser.parseFromString(editorContent, 'text/html');
  const smartLinkElements = doc.getElementsByClassName('docreference');

  if (smartLinkElements.length === 0) {
    return;
  }

  const { qris, docCodes, docMatrixIds } =
    extractQrisAndCodesAndMatrixId(smartLinkElements);

  const qriBulkDetails = await qriServiceApi.getBulkDetails(
    process.env.REACT_APP_QRI_SERVICE_URL!,
    [...qris],
  );
  const smartlinkInfoMap = await generateSmartlinkInfoMap(
    companyId,
    docCodes,
    docMatrixIds,
  );

  if (!qriBulkDetails && Object.keys(smartlinkInfoMap).length === 0) {
    return;
  }

  try {
    recursivelyReplaceMentionTextNodes(
      editor,
      Array.from(root.getChildren()) as any,
      qriBulkDetails,
      smartlinkInfoMap,
    );
  } catch (error) {
    logCustomError(SMART_LINK_PLUGIN_BULK_RESOLVE_ERROR, { error });
  }
};

export const getMentionIdFromText = (text: string) => {
  return text.split(' ')[0];
};

export const getMentionIdFromMentionValues = (mentionValues: any) => {
  const mentionIdWithMarker = mentionValues.mentionId ?? mentionValues.id;
  if (mentionIdWithMarker) {
    return getDocCodeOrMatrixId(mentionIdWithMarker);
  }

  return getMentionIdFromText(mentionValues._text);
};

export const recursivelyReplaceMentionTextNodes = (
  editor: CKEditorInstance,
  searchNodes: Element[],
  qriBulkDetails: QriBulkDetails | undefined,
  smartlinkInfoMap: SmartlinkInfoMap,
) => {
  return searchNodes.forEach((node) => {
    if (node.is('$text') && node.hasAttribute('mention')) {
      const mentionValues: any = node.getAttribute('mention');
      const qri = mentionValues.qri;

      if (qri) {
        const newSmartlinkText = getSmartlinkTextByQri(
          node.data,
          qriBulkDetails!,
          qri,
        );
        if (newSmartlinkText) {
          replaceSmartLinkText(editor, node, newSmartlinkText);
        }
      }

      const mentionId = getMentionIdFromMentionValues(mentionValues);
      if (mentionId) {
        const smartlinkInfo = smartlinkInfoMap[mentionId];
        if (smartlinkInfo) {
          replaceTextNode(
            node,
            editor.model,
            smartlinkInfo.code + ' ' + smartlinkInfo.title,
            smartlinkInfo.document_matrix_id,
          );
        } else if (!node.data.includes(unresolvedLinkToken)) {
          replaceTextNode(
            node,
            editor.model,
            node.data + ' ' + unresolvedLinkToken,
          );
        }
      }
    }
    if (!node.is('$text') && node.getChildren()) {
      recursivelyReplaceMentionTextNodes(
        editor,
        Array.from(node.getChildren()) as any,
        qriBulkDetails,
        smartlinkInfoMap,
      );
    }
  });
};

export const replaceSmartLinkText = (
  editor: any,
  textNode: Text,
  smartlinkText: string,
) => {
  editor.model.enqueueChange((writer: any) => {
    const position = writer.createPositionAt(textNode, 'before');
    const mentionAttribute = textNode.getAttribute('mention') as any;
    const qri = mentionAttribute.qri;

    let smartLink = `<a class="docreference qualio_resource resource_${mentionAttribute.resource}" data-qri="${qri}" href="${qri}" target="_blank">${smartlinkText}</a>`;
    if (mentionAttribute.resource === 'user') {
      smartLink = `<a class="docreference qualio_resource resource_user unclickable" data-qri="${qri}">${smartlinkText}</a>`;
    }

    const htmlDP = editor.data.processor;
    const viewFragment = htmlDP.toView(smartLink);
    const modelFragment = editor.data.toModel(viewFragment);

    writer.remove(textNode);
    editor.model.insertContent(modelFragment, position);
  });
};

export const replaceTextNode = (
  textNode: Text,
  model: Model,
  newText: string,
  updatedMentionId?: string,
) => {
  model.enqueueChange((writer: any) => {
    const position = writer.createPositionAt(textNode, 'before');
    const mentionAttribute = textNode.getAttribute('mention') as any;
    mentionAttribute._text = newText;
    mentionAttribute.text = newText;
    if (updatedMentionId) {
      mentionAttribute.mentionId = '@' + updatedMentionId;
    }

    if (!mentionAttribute.mentionId) {
      mentionAttribute.mentionId =
        mentionAttribute.id ??
        '@' + getMentionIdFromText(mentionAttribute._text);
    }
    if (!mentionAttribute.resource) {
      mentionAttribute.resource = 'document';
    }

    writer.remove(textNode);
    const newTextNode = writer.createText(newText, {
      mention: mentionAttribute,
    });
    model.insertContent(newTextNode, position);
  });
};

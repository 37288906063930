import { QBox, QText } from '@qualio/ui-components';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import styles from './FileDocumentLoadingIndicator.module.css';

export const FileDocumentLoadingIndicator = () => {
  return (
    <QBox className={styles['loading-spinner-wrapper']}>
      <QText data-cy="loading-text" id={'loadingMessage'} fontSize={'sm'}>
        A preview of the document is being generated
      </QText>
      <QBox>
        <LoadingSpinner />
      </QBox>
    </QBox>
  );
};

import { QTagProps } from '@qualio/ui-components';
import { DocumentStatus } from '../api/model/document';
import { MedtechUserV2 } from '../api/user';

export const getUserOptions = (users: MedtechUserV2[]) => {
  return users
    .filter((user) => user.permissions.includes('can_author_doc'))
    .map((user) => {
      const idStr = user.id.toString();
      return {
        label: user.full_name,
        value: idStr,
        properties: {
          key: idStr,
          id: idStr,
        },
      };
    });
};

type DocumentStatusConfig = {
  label: string;
  color: QTagProps['variantColor'];
};

export const DocumentStatusIdToStatusConfigMap: {
  [statusId in DocumentStatus]: DocumentStatusConfig;
} = {
  [DocumentStatus.Approved]: {
    label: 'Approved',
    color: 'purple',
  },
  [DocumentStatus.Approval_Declined]: {
    label: 'Approval declined',
    color: 'orange',
  },
  [DocumentStatus.Deleted]: {
    label: 'Deleted',
    color: 'red',
  },
  [DocumentStatus.Draft]: {
    label: 'Draft',
    color: 'gray',
  },
  [DocumentStatus.Effective]: {
    label: 'Effective',
    color: 'green',
  },
  [DocumentStatus.For_Approval]: {
    label: 'For approval',
    color: 'blue',
  },
  [DocumentStatus.For_Periodic_Review]: {
    label: 'For periodic review',
    color: 'blue',
  },
  [DocumentStatus.For_Retirement]: {
    label: 'For retirement',
    color: 'red',
  },
  [DocumentStatus.For_Review]: {
    label: 'For review',
    color: 'blue',
  },
  [DocumentStatus.Retired]: {
    label: 'Retired',
    color: 'red',
  },
  [DocumentStatus.Superseded]: {
    label: 'Superseded',
    color: 'red',
  },
};

import { QText } from '@qualio/ui-components';
import * as mime from 'mime';
import { integrationsApiClient } from '../api';
import {
  QualioDocument,
  QualioDocumentAttachment,
} from '../api/model/document';
import {
  EXTERNAL_SOURCE_FILE_ERROR,
  logCustomError,
} from '../messages/LogErrorMessages';
import {
  DownloadUrlResponse,
  downloadUrlResponseSchema,
} from '../views/components/FileDocumentEditor/types';
import {
  QFilePickerError,
  RemoteFileMetadata,
} from '../views/components/QFilePicker';

export const ACCEPTED_FILE_DOCUMENT_EXTS: string[] = [
  '.png',
  '.bmp',
  '.jpg',
  '.pdf',
  '.doc',
  '.docx',
  '.odp',
  '.pptx',
];

export const BULK_FILE_UPLOAD_PAGE_DESCRIPTION_MESSAGE = (
  <QText fontSize="sm">
    Import your external files into Qualio (.docx, .pdf, images or .pptx). Upon
    import,
    <br />
    these files will be created as draft documents, which can then be approved
    and made effective as needed.
  </QText>
);

export const FILE_UPLOAD_SIZE_LIMIT_MB = 100;
export const BULK_FILE_UPLOAD_FILES_LIMIT = 50;

export const getFileAttachmentFromFileUploadDocument = (
  fileDocument: QualioDocument,
) => {
  let attachmentId: number;
  const section = fileDocument.sections[0];
  if (section.content?.length > 0 && isNumber(section.content)) {
    attachmentId = +section.content;
  }
  const fileDocumentAttachment =
    fileDocument.sections[0].section_attachments?.find(
      (attachment) => attachment.id === attachmentId,
    );
  return fileDocumentAttachment;
};

export const translateExtensionToMimeType = (ext: string) => {
  return mime.getType(ext) ?? '';
};

export const removeFileExtension = (filename: string) =>
  translateExtensionToMimeType(getFileExtension(filename) ?? '')
    ? filename.split('.').slice(0, -1).join('.')
    : filename; // Re-join with periods in case there are any periods in the filename

export const getFileExtension = (filename: string) => {
  const parts = filename.split('.');
  if (parts.length > 1) {
    return parts[parts.length - 1];
  } else {
    return undefined;
  }
};

export const fileTypeFilter = (attachment: QualioDocumentAttachment) => {
  return (
    attachment.preview_state !== 'unsupported' &&
    checkFileExtensionMatchesAcceptedMimeTypes(attachment.filename)
  );
};

export const checkFileExtensionMatchesAcceptedMimeTypes = (
  filename: string,
) => {
  const fileExtension = getFileExtension(filename);
  if (!fileExtension) {
    return false;
  }
  const mimeType = mime.getType(fileExtension);
  const allowed_mime_types = ACCEPTED_FILE_DOCUMENT_EXTS.map(
    translateExtensionToMimeType,
  );
  return mimeType && allowed_mime_types.includes(mimeType);
};

export const getFilesFromList = (files: FileList | null) => {
  if (files === null) {
    return files;
  }

  return Array.from(files);
};

export const ExternalFileToastMessages: {
  [name: string]: { title: string; message: string };
} = {
  FETCH_LATEST_FAILED: {
    title: 'Failed to update',
    message:
      'The document failed to update to the latest version of the source file.',
  },
  FETCH_LATEST_SUCCESS: {
    title: 'Updated!',
    message:
      'The document was successfully updated to the latest version of the source file.',
  },
  ADD_FILE_FAILED: {
    title: 'Failed to add file',
    message: 'There was an error while adding the file. Please try again.',
  },
  ADD_MULTIPLE_FILES_FAILED: {
    title: 'Failed to add files',
    message:
      'There was an error while adding some of the files. Please try again.',
  },
} as const;

export const isNumber = (text: string): boolean => /^\d+$/.test(text);

export const buildErrorPickHandler = (
  handlePickError: (
    errorProps: (typeof ExternalFileToastMessages)[keyof typeof ExternalFileToastMessages],
    message?: string | undefined,
  ) => void,
  multiple: boolean,
) => {
  return (errors: QFilePickerError[]) => {
    logCustomError(EXTERNAL_SOURCE_FILE_ERROR, { error: errors });
    const errorMessage =
      Array.isArray(errors) && errors.length ? errors[0].message : undefined;
    const errorProps = multiple
      ? ExternalFileToastMessages.ADD_MULTIPLE_FILES_FAILED
      : ExternalFileToastMessages.ADD_FILE_FAILED;
    handlePickError(errorProps, errorMessage);
  };
};

export const getExternalFileDownloadUrlProps = async (
  remoteFileMetadata: RemoteFileMetadata,
): Promise<DownloadUrlResponse> => {
  const response = await integrationsApiClient.get(
    remoteFileMetadata.downloadUrl,
  );

  const parsedDownloadUrlResponse = downloadUrlResponseSchema.parse(
    response.data,
  );
  return parsedDownloadUrlResponse;
};

import { format } from 'date-fns';
import { format as formatTZ, toDate, utcToZonedTime } from 'date-fns-tz';

const getSignedOffset = (offsetMinutes: number) => {
  // positive difference to UTC time is reported as "UTC -0[offset in hours]00" and vice-versa
  const sign = offsetMinutes > 0 ? '-' : '+';

  const offsetHours = Math.abs(offsetMinutes / 60);

  const formattedHour = String(offsetHours).padStart(2, '0').padEnd(4, '0');

  return `${sign}${formattedHour}`;
};

export const getUTCDate = (date = new Date()) => {
  const formattedDate = format(date, 'yyyy-MM-dd HH:mm:ss');
  const offset = date.getTimezoneOffset();
  if (offset === 0) {
    return `${formattedDate} (UTC)`;
  } else {
    return `${formattedDate} (UTC ${getSignedOffset(offset)})`;
  }
};

export const formatDate = (date: Date) => {
  return `${date.toLocaleDateString()} ${date.toLocaleString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
  })}`;
};

export const formatDateFromTimestamp = (
  timeStamp: string | number,
  tz: string | undefined,
) => {
  const timeZone = tz ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
  const parsedDate = toDate(timeStamp);
  const timeZoneDate = utcToZonedTime(parsedDate, timeZone);
  return formatTZ(timeZoneDate, 'MMM d u h:mm a', { timeZone });
};

export const formatDateFromTimestampInSecondsFromEpoch = (
  timeStamp: string | number,
  tz: string | undefined,
) => {
  return formatDateFromTimestamp((timeStamp as number) * 1000, tz);
};

export const convertEpochToDate = (epochTime: number): Date =>
  new Date(epochTime * 1000);

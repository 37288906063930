import { User } from '@ckeditor/ckeditor5-collaboration-core';
import Suggestion from '@ckeditor/ckeditor5-track-changes/src/suggestion';
import { SuggestionThreadConfig } from '@ckeditor/ckeditor5-track-changes/src/ui/view/basesuggestionthreadview';
import SuggestionThreadView from '@ckeditor/ckeditor5-track-changes/src/ui/view/suggestionthreadview';
import { Locale } from '@ckeditor/ckeditor5-utils';
import { DocumentStatus, QualioDocument } from '../../api/model/document';

export default class QualioSuggestionThreadView extends SuggestionThreadView {
  static qualioDocument: QualioDocument;
  constructor(
    locale: Locale,
    model: Suggestion,
    localUser: User,
    config: SuggestionThreadConfig,
  ) {
    super(locale, model, localUser, config);

    const template = this.getTemplate() as any;
    template.children[2].template.children[1].cancelButtonView.set({
      label: 'Cancel',
      withText: true,
      icon: null,
    });
    template.children[2].template.children[1].submitButtonView.set({
      label: 'Reply',
      withText: true,
      icon: null,
    });
    handleDisplayingSuggestButtons(localUser, template, model);
    super.setTemplate(template);
  }
}

export const qualioSuggestionThreadBuilder = (
  qualioDocument: QualioDocument,
) => {
  QualioSuggestionThreadView.qualioDocument = qualioDocument;
  return QualioSuggestionThreadView;
};

export const handleDisplayingSuggestButtons = (
  localUser: User,
  template: any,
  suggestion: Suggestion,
) => {
  if (!shouldDisplayApplyButton(localUser)) {
    template.children[0].children[1].children[1].children[0].isVisible = false;
  }
  if (!shouldDisplayDeclineButton(localUser, suggestion)) {
    template.children[0].children[1].children[1].children[1].isVisible = false;
  }
};

const shouldDisplayApplyButton = (localUser: User) => {
  return (
    (isDocumentOwner(localUser, QualioSuggestionThreadView.qualioDocument) ||
      isDocumentEditor(localUser, QualioSuggestionThreadView.qualioDocument)) &&
    QualioSuggestionThreadView.qualioDocument.status_id === DocumentStatus.Draft
  );
};

const shouldDisplayDeclineButton = (
  localUser: User,
  suggestion: Suggestion,
) => {
  return (
    isDocumentOwner(localUser, QualioSuggestionThreadView.qualioDocument) ||
    isDocumentEditor(localUser, QualioSuggestionThreadView.qualioDocument) ||
    localUser.id === suggestion.author.id
  );
};

const isDocumentOwner = (localUser: User, doc: QualioDocument): boolean => {
  const { id: currentUserEmail } = localUser;
  return doc.owner.email === currentUserEmail;
};

const isDocumentEditor = (localUser: User, doc: QualioDocument): boolean => {
  const { id: currentUserEmail } = localUser;
  const { audit_groups: { editor_group = [] } = {} } =
    QualioSuggestionThreadView.qualioDocument;

  return editor_group.some(({ email }) => email === currentUserEmail);
};

import { ExportPdfConverterOptions } from '@ckeditor/ckeditor5-export-pdf/src/exportpdf';
import { saveAs } from 'file-saver';
import { pdfApiClient } from '../api';
import { documentApi } from './document';
import { tokenApi } from './token';

export const pdfApi = {
  async exportPDF(
    payload: PDFApiPayload,
    filename: string,
    errorHandler: (msg?: string) => void,
    companyId: number,
    documentId: number,
    shouldAuditExport = false,
  ): Promise<void> {
    const tokenResult = await tokenApi.fetch();

    return pdfApiClient
      .post('/', payload, {
        headers: {
          Authorization: tokenResult,
          'x-cs-app-id': 'cke5',
        },
        withCredentials: false,
        responseType: 'blob',
      })
      .then((res) => {
        const file = new Blob([res.data], { type: 'application/pdf' });
        if (shouldAuditExport) {
          documentApi
            .completeControlledExport(companyId, documentId)
            .then(() => {
              saveAs(file, filename);
            })
            .catch(() => {
              errorHandler();
            });
        } else {
          saveAs(file, filename);
        }
      })
      .catch((error: any) => {
        const msg =
          error.response?.status === 413
            ? 'Document too large to export'
            : 'There was an error exporting the document';
        errorHandler(msg);
      });
  },
};

interface PDFApiPayload {
  html: string;
  options: ExportPdfConverterOptions;
}

import { QButton, useCurrentUser } from '@qualio/ui-components';
import { useContext, useMemo, useState } from 'react';
import { DocumentOverviewContext } from '../../Context';
import { shouldShowEditPropertiesButton } from '../../RenderLogic';
import { EditDocumentPropertiesModal } from '../EditDocumentPropertiesModal';

export const EditPropertiesButton = () => {
  const currentUser = useCurrentUser();
  const { qualioDocument } = useContext(DocumentOverviewContext);
  const canEditProperties = useMemo(
    () => shouldShowEditPropertiesButton(qualioDocument, currentUser),
    [currentUser, qualioDocument],
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!canEditProperties) {
    return null;
  }

  return (
    <>
      <QButton
        data-cy="document-edit-properties-button"
        variant="ghost"
        onClick={() => setIsModalOpen(true)}
      >
        Edit
      </QButton>
      <EditDocumentPropertiesModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import * as jose from 'jose';
import { CKEJWTPayload, EditorPermission } from '../types/CKEJWTPayload';

export const configureAxiosMocks = (): void => {
  if (
    process.env.NODE_ENV === 'development' &&
    (process.env.REACT_APP_ENV === 'development' ||
      process.env.REACT_APP_RUNNING_SERVICES === 'local')
  ) {
    const mock = new MockAdapter(axios, { delayResponse: 500 });
    const downloadUrlRegex = new RegExp(
      `${process.env.REACT_APP_INTEGRATION_API_URL}/company/[0-9]+/source/onedrive/[^/]+/downloadWithoutRedirect`,
    );
    const changeManagementUrlRegex = new RegExp(
      `${process.env.REACT_APP_MEDTECH_API_URL}/v2/[0-9]+/change-management/change-request-item/type/document/id/[^/]+/reference/[0-9]+/change-request`,
    );
    const changeManagementIdUrlRegex = new RegExp(
      `${process.env.REACT_APP_MEDTECH_API_URL}/v2/[0-9]+/change-management/change-requests/[0-9]`,
    );
    const attachmentTokenUrlRegex = new RegExp(
      `${process.env.REACT_APP_MEDTECH_API_URL}/[0-9]+/attachment-token`,
    );
    // mock cke token request
    mock.onGet('https://token.cke.dev.qualio.com/').reply(async (config) => {
      const commentsPermissions = config.params.get('comments');
      const mockJwtToken = await createMockTokenServiceJWT(
        {
          email: 'testuser1@example.com',
          fullName: 'José Bermúdez',
        },
        parseInt(commentsPermissions),
      );
      // return an array in the form of [status, data, headers]
      return [200, mockJwtToken];
    });

    mock
      .onGet(`${process.env.REACT_APP_INTEGRATION_API_URL}/company/1/source`)
      .reply(() => {
        return [
          200,
          {
            configured: [
              {
                company: 1,
                source: 'azureDevops',
                enabled: true,
              },
              {
                company: 1,
                source: 'jira',
                enabled: true,
              },
              {
                company: 1,
                source: 'onedrive',
                enabled: true,
              },
              {
                company: 1,
                source: 'salesforce',
                enabled: true,
              },
              {
                company: 1,
                source: 'testrail',
                enabled: true,
              },
              {
                company: 1,
                source: 'xrayCloud',
                enabled: true,
              },
            ],
            available: [
              {
                company: 1,
                source: 'asana',
                enabled: false,
              },
              {
                company: 1,
                source: 'complianceWire',
                enabled: false,
              },
              {
                company: 1,
                source: 'xray',
                enabled: false,
              },
              {
                company: 1,
                source: 'zephyrScale',
                enabled: false,
              },
            ],
          },
        ];
      });

    mock
      .onGet(
        `${process.env.REACT_APP_INTEGRATION_API_URL}/company/1/oauth/onedrive/accessToken`,
      )
      .reply(() => {
        return [
          200,
          {
            data: {
              // If you actually need to open the picker
              // you can copy a real access token from staging
              // the window will open, but the sharepoint won't load
              access_token: 'fake-token',
              tenant: 'qualio-my',
            },
          },
        ];
      });

    mock
      .onGet(
        `${process.env.REACT_APP_INTEGRATION_API_URL}/company/1/oauth/onedrive-business/accessToken`,
      )
      .reply(() => {
        return [
          200,
          {
            data: {
              // If you actually need to open the picker
              // you can copy a real access token from staging
              // the window will open, but the sharepoint won't load
              access_token: 'fake-token',
              tenant: 'qualio-my',
            },
          },
        ];
      });

    mock.onGet(downloadUrlRegex).reply(() => {
      return [
        200,
        // you'll need to change this to use an actual presigned url from staging
        {
          location: 'https://www.testpresignedurl.com',
          lastModified: '2023-12-19T13:33:27Z',
        },
      ];
    });

    mock.onPost('https://ai.staging.qualio.com/change-control').reply(() => {
      return [
        200,
        {
          rationale: [
            'The existing change control lacks sufficient detail and documentation to effectively manage changes to the document. It should be improved to ensure changes are properly reviewed, approved, and communicated.',
            'The change control should clearly describe the specific changes made to the document, the reasons for the changes, and the potential impact on the document and associated processes.',
            'Attachments related to the change should be included in the change control to provide a comprehensive record of the change.',
            'The change control should be structured in a way that aligns with the requirements of the ISO 9001 standard for document control, ensuring traceability and accountability.',
          ],
          changeControl: [
            {
              title: 'Description of change',
              content: `<p>The changes made to the document include the addition of a new table in the 'DEFAULT' section, as well as the insertion of text within the table cells. Additionally, a new image and a new attachment have been added to the document.</p>
                <ol><li>Hello</li><li>world</li></ol>`,
            },
            {
              title: 'Reason for change',
              content:
                '<p>The changes were made to enhance the content and visual representation of the information in the document. The new table and image are intended to improve the clarity and understanding of the information presented.</p>',
            },
            {
              title: 'Impact assessment',
              content:
                '<p>The changes to the document are not expected to have a significant impact on the overall content or structure of the document. However, the new attachments and visual elements may require updates to related processes and procedures to ensure proper version control and access.</p>',
            },
            {
              title: 'Attachment',
              content:
                '<p>The changes to the document include the addition of the following attachments:</p><ul><li>attachment.docx</li><li>Chris.jpg</li></ul>',
            },
          ],
        },
      ];
    });
    mock
      .onPost('https://ai.staging.qualio.com/training-assessment')
      .reply(() => {
        return [
          200,
          {
            rationale: [
              "The document covers key sections related to medical device and drug manufacturing, including a table section and a non-table section. It is important to assess the employee's understanding of the content and structure of these sections, as well as their ability to interpret and apply the information.",
              'The table section contains edited content, which is critical for the employee to understand in order to ensure accurate and up-to-date information is being used in the manufacturing process.',
              "The non-table section includes a list, which is a common way to present work instructions or procedures. Evaluating the employee's understanding of this format is important to ensure they can effectively follow the necessary steps.",
            ],
            questions: [
              {
                question:
                  "Which of the following is a correct statement about the 'Table Section That has been edited' in the document?",
                answers: [
                  {
                    answer:
                      'The table has been modified to include additional rows and columns.',
                    is_correct: true,
                  },
                  {
                    answer:
                      'The table has not been edited and remains unchanged.',
                    is_correct: false,
                  },
                  {
                    answer: 'The table has been removed from the document.',
                    is_correct: false,
                  },
                  {
                    answer:
                      'The table has been replaced with a different type of content.',
                    is_correct: false,
                  },
                ],
              },
              {
                question:
                  "What is the purpose of the list in the 'Non Table Section' of the document?",
                answers: [
                  {
                    answer:
                      'To provide a set of work instructions or procedures.',
                    is_correct: true,
                  },
                  {
                    answer: 'To display a list of unrelated items.',
                    is_correct: false,
                  },
                  {
                    answer: 'To introduce a new section of the document.',
                    is_correct: false,
                  },
                  {
                    answer: 'To summarize the content of the table section.',
                    is_correct: false,
                  },
                ],
              },
              {
                question:
                  "Which of the following is a key feature of the 'New Section' table in the document?",
                answers: [
                  {
                    answer: 'The table includes a row with merged cells.',
                    is_correct: true,
                  },
                  {
                    answer:
                      'The table has a fixed width and does not adjust to the content.',
                    is_correct: false,
                  },
                  {
                    answer: 'The table only contains a single column.',
                    is_correct: false,
                  },
                  {
                    answer: 'The table does not have any content in its cells.',
                    is_correct: false,
                  },
                ],
              },
            ],
          },
        ];
      });
    mock.onGet(changeManagementUrlRegex).reply(() => {
      return [
        200,
        {
          id: 1,
          created_at: Date.now(),
          closed_at: null,
          is_deleted: false,
          owner: {
            usercompany_id: 1,
            full_name: 'José Bermúdez',
            id: 1,
          },
          status: 'in_progress',
          title: 'test',
          company_id: 1,
          last_modified_at: Date.now(),
          code_prefix: 'CCR',
          code_number: 1,
          code: 'CCR-1',
          transition_items_flag: true,
        },
      ];
    });
    mock.onGet(changeManagementIdUrlRegex).reply(() => {
      return [
        200,
        {
          id: 1,
          created_at: Date.now(),
          closed_at: null,
          is_deleted: false,
          owner: {
            usercompany_id: 1,
            full_name: 'José Bermúdez',
            id: 1,
          },
          sections: [
            {
              id: 1,
              position: 1,
              title: 'The first section',
              content:
                "<p>The changes made to the document include the addition of a new table in the 'DEFAULT' section, as well as the insertion of text within the table cells. Additionally, a new image and a new attachment have been added to the document.</p>\n                <ol><li>Hello</li><li>world</li></ol>",
            },
            {
              id: 2,
              position: 2,
              title: 'The second section',
              content:
                "<p>The changes made to the document include the addition of a new table in the 'DEFAULT' section, as well as the insertion of text within the table cells. Additionally, a new image and a new attachment have been added to the document.</p>\n                <ol multilevel='multilevel'><li>Hello</li><li>world</li></ol>",
            },
          ],
          status: 'in_progress',
          title: 'test',
          company_id: 1,
          last_modified_at: Date.now(),
          code_prefix: 'CCR',
          code_number: 1,
          code: 'CCR-1',
          transition_items_flag: true,
        },
      ];
    });
    mock.onGet(attachmentTokenUrlRegex).reply(() => {
      return [200, 'actual_token_from_staging'];
    });
    // Let everything else through
    mock.onAny().passThrough();
  }
};

const createJWTPayload = (
  currentUser: {
    email: string;
    fullName: string;
  },
  commentsPermissions: number,
): CKEJWTPayload => {
  const permissions: EditorPermission[] = ['document:read', 'document:write'];
  if (commentsPermissions >= 1) {
    permissions.push('comment:read');
  }
  if (commentsPermissions >= 2) {
    permissions.push('comment:write');
  }
  if (commentsPermissions >= 3) {
    permissions.push('comment:admin');
  }
  // return a JWT signed with same key the token service uses to sign JWTs, so that our on-prem CKE services will accept it
  return {
    aud: process.env.REACT_APP_CKE_COLLAB_ENV_ID ?? '',
    sub: currentUser.email,
    user: {
      email: currentUser.email,
      name: currentUser.fullName,
    },
    auth: {
      collaboration: {
        '*': {
          permissions,
        },
      },
    },
  };
};

const createSignedJWT = (payload: CKEJWTPayload): Promise<string> => {
  const signingKey = process.env.REACT_APP_TOKEN_SIGNING_KEY;
  if (!signingKey) {
    throw new Error(
      'required env variable REACT_APP_TOKEN_SIGNING_KEY is undefined',
    );
  }
  return new jose.SignJWT({ ...payload })
    .setProtectedHeader({ alg: 'HS256' })
    .setIssuedAt(Math.round(new Date().getTime() / 1000))
    .setExpirationTime('2h')
    .sign(new TextEncoder().encode(signingKey));
};

const createMockTokenServiceJWT = (
  currentUser: {
    email: string;
    fullName: string;
  },
  commentsPermissions: number,
): Promise<string> => {
  const payload = createJWTPayload(currentUser, commentsPermissions);
  return createSignedJWT(payload);
};

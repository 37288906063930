import { AxiosError } from 'axios';
import { documentApi } from '../../api/document';
import {
  createQualioDocumentPatchForSectionUpdate,
  QualioDocument,
} from '../../api/model/document';
import {
  DOCUMENT_REDUCER_SAVE_ERROR,
  logCustomError,
} from '../../messages/LogErrorMessages';
import {
  savingFailedCloudSectionVersion,
  savingFailedErrorMessage,
} from '../../messages/UserErrorMessages';
import { CKEditorInstance } from '../../types/CKEditorInstance';
import { addSectionToEditedSectionsList } from '../../util/ActivityLogUtils';
import { getDocumentEditorGlobalWindowObject } from '../../util/AppUtils';
import { CKEditorUtils } from '../../util/CKEditorUtils';
import { isCommentsOnlyMode } from '../../util/EditModeUtils';
import { getEditorVersionEnvironmentVariable } from '../../util/EnvUtils';

export const saveSectionFromEditor = async (
  document: QualioDocument,
  sectionEditor: CKEditorInstance,
  handleSaveError: (
    msg: string,
    sectionEditor?: CKEditorInstance,
    doc?: QualioDocument,
  ) => void,
  handleSaveSuccess: () => void,
) => {
  // only updates to smart link texts can make content in an for_approval document change. We do not need to patch this update to MTBE as every fetch of the doc from MTBE will update the smart link text on the fly anyways
  if (document.status_id !== 'draft' && document.status_id !== 'for_review') {
    return;
  }
  const sectionText = sectionEditor.getData();
  if (sectionText !== undefined) {
    const sectionIdToUpdate = sectionEditor.config.get('sectionId') as number;
    const cloudSectionVersion =
      CKEditorUtils.getCloudDocumentVersion(sectionEditor);
    const isCommentOrReadOnlyEditor =
      isCommentsOnlyMode(sectionEditor) || sectionEditor.isReadOnly;
    const trackChangesCommand = sectionEditor.commands.get('trackChanges');
    const isTrackChangesModeEnabled =
      trackChangesCommand && trackChangesCommand.value;
    let updateLastModifiedTimestamp = true;
    if (
      isCommentOrReadOnlyEditor ||
      isTrackChangesModeEnabled ||
      document.status_id !== 'draft'
    ) {
      updateLastModifiedTimestamp = false;
    }

    const updatedSection = document.sections.find(
      (section) => section.id === sectionIdToUpdate,
    );

    const shouldCreateSectionEditActivityLog =
      !isCommentOrReadOnlyEditor && !isTrackChangesModeEnabled
        ? addSectionToEditedSectionsList(sectionIdToUpdate, 'document')
        : false;

    const payload = createQualioDocumentPatchForSectionUpdate(
      document,
      updatedSection,
      sectionText,
      cloudSectionVersion,
      getEditorVersionEnvironmentVariable(),
      updateLastModifiedTimestamp,
    );

    try {
      if (!updateLastModifiedTimestamp) {
        await documentApi.update(
          payload,
          document.id,
          shouldCreateSectionEditActivityLog,
          false,
        );
      } else {
        await documentApi.update(
          payload,
          document.id,
          shouldCreateSectionEditActivityLog,
        );
        getDocumentEditorGlobalWindowObject().lastUpdated =
          new Date().toISOString();
      }

      handleSaveSuccess();
    } catch (error: any) {
      if (errorIsCloudDocumentVersionRejection(error)) {
        handleSaveError(
          savingFailedCloudSectionVersion(updatedSection?.position || ''),
        );
        return;
      }
      logCustomError(DOCUMENT_REDUCER_SAVE_ERROR, {
        error,
        documentId: document.id,
      });
      handleSaveError(
        error.response?.data?.message || savingFailedErrorMessage,
        sectionEditor,
        document,
      );
    }
  }
};

const errorIsCloudDocumentVersionRejection = (err: any) => {
  if (err instanceof AxiosError) {
    if (err.response?.status === 400) {
      const msg = err.response?.data?.cloud_section_version || '';
      if (/cloud_section_version \d+ out of date/.test(msg)) {
        return true;
      }
    }
  }
  return false;
};

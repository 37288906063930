import { QLookup, QModalHeader } from '@qualio/ui-components';
import { useCallback, useContext, useMemo } from 'react';
import { Group } from '../../../../../api/model/group';
import { Tag } from '../../../../../api/model/tag';
import { MedtechUserV2 } from '../../../../../api/user';
import { isBasicUsergroup } from '../../../../../util/CurrentUser';
import { QLookupModalComposedHeader } from '../../../QLookupModalComposedHeader';
import { DocumentOverviewContext } from '../../Context';
import {
  canUserAccessAtLeastOnePrivateTag,
  filterUserOptionLookup,
  getDocumentTagIdsToGroupIdsMap,
  getUserLookupView,
} from '../utils';

export type EditorsLookupProps = {
  tags: Tag[];
  isOpen: boolean;
  onClose: () => void;
  users: MedtechUserV2[];
  groups: Group[];
  isEditorPreselected: QLookup.MultiSelectProps<MedtechUserV2>['isItemPreSelected'];
  updateEditors: QLookup.MultiSelectProps<MedtechUserV2>['onSelect'];
};
export const EditorsLookup = ({
  tags,
  isOpen,
  onClose,
  users,
  groups,
  updateEditors,
  isEditorPreselected,
}: EditorsLookupProps) => {
  const { qualioDocument } = useContext(DocumentOverviewContext);

  const filterOptionCallback = useCallback(
    (user: MedtechUserV2, searchTerm: string | undefined) => {
      if (!searchTerm) {
        return true;
      }
      return filterUserOptionLookup(searchTerm, user, groups);
    },
    [groups],
  );

  const documentTagIdsToGroupIdsMap = useMemo(
    () => getDocumentTagIdsToGroupIdsMap(qualioDocument, tags),
    [qualioDocument, tags],
  );

  const usersWithDocumentAccess = useMemo(
    () =>
      users.filter((user) => {
        return (
          !!user.full_name &&
          (isEditorPreselected?.(user) ||
            (!isBasicUsergroup(user) &&
              (documentTagIdsToGroupIdsMap.size === 0 ||
                canUserAccessAtLeastOnePrivateTag(
                  user,
                  Array.from(documentTagIdsToGroupIdsMap.values()),
                ))))
        );
      }),
    [documentTagIdsToGroupIdsMap, isEditorPreselected, users],
  );

  const userDataView: QLookup.DataView<MedtechUserV2> = useMemo(
    () => getUserLookupView(groups),
    [groups],
  );

  return (
    <QLookup.DataProvider.Fixed
      data={usersWithDocumentAccess}
      filterOption={filterOptionCallback}
    >
      <QLookup.MultiSelect<MedtechUserV2>
        isOpen={isOpen}
        action="Apply changes"
        searchPlaceholder="Filter by name, email or group name..."
        accessors={{
          id: 'id',
        }}
        isItemPreSelected={isEditorPreselected}
        onSelect={updateEditors}
        onCancel={onClose}
        view={userDataView}
        defaultSortBy={{
          id: 'full_name',
        }}
      >
        <QModalHeader>
          <QLookupModalComposedHeader
            title="Manage editors"
            subtitle="Select users who can make edits, comments and suggestions to the document."
          />
        </QModalHeader>
      </QLookup.MultiSelect>
    </QLookup.DataProvider.Fixed>
  );
};

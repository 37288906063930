import { useCallback, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import {
  CurrentUser,
  CurrentUserContextType,
  QToastProps,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';

import { documentApi } from '../api/document';
import { GroupUser, QualioDocument } from '../api/model/document';

import {
  isApprover,
  isEditor,
  isOwner,
  isQualityUserInAccount,
  isReviewer,
} from '../util/CurrentUser';
import { delayedRedirectToDashboard } from '../util/PageUtils';

type ChangeSubject =
  | 'owner'
  | 'status'
  | 'format'
  | 'template version'
  | 'editors'
  | 'approvers'
  | 'reviewers'
  | 'none';

export enum ChangeAction {
  Redirect = 'redirect',
  Reload = 'reload',
}

export const determineChangeAction = (
  document: QualioDocument,
  currentUser: CurrentUser,
): ChangeAction => {
  if (isQualityUserInAccount(currentUser)) {
    return ChangeAction.Reload;
  }
  if (isOwner(currentUser, document)) {
    return ChangeAction.Reload;
  }
  if (isEditor(currentUser, document)) {
    return ChangeAction.Reload;
  }
  if (document.status_id === 'effective') {
    return ChangeAction.Reload;
  }
  if (
    isReviewer(currentUser, document) &&
    document.status_id === 'for_review'
  ) {
    return ChangeAction.Reload;
  }
  if (
    isApprover(currentUser, document) &&
    document.status_id === 'for_approval'
  ) {
    return ChangeAction.Reload;
  } else {
    return ChangeAction.Redirect;
  }
};

const capitalise = (str: string) =>
  `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

const didGroupMembersChange = (
  oldGroup: GroupUser[],
  newGroup: GroupUser[],
) => {
  if (oldGroup.length !== newGroup.length) {
    return true;
  }

  const userIdSetOldGroup = new Set(
    oldGroup.map((groupUser) => groupUser.user_id),
  );

  for (const groupUserNewGroup of newGroup) {
    if (!userIdSetOldGroup.has(groupUserNewGroup.user_id)) {
      return true;
    }
    userIdSetOldGroup.delete(groupUserNewGroup.user_id);
  }

  return userIdSetOldGroup.size !== 0;
};

export type CollaborationSessionManagerProps = {
  showToast: (options: QToastProps) => void;
  currentUser: CurrentUserContextType;
  qualioDocument: QualioDocument;
};

export const useCollabServerConnectionManager = (
  qualioDocument: QualioDocument | undefined,
) => {
  const { showToast } = useToastProvider();
  const queryClient = useQueryClient();
  const currentUser = useCurrentUser();
  const [sessionDisconnected, setSessionDisconnected] = useState(false);

  const actOnSessionDisconnectionEvent = (fetchedDocument: QualioDocument) => {
    let changeSubject: ChangeSubject = 'none';
    if (!fetchedDocument) {
      return;
    }
    if (qualioDocument?.owner.id !== fetchedDocument.owner.id) {
      changeSubject = 'owner';
    } else if (qualioDocument?.status_id !== fetchedDocument.status_id) {
      changeSubject = 'status';
    } else if (
      qualioDocument?.document_format !== fetchedDocument.document_format
    ) {
      changeSubject = 'format';
    } else if (qualioDocument?.template_id !== fetchedDocument.template_id) {
      changeSubject = 'template version';
    } else if (
      didGroupMembersChange(
        qualioDocument?.audit_groups?.editor_group ?? [],
        fetchedDocument.audit_groups?.editor_group ?? [],
      )
    ) {
      changeSubject = 'editors';
    } else if (
      didGroupMembersChange(
        qualioDocument?.audit_groups?.approver_group ?? [],
        fetchedDocument.audit_groups?.approver_group ?? [],
      )
    ) {
      changeSubject = 'approvers';
    } else if (
      didGroupMembersChange(
        qualioDocument?.audit_groups?.review_group ?? [],
        fetchedDocument.audit_groups?.review_group ?? [],
      )
    ) {
      changeSubject = 'reviewers';
    }
    if (changeSubject !== 'none') {
      doDocumentChangeAction(fetchedDocument, currentUser, changeSubject);
    }
  };

  const doDocumentChangeAction = (
    newDocument: QualioDocument,
    currentUser: CurrentUser,
    changeSubject: ChangeSubject,
  ) => {
    const changeAction = determineChangeAction(newDocument, currentUser);
    if (changeAction === ChangeAction.Reload) {
      showToast({
        title: `${capitalise(changeSubject)} changed`,
        description: `The ${changeSubject} of this document changed. This page will reload to show the updated state.`,
        duration: 10000,
        status: 'warning',
        id: `warning-status-toast-1`,
        replace: true,
      });
      setTimeout(() => {
        queryClient
          .invalidateQueries('collabToken')
          .catch((error) => console.log(error));
        queryClient
          .invalidateQueries('newDocument')
          .catch((error) => console.log(error));
      }, 5000);
    } else if (changeAction === ChangeAction.Redirect) {
      showToast({
        title: `${capitalise(changeSubject)} changed`,
        description: `The ${changeSubject} of this document has changed. You will be redirected to the dashboard as you do not have permissions to access the latest version of this document.`,
        duration: 10000,
        status: 'warning',
        id: `warning-status-toast-1`,
        replace: true,
      });
      delayedRedirectToDashboard();
    }
  };

  const getDocument = async () => {
    const id = qualioDocument?.id.toString() ?? '';
    return await documentApi.fetch(id);
  };

  useQuery({
    queryKey: ['newDocument'],
    queryFn: getDocument,
    enabled: Boolean(sessionDisconnected && qualioDocument),
    onSuccess: (fetchedDocument) =>
      actOnSessionDisconnectionEvent(fetchedDocument),
  });

  const handleSessionDisconnectionEvent = useCallback(() => {
    setSessionDisconnected(true);
  }, [setSessionDisconnected]);

  return { handleSessionDisconnectionEvent };
};

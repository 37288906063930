import { Context, ContextPlugin } from '@ckeditor/ckeditor5-core';
import { CKEditorInstance } from '../types/CKEditorInstance';
import { CKEditorUtils } from './CKEditorUtils';

const SIDEBAR_WIDTH_BREAKPOINT = 1200;
const ZOOM_RESIZE_CLASS = 'zoom-resize';

interface RefreshDisplayModeArgs {
  instances?: CKEditorInstance[] | Context[];
  windowWidth?: number;
  forceNarrow?: boolean;
  breakpointOverride?: number;
}

export const refreshDisplayMode = ({
  instances,
  windowWidth,
  forceNarrow,
  breakpointOverride,
}: RefreshDisplayModeArgs): void => {
  // ready handlers can only run after the sidebar is set
  const sidebarElementDocumentTab = document.querySelector('#commentsSidebar');
  const sidebarElementCCTab = document.querySelector('#changeControlSidebar');
  const sidebarElements = [sidebarElementDocumentTab];
  if (sidebarElementCCTab) {
    sidebarElements.push(sidebarElementCCTab);
  }
  const fileWrapper = document.querySelector('#fileWrapper');
  const shouldBeNarrow =
    windowWidth &&
    windowWidth < (breakpointOverride ?? SIDEBAR_WIDTH_BREAKPOINT);

  if (shouldBeNarrow || forceNarrow) {
    sidebarElements.forEach((sidebar) => sidebar?.classList.add('narrow'));
    fileWrapper?.classList.add('narrow-sidebar');
    if (forceNarrow) {
      sidebarElementDocumentTab?.classList.add(ZOOM_RESIZE_CLASS);
      fileWrapper?.classList.add(ZOOM_RESIZE_CLASS);
    }
    instances?.forEach((instance) => {
      CKEditorUtils.withPlugin<AnnotationsUIsPlugin>(
        instance,
        'AnnotationsUIs',
        (annotationsUIs) => {
          if (annotationsUIs.isActive('wideSidebar')) {
            annotationsUIs.switchTo('narrowSidebar');
          }
        },
      );
    });
  }
  if (forceNarrow === false || shouldBeNarrow === false) {
    const hasZoomClass = fileWrapper?.classList.contains(ZOOM_RESIZE_CLASS);
    if (
      (shouldBeNarrow === false &&
        forceNarrow === undefined &&
        !hasZoomClass) ||
      (shouldBeNarrow === false && forceNarrow === false && hasZoomClass)
    ) {
      sidebarElementDocumentTab?.classList.remove('narrow');
      sidebarElements.forEach((sidebar) => {
        if (sidebar?.classList.contains('narrow')) {
          sidebar?.classList.remove('narrow');
        }
      });
      fileWrapper?.classList.remove('narrow-sidebar');

      instances?.forEach((instance) => {
        CKEditorUtils.withPlugin<AnnotationsUIsPlugin>(
          instance,
          'AnnotationsUIs',
          (annotationsUIs) => {
            if (annotationsUIs.isActive('narrowSidebar')) {
              annotationsUIs.switchTo('wideSidebar');
            }
          },
        );
      });
    }
    if (forceNarrow === false && hasZoomClass) {
      sidebarElementDocumentTab?.classList.remove(ZOOM_RESIZE_CLASS);
      fileWrapper?.classList.remove(ZOOM_RESIZE_CLASS);
    }
  }
};

export interface AnnotationsUIsPlugin extends ContextPlugin {
  isActive: (arg0: string) => boolean;
  switchTo: (arg0: string) => void;
}

import { QIcon, QStack } from '@qualio/ui-components';
import { QActivityText } from './QActivityText';

type QActivityTransitionProps = {
  oldValue: string | JSX.Element;
  newValue: string | JSX.Element;
};
export const QActivityTransition = ({
  oldValue,
  newValue,
}: QActivityTransitionProps) => {
  return (
    <QStack
      style={{ flexWrap: 'wrap', rowGap: '8px' }}
      spacing={0}
      direction="row"
      alignItems="center"
    >
      {typeof oldValue === 'string' ? (
        <QActivityText text={oldValue} weight="semibold" />
      ) : (
        <>{oldValue}</>
      )}
      <QStack paddingInline="8px">
        <QIcon iconName="ArrowRight" />
      </QStack>
      {typeof newValue === 'string' ? (
        <QActivityText text={newValue} weight="semibold" />
      ) : (
        <>{newValue}</>
      )}
    </QStack>
  );
};

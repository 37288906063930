import { PersonStatus, QPerson, QStack } from '@qualio/ui-components';
import { MedtechUserV2 } from '../../../../api/user';

export const QPersonList = ({
  users,
  status,
}: {
  users: MedtechUserV2[];
  status: PersonStatus;
}) => {
  return (
    <>
      {users.length
        ? users.map((user: MedtechUserV2) => {
            return (
              <QStack pr={4} key={user.id}>
                <QPerson fullName={user.full_name} status={status} />
              </QStack>
            );
          })
        : null}
    </>
  );
};

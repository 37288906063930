import { useContext, useState } from 'react';

import {
  QDivider,
  QLink,
  QStack,
  QText,
  useCurrentUser,
} from '@qualio/ui-components';

import { shouldRenderPeriodicReview } from '../../RenderLogic';

import { PeriodicReview as PeriodicReviewType } from '../../../../../api/model/periodicReview';
import { DocumentOverviewContext } from '../../Context';
import { PeriodicReviewDetailsPanel } from './PeriodicReviewDetailsPanel';

export const PeriodicReview = ({
  periodicReview,
}: {
  periodicReview?: PeriodicReviewType;
}) => {
  const currentUser = useCurrentUser();
  const { qualioDocument } = useContext(DocumentOverviewContext);
  const [sidePanelOpen, setSidePanelOpen] = useState(false);

  if (
    !shouldRenderPeriodicReview(currentUser, qualioDocument, periodicReview)
  ) {
    return null;
  }

  return (
    <>
      <QStack spacing="18px">
        <QText fontWeight="semibold" fontSize="md">
          Periodic review
        </QText>
        <QStack isInline spacing={3}>
          <QLink
            data-cy="periodic-review-sidedrawer"
            onClick={() => setSidePanelOpen(true)}
          >
            View details
          </QLink>
        </QStack>
      </QStack>
      <QDivider mt="6" mb="6" />
      {sidePanelOpen && (
        <PeriodicReviewDetailsPanel
          periodicReviewId={periodicReview?.id}
          isOpen={sidePanelOpen}
          onClose={() => setSidePanelOpen(false)}
        />
      )}
    </>
  );
};

import { QBox, QText } from '@qualio/ui-components';

export type DetailCardLayoutProps = {
  children: any;
  title: string;
};

export const DetailCardLayout = ({
  children,
  title,
}: DetailCardLayoutProps) => {
  return (
    <QBox
      borderColor="gray.200"
      borderWidth="1px"
      borderTopRadius="4px"
      borderBottomRadius="4px"
    >
      <QBox
        px={4}
        py={2}
        bgColor="gray.50"
        borderBottom="1px"
        borderColor="gray.200"
        borderTopRadius="4px"
      >
        <QText weight="semibold" fontSize="sm">
          {title}
        </QText>
      </QBox>
      <QBox px={4} py={4}>
        {children}
      </QBox>
    </QBox>
  );
};

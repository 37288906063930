import { getEditorVersionEnvironmentVariable } from '../util/EnvUtils';
import { isNumber } from '../util/FileUploadUtils';
import { documentApi } from './document';
import {
  createQualioDocumentPatchForSectionUpdate,
  QualioDocument,
  QualioDocumentAttachment,
} from './model/document';

export const qualioFileDocumentApi = {
  async setAttachmentAsDocumentContent(
    attachmentId: number | string,
    document: QualioDocument,
  ) {
    const sectionText = String(attachmentId);
    const payload = createQualioDocumentPatchForSectionUpdate(
      document,
      document.sections[0],
      sectionText,
      document.sections[0].cloud_section_version,
      getEditorVersionEnvironmentVariable(),
      false,
    );

    await documentApi.update(payload, document.id);
  },

  async removeAttachmentAsDocumentContent(document: QualioDocument) {
    const sectionText = '';
    const payload = createQualioDocumentPatchForSectionUpdate(
      document,
      document.sections[0],
      sectionText,
      document.sections[0].cloud_section_version,
      getEditorVersionEnvironmentVariable(),
      false,
    );

    await documentApi.update(payload, document.id);
  },

  parseAttachmentIdsFromQualioFileDocument(document: QualioDocument): string[] {
    if (!document.sections || document.sections.length === 0) {
      return [];
    }
    const section = document.sections[0];
    if (section.content?.length > 0 && isNumber(section.content)) {
      return [section.content];
    }
    return [];
  },

  fetchAttachmentInfoFromQualioFileDocument(
    document: QualioDocument,
  ): Promise<QualioDocumentAttachment[]> {
    const attachmentIds =
      this.parseAttachmentIdsFromQualioFileDocument(document);
    return Promise.all(
      attachmentIds.map((attachmentId) =>
        documentApi
          .fetchAttachmentInfo(attachmentId)
          .then((attachmentInfo) => ({
            ...attachmentInfo,
            id: String(attachmentInfo.id),
            filename: attachmentInfo.filename,
            contentType: 'pdf',
            url: '',
            preview_url: '',
            preview_state:
              attachmentInfo.preview_state as QualioDocumentAttachment['preview_state'],
            preview_type:
              attachmentInfo.preview_type as QualioDocumentAttachment['preview_type'],
          })),
      ),
    );
  },
};
